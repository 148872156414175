<script lang="ts">
  import { agreeableStateStore } from '@dabble/data/agreeable';
  import { t } from '@dabble/data/intl';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiDelete } from '@mdi/js';
  import { beyondGrammar } from '../grammar-api';
  import { DictionaryEntry } from '../grammar-types';
  import ConfirmDelete from './ConfirmDelete.svelte';

  let confirmDelete: DictionaryEntry | null = null;
  let definitions: DictionaryEntry[] = [];

  $: if (agreeableStateStore.get().authed) loadDictionaryEntries();

  async function loadDictionaryEntries() {
    definitions = await beyondGrammar.getDictionaryEntries();
    if (definitions) {
      definitions.sort((a, b) => a.word.toLocaleLowerCase().localeCompare(b.word.toLocaleLowerCase()));
    }
  }

  async function onClearDefinition(id: string) {
    await beyondGrammar.removeFromDictionary(id);
    await loadDictionaryEntries();
  }

  function requestClearDefinition(definition: DictionaryEntry) {
    confirmDelete = definition;
  }

  async function onClearDictionary() {
    definitions.forEach(async definition => {
      await beyondGrammar.removeFromDictionary(definition.id);
    });
    await loadDictionaryEntries();
  }

  function requestClearDictionary() {
    confirmDelete = { id: 'all', word: '', replace: null };
  }

  async function onDelete() {
    if (!confirmDelete) return;
    if (confirmDelete.id === 'all') {
      await onClearDictionary();
    } else {
      await onClearDefinition(confirmDelete.id);
    }
    confirmDelete = null;
  }
</script>

<div class="section dictionary">
  <h2>
    {$t('account_screen_dictionary')}
  </h2>

  <!-- TODO FIXME Add PWA languages here since we took them away from Preferences UI language -->
  <!-- <select class="form-control" id="localLanguage" on:change={onLocaleChange}>
    <option value="" disabled>{$t('account_set_ui_language')}</option>
    {#each $locales as locale}
      <option selected={locale === currentLocale} value={locale}>{languageNames.of(locale)}</option>
    {/each}
  </select> -->

  {#if confirmDelete}
    <ConfirmDelete on:confirmed={onDelete} on:close={() => (confirmDelete = null)}>
      {confirmDelete.word === 'all'
        ? 'Remove all your dictionary entries?'
        : `Remove entry for "${confirmDelete.word}"`}
    </ConfirmDelete>
  {/if}
  <div class="delete-header">{$t('delete_dictionary_entries')}</div>
  <div class="definition-list">
    {#if definitions && definitions.length}
      {#each definitions as definition}
        <button class="definition-entry" on:click={() => requestClearDefinition(definition)}>
          <div class="definition-entry-icon">
            <Icon path={mdiDelete} />
          </div>
          {definition.word}
        </button>
      {/each}
    {/if}
  </div>
  <footer>
    <button class="btn primary" on:click={() => requestClearDictionary()}>{$t('delete_all_dictionary_entries')}</button>
  </footer>
</div>

<style>
  .dictionary {
    margin: 0;
  }

  .delete-header {
    margin-top: 0.8rem;
    height: 2rem;
  }

  .definition-list {
    width: 100%;
    height: 20rem;
    border: 1px solid #9f9f9f;
    border-radius: 5px;
    overflow: auto;
    margin-bottom: 1rem;
  }

  .definition-entry {
    display: flex;
    height: 2rem;
    width: 100%;
    padding: 0 1rem;
    text-align: left;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  .definition-entry:hover {
    color: var(--text-color-normal);
    background-color: var(--side-nav-color-light);
    text-decoration: none;
    box-shadow: inset 0 0 0 1px var(--side-nav-color-mid);
  }
  .definition-entry-icon {
    margin-top: 4px;
    margin-right: 8px;
  }
</style>
