<script lang="ts">
  import { readonlyStore as globalReadonlyStore } from '@dabble/data/app-state';
  import { sizeStore } from '@dabble/data/device';
  import { Editable } from '@dabble/data/editables';
  import { t } from '@dabble/data/intl';
  import { projectStore } from '@dabble/data/project-data';
  import { Doc } from '@dabble/data/types';
  import { confirm, shownCardStore } from '@dabble/data/ui';
  import { getTitle, isDocEmpty } from '@dabble/data/util';
  import CardMenu from '@dabble/toolkit/CardMenu.svelte';
  import EditableContent from '@dabble/toolkit/EditableContent.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import Title from '@dabble/toolkit/Title.svelte';
  import { addDocToDrag } from '@dabble/toolkit/drag-drop-utils';
  import { draggable as draggableAction } from '@dabble/toolkit/draggable';
  import { tap } from '@dabble/toolkit/tap';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import { mdiDelete, mdiDotsVertical } from '@mdi/js';
  import { Unsubscriber } from 'easy-signal';
  import { createEventDispatcher, onMount, tick } from 'svelte';
  import { EditorRange, TypesetTypes } from 'typewriter-editor';
  import CardRibbon from './CardRibbon.svelte';
  import Dropdown from './Dropdown.svelte';

  export let doc: Doc;
  export let highlightable = false;
  export let compact = false;
  export let small = false;
  export let medium = false;
  export let autoselect = false;
  export let showDelete = false;
  export let events = ''; // Defines events which will receive custom handling
  export let draggable = false;
  export let readonly = false;
  export let titlePlaceholder = null;
  export let descriptionPlaceholder = null;

  const dispatch = createEventDispatcher();
  const typeset: TypesetTypes = {
    lines: ['paragraph', 'list'],
    formats: ['link', 'bold', 'italic'],
    embeds: [],
  };

  let titleEditable: Editable;
  let descEditable: Editable;
  let lastSelection: Range;

  $: isReadonly = $globalReadonlyStore || readonly;
  $: dragOptions = draggable &&
    !isReadonly && {
      start(event: DragEvent) {
        addDocToDrag(event.dataTransfer, doc);
      },
    };
  $: menuOpen = false;
  $: editingRibbon = false;

  if (autoselect) {
    onMount(async () => {
      await tick();
      if (!doc) return;
      const end: EditorRange = [Number.POSITIVE_INFINITY, Number.POSITIVE_INFINITY];
      if (!doc.title && titleEditable) titleEditable.editor.select(end);
      else if (descEditable) descEditable.editor.select(end);
    });
  }

  function openCard(event: Event) {
    if (!(small || medium) || (event.target as Element).closest('.trash')) return;
    if (events.includes('click')) {
      dispatch('click');
    } else {
      const selection = document.getSelection();
      lastSelection = selection.rangeCount && selection.getRangeAt(0);

      dispatch('open');
      $shownCardStore = { docId: doc.id, target: this, readonly: isReadonly };

      let unsub: Unsubscriber,
        processed = false;
      unsub = shownCardStore.subscribe(value => {
        if (value) return;
        if (unsub) unsub();
        else processed = true;
        dispatch('close');
        if (lastSelection) {
          selection.removeAllRanges();
          selection.addRange(lastSelection);
          lastSelection = null;
        }
      });
      if (processed) unsub();
    }
  }

  async function deleteDoc() {
    if (events.includes('delete')) {
      dispatch('delete');
    } else {
      dispatch('delete');
      const title = getTitle(doc);
      if (isDocEmpty(doc) || (await confirm($t('send_to_trash'), $t('trash_confirm', { title })))) {
        projectStore.trashDoc(doc.id);
      }
    }
  }

  function editRibbon() {
    editingRibbon = true;
  }

  function doneEditingRibbon() {
    editingRibbon = false;
  }
</script>

{#if doc}
  <div
    class="notecard"
    data-id={doc.id}
    class:compact
    class:small
    class:medium
    class:highlightable
    class:has-label={doc.label}
    use:tap={['pointer']}
    on:tap={openCard}
    use:draggableAction={dragOptions}
  >
    {#if small || medium}
      <Title class="content" tag="div" {doc} />
    {:else}
      <EditableContent
        bind:editable={titleEditable}
        {doc}
        placeholder={titlePlaceholder}
        field="title"
        class="title"
        header="h4"
        {readonly}
        showTitleImages={false}
      />
      <EditableContent
        bind:editable={descEditable}
        {doc}
        {typeset}
        placeholder={descriptionPlaceholder}
        field="description"
        class="text"
        {readonly}
        showTitleImages={false}
      />
    {/if}
    {#if !isReadonly}
      <div class="actions" class:menu-open={menuOpen}>
        <button
          class="icon menu-opener"
          on:mousedown|stopPropagation
          on:pointerdown|stopPropagation
          use:tap
          on:tap={() => (menuOpen = true)}
          use:tooltipTop={$sizeStore === 'desktop' && $t('nav_item_actions', { type: doc.type })}
        >
          <Icon path={mdiDotsVertical} />
        </button>
        {#if menuOpen}
          <Dropdown placement="bottom-start" arrow on:close={() => (menuOpen = false)}>
            <CardMenu {doc} on:editRibbon={editRibbon} />
            {#if showDelete}
              <hr />
              <button class="dropdown-item delete-button" on:click={deleteDoc}>
                <Icon path={mdiDelete} />
                {$t('send_to_trash')}
              </button>
            {/if}
          </Dropdown>
        {/if}
      </div>
    {/if}
    {#if doc.label}
      <CardRibbon
        {doc}
        color={doc.label.color}
        text={doc.label.text}
        editing={editingRibbon}
        on:done={doneEditingRibbon}
        on:editing={editRibbon}
      />
    {/if}
  </div>
{/if}

<style>
  :root {
    --card-width: 480px; /* 5 inches */
    --card-height: 288px; /* 3 inches */
    --card-width-md: 240px;
    --card-height-md: 144px;
    --card-width-sm: 160px;
    --card-height-sm: 96px;
    --line-height: 24px;
    --line-height-1: 25px;
  }
  .notecard {
    display: flex;
    flex-direction: column;
    max-width: var(--card-width);
    min-height: var(--card-height);
    position: relative;
    font-family: var(--font-family-text);
    font-size: 14px;
    color: var(--text-color-normal);
    background: var(--page-background);
    border-radius: 2px;
    box-sizing: border-box;
    border: none;
    padding: 0;
    box-shadow: 0 0 6px 1px var(--card-shadow-color);
    contain: layout;
  }
  .notecard :global(.content) {
    max-height: 100%;
    overflow: hidden;
  }
  .notecard.medium {
    width: var(--card-width-md);
    height: var(--card-height-md);
    min-height: 0;
  }
  .notecard.small {
    padding: 10px 16px;
    width: var(--card-width-sm);
    height: var(--card-height-sm);
    box-shadow: 0 1px 2px 1px var(--card-shadow-color);
    min-height: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    user-select: none;
    cursor: pointer;
    contain: layout size;
  }
  .notecard.compact {
    --line-height: 20px;
    --line-height-1: 21px;
    box-shadow: 0 1px 2px 1px var(--card-shadow-color);
  }
  .notecard.highlightable.small:hover {
    position: relative;
    transform: translate(0, -1px);
    box-shadow: 1px 4px 10px 1px var(--card-shadow-color);
  }
  .notecard :global(ul),
  .notecard :global(ol) {
    margin: 0;
  }
  .notecard :global(.title) {
    flex: 0 0 auto;
    border-bottom: 1px solid var(--red-line);
  }
  .notecard :global(.title) {
    padding: 6px 14px 0;
  }
  .notecard :global(.title h4) {
    font-family: var(--font-family-text);
    color: var(--text-color-normal);
    text-transform: none;
    margin: 0;
    font-weight: bold;
    line-height: var(--line-height);
    font-size: 16px;
  }
  .notecard :global(.text) {
    flex: 1 1 auto;
    padding: 2px 14px 4px;
    color: var(--text-color-normal);
    font-size: 15px;
    line-height: var(--line-height-1);
    background-image: repeating-linear-gradient(
      to bottom,
      transparent,
      transparent var(--line-height),
      var(--blue-line) var(--line-height),
      var(--blue-line) var(--line-height-1)
    );
    background-attachment: local;
  }
  .notecard.compact :global(.title) {
    padding: 4px 8px 0;
    overflow: hidden;
    white-space: nowrap;
  }
  .notecard.compact :global(.title h4) {
    font-size: 15px;
  }
  .notecard.compact :global(.text) {
    font-size: 14px;
    padding: 2px 8px 4px;
  }
  .notecard.has-label :global(.text) {
    padding-bottom: 28px;
  }
  .notecard :global(.text p) {
    margin: 0;
    line-height: inherit;
  }

  /* Action Menu styles */
  .notecard .actions {
    display: flex;
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 24px;
  }
  :global(.is-mouse) .notecard .actions {
    display: none;
    font-size: 20px;
  }
  :global(.is-mouse) .notecard:hover .actions,
  :global(.is-mouse) .notecard .actions.menu-open {
    display: flex;
  }

  /* Delete Button styles */
  .notecard .delete-button {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .notecard:hover .delete-button {
    display: block;
  }
  .notecard:active .delete-button {
    opacity: 0;
  }
  .notecard .delete-button:active {
    opacity: 1;
  }
  .has-label .delete-button {
    color: var(--white);
    text-shadow: var(--text-lowlight);
  }
  :global(.size-desktop) .has-label .delete-button:hover {
    color: var(--white);
  }
</style>
