import { featuresStore } from '@dabble/data/global-data';
import { plugins } from '@dabble/data/plugins';
import { settingsStore } from '@dabble/data/settings';
import { documentsStore } from '@dabble/data/ui';
import { preferencesStore } from '@dabble/data/user-data';
import { derived, observe, readable } from 'easy-signal';
import ThemePreferences from './components/ThemePreference.svelte';
import './dark.css';
import './locales';

type Theme = 'default' | 'dark' | 'auto';

settingsStore.configure('dabble', {
  plugins: {
    account: {
      preferences: {
        themes: ThemePreferences,
      },
    },
  },
});

export const themeStore = derived(() => {
  let theme: Theme = 'default';
  if (!preferencesStore.get().theme) {
    theme = preferencesStore.get().theme || localStorage.theme || 'default';
  } else if (featuresStore.get().dark_mode) {
    theme = (preferencesStore.get().theme || 'default') as Theme;
  }
  if (theme !== localStorage.theme) localStorage.theme = theme;
  return theme;
});

const prefersDarkSchemeStore = readable(false, set => {
  const media = matchMedia('(prefers-color-scheme: dark)');
  const update = () => set(media.matches);
  media.addEventListener('change', update);
  update();
  return () => media.removeEventListener('change', update);
});

export const uiThemeStore = derived(() => {
  const currentTheme = themeStore.get();
  return currentTheme === 'dark' || (currentTheme === 'auto' && prefersDarkSchemeStore) ? 'dark' : 'light';
});

plugins.register({ themeStore, uiThemeStore });

observe(() => {
  documentsStore.get().forEach(document => (document.documentElement.dataset.theme = uiThemeStore.get()));
});
