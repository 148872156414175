import { DabbleDatabase } from '@dabble/data/old-types';
import { rest } from '@dabble/data/rest';
import { DabbleAgreeableClient, EventMetadata, UserAttributes } from '@dabble/data/types';
import { ExtendAPI, Leader, cachedValues } from 'agreeable-client';
import { simplifiedConcurrency } from 'simplified-concurrency';

const NEW_SESSION_TIME = 1000 * 60 * 30; // 30 minutes

export function analyticsLeaderExtension(
  client: DabbleAgreeableClient,
  db: DabbleDatabase,
  extendApi: ExtendAPI,
  leader: Leader
) {
  // Record a session if the user has been offline for more than 30 minutes
  cachedValues.get('lastOnline').then(lastOnline => {
    if (!lastOnline || lastOnline < Date.now() - NEW_SESSION_TIME) {
      record('session');
    }
    cachedValues.set('lastOnline', client.now());
  });

  // Add API methods to the leader to be called by client.call('method', ...args)
  extendApi({ record, recordUserData });

  async function record(name: string, metadata?: EventMetadata, timestamp?: number) {
    if (client.state.get().authed) {
      await rest.post('/analytics/events').send({ name, properties: metadata, timestamp });
    } else if (db?.isOpen && db.stores.analytics) {
      await db.stores.analytics.put({ name, metadata, timestamp: client.now() });
    }
  }

  async function recordUserData(attributes: UserAttributes) {
    if (client.state.get().authed) {
      await rest.post('/analytics/properties').send(attributes);
    } else if (db?.isOpen && db.stores.analytics) {
      await storeUserData(attributes);
    }
  }

  async function syncAnalytics() {
    await db.stores.analytics.getAll().then(events => {
      events.map(async event => {
        if (event.name === 'user') {
          await recordUserData(event.metadata);
        } else {
          await record(event.name, event.metadata, event.timestamp);
        }
        await db.stores.analytics.delete(event.id);
      });
    });
  }

  // Because we load/store data we need to use blockable to ensure only one call is processed at a time
  const storeUserData = simplifiedConcurrency().blockable(async (attributes: UserAttributes) => {
    let userEvent = await db.stores.analytics.get(0);
    if (!userEvent) {
      userEvent = { id: 0, name: 'user', metadata: attributes, timestamp: client.now() };
    } else {
      userEvent.metadata = { ...userEvent.metadata, ...attributes };
    }
    await db.stores.analytics.put(userEvent);
  });

  return syncAnalytics;
}
