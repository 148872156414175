import { writable } from 'easy-signal';

export type Documents = Set<Document>;

export type DocumentStore = ReturnType<typeof createDocumentStore>;

/**
 * A store which holds all the current DOM Documents that are part of the application from iframes and the main window.
 * This allows us to interact with all the documents in the application.
 */
export function createDocumentStore() {
  let documents: Documents = new Set([document]);

  const { get, set, subscribe } = writable(documents);

  function add(document: Document) {
    documents = new Set(documents);
    documents.add(document);
    set(documents);
  }

  function remove(document: Document) {
    documents = new Set(documents);
    documents.delete(document);
    set(documents);
  }

  return {
    get,
    add,
    remove,
    subscribe,
  };
}
