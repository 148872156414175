import { getNow } from '@dabble/data/date';
import { plugins } from '@dabble/data/plugins';
import { projectStore } from '@dabble/data/project-data';
import { hasChanges } from '@dabble/data/project-patch';
import { locallyStoredWritable } from '@dabble/data/stores/locally-stored-writable';
import { ProjectStore } from '@dabble/data/stores/project';
import { Doc } from '@dabble/data/types';
import { viewport } from '@dabble/data/ui';
import { createId } from '@dabble/data/uuid';
import { getImmutableValue, makeChanges } from '@dabble/util/immutable';
import { derived, Readable, signal, Signal } from 'easy-signal';

const EMPTY: StickyNoteMap = {};
export const SIZE = 240;

export interface StickyNote extends CreateStickyNote {
  id: string;
  created: number;
  content: string;
}

export interface CreateStickyNote {
  docId: string;
  top: number;
  horizontal: number;
  color: string;
}

export interface StickyNoteMap {
  [id: string]: StickyNote;
}

export interface StickyNotesStore extends Readable<StickyNoteMap> {
  changing: Signal;
  createStickyNote(data: CreateStickyNote): Promise<string>;
  updateStickyNote(id: string, updates: Partial<StickyNote>): Promise<void>;
  deleteStickyNote(id: string): Promise<void>;
}

export const stickyNotesStore = createStickyNotesStores(projectStore, viewport.focusedDocId);
export const showStickyNotes = locallyStoredWritable('showStickyNotes', true);
plugins.register({ stickyNotes: stickyNotesStore, showStickyNotes });

export function getBoundedPosition(top: number, left: number, height: number, width: number) {
  const horizontal = Math.max(0, Math.min(1, left / (width - SIZE)));
  top = Math.round(Math.max(0, Math.min(height - SIZE, top)));
  return { top, horizontal };
}

// Holds all the stickyNotes for currently displayed editors and their position
export function createStickyNotesStores(projectStore: ProjectStore, docIdStore: Readable<string>): StickyNotesStore {
  const changing = signal();
  let stickyNotes: StickyNoteMap = EMPTY;
  let lastDocId: string;

  // This store is a map of all stickyNotes by id in the current doc and all of its decendents (including virtual docs)
  const { get, subscribe } = derived(() => {
    const docs = projectStore.get().docs;
    const docId = docIdStore.get();
    const doc = docs[docId];
    if (doc) {
      stickyNotes = doc.stickyNotes || EMPTY;
    }
    if (docId !== lastDocId) {
      changing();
      lastDocId = docId;
    }
    if (!docId) {
      return (stickyNotes = EMPTY);
    }
    const ids = new Set(Object.keys(stickyNotes));

    makeChanges(() => {
      addStickyNotes(docs[docId]);
      if (ids.size) {
        stickyNotes = getImmutableValue(stickyNotes);
        ids.forEach(id => delete stickyNotes[id]);
      }

      function addStickyNotes(doc: Doc) {
        if (!doc) return;
        if (doc.stickyNotes) {
          const fields = new Set<string>();
          Object.values(doc.stickyNotes as StickyNoteMap).forEach(stickyNote => {
            ids.delete(stickyNote.id);
            if (stickyNotes[stickyNote.id] !== stickyNote) {
              // Doing it this way will make stickyNotes not change if no stickyNotes were updated in the project
              stickyNotes = getImmutableValue(stickyNotes);
              stickyNotes[stickyNote.id] = stickyNote;
            }
          });
        }
        projectStore.get().childrenLookup[doc.id]?.forEach(addStickyNotes);
      }
    });

    return stickyNotes;
  });

  async function createStickyNote(data: CreateStickyNote) {
    const { docId } = data;
    const doc = projectStore.getDoc(docId);

    const patch = projectStore.patch();
    if (!doc.stickyNotes) {
      patch.patch.add(`/docs/${docId}/stickyNotes`, {});
    }
    const stickyNote: StickyNote = {
      ...data,
      id: createId(4, doc.stickyNotes, docId),
      created: getNow(),
      content: ''
    };
    patch.patch.add(`/docs/${docId}/stickyNotes/${stickyNote.id}`, stickyNote);
    await patch.save();
    return stickyNote.id;
  }

  async function updateStickyNote(id: string, updates: Partial<StickyNote>) {
    const { stickyNote, path } = findStickyNote(id);
    if (!stickyNote) return;
    if (!hasChanges(stickyNote, updates)) return;
    const patch = projectStore.patch();
    patch.patch.addUpdates(updates, path);
    await patch.save();
  }

  async function deleteStickyNote(id: string) {
    const { stickyNote, path } = findStickyNote(id);
    if (!stickyNote) return;
    const patch = projectStore.patch();
    patch.patch.remove(path);
    await patch.save();
  }

  function findStickyNote(id: string) {
    const stickyNote = stickyNotes[id];
    const docId = stickyNote?.docId;
    const path = `/docs/${docId}/stickyNotes/${id}`;
    return { stickyNote, path, docId };
  }

  return {
    changing,
    createStickyNote,
    updateStickyNote,
    deleteStickyNote,
    get,
    subscribe,
  };
}
