import { Readable, Unsubscriber, writable } from 'easy-signal';

export interface Plugins {
  [pluginStoreName: string]: any;
}

export interface Stores {
  [name: string]: Readable<any>;
}

export interface Unsubscribers {
  [name: string]: Unsubscriber;
}

export interface PluginsStore extends Readable<Plugins> {
  stores: Stores;
  register(stores: { [name: string]: Readable<any> }): void;
  unregister(stores: { [name: string]: Readable<any> }): void;
}

/**
 * A store that will update whenever data from a plugin store updates. A plugin may register their stores with this
 * store to allow other plugins to access that data.
 */
export function createPluginsStore(): PluginsStore {
  const unsubs: Unsubscribers = {};
  const stores: Stores = {};
  const { get, update, subscribe } = writable({});

  function register(storesMap: { [name: string]: Readable<any> }) {
    Object.keys(storesMap).forEach(name => {
      if (stores[name]) {
        throw new Error(`A plugin store is already registered under the name "${name}".`);
      }
      const store = storesMap[name];
      stores[name] = store;
      unsubs[name] = store.subscribe(value => {
        const key = name.replace(/Store$/, '');
        update(data => ({ ...data, [key]: value }));
      });
    });
  }

  function unregister(storesMap: { [name: string]: Readable<any> }) {
    Object.keys(storesMap).forEach(name => {
      if (stores[name]) {
        unsubs[name]();
        delete stores[name];
        delete unsubs[name];
      }
    });
  }

  return {
    stores,
    get,
    register,
    unregister,
    subscribe,
  };
}
