import { $t } from '@dabble/data/intl';
import { projectMetaStore, projectStore } from '@dabble/data/project-data';
import { Doc } from '@dabble/data/types';
import { confirm } from '@dabble/data/ui';
import { userRoleStore } from '@dabble/data/user-project';
import { mdiImageAlbum } from '@mdi/js';
import { writable } from 'easy-signal';

export const coverImageDocId = writable('');

export const setCoverArt = [
  {
    key: 'novel_cover_art',
    icon: mdiImageAlbum,
    action: openDropCoverModal,
    hide: (doc: Doc) => (doc.image ? true : false),
    disabled: () => !userRoleStore.get(),
  },
];

export const removeCoverArt = [
  {
    key: 'novel_cover_art_remove',
    icon: mdiImageAlbum,
    action: deleteCoverArt,
    hide: (doc: Doc) => (doc.image ? false : true),
    disabled: () => !userRoleStore.get(),
  },
];

function openDropCoverModal(doc: Doc) {
  coverImageDocId.set(doc.id);
}

async function deleteCoverArt(doc: Doc) {
  const shouldDelete = await confirm(
    $t('novel_cover_art_remove_dialog_title'),
    $t('novel_cover_art_remove_dialog_text')
  );
  if (shouldDelete) {
    await projectMetaStore.update({ image: null });
    await projectStore.updateDoc(doc.id, { image: null, icon: null });
  }
}
