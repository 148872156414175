import { signal } from 'easy-signal';

export enum ServiceWorkerEvent {
  /**
   * Event called exactly once when ServiceWorker or AppCache is installed. Can be useful to display "App is ready for
   * offline usage" message.
   */
  installed = 'installed',

  /**
   * Not supported for AppCache. Event called when update is found and browsers started updating process. At this
   * moment, some assets are downloading.
   */
  updating = 'updating',

  /**
   * Event called when onUpdating phase finished. All required assets are downloaded at this moment and are ready to be
   * updated. Call runtime.applyUpdate() to apply update.
   */
  updateReady = 'updateReady',

  /**
   * Event called when upUpdating phase failed by some reason. Nothing is downloaded at this moment and current update
   * process in your code should be canceled or ignored.
   */
  updateFailed = 'updateFailed',

  /**
   * Event called when update is applied, either by calling runtime.applyUpdate() or some other way by a browser itself.
   */
  updated = 'updated',

  /**
   * Called when an error occurred during the update process.
   */
  error = 'error',
}

export function installServiceWorker(swUrl: string) {
  const onState = signal<(event: ServiceWorkerEvent) => any>();

  if (navigator.serviceWorker) {
    navigator.serviceWorker.register(swUrl).then(
      registration => {
        onUpdate();
        registration.onupdatefound = onUpdate;

        function onUpdate() {
          const sw = registration.installing || registration.waiting;

          // No SW or already handled
          if (!sw || sw.onstatechange) return;

          let ignoreWaiting: boolean = !!registration.waiting;
          const alreadyActive = registration.active;

          sw.onstatechange = () => {
            switch (sw.state) {
              case 'redundant':
                if (alreadyActive) {
                  onState(ServiceWorkerEvent.updateFailed);
                }
                sw.onstatechange = null;
                break;

              case 'installing':
                if (alreadyActive) {
                  onState(ServiceWorkerEvent.updating);
                }
                break;

              case 'installed':
                if (alreadyActive && !ignoreWaiting) {
                  onState(ServiceWorkerEvent.updateReady);
                }
                break;

              case 'activated':
                if (alreadyActive) {
                  onState(ServiceWorkerEvent.updated);
                } else {
                  onState(ServiceWorkerEvent.installed);
                }
                sw.onstatechange = null;
                break;
            }
          };
        }
      },
      err => {
        console.error(err);
        onState(ServiceWorkerEvent.error);
      }
    );
  }

  return { onState, applyUpdate };
}

async function applyUpdate() {
  const registration = await navigator.serviceWorker.getRegistration();
  if (!registration || !registration.waiting) {
    return false;
  }

  registration.waiting.postMessage('skipWaiting');

  return true;
}
