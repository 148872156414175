import { $t } from '@dabble/data/intl';
import { projectStore } from '@dabble/data/project-data';
import { Doc } from '@dabble/data/types';
import { inform } from '@dabble/data/ui';
import { CommentMap } from '@dabble/plugins/comments/comments-store';
import { Delta, EditorRange, Op, TextDocument } from 'typewriter-editor';

export async function splitScene(doc: Doc, selection: EditorRange, isNewLine = true) {
  const patch = projectStore.patch();
  const parent = projectStore.getParent(doc.id);
  const atNext = parent.children.indexOf(doc.id) + 1;
  const nextLine = selection[0] + (isNewLine ? 1 : 0);

  const text = doc.body || new TextDocument();
  const newSceneDelta = text.slice(nextLine, text.length - 1); // from selection to end

  const newDoc = {
    id: projectStore.createDocId(),
    type: 'novel_scene',
  } as Doc;

  const lenOfDelete = text.length - selection[0];
  const deleteDelta = new Delta().retain(selection[0]).delete(lenOfDelete - 1);

  const comments = getComments(newSceneDelta, doc.comments, newDoc.id);

  if (comments) {
    newDoc.comments = comments;
    for (const index in comments) {
      const comment = comments[index];
      patch.patch.remove(`/docs/${doc.id}/comments/${comment.id}`);
    }
  }

  await patch
    .createDoc(newDoc, parent.id, atNext)
    .changeText(newDoc.id, 'body', newSceneDelta)
    .changeText(doc.id, 'body', deleteDelta)
    .save();

  inform('success', $t('novel_scene_split'), $t('novel_scene_split_body'));
  projectStore.forceTextUpdate();

  return newDoc.id;
}

export async function splitChapter(doc: Doc, selection: EditorRange, isNewLine = true) {
  const patch = projectStore.patch();
  const chapter = projectStore.getParent(doc.id);
  const parent = projectStore.getParent(chapter.id);
  const atNextChapter = parent.children.indexOf(chapter.id) + 1;
  const atNextScene = chapter.children.indexOf(doc.id) + 1;
  const nextLine = selection[0] + (isNewLine ? 1 : 0);
  const text = doc.body || new TextDocument();
  const newSceneDelta = text.slice(nextLine, text.length - 1); // from selection to end
  const lenOfDelete = text.length - selection[0];
  const deleteDelta = new Delta().retain(selection[0]).delete(lenOfDelete - 1);

  const newChapter = {
    id: projectStore.createDocId(),
    type: 'novel_chapter',
  };

  const newScene: Doc = {
    id: projectStore.createDocId(),
    type: 'novel_scene',
  };

  const comments = getComments(newSceneDelta, doc.comments, newScene.id);

  if (comments) {
    newScene.comments = comments;
    for (const index in comments) {
      const comment = comments[index];
      patch.patch.remove(`/docs/${doc.id}/comments/${comment.id}`);
    }
  }

  // create new chapter
  patch.createDoc(newChapter, parent.id, atNextChapter, false);

  // move other scenes in reverse order to preserve order
  for (let i = chapter.children.length - 1; i >= atNextScene; i--) {
    patch.moveDoc(chapter.children[i], newChapter.id);
  }
  // create new scene and move text
  patch
    .createDoc(newScene, newChapter.id)
    .changeText(newScene.id, 'body', newSceneDelta)
    .changeText(doc.id, 'body', deleteDelta);

  await patch.save();

  inform('success', $t('novel_chapter_split'), $t('novel_chapter_split_body'));
  projectStore.forceTextUpdate();
  return newChapter.id;
}

function getComments(delta: Delta, comments: CommentMap, docId: string) {
  if (!comments) return;
  const iter = Op.iterator(delta.ops);
  let op: Op;
  const newMap: CommentMap = {};

  // Find the comment in the text document if it exists
  while ((op = iter.next()) && op.retain !== Infinity) {
    const commentIds = op.attributes?.comment;
    if (commentIds) {
      Object.keys(commentIds).forEach(id => {
        if (!newMap[id] && comments[id]) {
          newMap[id] = { ...comments[id], docId };
        }
      });
    }
  }

  return Object.keys(newMap).length ? newMap : undefined;
}
