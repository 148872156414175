<script lang="ts">
  import { projectIdStore } from '@dabble/data/ids';
  import { t } from '@dabble/data/intl';
  import { goto } from '@dabble/data/navigation';
  import { plugins } from '@dabble/data/plugins';
  import { projectRolesStore } from '@dabble/data/project-data';
  import { rest } from '@dabble/data/rest';
  import { User } from '@dabble/data/types';
  import { userAccountStore } from '@dabble/data/user-data';
  import { editor } from '@dabble/plugins/export/utils';
  import Modal from '@dabble/toolkit/Modal.svelte';
  import { Invite } from 'agreeable-client';
  import { getUserSync } from '../collab-stores';

  const { currentUser } = plugins.stores;

  if (sessionStorage.invite) {
    loadInvite();
  }

  let invite: Invite;
  let waitingForLogin: boolean;
  let invites: Record<string, Invite>;
  let fromUser: User;

  // If waitingForLogin is true and currentUser changes, set it to false to open the modal again
  $: accessToken = $userAccountStore?.external?.google?.access_token;
  $: connected = accessToken ? true : false;
  $: if ($currentUser) onCurrentUser();
  $: if ($projectRolesStore && connected) invites = $projectRolesStore.invites || {};
  $: fromUser = loadInvite();

  function onCurrentUser() {
    if (waitingForLogin) {
      waitingForLogin = false;
    }
  }

  function loadInvite() {
    try {
      invite = $projectRolesStore.invites[sessionStorage.invite];
      fromUser = getUserSync(invite.from, editor);
      return fromUser;
    } catch {
      return null;
    }
  }

  function loginScreen(screen: string) {
    goto(`/auth/${screen}`);
    waitingForLogin = true;
  }

  async function execute(action: string) {
    if (action === 'decline' || $currentUser) {
      try {
        // TODO: the agreeableWorker.call for this doesn't do anything.
        // await agreeableWorker.call('acceptInvite', { invite });
        await rest.post(`/projects/${projectIdStore.get()}/roles/users/${sessionStorage.invite}`).send({ action });
      } catch (err) {
        clearInvite();
        await alert($t('error') + $t(err.message));
        return;
      }
      clearInvite();
      await alert($t('success') + $t(`collab_success_${action}`));
    } else {
      waitingForLogin = true;
    }
  }

  function clearInvite() {
    invite = null;
    delete sessionStorage.invite;
  }
</script>

{#if invite && !waitingForLogin}
  <Modal title="Want to co-author?" on:close={() => (invite = null)}>
    <section>
      <p>{@html $t('collab_invited_you', { inviter: fromUser.name, title: invite.to.doc })}</p>
      {#if $currentUser}
        <p>{@html $t('collab_invite_accept', { email: $currentUser.email })}</p>
      {:else}
        <p>{@html $t('collab_signin_first_message')}</p>
      {/if}
      <footer>
        <button class="btn secondary" on:click={() => execute('decline')}>{$t('collab_decline')}</button>
        {#if $currentUser}
          <button class="btn secondary" on:click={() => loginScreen('signin')}>{$t('account_login_another')}</button>
          <button class="btn primary" on:click={() => execute('accept')}>{$t('collab_accept')}</button>
        {:else}
          <button class="btn primary" on:click={() => loginScreen('signup')}>{$t('account_signup')}</button>
          <button class="btn primary" on:click={() => loginScreen('signin')}>{$t('account_login')}</button>
        {/if}
      </footer>
    </section>
  </Modal>
{/if}
