<script lang="ts">
  import { featuresStore } from '@dabble/data/global-data';
  import { t } from '@dabble/data/intl';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiNote } from '@mdi/js';
  import { showStickyNotes } from '../stickies-store';

  function toggle() {
    if (!$featuresStore.stickies) {
      featuresStore.promptUpgrade('stickies');
    } else {
      $showStickyNotes = !$showStickyNotes;
    }
  }
</script>

<button class="dropdown-item" class:checked={$featuresStore.stickies && $showStickyNotes} on:click|stopPropagation={toggle}>
  <Icon path={mdiNote} />
  {$t('sticky_notes')}
</button>
