<script lang="ts">
  import { featuresStore } from '@dabble/data/global-data';
  import { t } from '@dabble/data/intl';
  import { alert } from '@dabble/data/ui';
  import { preferencesStore } from '@dabble/data/user-data';

  $: theme = !$featuresStore.dark_mode || !$preferencesStore.theme ? 'default' : $preferencesStore.theme;

  async function updateTheme(theme: string) {
    if (!featuresStore.get().dark_mode) {
      alert($t('alert_header_not_available'), $t('alert_not_available'));
    } else {
      preferencesStore.update({ theme });
    }
  }
</script>

<div class="section">
  <h2>{$t('preferences_theme_header')}</h2>
  <div class="theme-thumbs">
    <button class="theme-thumb" class:selected={theme === 'default'} on:click={() => updateTheme('default')}>
      <div class="theme-image default" />
      <div class="theme-info">
        <div class="theme-title">{$t('preferences_theme_default_title')}</div>
        <div class="description">{$t('preferences_theme_default_description')}</div>
      </div>
    </button>
    <button class="theme-thumb" class:selected={theme === 'dark'} on:click={() => updateTheme('dark')}>
      <div class="theme-image dark" />
      <div class="theme-info">
        <div class="theme-title">{$t('preferences_theme_dark_title')}</div>
        <div class="description">{$t('preferences_theme_dark_description')}</div>
      </div>
    </button>
    <button class="theme-thumb" class:selected={theme === 'auto'} on:click={() => updateTheme('auto')}>
      <div class="theme-image auto" />
      <div class="theme-info">
        <div class="theme-title">{$t('preferences_theme_auto_title')}</div>
        <div class="description">{$t('preferences_theme_auto_description')}</div>
      </div>
    </button>
  </div>
</div>

<style>
  .section {
    order: -10;
  }
  .theme-thumbs {
    display: flex;
    flex-wrap: wrap;
  }
  .theme-thumb {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    margin-bottom: 30px;
    width: 260px;
    height: 160px;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: var(--home-project-shadow);
    transition: all 0.15s ease-out;
    border: none;
  }
  .theme-thumb:hover {
    transform: scale(1.02);
  }
  .theme-thumb:active {
    transform: scale(1.04);
  }
  .theme-thumb.selected::before {
    content: '';
    position: absolute;
    left: -10px;
    right: -10px;
    top: -10px;
    bottom: -10px;
    border-radius: 12px;
    background: rgba(var(--dabble-blue-rgb), 0.2);
  }
  .theme-image {
    position: relative;
    height: 50%;
    width: 100%;
    background-size: cover;
    box-shadow: inset 0 -1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 6px 6px 0 0;
  }
  .theme-image.default {
    background-image: url(../images/theme-default.png);
  }
  :global(html[data-theme='dark']) .theme-image.default,
  :global(html[data-theme='dark']) .theme-image.auto {
    filter: brightness(0.75);
  }
  .theme-image.dark {
    background-image: url(../images/theme-dark.png);
  }
  .theme-image.auto {
    background-image: url(../images/theme-auto.png);
  }
  .theme-info {
    position: relative;
    flex: 1;
    padding: 8px;
    background: var(--white);
    border-radius: 0 0 6px 6px;
  }
  .theme-title {
    font-weight: bold;
    margin-bottom: 8px;
  }
  @media (max-width: 600px) {
    .theme-thumb {
      margin-right: 0;
    }
  }
</style>
