<script lang="ts">
  import { t } from '@dabble/data/intl';
  import { projectStore } from '@dabble/data/project-data';
  import { Doc } from '@dabble/data/types';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiCloseThick, mdiPencil, mdiPlusThick, mdiRibbon } from '@mdi/js';
  import { createEventDispatcher } from 'svelte';
  import { mdiRibbonVariant } from './custom-icons';

  // export let deleteDoc;
  export let doc: Doc;

  const dispatch = createEventDispatcher();
  let hasLabel = false;

  $: hasLabel = !!doc.label;

  function addRibbon() {
    const label = { text: '', color: 'red' };

    projectStore.updateDoc(doc.id, { label });
    dispatch('editRibbon');
  }

  function editRibbon() {
    dispatch('editRibbon');
  }

  function removeRibbon() {
    if (hasLabel) {
      projectStore.updateDoc(doc.id, { label: null });
    }
  }
</script>

{#if !hasLabel}
  <button class="dropdown-item add-ribbon" on:click={addRibbon}>
    <Icon path={mdiPlusThick} class="prefix" />
    <Icon path={mdiRibbonVariant} />
    {$t('add_ribbon')}
  </button>
{/if}

{#if hasLabel}
  <button class="dropdown-item edit-ribbon" on:click={editRibbon}>
    <Icon path={mdiPencil} />
    {$t('edit_ribbon')}
  </button>

  <button class="dropdown-item remove-ribbon" on:click={removeRibbon}>
    <Icon path={mdiCloseThick} class="prefix" />
    <Icon path={mdiRibbon} />
    {$t('remove_ribbon')}
  </button>
{/if}
