import { derived } from 'easy-signal';
import { delegate } from './delegate';
import { docIdStore, projectIdStore } from './ids';
import { projectStore } from './project-data';
import { settingsStore } from './settings';
import { createDocStore } from './stores/doc';
import { DocSettings } from './types';
import { userProjectsStore } from './user-data';
import { EMPTY_OBJECT } from './util';

export const docStore = createDocStore(projectStore, docIdStore);
export const docSettingsStore = derived(
  () => settingsStore.get()[docStore.get()?.type] || (EMPTY_OBJECT as DocSettings)
);

export function selectDoc(docId: string) {
  const projectId = projectIdStore.get();
  if (!projectId) return;
  docIdStore.set(docId);
  if (docId && !delegate) {
    userProjectsStore.update({ [projectId]: { lastSelected: docId } });
  }
}
