<script lang="ts">
  import { readonlyStore } from '@dabble/data/app-state';
  import { t } from '@dabble/data/intl';
  import { plugins } from '@dabble/data/plugins';
  import { projectStore } from '@dabble/data/project-data';
  import { getTitle } from '@dabble/data/util';
  import Dropdown from '@dabble/toolkit/Dropdown.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { autolink, br, escape } from '@dabble/toolkit/helpers';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import { mdiCheck, mdiCommentCheck, mdiDelete, mdiDotsVertical, mdiPencil } from '@mdi/js';
  import { createEventDispatcher, tick } from 'svelte';
  import { Comment, CommentReply, commentsInTextStore, commentsStore, updateCommentDisplay } from '../comments-store';
  import CommentEditor from './CommentEditor.svelte';
  import CommentHeader from './CommentHeader.svelte';
  import ConfirmDelete from './ConfirmDelete.svelte';

  export let comment: Comment;
  export let reply: CommentReply | null = null;

  let node: HTMLElement;
  let menuOpen = false;
  let editing = false;
  let confirmDelete = false;

  const dispatch = createEventDispatcher();
  const uid = plugins.stores.uid;
  const domain = 'https://app.dabblewriter.com';

  $: if (menuOpen) dispatch('menuOpen');
  $: entry = reply || comment;
  $: readonly = $readonlyStore;

  function edit() {
    editing = true;
  }

  function cancelEdit() {
    focus();
    editing = false;
  }

  async function onSave({ detail: { content } }: CustomEvent) {
    cancelEdit();
    if (content === entry.content) return;

    if (reply) {
      const index = comment.replies.findIndex(r => (r as Comment).id === (reply as Comment).id);
      if (index >= 0) await commentsStore.updateReply(comment.id, index, content);
    } else {
      await commentsStore.updateComment(comment.id, content);
    }
    await tick();
    updateCommentDisplay();
  }

  async function onDelete() {
    confirmDelete = false;

    if (reply) {
      const index = comment.replies.findIndex(r => (r as Comment).id === (reply as Comment).id);
      if (index >= 0) await commentsStore.deleteReply(comment.id, index);
    } else {
      await commentsStore.deleteComment(comment.id);
    }
    await tick();
    updateCommentDisplay();
  }

  async function resolve() {
    await commentsStore.resolveComment(comment.id, comment.resolved);
    projectStore.forceTextUpdate();
    await tick();
    updateCommentDisplay(true);
    dispatch('resolve');
  }

  function closeMenu({ detail: { clicked } }: CustomEvent) {
    menuOpen = false;
    if (node) {
      if (clicked) {
        focus();
      } else {
        node.dispatchEvent(new FocusEvent('focusout', { bubbles: true }));
      }
    }
  }

  function replaceLocalWithTitle(content: string) {
    return content.replace(/<a href="([^"]+)"[^>]*>([^<]+)/g, (whole, href, text) => {
      if (!href.startsWith(domain)) return whole;

      const path = href.replace(domain, '');
      if (path === '/') text = $t('back_home');
      else if (path.startsWith(`/p/${$projectStore.projectId}`)) {
        const docId = path.split('/').pop();
        const doc = projectStore.getDoc(docId);
        text = doc ? getTitle(doc) : path;
      } else {
        text = path;
      }

      return `<a href="${path}">${text}</a>`;
    });
  }

  function focus() {
    const thread: HTMLElement = node.closest('.thread.selected');
    if (thread) thread.focus();
  }
</script>

{#if !reply && confirmDelete}
  <ConfirmDelete on:confirmed={onDelete} on:close={() => (confirmDelete = false)}
    >{$t('comments_delete_thread')}</ConfirmDelete
  >
{/if}

<div class="comment-entry" bind:this={node}>
  <CommentHeader uid={entry.uid} comment={entry}>
    {#if !reply && !comment.resolved}
      <button class="icon accept-btn" use:tooltipTop={$t('comments_accept')} on:click={resolve}>
        <Icon path={mdiCheck} />
      </button>
    {/if}
    <button class="icon menu-opener" on:click={() => (menuOpen = true)}>
      <Icon path={mdiDotsVertical} />
    </button>
    {#if menuOpen}
      <Dropdown placement="bottom-end" class="comment-dropdown" arrow on:close={closeMenu}>
        {#if !reply && comment.resolved && $commentsInTextStore.has(comment.id)}
          <button class="dropdown-item" on:click={resolve} disabled={readonly}>
            <Icon path={mdiCommentCheck} />
            {$t('comments_reopen')}
          </button>
        {/if}
        <button class="dropdown-item" on:click={edit} disabled={readonly || entry.uid !== $uid}>
          <Icon path={mdiPencil} />
          {$t('edit')}
        </button>
        <hr />
        <button class="dropdown-item" on:click={() => (confirmDelete = true)} disabled={readonly || entry.uid !== $uid}>
          <Icon path={mdiDelete} />
          {$t('delete')}
        </button>
      </Dropdown>
    {/if}
  </CommentHeader>

  {#if editing}
    <CommentEditor
      on:update
      on:save={onSave}
      on:cancel={cancelEdit}
      style={reply ? 'edit_reply' : 'edit_comment'}
      autofocus
      content={entry.content}
    />
  {:else}
    <div class="content">
      {@html br(replaceLocalWithTitle(autolink(escape(entry.content), true)))}
    </div>
  {/if}

  {#if reply && confirmDelete}
    <ConfirmDelete on:confirmed={onDelete} on:close={() => (confirmDelete = false)}
      >{$t('comments_delete_reply')}</ConfirmDelete
    >
  {/if}
</div>

<style>
  .comment-entry {
    position: relative;
    padding: var(--comment-padding) 8px;
    contain: layout;
  }
  .comment-entry:not(:last-child) {
    padding-bottom: calc(var(--comment-padding) - 1px);
    border-bottom: var(--form-border);
  }
  .accept-btn,
  .menu-opener {
    align-self: flex-start;
  }
  .accept-btn {
    margin-top: -4px;
    font-size: 24px;
    color: var(--dabble-blue);
  }
  .menu-opener {
    margin-right: -12px;
  }
</style>
