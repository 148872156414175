{
  "CREDENTIAL_TOO_OLD_LOGIN_AGAIN": "You must verify your old password to change it.",
  "EMAIL_EXISTS": "This email is being used by another account.",
  "EMAIL_NOT_FOUND": "You entered an incorrect email.",
  "FORBIDDEN": "You are not permitted to do this.",
  "INVALID_ARGUMENTS": "You need to provide your name, email, and password.",
  "INVALID_CUSTOM_TOKEN": "User credentials are no longer valid. Sign in again.",
  "INVALID_DATA": "No profile changes were provided.",
  "INVALID_ID_TOKEN": "User credentials are no longer valid. Sign in again.",
  "INVALID_PASSWORD": "You entered an incorrect password.",
  "TOO_MANY_ATTEMPTS_TRY_LATER": "You have tried too many times. Requests are being blocked to prevent suspicious activity. Try again later.",
  "USER_DISABLED": "Your account has been disabled.",
  "UNAUTHORIZED": "You must be signed in to do this.",
  "USER_NOT_FOUND": "User not found.",
  "WEAK_PASSWORD": "The password must be 6 characters or longer.",
  "account_disconnected": "Disconnected",
  "account_get_help_tooltip": "Features, bugs, or help",
  "account_help": "Help",
  "account_help_center": "Help Center",
  "account_login": "Log in",
  "account_signup": "Create Dabble account",
  "account_login_another": "Sign In To Another Account",
  "account_logout": "Sign Out",
  "account_offline": "Offline",
  "account_release_notes": "Release Notes",
  "account_report_bug": "Report a Bug",
  "account_request_feature": "Request a Feature",
  "account_screen_billing": "Billing",
  "account_screen_preferences": "Preferences",
  "account_screen_profile": "Profile",
  "account_switch_account": "Switch Account",
  "account_update_info_tooltip": "Update your info",
  "account_ui_language": "U/I Language",
  "account_set_ui_language": "Set U/I Language",
  "account_user_interface": "User Interface",
  "auth_disconnected": "You are <strong>not connected</strong> to the server. :(<br>Check your connection? Try again later?",
  "auth_signin": "Sign Into Your Account",
  "auth_signup": "Create a Dabble account",
  "auth_reauth": "Re-authenticate",
  "auth_forgot_password": "Don't remember your password?",
  "auth_not_registered": "Not registered?",
  "auth_create_account": "Create an account!",
  "auth_signin_here": "Sign in here.",
  "auth_already_registered": "Already registered?",
  "auth_cancel": "Cancel",
  "auth_send_reset": "Send Reset Email",
  "auth_terms": "When you sign in and use Dabble, you agree to our <br><a href=\"https://www.dabblewriter.com/terms\" target=\"_blank\" class=\"external\">terms of service</a> and <a href=\"https://www.dabblewriter.com/privacy\" target=\"_blank\" class=\"external\">privacy policy</a>.",
  "auth_email": "Email",
  "auth_name": "Name",
  "auth_new_password": "New Password",
  "auth_password": "Password",
  "dictionary": "Dictionary Maintenance",
  "preferences_autofade_description": "Fade out non-essentials when typing. Fancy. Easy. Awesome.",
  "preferences_autofade_label": "Auto-fade",
  "preferences_autoscroll_description": "Scroll the page up as the cursor moves down. Like a typewriter. No need to take your hands off the keyboard.",
  "preferences_autoscroll_label": "Typewriter Scrolling",
  "preferences_extra_space_label": "Prevent Extra Spaces",
  "preferences_extra_space_description": "Prevent 2 spaces from being added next to each other to keep the manuscript clean. Helps against that bad double-space-after-periods habit.",
  "preferences_holidays_label": "Holiday Themes",
  "preferences_holidays_description": "Add some extra holiday cheer to Dabble on popular holidays.",
  "preferences_loading_quote_label": "Show Quotes on Startup",
  "preferences_loading_quote_description": "Gotta love inspirational writing quotes. Or not.",
  "preferences_touch_ui_label": "Prefer Touch UI",
  "preferences_touch_ui_description": "When touch is used, keep the touch-style user interface instead of toggling back to mouse-style on computers.",
  "preferences_native_find_description": "Use Dabble’s Find and Replace instead of the browser’s. If you want.",
  "preferences_native_find_label": "Use Dabble’s Find/Replace",
  "preferences_native_context_menu_description": "Use the Dabble’s context menu for text editing.",
  "preferences_native_context_menu_label": "Use Dabble’s Context Menu",
  "profile_password_updated": "Password successfully updated.",
  "profile_update": "Update Profile",
  "profile_updated": "Profile successfully updated.",
  "profile_update_description": "Change your name, update your email, and/or pick a new password. Just the regular stuff.",
  "preferences_no_mobile_label": "Prefer Desktop UI",
  "preferences_no_mobile_description": "Prefer to keep the desktop ui when on a tablet in landscape mode",
  "no_url": "Names cannot be variations of urls",
  "min_3": "Names should be at least three characters long",
  "invalid_email": "Invalid email address",
  "1_symbol": "Your password should have at least 1 symbol in it.",
  "1_lowercase": "Your password should have at least 1 lowercase letter in it.",
  "1_uppercase": "Your password should have at least 1 uppercase letter in it.",
  "1_digit": "Your password should have at least 1 number in it.",
  "8_32_characters": "Your password should have between 8 and 32 characters.",
  "doc_print": "Print",
  "doc_underline": "Underline",
  "doc_escape": "Clear Selection/Hide Cursor",
  "rule_characters": "between 8 and 32 characters",
  "rule_symbol": "1 symbol",
  "rule_lower": "1 lowercase letter",
  "rule_upper": "1 uppercase letter",
  "rule_digit": "1 number"
}
