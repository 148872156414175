import { agreeable } from '@dabble/data/agreeable';
import { t } from '@dabble/data/intl';
import { alert } from '@dabble/data/ui';
import { PasteEvent } from 'typewriter-editor';
import { FileType } from './content';

export interface ImageInfo {
  dataUrl: string;
  width: number;
  height: number;
}

export interface Image extends ImageInfo {
  url: string;
  image?: string; // old url field we still need to support
}

export type ImageStyles = 'inset-center' | 'outset-left' | 'outset-center' | 'center' | 'fill-width' | 'full-page';

export interface ImageAttributes {
  image: string;
  alt?: string;
  dataUrl?: string;
  style?: string;
  width: number;
  height: number;
}

export async function constrainImage(file: FileType, maxSize: number): Promise<FileType> {
  return agreeable.call('constrainImage', file, maxSize);
}

export async function getImagePlaceholder(file: FileType): Promise<ImageInfo> {
  return agreeable.call('getImagePlaceholder', file);
}

export function removeEmbededImagesOnPaste(event: PasteEvent) {
  if (hasImage(event.html.toString())) {
    event = updateImageDeltas(event);
  }

  return event;
}

export function hasImage(htmlString: string) {
  return htmlString.includes('<img');
}

export async function getImage(image: Image) {
  const sourceUrl = image.image || image.url;
  try {
    // fetch should grab from cache if it exists or go to url when online
    const response = await fetch(sourceUrl, {
      method: 'get',
    });
    return response.arrayBuffer();
  } catch (e) {
    // if it fails export small dataUrl instead
    return _base64ToArrayBuffer(image.dataUrl);
  }
}

export function updateImageDeltas(event: PasteEvent) {
  let removed = false;
  for (let i = event.delta.ops.length; i > 0; i--) {
    const j = i - 1;
    const op = event.delta.ops[j] as any;
    if (typeof op.insert !== 'string' && op.insert.image) {
      if (!op.insert.image.match(/^https:\/\/files.dabblewriter.com/)) {
        event.delta.ops.splice(j, 1);
        removed = true;
      }
    }
  }
  if (removed) {
    alert(t.get()('content_paste_images_removed_title'), t.get()('content_paste_images_removed_text'));
  }

  return event;
}

function _base64ToArrayBuffer(base64: string) {
  const re = /data:image\/png;base64,(.*)/;
  const parts = re.exec(base64);
  if (parts) {
    const binary_string = window.atob(parts[1]);
    const len = binary_string.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }
  return base64;
}
