import { Readable, readable, Updater, writable, Writable } from 'easy-signal';

export interface StoredWritable<T> extends Writable<T> {
  isDefault: () => boolean;
}

/**
 * A store that reads from and writes to local storage.
 */
export function locallyStoredWritable<T>(name: string, value?: T) {
  return dynamicStoredWritable(readable(name), value);
}

/**
 * A store that reads from and writes to local storage with a dynamic name.
 */
export function dynamicStoredWritable<T>(nameStore: Readable<string>, defaultValue?: T, constrain?: (value: T) => T) {
  let name: string;

  const {
    get,
    set: origSet,
    subscribe,
  } = writable<T>(defaultValue, set => {
    return nameStore.subscribe(nameValue => {
      name = nameValue;
      try {
        const stored = localStorage.getItem(name);
        if (stored) {
          set(JSON.parse(stored));
          return;
        }
      } catch (err) {
        try {
          const stored = localStorage.getItem(name);
          if (stored) {
            set(stored as unknown as T);
            return;
          }
        } catch (err) {}
      }
      set(defaultValue);
    });
  });

  function set(value: T) {
    if (constrain) value = constrain(value);
    if (value == null || value === defaultValue) {
      localStorage.removeItem(name);
    } else {
      localStorage[name] = typeof value === 'string' ? value : JSON.stringify(value);
    }
    return origSet(value == null ? defaultValue : value);
  }

  function update(fn: Updater<T>): void {
    return set(fn(defaultValue));
  }

  function isDefault() {
    return get() === defaultValue;
  }

  return {
    isDefault,
    get,
    set,
    update,
    subscribe,
  };
}
