<script lang="ts">
  import { sizeStore } from '@dabble/data/device';
  import { docSettingsStore, docStore } from '@dabble/data/doc-data';
  import { EditorElement } from '@dabble/data/editables';
  import { docIdStore } from '@dabble/data/ids';
  import { t } from '@dabble/data/intl';
  import { plugins } from '@dabble/data/plugins';
  import { projectMetasStore, projectStore } from '@dabble/data/project-data';
  import { Focus } from '@dabble/data/stores/focus';
  import {
    displayedDocIdStore,
    displayedDocStore,
    displayingDocStore,
    editingModeStore,
    focusStore,
    leftNavWidthStore,
    rightPaneTotalWidthStore,
    showLeftPaneStore,
    showRightPaneStore,
    viewport,
    writingStore,
  } from '@dabble/data/ui';
  import { preferencesStore } from '@dabble/data/user-data';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import Interactions from '@dabble/toolkit/Interactions.svelte';
  import { autoscroll } from '@dabble/toolkit/autoscroll';
  import { addListener } from '@dabble/toolkit/listen';
  import { pagepad } from '@dabble/toolkit/pagepad';
  import { dbltap, tap } from '@dabble/toolkit/tap';
  import { mdiPencil } from '@mdi/js';
  import { SvelteComponent, onMount, tick } from 'svelte';
  import { Editor, TextDocument } from 'typewriter-editor';
  import StatusBar from './StatusBar.svelte';

  export let loading = true;
  let workspace: HTMLElement;
  let hideStatusBar: boolean;
  let justFocused = false;
  let currentView: SvelteComponent;
  let sidebarSizeDifference = 0;
  let workspaceWidth = 1000;
  let noBackground = false;

  const { showCoauthors } = plugins.stores;

  $: hideStatusBar = $docSettingsStore.hideStatusBar;
  $: setJustFocused($focusStore);
  $: viewport.container.set(workspace || null);
  $: if (!loading && $docStore) onDocDisplayed();
  $: if ($editingModeStore === 1) {
    removeSelection();
  }
  $: paddingLeft = ($sizeStore === 'desktop' && $showLeftPaneStore && $leftNavWidthStore) || 0;
  $: paddingRight = ($sizeStore === 'desktop' && $showRightPaneStore && $rightPaneTotalWidthStore) || 0;
  $: focusPadLeft = sidebarSizeDifference < 0 ? -sidebarSizeDifference : 0;
  $: focusPadRight = sidebarSizeDifference > 0 ? sidebarSizeDifference : 0;
  $: if ($docIdStore) noBackground = false;

  onMount(() => {
    const selChangeUnsub = addListener(workspace, 'editor-change', onEditorChange);
    $writingStore = false;
    focusStore.stop();
    return () => {
      selChangeUnsub();
      viewport.container.set(null);
      onDocDisplayed();
    };
  });

  function removeSelection() {
    const selection = workspace && workspace.ownerDocument && workspace.ownerDocument.getSelection();
    if (!selection || !selection.anchorNode) return;
    if (workspace.contains(selection.anchorNode)) {
      selection.removeAllRanges();
    }
  }

  function onDocDisplayed() {
    if (!$docStore) {
      $displayingDocStore = false;
      if ($displayedDocStore) $displayedDocStore = null;
      if ($displayedDocIdStore) $displayedDocIdStore = null;
    } else {
      $displayingDocStore = true;
      $displayedDocStore = $docStore;
      const docId = ($docStore && $docStore.id) || null;
      if ($displayedDocIdStore !== docId) $displayedDocIdStore = docId;
    }
  }

  async function setJustFocused(focus: Focus) {
    if (focus.focused || focus.autofocused) {
      justFocused = true;
      // Fixes Safari's trigger of a phantom mousemove event
      setTimeout(() => (justFocused = false), 100);
    }

    if (focus.focused && workspace.scrollWidth === workspace.clientWidth) {
      sidebarSizeDifference = paddingRight - paddingLeft;
    } else if (sidebarSizeDifference) {
      sidebarSizeDifference = 0;
    }
  }

  function onInput({ target }: Event) {
    if (!$writingStore) $writingStore = true;

    // Handle auto-focus
    if (
      $preferencesStore.noAutofade ||
      $docSettingsStore.disableFocusMode ||
      $focusStore.focusing ||
      $focusStore.focused ||
      $focusStore.autofocused ||
      !(target as HTMLElement).closest('.page')
    )
      return;
    focusStore.start();
  }

  let mousePos: { x: number; y: number };
  function onMouseMove({ detail: event }: CustomEvent<MouseEvent>) {
    if ($focusStore.autofocused) {
      // Leave autofocus if the mouse is moved substantially, but not if it moves a pixel or two
      if (!mousePos) {
        mousePos = { x: event.clientX, y: event.clientY };
      } else if (Math.abs(event.clientX - mousePos.x) + Math.abs(event.clientY - mousePos.y) > 10) {
        onStopWriting();
      }
    } else {
      onStopWriting();
    }
  }

  function onStopWriting() {
    if (justFocused) return;
    $writingStore = false;
    mousePos = null;
    focusStore.stop();
  }

  async function startEdit() {
    $editingModeStore = 2;
    await tick();
    viewport.selectNearTop();
  }

  async function onEdit({ relatedTarget, pageX, pageY }: MouseEvent) {
    if (!$editingModeStore) return;
    let related = relatedTarget as Element;
    if (related.nodeType !== Node.ELEMENT_NODE) related = related.parentElement;
    const editorElement = related.closest && related.closest('.typewriter-editor');
    const editor = editorElement && (editorElement as EditorElement).editor;
    if (!editor) return;
    const field = editor.identifier;
    // find the closest selection for the x/y, scroll it to be within the to 1/3 of the viewport, and select it
    const index = editor.getIndexFromPoint(pageX, pageY);
    $editingModeStore = 2;
    await tick();
    viewport.select(field, [index, index], true);
  }

  async function onEditorChange({
    detail: { editor, old, doc },
  }: CustomEvent<{ editor: Editor; old: TextDocument; doc: TextDocument }>) {
    if ($editingModeStore !== 2) return;
    if (old.selection || !doc.selection) return;
    const field = editor.identifier;
    // find the closest selection for the x/y, scroll it to be within the to 1/3 of the viewport, and select it
    viewport.scrollIntoView(field, doc.selection[0], true);
  }
</script>

<div
  class="project-screen"
  class:focused={$focusStore.focused || $focusStore.autofocused}
  class:focusing={$focusStore.focusing}
  class:focus-slowly={$focusStore.focusing && !$focusStore.focusLock}
  class:show-author={$showCoauthors}
  style:background-color={(!noBackground && currentView?.backgroundColor) || 'transparent'}
  style:padding-left="{paddingLeft}px"
  style:padding-right="{paddingRight}px"
>
  <div
    bind:this={workspace}
    id="project-container"
    class="project-container focus-scroll {$docStore && $docStore.type}"
    style="padding-left:{focusPadLeft}px;padding-right:{focusPadRight}px;--workspace-width:{workspaceWidth}px"
    use:tap
    use:dbltap
    use:pagepad
    use:autoscroll={!$preferencesStore.noScrollLock}
    on:dbltap={$editingModeStore && onEdit}
    on:editorinput={onInput}
    bind:offsetWidth={workspaceWidth}
  >
    {#if !loading}
      {#if $docStore}
        {#if $docSettingsStore.view}
          <svelte:component
            this={$docSettingsStore.view.component}
            doc={$docStore}
            bind:this={currentView}
            bind:noBackground
          />
        {/if}
      {:else if $projectStore.project}
        <div class="document-not-found">
          {$t('not_found_doc')}
        </div>
      {:else if $projectMetasStore[$projectStore.projectId]}
        <div class="document-not-found">
          {$t('not_found_project_data')}
        </div>
      {:else}
        <div class="document-not-found">
          {$t('not_found_project')}
        </div>
      {/if}
    {/if}
  </div>
</div>

{#if !hideStatusBar && $editingModeStore !== 2}
  <div
    class="status-container"
    class:focused={$focusStore.focused || $focusStore.autofocused}
    class:focusing={$focusStore.focusing}
    class:focus-slowly={$focusStore.focusing && !$focusStore.focusLock}
  >
    <StatusBar {paddingLeft} {paddingRight} />
  </div>
{/if}

{#if $editingModeStore === 1}
  <button class="btn info large edit-button" use:tap on:tap={startEdit}>
    <Icon path={mdiPencil} />
  </button>
{/if}

<Interactions on:mousemove={onMouseMove} on:touchstart={onStopWriting} on:shortcut-Escape={onStopWriting} />

<style>
  .project-screen {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    height: 100%;
    width: 100%;
    overflow: hidden;
    contain: strict;
  }
  .project-container {
    flex: 1 1 100%;
    cursor: auto;
    border: none;
    -webkit-overflow-scrolling: touch;
    overflow: auto;
    overflow-y: scroll;
  }
  .status-container {
    position: relative;
  }
  .document-not-found {
    margin-top: 100px;
    text-align: center;
    font-weight: bold;
    font-size: 36px;
    color: rgba(0, 0, 0, 0.5);
    text-shadow: var(--text-highlight);
  }
  .edit-button.btn.large {
    position: fixed;
    display: flex;
    font-size: 32px;
    padding: 16px;
    right: 32px;
    bottom: 80px;
    border-radius: 100px;
    box-shadow: var(--global-edit-button-shadow);
  }
</style>
