<script lang="ts">
  import { projectStore } from '@dabble/data/project-data';
  import { Doc } from '@dabble/data/types';
  import TitleImage from '@dabble/plugins/content/components/TitleImage.svelte';
  import EditableContent from '@dabble/toolkit/EditableContent.svelte';
  import Page from '@dabble/toolkit/Page.svelte';

  export let doc: Doc;
  let book: Doc;
  $: book = getBook(doc);

  function getBook(doc: Doc) {
    return projectStore.getParent(doc.id);
  }
</script>

<Page {doc} class="part-view title-page">
  <EditableContent {doc} field="title" prefix={!book?.hideChapterHeadings} header="h1" />
  <TitleImage {doc} />
</Page>

<style>
  :global(.part-view.title-page h1) {
    padding-bottom: 2em;
  }
  :global(.part-view.title-page .title-prefix) {
    top: -3em;
  }
</style>
