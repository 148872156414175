import { readonlyStore } from '@dabble/data/app-state';
import { featuresStore } from '@dabble/data/global-data';
import { localeStore } from '@dabble/data/intl';
import { projectMetaSettingsStore } from '@dabble/data/project-data';
import { ProjectMetaSettings } from '@dabble/data/types';
import { createInViewportStore } from '@dabble/toolkit/viewport';
import { derived, readable } from 'easy-signal';
import { Editor, isEqual } from 'typewriter-editor';

export interface AllowedChecks {
  spelling: boolean;
  grammar: boolean;
}

export interface GrammarSettings extends AllowedChecks {
  language: string;
}

export const allowedChecksStore = createAllowedChecksStore();
export const shouldCheckStore = derived(() => allowedChecksStore.get().grammar || allowedChecksStore.get().spelling);
export const spellingLanguageStore = createSpellingLanguageStore();
export const grammarSettingsStore = createGrammarSettingsStore();

/**
 * Store for tracking whether the editor is enabled or not.
 */
export function createEnabledStore(editor: Editor) {
  return readable(editor.enabled, (set: Function) => {
    const onChange = () => set(editor.enabled);
    editor.on('enabledchange', onChange);
    return () => editor.off('enabledchange', onChange);
  });
}

/**
 * Store for tracking whether the editor show show any grammar/spelling issues.
 */
export function createShowGrammarStore(editor: Editor) {
  // Once in the viewport, it should always show if turned on (don't turn on/off based on scrolling)
  let showGrammar = false;
  const inViewportStore = createInViewportStore(editor.root);
  const enabledStore = createEnabledStore(editor);
  return derived(() => {
    if (!enabledStore.get() || (!allowedChecksStore.get().spelling && !allowedChecksStore.get().grammar)) return false;
    if (showGrammar) return true;
    if (inViewportStore.get()) return (showGrammar = true); // Once it enters the viewport, it should always show
  });
}

export function createAllowedChecksStore() {
  let prior: AllowedChecks = { spelling: true, grammar: false };

  return derived(() => {
    const { spellingCheck, grammarCheck } = projectMetaSettingsStore.get() || ({} as ProjectMetaSettings);
    const spelling = !readonlyStore.get() && Object.keys(featuresStore.get()).includes('spell_check') && spellingCheck !== false;
    const grammar = (!readonlyStore.get() && Object.keys(featuresStore.get()).includes('grammar') && grammarCheck) || false;
    if (spelling === prior.spelling && grammar === prior.grammar) return prior;
    return (prior = { spelling, grammar } as AllowedChecks);
  });
}

export function createSpellingLanguageStore() {
  return derived(() => {
    return projectMetaSettingsStore.get()?.spellingLanguage || localeStore.get();
  });
}

export function createGrammarSettingsStore() {
  let prior: GrammarSettings = { spelling: false, grammar: false, language: '' };

  return derived(() => {
    const value = {
      spelling: allowedChecksStore.get().spelling,
      grammar: allowedChecksStore.get().grammar,
      language: spellingLanguageStore.get(),
    };
    if (!isEqual(value, prior)) {
      prior = value;
    }
    return prior;
  });
}
