<script lang="ts">
  import { t } from '@dabble/data/intl';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiContentCopy, mdiContentCut, mdiContentPaste, mdiDelete, mdiRedo, mdiSelectAll, mdiUndo } from '@mdi/js';
  import { onMount } from 'svelte';

  export let target: Element |HTMLElement;
  let doc: Document;

  $: doc = target && target.ownerDocument;

  async function exec(command: string) {
    if (command === 'paste' && !doc.queryCommandSupported('paste')) {
      const text = await navigator.clipboard.readText();
      target.focus();
      doc.execCommand('insertText', false, text);
    } else {
      target.focus();
      doc.execCommand(command);
    }
  }

  function query(command: string) {
    return doc.queryCommandEnabled(command);
  }

  if (target) target.focus();
  onMount(() => target.focus());
</script>

<button class="dropdown-item" on:click={() => exec('undo')} disabled={!query('undo')}>
  <Icon path={mdiUndo} />
  {$t('text_undo')}
</button>
<button class="dropdown-item" on:click={() => exec('redo')} disabled={!query('redo')}>
  <Icon path={mdiRedo} />
  {$t('text_redo')}
</button>
<hr />
<button class="dropdown-item" on:click={() => exec('cut')} disabled={!query('cut')}>
  <Icon path={mdiContentCut} />
  {$t('text_cut')}
</button>
<button class="dropdown-item" on:click={() => exec('copy')} disabled={!query('copy')}>
  <Icon path={mdiContentCopy} />
  {$t('text_copy')}
</button>
<button
  class="dropdown-item"
  on:click={() => exec('paste')}
  disabled={doc.queryCommandSupported('paste') && !query('paste')}
>
  <Icon path={mdiContentPaste} />
  {$t('text_paste')}
</button>
<button class="dropdown-item" on:click={() => exec('delete')} disabled={!query('delete')}>
  <Icon path={mdiDelete} />
  {$t('text_delete')}
</button>
<hr />
<button class="dropdown-item" on:click={() => exec('selectall')} disabled={!query('selectall')}>
  <Icon path={mdiSelectAll} />
  {$t('text_selectall')}
</button>
