<script lang="ts">
  import { sizeStore } from '@dabble/data/device';
  import { featuresStore } from '@dabble/data/global-data';
  import { activeCommentIdStore, chosenCommentIdStore, showCommentsStore } from '../comments-store';
  import CommentGutterList from './CommentGutterList.svelte';
  import { highlightComment } from './display-helpers';

  // This component is the list of comments next to the page (in the page gutter)

  export let pageElement;

  $: show = $featuresStore.comments && $showCommentsStore;
  $: pageElement && highlightComment(pageElement, $activeCommentIdStore, !!$chosenCommentIdStore);
</script>

{#if show && $sizeStore === 'desktop'}
  <CommentGutterList {pageElement} />
{/if}
