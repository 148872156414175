<svelte:options accessors />

<script lang="ts">
  import { todayStore } from '@dabble/data/date';
  import { dateOptions, t } from '@dabble/data/intl';
  import { getUser } from '@dabble/data/users-data';
  import { format, isSameDay, isSameYear } from 'date-fns';
  import { onMount } from 'svelte';
  import { CommentReply } from '../comments-store';

  export let uid: string;
  export let comment: CommentReply | undefined = undefined;

  $: username = '';

  function getDate(today: Date | number | string, date: Date | number | string) {
    const time = format(date, 'p', dateOptions) + ' ';
    if (isSameDay(today, date)) {
      return time + $t('today');
    } else if (isSameYear(today, date)) {
      return time + format(date, 'MMM d', dateOptions);
    } else {
      return time + format(date, 'MMM d, yyyy', dateOptions);
    }
  }

  onMount(async () => {
    const user = await getUser(uid);
    username = user?.name || '';
  });
</script>

<div class="header">
  <!-- <Avatar uid={comment && comment.uid || uid}/> -->
  <div class="header-text">
    <div class="name">
      {username}
    </div>
    {#if comment}
      <div class="date">
        {getDate($todayStore, new Date(comment.created))}
      </div>
    {/if}
  </div>
  <slot />
</div>

<style>
  .header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  .header-text {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    min-width: 0;
  }
  .name {
    padding: 0;
    font-weight: bold;
    font-size: var(--font-size-sm);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .date {
    color: var(--text-color-lighter);
    font-size: var(--font-size-xs);
  }
</style>
