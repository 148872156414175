import { preferencesStore } from '@dabble/data/user-data';
import { Delta, Editor, EditorChangeEvent, normalizeRange } from 'typewriter-editor';

const extraSpaces = / {2,}/g;

export default function spaces(editor: Editor) {
  function onTextChanging(event: EditorChangeEvent) {
    const { doc, old, change } = event;
    const { selection, delta } = change || {};
    if (!change || !change.contentChanged || !selection || preferencesStore.get().allowDoubleSpace) return;

    const at = normalizeRange(selection)[0];

    if (delta.ops.length <= 2 && delta.ops[delta.ops.length - 1].insert === ' ') {
      const text = doc.getText([at - 2, at + 1]);

      if (/ {2}[^ ]?$/.test(text)) {
        event.preventDefault();

        const firefoxBug =
          old.selection[0] === old.selection[1] &&
          selection[0] === selection[1] &&
          selection[0] - old.selection[0] === 2;

        if (/ $/.test(text)) {
          editor.select(selection);
        } else if (firefoxBug && / \n$/.test(text)) {
          editor.select([selection[0] - 1, selection[1] - 1]);
        }
        return false;
      }
    } else if (delta.ops.some(op => typeof op.insert === 'string' && op.insert.length > 2)) {
      const removeSpaces = new Delta();
      delta.ops.forEach(op => {
        if (typeof op.retain === 'number') {
          removeSpaces.retain(op.retain);
        } else if (typeof op.insert === 'string') {
          let lastMatchIndex = 0;
          let match: RegExpExecArray;
          while ((match = extraSpaces.exec(op.insert))) {
            const length = match[0].length;
            removeSpaces.retain(extraSpaces.lastIndex - length - lastMatchIndex + 1).delete(length - 1);
            lastMatchIndex = extraSpaces.lastIndex;
          }
          removeSpaces.retain(op.insert.length - lastMatchIndex);
        }
      });
      event.modify(removeSpaces);
    }
  }

  return {
    init() {
      editor.on('changing', onTextChanging);
    },
    destroy() {
      editor.off('changing', onTextChanging);
    },
  };
}
