import { UserAccess } from 'agreeable-client';
import { derived } from 'easy-signal';
import { createAgreeablePrefixedStore } from './agreeable/agreeable-stores';
import { projectIdStore, uidStore } from './ids';
import { getUrl, goto } from './navigation';
import { projectRolesStore, projectStore } from './project-data';
import { settingsStore } from './settings';
import { Project, ProjectSettings } from './types';
import { userProjectsStore } from './user-data';
import { EMPTY_OBJECT } from './util';

export const userAccessStore = derived(() => projectRolesStore.get()?.users?.[uidStore.get()] || ({} as UserAccess));
export const userProjectStore = createAgreeablePrefixedStore(userProjectsStore, projectIdStore);
export const userDocsStore = createAgreeablePrefixedStore(userProjectStore, 'docs');
export const userProjectGoalStore = createAgreeablePrefixedStore(userProjectStore, 'goal');

export function gotoDefault(project: Project) {
  const projectSettings = settingsStore.getFor(project) as ProjectSettings;
  if (!projectSettings.getDefaultDocument) return;
  const doc = projectSettings.getDefaultDocument(project);
  if (!doc) return;
  goto(getUrl(doc.id, project.id), true);
}

export async function selectProject(projectId: string) {
  const userProject = userProjectsStore.get()[projectId];
  if (!userProject) return;
  const currentProjectId = projectStore.get().projectId;
  if (currentProjectId && currentProjectId !== projectId) {
    await projectStore.unload();
  }

  await goto(getUrl(userProject.lastSelected, projectId));

  if (!userProject.lastSelected) {
    if (projectStore.get().project) gotoDefault(projectStore.get().project);
    else {
      const unsubscribe = projectStore.subscribe(data => {
        if (data.project) {
          unsubscribe();
          gotoDefault(data.project);
        }
      });
    }
  }
}

export function getProjectUrl(projectId: string) {
  const userProject = userProjectsStore.get()[projectId];
  if (!userProject) return '/';
  return getUrl(userProject.lastSelected, projectId);
}

export const userRoleStore = derived(() => {
  return projectRolesStore.get().users?.[uidStore.get()] || EMPTY_OBJECT;
});
