import { projectStore } from '@dabble/data/project-data';
import { Doc } from '@dabble/data/types';
import { userRoleStore } from '@dabble/data/user-project';
import { mdiBookOpenOutline } from '@mdi/js';

export const createToc = [
  {
    key: 'novel_create_toc',
    icon: mdiBookOpenOutline,
    action: setCreateToc,
    hide: (doc: Doc) => (doc.toc ? true : false),
    disabled: () => !userRoleStore.get(),
  },
];

export const removeToc = [
  {
    key: 'novel_remove_toc',
    icon: mdiBookOpenOutline,
    action: setRemoveToc,
    hide: (doc: Doc) => (doc.toc ? false : true),
    disabled: () => !userRoleStore.get(),
  },
];

async function setCreateToc(doc: Doc) {
  await projectStore.updateDoc(doc.id, { toc: {} });
}

async function setRemoveToc(doc: Doc) {
  await projectStore.updateDoc(doc.id, { toc: undefined });
}
