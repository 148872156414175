<script lang="ts">
  import { agreeableStateStore } from '@dabble/data/agreeable';
  import { featuresStore } from '@dabble/data/global-data';
  import { t } from '@dabble/data/intl';
  import Dropdown from '@dabble/toolkit/Dropdown.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import Modal from '@dabble/toolkit/Modal.svelte';
  import { version } from '@dabble/version';
  import { mdiAccountGroup, mdiBullhorn, mdiChat, mdiEmail, mdiHelpCircle, mdiKeyboard } from '@mdi/js';

  let dropdownVisible: boolean;
  let shortcuts = false;

  function chat() {
    if (window.Intercom) {
      window.Intercom('show');
    }
  }

  function openShortcuts() {
    shortcuts = true;
  }

  function closeShortcuts() {
    shortcuts = false;
  }
</script>

<button class="btn dropdown-toggle focus-fade" class:active={dropdownVisible} on:click={() => (dropdownVisible = true)}>
  <Icon path={mdiHelpCircle} />
</button>
{#if dropdownVisible}
  <Dropdown on:close={() => (dropdownVisible = false)} placement="bottom-end">
    <a href="https://help.dabblewriter.com/" target="_blank" class="dropdown-item" rel="noreferrer">
      <Icon path={mdiHelpCircle} />
      {$t('support_visit_help')}
    </a>
    {#if $featuresStore.support_chat && $agreeableStateStore.online && window.Intercom}
      <button class="dropdown-item" on:click={chat}>
        <Icon path={mdiChat} />
        {$t('support_chat')}
      </button>
    {:else}
      <a class="dropdown-item" href="mailto:support@dabblewriter.com" target="_blank">
        <Icon path={mdiEmail} />
        {$t('support_email')}
      </a>
    {/if}
    <a href="https://storycraft.cafe/" target="_blank" class="dropdown-item" rel="noreferrer">
      <Icon path={mdiAccountGroup} />
      {$t('dabble_community')}
    </a>
    <a href="https://dabble.featureupvote.com/" target="_blank" class="dropdown-item" rel="noreferrer">
      <Icon path={mdiBullhorn} />
      {$t('request_feature')}
    </a>
    <button class="dropdown-item" on:click={openShortcuts}>
      <Icon path={mdiKeyboard} />
      {$t('shortcuts')}
    </button>
    <hr />
    <div class="dropdown-item disabled">
      Dabble v{version}
    </div>
  </Dropdown>
{/if}

{#if shortcuts}
  <Modal title={$t('dabble_shortcuts')} size="large" on:close={closeShortcuts}>
    <section class="modal">
      <div>
        <h2>{$t('navigation_pane')}</h2>

        <ul>
          <li><code>DEL</code> - {$t('nav_delete')}</li>
        </ul>

        <h2>{$t('formatting')}</h2>

        <ul>
          <li><code>CTRL + 1 (CMD + 1)</code> - {$t('doc_heading_1')}</li>
          <li><code>CTRL + 2 (CMD + 2)</code> - {$t('doc_heading_2')}</li>
          <li><code>CTRL + 3 (CMD + 3)</code> - {$t('doc_heading_3')}</li>
          <li><code>CTRL + 4 (CMD + 4)</code> - {$t('doc_heading_4')}</li>
          <li><code>CTRL + 5 (CMD + 5)</code> - {$t('doc_heading_5')}</li>
          <li><code>CTRL + 6 (CMD + 6)</code> - {$t('doc_heading_6')}</li>
          <li><code>CTRL + B (CMD + B)</code> - {$t('doc_bold')}</li>
          <li><code>CTRL + I (CMD + I)</code> - {$t('doc_italic')}</li>
          <li><code>CTRL + U (CMD + U)</code>- {$t('doc_underline')}</li>
        </ul>
      </div>
      <div>
        <h2>{$t('dabble_document')}</h2>
        <ul>
          <li><code>CTRL + C (CMD + C)</code> - {$t('doc_copy')}</li>
          <li><code>CTRL + X (CMD + X)</code> - {$t('doc_cut')}</li>
          <li><code>CTRL + V (CMD + V)</code> - {$t('doc_paste')}</li>
          <li><code>CTRL + Z (CMD + Z)</code> - {$t('doc_undo')}</li>
          <li><code>CTRL + Y (CMD + Y)</code> - {$t('doc_redo')}</li>
          <li><code>CTRL + F (CMD + F)</code> - {$t('doc_find')}</li>
          <li><code>CTRL + H (CMD + H)</code> - {$t('doc_replace')}</li>
          <li><code>SHIFT + ENTER</code> - {$t('doc_break')}</li>
          <li><code>CTRL + ENTER (CMD + ENTER)</code> - {$t('doc_new_scene')}</li>
          <li><code>CTRL + SHIFT + ENTER (CMD + SHIFT + ENTER)</code> - {$t('doc_new_chapter')}</li>
          <li><code>(CMD + E)</code> - {$t('doc_current_mac')}</li>
          <li><code>(CMD + G)</code> - {$t('doc_next_mac')}</li>
          <li><code>CTRL + P(CMD + P)</code> - {$t('doc_print')}</li>
          <li><code>ESC</code> - {$t('doc_escape')}</li>
        </ul>
      </div>
    </section>
  </Modal>
{/if}

<style>
  section.modal {
    display: flex;
  }

  section > div {
    width: 50%;
    padding: 10px;
  }

  section ul {
    padding: 0;
    margin: 0;
  }

  section li {
    list-style-type: none;
  }

  code {
    background-color: var(--gray-lighter);
    color: var(--dabble-red);
    font-size: 0.8rem;
    padding: 2px;
  }
</style>
