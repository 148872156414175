import { UserProject, UserProjectsStore } from '../agreeable/agreeable-config';
import { DabbleAgreeableClient } from '../types';
import { createDerivedStore } from './derived-store';

export type ProjectMetasStore = ReturnType<typeof createProjectMetasStore>;

export const userProjectSort = (a: [string, UserProject], b: [string, UserProject]) =>
  (b[1].lastOpened || Number.MAX_SAFE_INTEGER) - (a[1].lastOpened || Number.MAX_SAFE_INTEGER);

function getSortedProjectKeys(userProjectsStore: UserProjectsStore) {
  const entries = Object.entries(userProjectsStore.get()).sort(userProjectSort);
  return entries.map(([projectId]) => projectId);
}

/**
 * A store with all the project meta data pulled into one object.
 */
export function createProjectMetasStore(agreeable: DabbleAgreeableClient, userProjectsStore: UserProjectsStore) {
  return createDerivedStore(
    () => getSortedProjectKeys(userProjectsStore),
    projectId => agreeable.projects(projectId)
  );
}

/**
 * A store with all the project roles data pulled into one object.
 */
export function createRolesStore(agreeable: DabbleAgreeableClient, userProjectsStore: UserProjectsStore) {
  return createDerivedStore(
    () => getSortedProjectKeys(userProjectsStore),
    projectId => agreeable.projects(projectId).roles
  );
}
