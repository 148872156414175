import { rest } from '@dabble/data/rest';
import { ExportData, ExportFileConstructor } from '../types';
import { WordFile } from './word';

class GoogleDocXFile {
  constructor(data: ExportData) {
    const wordFile = new WordFile(data);
    wordFile.save = async function save() {
      const meta = new Blob([JSON.stringify({ name: this.data.title })], {
        type: 'application/json; charset=UTF-8',
      });
      const file = await wordFile.package();
      const result = await rest.post('/google/upload?uploadType=multipart').send([meta, file]);
      return `https://docs.google.com/document/d/${result.id}`;
    };
    return wordFile;
  }
}

export const File = GoogleDocXFile as ExportFileConstructor;
