import { type createAgreeablePrefixedStore } from '@dabble/data/agreeable/agreeable-stores';
import { isApple } from '@dabble/data/device';
import { FindStateStore } from '@dabble/data/stores/find-replace';
import { RouterStore } from '@dabble/data/stores/router';
import { Preferences } from '@dabble/data/types';
import { desktop } from '@dabble/desktop';
import interactions from '@dabble/toolkit/interactions';
import { Unsubscriber } from 'easy-signal';

/**
 * A tie to the UI/view to help find-replace find the currently selected text, active element, current editables on the screen, visible docs, and more.
 */
export function setupFindReplace(
  findReplace: FindStateStore,
  preferences: ReturnType<typeof createAgreeablePrefixedStore>,
  router: RouterStore
): void {
  let removeShortcuts: Unsubscriber;

  if (desktop.inApp) {
    addShortcuts();
  } else {
    preferences.subscribe(onPreferencesChange);
  }

  // Functions

  function onPreferencesChange(preferences: Preferences) {
    if (preferences.preferNativeFind) {
      if (removeShortcuts) removeShortcuts();
      removeShortcuts = null;
    } else {
      if (!removeShortcuts) addShortcuts();
    }
  }

  interface FunctionWithPrevent extends Function {
    preventDefault: (event: Event) => void;
  }

  function preventDefaultAnd(method: Function) {
    const modified = method as FunctionWithPrevent;
    return (
      modified.preventDefault ||
      (modified.preventDefault = (event: Event) => {
        if (!router.getUrl().startsWith('/p/')) return;
        event.preventDefault();
        method();
      })
    );
  }

  function addShortcuts() {
    if (isApple) {
      interactions.on('shortcut:Cmd+F', preventDefaultAnd(findReplace.open));
      interactions.on('shortcut:Cmd+Shift+F', preventDefaultAnd(findReplace.openInProject));
      interactions.on('shortcut:Cmd+Alt+F', preventDefaultAnd(findReplace.openReplace));
      interactions.on('shortcut:Cmd+G', preventDefaultAnd(findReplace.findNext));
      interactions.on('shortcut:Cmd+Shift+G', preventDefaultAnd(findReplace.findPrevious));
      interactions.on('shortcut:Cmd+E', preventDefaultAnd(findReplace.useCurrentWordForFind));
      removeShortcuts = () => {
        interactions.off('shortcut:Cmd+F', preventDefaultAnd(findReplace.open));
        interactions.off('shortcut:Cmd+Shift+F', preventDefaultAnd(findReplace.openInProject));
        interactions.off('shortcut:Cmd+Alt+F', preventDefaultAnd(findReplace.openReplace));
        interactions.off('shortcut:Cmd+G', preventDefaultAnd(findReplace.findNext));
        interactions.off('shortcut:Cmd+Shift+G', preventDefaultAnd(findReplace.findPrevious));
        interactions.off('shortcut:Cmd+E', preventDefaultAnd(findReplace.useCurrentWordForFind));
      };
    } else {
      interactions.on('shortcut:Ctrl+F', preventDefaultAnd(findReplace.open));
      interactions.on('shortcut:Ctrl+Shift+F', preventDefaultAnd(findReplace.openInProject));
      interactions.on('shortcut:Ctrl+H', preventDefaultAnd(findReplace.openReplace));
      interactions.on('shortcut:Cmd+G', preventDefaultAnd(findReplace.findNext));
      interactions.on('shortcut:Cmd+Shift+G', preventDefaultAnd(findReplace.findPrevious));
      removeShortcuts = () => {
        interactions.off('shortcut:Ctrl+F', preventDefaultAnd(findReplace.open));
        interactions.off('shortcut:Ctrl+Shift+F', preventDefaultAnd(findReplace.openInProject));
        interactions.off('shortcut:Ctrl+H', preventDefaultAnd(findReplace.openReplace));
        interactions.off('shortcut:F3', preventDefaultAnd(findReplace.findNext));
        interactions.off('shortcut:Shift+F3', preventDefaultAnd(findReplace.findPrevious));
      };
    }
  }
}
