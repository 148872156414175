import { AlignmentType, INumberingOptions, LevelFormat, convertInchesToTwip } from 'docx';

const numeric = {
  reference: 'numeric',
  levels: [
    {
      level: 0,
      format: LevelFormat.DECIMAL,
      alignment: AlignmentType.LEFT,
      text: '%1.',
      style: {
        paragraph: {
          indent: {
            left: convertInchesToTwip(0.5),
            hanging: convertInchesToTwip(0.25),
          },
        },
      },
    },
    {
      level: 1,
      format: LevelFormat.DECIMAL,
      alignment: AlignmentType.LEFT,
      text: '%2.',
      style: {
        paragraph: {
          indent: {
            left: convertInchesToTwip(1),
            hanging: convertInchesToTwip(0.25),
          },
        },
      },
    },
    {
      level: 2,
      format: LevelFormat.DECIMAL,
      alignment: AlignmentType.LEFT,
      text: '%3.',
      style: {
        paragraph: {
          indent: { left: 2160, hanging: convertInchesToTwip(0.25) },
        },
      },
    },
    {
      level: 3,
      format: LevelFormat.DECIMAL,
      alignment: AlignmentType.LEFT,
      text: '%4.',
      style: {
        paragraph: {
          indent: { left: 2880, hanging: convertInchesToTwip(0.25) },
        },
      },
    },
    {
      level: 4,
      format: LevelFormat.DECIMAL,
      alignment: AlignmentType.LEFT,
      text: '%5.',
      style: {
        paragraph: {
          indent: { left: 3600, hanging: convertInchesToTwip(0.25) },
        },
      },
    },
    {
      level: 5,
      format: LevelFormat.DECIMAL,
      alignment: AlignmentType.LEFT,
      text: '%6.',
      style: {
        paragraph: {
          indent: { left: 4320, hanging: convertInchesToTwip(0.25) },
        },
      },
    },
    {
      level: 6,
      format: LevelFormat.DECIMAL,
      alignment: AlignmentType.LEFT,
      text: '%7.',
      style: {
        paragraph: {
          indent: { left: 5040, hanging: convertInchesToTwip(0.25) },
        },
      },
    },
    {
      level: 7,
      format: LevelFormat.DECIMAL,
      alignment: AlignmentType.LEFT,
      text: '%8.',
      style: {
        paragraph: {
          indent: { left: 5760, hanging: convertInchesToTwip(0.25) },
        },
      },
    },
    {
      level: 8,
      format: LevelFormat.DECIMAL,
      alignment: AlignmentType.LEFT,
      text: '%9.',
      style: {
        paragraph: {
          indent: { left: 6480, hanging: convertInchesToTwip(0.25) },
        },
      },
    },
  ],
};

const lowerLetter = {
  reference: 'lowerLetter',
  levels: [
    {
      level: 0,
      format: LevelFormat.LOWER_LETTER,
      alignment: AlignmentType.LEFT,
      text: '%1.',
      style: {
        paragraph: {
          indent: {
            left: convertInchesToTwip(0.5),
            hanging: convertInchesToTwip(0.25),
          },
        },
      },
    },
    {
      level: 1,
      format: LevelFormat.LOWER_LETTER,
      alignment: AlignmentType.LEFT,
      text: '%2.',
      style: {
        paragraph: {
          indent: {
            left: convertInchesToTwip(1),
            hanging: convertInchesToTwip(0.25),
          },
        },
      },
    },
    {
      level: 2,
      format: LevelFormat.LOWER_LETTER,
      alignment: AlignmentType.LEFT,
      text: '%3.',
      style: {
        paragraph: {
          indent: { left: 2160, hanging: convertInchesToTwip(0.25) },
        },
      },
    },
    {
      level: 3,
      format: LevelFormat.LOWER_LETTER,
      alignment: AlignmentType.LEFT,
      text: '%4.',
      style: {
        paragraph: {
          indent: { left: 2880, hanging: convertInchesToTwip(0.25) },
        },
      },
    },
    {
      level: 4,
      format: LevelFormat.LOWER_LETTER,
      alignment: AlignmentType.LEFT,
      text: '%5.',
      style: {
        paragraph: {
          indent: { left: 3600, hanging: convertInchesToTwip(0.25) },
        },
      },
    },
    {
      level: 5,
      format: LevelFormat.LOWER_LETTER,
      alignment: AlignmentType.LEFT,
      text: '%6.',
      style: {
        paragraph: {
          indent: { left: 4320, hanging: convertInchesToTwip(0.25) },
        },
      },
    },
    {
      level: 6,
      format: LevelFormat.LOWER_LETTER,
      alignment: AlignmentType.LEFT,
      text: '%7.',
      style: {
        paragraph: {
          indent: { left: 5040, hanging: convertInchesToTwip(0.25) },
        },
      },
    },
    {
      level: 7,
      format: LevelFormat.LOWER_LETTER,
      alignment: AlignmentType.LEFT,
      text: '%8.',
      style: {
        paragraph: {
          indent: { left: 5760, hanging: convertInchesToTwip(0.25) },
        },
      },
    },
    {
      level: 8,
      format: LevelFormat.LOWER_LETTER,
      alignment: AlignmentType.LEFT,
      text: '%9.',
      style: {
        paragraph: {
          indent: { left: 6480, hanging: convertInchesToTwip(0.25) },
        },
      },
    },
  ],
};

const upperLetter = {
  reference: 'upperLetter',
  levels: [
    {
      level: 0,
      format: LevelFormat.UPPER_LETTER,
      alignment: AlignmentType.LEFT,
      text: '%1.',
      style: {
        paragraph: {
          indent: {
            left: convertInchesToTwip(0.5),
            hanging: convertInchesToTwip(0.25),
          },
        },
      },
    },
    {
      level: 1,
      format: LevelFormat.UPPER_LETTER,
      alignment: AlignmentType.LEFT,
      text: '%2.',
      style: {
        paragraph: {
          indent: {
            left: convertInchesToTwip(1),
            hanging: convertInchesToTwip(0.25),
          },
        },
      },
    },
    {
      level: 2,
      format: LevelFormat.UPPER_LETTER,
      alignment: AlignmentType.LEFT,
      text: '%3.',
      style: {
        paragraph: {
          indent: { left: 2160, hanging: convertInchesToTwip(0.25) },
        },
      },
    },
    {
      level: 3,
      format: LevelFormat.UPPER_LETTER,
      alignment: AlignmentType.LEFT,
      text: '%4.',
      style: {
        paragraph: {
          indent: { left: 2880, hanging: convertInchesToTwip(0.25) },
        },
      },
    },
    {
      level: 4,
      format: LevelFormat.UPPER_LETTER,
      alignment: AlignmentType.LEFT,
      text: '%5.',
      style: {
        paragraph: {
          indent: { left: 3600, hanging: convertInchesToTwip(0.25) },
        },
      },
    },
    {
      level: 5,
      format: LevelFormat.UPPER_LETTER,
      alignment: AlignmentType.LEFT,
      text: '%6.',
      style: {
        paragraph: {
          indent: { left: 4320, hanging: convertInchesToTwip(0.25) },
        },
      },
    },
    {
      level: 6,
      format: LevelFormat.UPPER_LETTER,
      alignment: AlignmentType.LEFT,
      text: '%7.',
      style: {
        paragraph: {
          indent: { left: 5040, hanging: convertInchesToTwip(0.25) },
        },
      },
    },
    {
      level: 7,
      format: LevelFormat.UPPER_LETTER,
      alignment: AlignmentType.LEFT,
      text: '%8.',
      style: {
        paragraph: {
          indent: { left: 5760, hanging: convertInchesToTwip(0.25) },
        },
      },
    },
    {
      level: 8,
      format: LevelFormat.UPPER_LETTER,
      alignment: AlignmentType.LEFT,
      text: '%9.',
      style: {
        paragraph: {
          indent: { left: 6480, hanging: convertInchesToTwip(0.25) },
        },
      },
    },
  ],
};

const lowerRoman = {
  reference: 'lowerRoman',
  levels: [
    {
      level: 0,
      format: LevelFormat.LOWER_ROMAN,
      alignment: AlignmentType.LEFT,
      text: '%1.',
      style: {
        paragraph: {
          indent: {
            left: convertInchesToTwip(0.5),
            hanging: convertInchesToTwip(0.25),
          },
        },
      },
    },
    {
      level: 1,
      format: LevelFormat.LOWER_ROMAN,
      alignment: AlignmentType.LEFT,
      text: '%2.',
      style: {
        paragraph: {
          indent: {
            left: convertInchesToTwip(1),
            hanging: convertInchesToTwip(0.25),
          },
        },
      },
    },
    {
      level: 2,
      format: LevelFormat.LOWER_ROMAN,
      alignment: AlignmentType.LEFT,
      text: '%3.',
      style: {
        paragraph: {
          indent: { left: 2160, hanging: convertInchesToTwip(0.25) },
        },
      },
    },
    {
      level: 3,
      format: LevelFormat.LOWER_ROMAN,
      alignment: AlignmentType.LEFT,
      text: '%4.',
      style: {
        paragraph: {
          indent: { left: 2880, hanging: convertInchesToTwip(0.25) },
        },
      },
    },
    {
      level: 4,
      format: LevelFormat.LOWER_ROMAN,
      alignment: AlignmentType.LEFT,
      text: '%5.',
      style: {
        paragraph: {
          indent: { left: 3600, hanging: convertInchesToTwip(0.25) },
        },
      },
    },
    {
      level: 5,
      format: LevelFormat.LOWER_ROMAN,
      alignment: AlignmentType.LEFT,
      text: '%6.',
      style: {
        paragraph: {
          indent: { left: 4320, hanging: convertInchesToTwip(0.25) },
        },
      },
    },
    {
      level: 6,
      format: LevelFormat.LOWER_ROMAN,
      alignment: AlignmentType.LEFT,
      text: '%7.',
      style: {
        paragraph: {
          indent: { left: 5040, hanging: convertInchesToTwip(0.25) },
        },
      },
    },
    {
      level: 7,
      format: LevelFormat.LOWER_ROMAN,
      alignment: AlignmentType.LEFT,
      text: '%8.',
      style: {
        paragraph: {
          indent: { left: 5760, hanging: convertInchesToTwip(0.25) },
        },
      },
    },
    {
      level: 8,
      format: LevelFormat.LOWER_ROMAN,
      alignment: AlignmentType.LEFT,
      text: '%9.',
      style: {
        paragraph: {
          indent: { left: 6480, hanging: convertInchesToTwip(0.25) },
        },
      },
    },
  ],
};

const upperRoman = {
  reference: 'upperRoman',
  levels: [
    {
      level: 0,
      format: LevelFormat.UPPER_ROMAN,
      alignment: AlignmentType.LEFT,
      text: '%1.',
      style: {
        paragraph: {
          indent: {
            left: convertInchesToTwip(0.5),
            hanging: convertInchesToTwip(0.25),
          },
        },
      },
    },
    {
      level: 1,
      format: LevelFormat.UPPER_ROMAN,
      alignment: AlignmentType.LEFT,
      text: '%2.',
      style: {
        paragraph: {
          indent: {
            left: convertInchesToTwip(1),
            hanging: convertInchesToTwip(0.25),
          },
        },
      },
    },
    {
      level: 2,
      format: LevelFormat.UPPER_ROMAN,
      alignment: AlignmentType.LEFT,
      text: '%3.',
      style: {
        paragraph: {
          indent: { left: 2160, hanging: convertInchesToTwip(0.25) },
        },
      },
    },
    {
      level: 3,
      format: LevelFormat.UPPER_ROMAN,
      alignment: AlignmentType.LEFT,
      text: '%4.',
      style: {
        paragraph: {
          indent: { left: 2880, hanging: convertInchesToTwip(0.25) },
        },
      },
    },
    {
      level: 4,
      format: LevelFormat.UPPER_ROMAN,
      alignment: AlignmentType.LEFT,
      text: '%5.',
      style: {
        paragraph: {
          indent: { left: 3600, hanging: convertInchesToTwip(0.25) },
        },
      },
    },
    {
      level: 5,
      format: LevelFormat.UPPER_ROMAN,
      alignment: AlignmentType.LEFT,
      text: '%6.',
      style: {
        paragraph: {
          indent: { left: 4320, hanging: convertInchesToTwip(0.25) },
        },
      },
    },
    {
      level: 6,
      format: LevelFormat.UPPER_ROMAN,
      alignment: AlignmentType.LEFT,
      text: '%7.',
      style: {
        paragraph: {
          indent: { left: 5040, hanging: convertInchesToTwip(0.25) },
        },
      },
    },
    {
      level: 7,
      format: LevelFormat.UPPER_ROMAN,
      alignment: AlignmentType.LEFT,
      text: '%8.',
      style: {
        paragraph: {
          indent: { left: 5760, hanging: convertInchesToTwip(0.25) },
        },
      },
    },
    {
      level: 8,
      format: LevelFormat.UPPER_ROMAN,
      alignment: AlignmentType.LEFT,
      text: '%9.',
      style: {
        paragraph: {
          indent: { left: 6480, hanging: convertInchesToTwip(0.25) },
        },
      },
    },
  ],
};

export const numbering: INumberingOptions = {
  config: [numeric, lowerLetter, upperLetter, lowerRoman, upperRoman],
};
