import { projectStore } from '@dabble/data/project-data';
import { settingsStore } from '@dabble/data/settings';
import { Doc } from '@dabble/data/types';
import { getTitle } from '@dabble/data/util';
import { readable, writable } from 'easy-signal';
import { Editor, EditorChangeEvent, EditorRange, ShortcutEvent } from 'typewriter-editor';
import Mentions from './Mentions.svelte';
import { getLink } from './note-linking';

export const currentHoverIndex = writable(0);
export const menuPosition = writable<EditorRange | undefined>([0, 0]);
export const lastSelection = writable([0, 0]);
export const wordCopy = writable('');
export const docs = writable<Doc[]>([]);
export const listScrollTop = writable(0);
export const listScrollPositionTracker = writable(0);
export const listItemHeight = readable(34);

export function mentionsModule(editor: Editor) {
  const arrowUp = 'ArrowUp';
  const arrowDown = 'ArrowDown';
  const enter = 'Enter';
  const backspace = 'Backspace';
  const menuShortcut = '@';

  let menu: Mentions = null;
  let allDocs: Doc[];
  let close: () => void;

  function populateAllDocs() {
    allDocs = Object.values(projectStore.get().docs)
      .filter(doc => {
        if (settingsStore.getFor(doc).mentionsMenu) {
          if (getTitle(doc)) return doc;
        }
      })
      .sort((a, b) => getTitle(a).toLocaleLowerCase().localeCompare(getTitle(b).toLocaleLowerCase()));
  }

  function onChange(event: EditorChangeEvent) {
    const { change } = event;
    const selection = change && change.selection;
    if (!change || !selection || !editor.enabled) return;

    const currentSelection: EditorRange = [selection[0] - 1, selection[1]];
    const currentChar = editor.doc.getText(currentSelection as EditorRange);
    menuPosition.set(currentSelection);

    //if current selection is continuous from the previous selection...
    if (
      currentSelection[0] - 1 === lastSelection.get()[0] &&
      currentSelection[1] - 1 === lastSelection.get()[1] &&
      (currentChar === '@' || wordCopy.get()[0] === '@')
    ) {
      //build a word copy
      wordCopy.update(copy => copy + currentChar);
      search(wordCopy.get().substring(1));
    } else if (
      currentSelection[0] + 1 === lastSelection.get()[0] &&
      currentSelection[1] + 1 === lastSelection.get()[1] &&
      (currentChar === '@' || wordCopy.get()[0] === '@')
    ) {
      // the delete case is handled in onKeyPress
    } else {
      closeMenu();
    }
    lastSelection.set(currentSelection);
  }

  function onShortcut(event: ShortcutEvent) {
    if (event.key === menuShortcut && !menu) {
      menu = new Mentions({
        target: document.documentElement,
        props: {
          editor,
        },
      });
      populateAllDocs();
      close = menu.$on('close', closeMenu);
      return;
    }

    if (!menu) return;

    if (event.key === backspace) {
      wordCopy.update(copy => copy.slice(0, -1));
      search(wordCopy.get().substring(1));
    } else if (event.key === enter) {
      event.preventDefault();
      const link = getLink(docs.get()[currentHoverIndex.get()]);
      const title = getTitle(docs.get()[currentHoverIndex.get()]);
      editor.delete([lastSelection.get()[0] - wordCopy.get().length + 1, lastSelection.get()[1]] as EditorRange);
      editor.insert(title, { link: link + '' });
      editor.formatText({ link: null });
      closeMenu();
    } else if (event.key === arrowUp) {
      event.preventDefault();
      if (currentHoverIndex.get() > 0) {
        currentHoverIndex.update(n => n - 1);
      }
      if (currentHoverIndex.get() < listScrollPositionTracker.get()) {
        listScrollTop.update(
          scrollTop => Math.round((scrollTop / listItemHeight.get()) * listItemHeight.get()) - listItemHeight.get()
        );
        listScrollPositionTracker.update(position => position - 1);
      }
    } else if (event.key === arrowDown) {
      event.preventDefault();
      if (currentHoverIndex.get() < docs.get().length - 1) {
        currentHoverIndex.update(i => i + 1);
      }
      if (currentHoverIndex.get() > listScrollPositionTracker.get() + 3) {
        listScrollTop.update(scrollTop => scrollTop + listItemHeight.get());
        listScrollPositionTracker.update(position => position + 1);
      }
    }
  }

  function getSubStringPosition(string: string, searchString: string) {
    return string.toLocaleLowerCase().indexOf(searchString.toLocaleLowerCase());
  }

  function search(wordCopy: string) {
    if (!menu) return;
    const searchString = wordCopy.toLocaleLowerCase();
    const filteredResults = allDocs.filter(doc => getTitle(doc).toLocaleLowerCase().includes(searchString));
    const sortedResults = filteredResults.sort(
      (a, b) => getSubStringPosition(getTitle(a), searchString) - getSubStringPosition(getTitle(b), searchString)
    );
    //if no results, close the menu or else set the list with sorted docs
    if (sortedResults.length === 0) {
      closeMenu();
    } else {
      listScrollTop.update(() => 0);
      currentHoverIndex.set(0);
      listScrollPositionTracker.set(0);
      docs.set([...sortedResults]);
    }
  }

  function closeMenu() {
    wordCopy.set('');
    listScrollTop.set(0);
    currentHoverIndex.set(0);
    listScrollPositionTracker.set(0);
    lastSelection.set([0, 0]);
    allDocs = [];
    docs.set(allDocs);
    destroyMenu();
  }

  function destroyMenu() {
    if (!menu) return;
    close();
    menu.$destroy();
    menu = null;
  }

  return {
    init() {
      if (!editor.typeset.formats.get('link')) {
        return;
      }
      editor.root.addEventListener('shortcut', onShortcut);
      editor.on('change', onChange);
    },
    destroy() {
      editor.root.removeEventListener('shortcut', onShortcut);
      editor.off('change', onChange);
      closeMenu();
    },
  };
}
