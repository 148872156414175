<script lang="ts">
  import { featuresStore } from '@dabble/data/global-data';
  import { t } from '@dabble/data/intl';
  import { projectStore } from '@dabble/data/project-data';
  import { settingsStore } from '@dabble/data/settings';
  import { Doc } from '@dabble/data/types';
  import { getTitle } from '@dabble/data/util';
  import { saveFile } from '@dabble/plugins/content/content';
  import { constrainImage } from '@dabble/plugins/content/images';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import { mdiAccount, mdiCamera } from '@mdi/js';

  export let doc: Doc;
  export let canEdit = false;
  let size = 100;
  let fileInput: HTMLInputElement;

  $: feature = settingsStore.getFor(doc.type).requiresFeature;
  $: editable = canEdit && (!feature || $featuresStore[feature]);
  $: name = getTitle(doc);
  $: initials = getInitials(doc.title);
  $: colorIndex = initials ? (initials.charCodeAt(0) - 64) % 5 : 0;

  function getInitials(name: string) {
    name = (name || '').trim();
    const parts = name.split(/\s+/);
    return !name ? '' : parts.length === 1 ? parts[0].slice(0, 2) : parts[0][0] + parts[parts.length - 1][0];
  }

  async function upload() {
    const file = fileInput.files[0];
    if (!file) return;
    const resized = await constrainImage(file, 400);
    const url = await saveFile(resized);
    projectStore.updateDoc(doc.id, { avatarUrl: url });
  }
</script>

<div class="avatar color-{colorIndex}" style="--size:{size}px" bind:offsetHeight={size}>
  {#if doc.avatarUrl}
    <img class="avatar-img" src={doc.avatarUrl} alt={name} />
  {:else if initials}
    <div class="avatar-initials">{initials}</div>
  {:else}
    <div class="avatar-icon"><Icon path={mdiAccount} /></div>
  {/if}
  {#if editable}
    <div class="actions">
      <button class="upload" on:click={() => fileInput.click()} use:tooltipTop={$t('change_avatar')}>
        <input bind:this={fileInput} type="file" on:change={upload} />
        <Icon path={mdiCamera} />
      </button>
    </div>
  {/if}
</div>

<style>
  .color-0 {
    --bgColor: rgb(var(--red-500) / var(--color-opacity));
  }
  .color-1 {
    --bgColor: rgb(var(--dabble-blue-500) / var(--color-opacity));
  }
  .color-2 {
    --bgColor: rgb(var(--amber-500) / var(--color-opacity));
  }
  .color-3 {
    --bgColor: rgb(var(--purple-900) / var(--color-opacity));
  }
  .color-4 {
    --bgColor: rgb(var(--green-700) / var(--color-opacity));
  }
  .avatar {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
    margin-top: -50%;
    width: 100%;
    aspect-ratio: 1 / 1;
    border-radius: 9999px;
    border: 4px solid var(--white);
    background: var(--white);
    box-shadow:
      0 5px 15px -2px rgba(0, 0, 0, 0.2),
      0 1px 4px -1px rgba(0, 0, 0, 0.1);
    --color-opacity: 100%;
  }
  :global(html[data-theme='dark']) .avatar {
    --color-opacity: 80%;
  }
  .avatar-img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  :global(html[data-theme='dark']) .avatar-img {
    filter: brightness(0.8);
  }
  .avatar-initials {
    display: grid;
    place-items: center;
    width: 100%;
    height: 100%;
    background-color: var(--bgColor);
    background: var(--bgColor) radial-gradient(circle at 50% 20%, rgb(255 255 255 / 40%), var(--bgColor) 75%);
    color: var(--white);
    text-align: center;
    font-size: calc(var(--size) / 2.5);
    line-height: 1;
    user-select: none;
    pointer-events: none;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  }
  .avatar-icon {
    flex: 0 0 auto;
    width: 120%;
    height: 120%;
    color: rgb(var(--dabble-blue-700) / 50%);
    --bgColor: rgb(var(--dabble-blue-300));
    background: var(--bgColor) radial-gradient(circle at 50% 20%, rgb(255 255 255 / 40%), var(--bgColor) 75%);
  }
  :global(html[data-theme='dark']) .avatar-icon {
    color: rgb(var(--dabble-blue-900) / 50%);
    --bgColor: rgb(var(--dabble-blue-600));
  }
  .avatar-icon :global(svg) {
    width: 100%;
    height: 100%;
  }
  .actions {
    display: none;
    position: absolute;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    inset: 0;
    color: #fff;
    font-size: 1.5rem;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0.9;
  }
  .avatar:hover .actions {
    display: flex;
  }
  .actions button {
    display: flex;
    padding: 1rem;
    border-radius: 999rem;
    background: rgba(0, 0, 0, 0.5);
    border: none;
    cursor: pointer;
  }
  .actions button:hover {
    background: rgba(0, 0, 0, 0.25);
  }
  .actions button :global(svg) {
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
  }
  .upload input {
    opacity: 0;
    width: 0px;
    height: 0px;
  }
</style>
