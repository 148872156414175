<script lang="ts">
  import { routerStore } from '@dabble/data/navigation';

  export let path;
  export let component;
  let params;

  $: params = $routerStore.matches(path);
</script>

{#if params}
  {#if component}
    <svelte:component this={component} {...params} />
  {:else}
    <slot {params} />
  {/if}
{/if}
