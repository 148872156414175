<script lang="ts">
  import { agreeable, agreeableStateStore } from '@dabble/data/agreeable';
  import { isAdminStore, uidStore } from '@dabble/data/ids';
  import { t } from '@dabble/data/intl';
  import { projectMetasStore } from '@dabble/data/project-data';
  import { userProjectsStore } from '@dabble/data/user-data';
  import { getTitle } from '@dabble/data/util';
  import Alert from '@dabble/toolkit/Alert.svelte';
  import { number } from '@dabble/toolkit/helpers';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { fade } from '@dabble/toolkit/transitions';
  import { mdiLoading } from '@mdi/js';
  import saveAs from 'file-saver';

  const levels = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const RECOMMENDED_MIN_SPACE = 5000000; // 5 MB

  let loading = '';
  let error: string;
  let quota: number;
  let usage: number;
  let lowSpace: boolean;
  let projectId: string;

  $: activeProjects = Object.fromEntries(Object.entries($userProjectsStore).filter(([, v]) => v.category !== 'trash'));

  updateStorageData();

  async function updateStorageData() {
    if (navigator.storage?.estimate) {
      const estimate = await navigator.storage.estimate();
      lowSpace = estimate.quota - estimate.usage <= RECOMMENDED_MIN_SPACE;
      quota = estimate.quota;
      usage = estimate.usage;
    } else {
      quota = null;
      usage = null;
    }
  }

  function bytes(bytes: number) {
    let i = 0;
    while (bytes / 1000 > 1 && i++ < levels.length - 1) {
      bytes /= 1000;
    }
    return number(bytes, 1) + ' ' + levels[i];
  }

  async function exportData() {
    loading = 'export';

    try {
      const data = await agreeable.call('exportProjectData', projectId);
      const blob = new Blob([JSON.stringify(data)], { type: 'text/json;charset=utf-8' });
      saveAs(blob, `${getTitle(projectMetasStore.get()[projectId]) || 'Untitled'}-${new Date().toISOString()}.json`);
    } catch (e) {
      error = e.message + ' — contact support';
    }

    loading = '';
  }

  async function exportDatabase() {
    loading = 'export';

    try {
      const data = await agreeable.call('exportDatabase');
      const blob = new Blob([JSON.stringify(data)], { type: 'text/json;charset=utf-8' });
      saveAs(blob, `dabble-${uidStore.get()}-${new Date().toISOString()}.json`);
    } catch (e) {
      error = e.message + ' — contact support';
    }

    loading = '';
  }

  async function importDatabase(event: Event) {
    const file = (event.target as HTMLInputElement).files[0];
    if (!file) return;
    try {
      await agreeable.call('importDatabase', file);
      location.reload();
    } catch (e) {
      error = e.message + ' — contact support';
    }
  }
</script>

<div class="section data">
  {#if loading}
    <div class="loading" transition:fade><span class="text">{$t('loading')}</span> <Icon path={mdiLoading} spin /></div>
  {/if}

  <h2>{$t('data_management')}</h2>

  {#if error}
    <Alert type="danger" dismissible on:close={() => (error = null)}>
      <strong>{$t('error')}:</strong>
      {error}
    </Alert>
  {/if}

  {#if quota !== null}
    <div class="preference vertical">
      <h6>{$t('data_used_header')}</h6>
      <div class="space-available" class:danger={lowSpace}>
        <div class="space-used" style="width: {Math.min(usage / quota, 1) * 100}%" />
      </div>
      <div>{@html $t('data_used_available', { used: bytes(usage), available: bytes(quota) })}</div>
      {#if lowSpace}
        <div><strong>{$t('data_clear_space')}</strong></div>
      {/if}
    </div>
  {/if}

  <div class="preference vertical">
    <h6>{$t('data_export_header')}</h6>
    <div class="description">{$t('data_export_description')}</div>
    <div>
      <select bind:value={projectId}>
        <option value="" disabled>{$t('data_select_project')}</option>
        {#each Object.entries(activeProjects) as [id]}
          <option value={id}>{getTitle($projectMetasStore[id]) || '???'}</option>
        {/each}
      </select>
    </div>
    {#if !$agreeableStateStore.authed}<p><strong>{$t('data_disconnected')}</strong></p>{/if}
    <div class="buttons">
      <button type="button" class="btn primary" on:click={() => exportData()} disabled={!projectId || loading != ''}
        >{$t('data_export_project')}</button
      >
      {#if loading === 'export'}
        &nbsp;<Icon path={mdiLoading} spin /> {$t('data_export_slow')}
      {/if}
    </div>
  </div>

  <div class="preference vertical">
    <h6>{$t('data_export_db_header')}</h6>
    <div class="description">{$t('data_export_db_description')}</div>
    <div class="buttons">
      <button type="button" class="btn primary" on:click={() => exportDatabase()} disabled={loading != ''}
        >{$t('data_export_db')}</button
      >
      {#if $isAdminStore}
        <button type="button" class="btn primary btn-input" disabled={loading != ''}>
          <input class="input" type="file" accept=".json" on:input={importDatabase} disabled={loading != ''} />
          {$t('data_import_db')}
        </button>
      {/if}
      {#if loading === 'export'}
        &nbsp;<Icon path={mdiLoading} spin /> {$t('data_export_slow')}
      {/if}
    </div>
  </div>
</div>

<style>
  .section {
    order: 10;
  }
  .loading {
    color: var(--text-color-lighterer);
  }
  .space-available {
    width: 100%;
    border: 1px solid var(--dabble-blue);
    padding: 0;
    margin-bottom: 0;
    border-radius: 2px;
  }
  .space-available.danger {
    border-color: var(--red);
  }
  .space-used {
    border-right: 1px solid var(--dabble-blue);
    background: rgba(var(--dabble-blue-rgb), 0.5);
    height: 14px;
    min-width: 4px;
    /* border-radius: 2px 0 0 2px; */
  }
  .space-available.danger .space-used {
    border-right: 1px solid var(--red);
    background: var(--red);
  }
  .btn-input {
    position: relative;
  }
  .btn-input .input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 162px;
    opacity: 0;
    cursor: pointer;
  }
</style>
