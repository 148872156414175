import { readable } from 'easy-signal';
import { agreeable } from './agreeable';
import { FeatureTags, GlobalSettings, Migrations } from './agreeable/agreeable-config';
import { createAgreeablePrefixedStore } from './agreeable/agreeable-stores';
import { createFeaturesStore } from './stores/features';
import { userAccountStore } from './user-data';

export const globalMigrationsStore = agreeable.global<Migrations>('migrations');
export const globalFeaturesStore = agreeable.global<FeatureTags>('features');
export const featuresStore = createFeaturesStore(globalFeaturesStore, userAccountStore);
export const globalSettingsStore = agreeable.global<GlobalSettings>('settings');
export const disabledFeaturesStore = createAgreeablePrefixedStore(globalSettingsStore, 'disabled');

globalSettingsStore.load();
globalMigrationsStore.load();
globalFeaturesStore.load();

// TODO: Hardcoded for now.  Redoing templates at some point
export const projectTemplatesStore = readable({
  fantasy: 'lr2CsHI2QDe1vDx1',
  general_fiction: 'aKJS2P2YqX3nd0IW',
  historical: '05riWXnA2yyFv9I0',
  mystery: 'qZCGdretq6jLu7ax',
  romance: 'Wyi2mupWzmjjYWyp',
  sci_fi: 'et0hs4ZliyoRIkEh',
});
