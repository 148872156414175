<script lang="ts">
  import { t } from '@dabble/data/intl';
  import { projectMetaSettingsStore } from '@dabble/data/project-data';
  import { readingVoiceStore, voicesStore } from '../reading';

  $: currentVoice = $readingVoiceStore?.name;

  function onVoiceChange(event: Event) {
    const readingVoiceStore = (event.target as HTMLSelectElement).value;
    projectMetaSettingsStore.update({ 'readingVoice': readingVoiceStore });
  }
</script>

<select class="form-control" id="spellingVoices" on:change={onVoiceChange} value={currentVoice}>
  <option value="">{$t('preferences_select_voice')}</option>
  {#each $voicesStore as voice}
    <option value={voice.name}>{voice.name}</option>
  {/each}
</select>
