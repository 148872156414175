<script lang="ts">
  import { docStore } from '@dabble/data/doc-data';
  import { featuresStore } from '@dabble/data/global-data';
  import { t } from '@dabble/data/intl';
  import { projectStore } from '@dabble/data/project-data';
  import { settingsStore } from '@dabble/data/settings';
  import { ProjectData } from '@dabble/data/stores/project';
  import { Doc } from '@dabble/data/types';
  import { viewport } from '@dabble/data/ui';
  import Card from '@dabble/toolkit/Card.svelte';
  import Title from '@dabble/toolkit/Title.svelte';
  import startCase from 'lodash/startCase';

  const { focusedDocId } = viewport;
  let shadowed = false;
  let offsetWidth = 0;

  $: focusedDoc = $projectStore.docs[$focusedDocId];
  $: docCards = getDocCards(focusedDoc);
  $: plotCards = focusedDoc && getPlotCards(focusedDoc, $projectStore);
  $: small = offsetWidth < 510;
  $: docSettings = focusedDoc && $settingsStore[focusedDoc.id];
  $: unstyled = docSettings && docSettings.unstyledPlaceholders && docSettings.unstyledPlaceholders.title;

  function getDocCards(doc: Doc) {
    const docs = [];
    while (doc) {
      docs.unshift(doc);
      if (doc === $docStore) break;
      doc = projectStore.getParent(doc.id);
    }
    return docs;
  }

  function getPlotCards(focusedDoc: Doc, projectData: ProjectData) {
    if (focusedDoc.type === 'novel_book') return [];
    return projectStore
      .linksTo(focusedDoc.id, 'plot')
      .map(link => link.from)
      .filter(Boolean)
      .sort(projectStore.pathSort);
  }

  function onCardOpen() {
    focusedDocId.lock();
  }

  function onCardClose() {
    focusedDocId.unlock(true);
  }

  function checkShadowed(event: Event) {
    shadowed = (event.target as HTMLElement).scrollTop > 0;
  }
</script>

<div class="scene-notes">
  <h3 class:shadowed class="section-header">
    {$t('novel_doc_notes')}
  </h3>
  {#if focusedDoc}
    <div class="doc-cards" class:large={!small} on:scroll={checkShadowed} bind:offsetWidth>
      {#each docCards as doc}
        <div class="doc-card">
          <div class="section-title">
            {$t('novel_doc_description', { type: startCase(doc.type.replace('novel_', '')) })}
          </div>
          <Card titlePlaceholder={unstyled && $t('novel_title')} {doc} {small} />
        </div>
      {/each}
      {#if focusedDoc && plotCards.length}
        <hr />
        <div class="section-title">{$t('novel_plot_notes')}</div>
        <div class="plot-cards">
          {#each plotCards as doc}
            <div class="card-title"><Title doc={$projectStore.parentsLookup[doc.id]} /></div>
            <Card {doc} {small} on:open={onCardOpen} on:close={onCardClose} readonly={!$featuresStore.plotting} />
          {/each}
        </div>
      {/if}
    </div>
  {/if}
</div>

<style>
  h3 {
    padding: 10px;
  }
  .scene-notes {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-height: 0;
  }
  .doc-cards {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 1 auto;
    min-height: 0;
    padding: 0 10px 40px;
    overflow-y: auto;
  }
  .large :global(.notecard) {
    width: var(--card-width);
  }
  .doc-card :global(.notecard:not(.small)) {
    min-height: 112px;
  }
  .plot-cards :global(.notecard:not(.small)) {
    min-height: 88px;
  }
  .doc-card {
    width: 100%;
  }
  hr {
    width: 100%;
    margin-top: 12px;
    border-top: 1px solid var(--side-nav-color-light);
    margin-bottom: 0;
  }
  .plot-cards :global(.notecard) {
    margin-bottom: 4px;
  }
  .section-title {
    color: var(--text-color-light);
    font-size: var(--font-size-xs);
    font-weight: bold;
    margin: 10px 0 4px;
  }
  .card-title {
    color: var(--text-color-lighter);
    font-size: var(--font-size-xs);
    margin: 10px 0 0;
  }
  .doc-cards > .doc-card:first-child .section-title,
  .plot-cards > .card-title:first-child {
    margin-top: 0;
  }
</style>
