import { DabbleDatabase } from '@dabble/data/old-types';
import { rest } from '@dabble/data/rest';
import { User } from '@dabble/data/types';
import { UserPublicData } from '@dabble/data/users-data';
import { ExtendAPI, Leader } from 'agreeable-client';
import { Browserbase, ObjectStore } from 'browserbase';
import { isEqual } from 'typewriter-editor';
import { DabbleAgreeableClient } from '../../data/agreeable/agreeable-client';

interface Account {
  user: User;
  lastUsed: number;
}

interface OtherData {
  id: string;
  [key: string]: any;
}

interface AuthDatabaseStores {
  auths: ObjectStore<Account>;
  signOut: ObjectStore<string>;
  other: ObjectStore<OtherData>;
}

// sync account data, give APIs for getting account from account db
export async function accountsLeaderExtension(
  client: DabbleAgreeableClient,
  dblDB: DabbleDatabase,
  extendApi: ExtendAPI,
  leader: Leader
) {
  const db = createAuthDB();
  const uid = client.uid.get();
  let getAccountsPromise: Promise<User[]>;
  let userAccounts: User[];
  await db.open();
  extendApi({ getUsers, getAccounts, signOut, getUsersInfo });

  // `justOpenedAccount` marks the current account as having just been switched to or logged into
  // Cache the call for 3 seconds to optimize many tabs opening (and calling this) at once.
  async function getAccounts(justOpenedAccount = false) {
    if (!justOpenedAccount && getAccountsPromise) return getAccountsPromise;
    setTimeout(() => (getAccountsPromise = null), 3000);
    return (getAccountsPromise = getAccountsImpl(justOpenedAccount));
  }

  async function getAccountsImpl(justOpenedAccount?: boolean) {
    const accounts = await db.stores.auths.getAll();

    let transaction = db.start();

    if (client.state.get().online) {
      const byId = new Map(accounts.map(a => [a.user.uid, a]));
      const result: User[] = await rest.get('/auth/accounts').send();
      // Need to get another transaction since the await on the previous line will close the transaction
      transaction = db.start();

      result.forEach(user => {
        const dbUser = byId.get(user.uid);
        if (!dbUser) {
          // signed in but not added to db yet
          const account = { user, lastUsed: client.now() };
          transaction.stores.auths.put(account, user.uid);
          accounts.push(account);
        } else if (!isEqual(user, dbUser.user)) {
          // updated user info
          transaction.stores.auths.put({ user, lastUsed: dbUser.lastUsed }, user.uid);
          dbUser.user = user;
        }
      });
    }

    if (justOpenedAccount) {
      const account = accounts.find(a => a.user.uid === uid);
      if (account) {
        account.lastUsed = client.now();
        transaction.stores.auths.put(account, uid);
      }
    }

    await transaction.commit();

    accounts.sort((a, b) => b.lastUsed - a.lastUsed);
    return (userAccounts = accounts.map(a => a.user));
  }

  function getUsers(uids: string[]) {
    return rest
      .get('/auth/users')
      .query({ uids: uids.join(',') })
      .send();
  }

  async function getUsersInfo({ uids, emails }: { uids?: string[]; emails?: string[] }) {
    if (!uids?.length && !emails?.length) return {};

    let result: { [key: string]: UserPublicData } = {};
    if (uids) {
      for (const id of uids) {
        let user = await dblDB.stores.usersInfo.get(id);
        if (user?.[id]) {
          result[id] = user[id];
          delete uids[uids.indexOf(id)];
        }
      }
    }
    if (emails) {
      for (const id of emails) {
        let user = await dblDB.stores.usersInfo.get(id);
        if (user?.[id]) {
          result[id] = user[id];
          delete uids[uids.indexOf(id)];
        }
      }
    }

    if (uids.length || emails.length) {
      let query: string[] = [];
      let queryString: string;

      if (uids?.length) query.push(`uids=${uids.join(',')}`);
      if (emails?.length) query.push(`emails=${emails.join(',')}`);
      if (query.length) queryString = `?${query.join('&')}`;

      if (!queryString) return result;

      const users = await rest.get(`auth/users${queryString}`).send();

      const trans = dblDB.start();
      for (const user of users) {
        const id = user?.uid || user?.email;
        trans.stores.usersInfo.put({ [id]: user }, 'usersInfo');
        result[id] = user;
      }

      await trans.commit();
    }
    return result;
  }

  async function signOut(from: string = uid) {
    userAccounts = userAccounts.filter(a => a.uid !== from);
    const next = userAccounts[0]?.uid;
    await leader.deleteDatabase(from);
    const trans = db.start();
    trans.stores.signOut.put(from, from);
    trans.stores.auths.delete(from);
    await trans.commit();
    if (client.state.get().online) {
      rest
        .delete('/auth/session')
        .send()
        .then(() => {
          return db.stores.signOut.delete(from);
        });
    }
    // Let the signing out tab finish before sending the sign out message to the others
    setTimeout(() => client.send({ signOut: from, next }), 100);
    return next;
  }

  return () => {};
}

function createAuthDB() {
  const db = new Browserbase<AuthDatabaseStores>('dabble-auth')
    .version(1, {
      auths: ' ',
      current: ' ',
    })
    .version(
      2,
      {
        other: 'id',
      },
      async (oldVersion, transaction) => {
        let trans = db.start(transaction);
        const data = await ((trans.stores as any).current as ObjectStore).getAll();

        trans = db.start(transaction);
        const other = data.map((record: any) => ({ id: 'current', uid: record.uid }) as OtherData);

        trans.stores.other.putAll(other);
        trans.upgradeStore('current', null);
      }
    )
    .version(3, {
      signOut: ' ',
    })
    .version(0, {
      auths: ' ',
      other: 'id',
      signOut: ' ',
    });

  return db;
}
