import PromiseQueue from '@dabble/data/promise-queue';
import { rest } from '@dabble/data/rest';
import { StatefulPromise, statefulPromise } from '@dabble/data/stateful-promise';
import { countWords } from '@dabble/data/word-count';
import { DictionaryEntry, Issue, IssueCategory, Language, Synonym } from '../grammar-types';

const UILanguages = ['en-US', 'es', 'es-ES'];
const MAX_SUGGESTION_LENGTH = 5;

export default class BeyondGrammarApi {
  static languages: Language[];
  static uiLocales: Language[];
  languageIsoCode: string;
  spellingCheck: boolean;
  grammarCheck: boolean;
  queue: PromiseQueue;

  constructor() {
    this.languageIsoCode = 'en-US';
    this.spellingCheck = true;
    this.grammarCheck = true;
    this.queue = new PromiseQueue(4, Infinity);
  }

  setLanguage(languageIsoCode: string): void {
    this.languageIsoCode = languageIsoCode;
  }

  check(text: string, runFirst = false): StatefulPromise<Issue[]> {
    if (!text || !text.trim() || countWords(text, 2) < 2) {
      return statefulPromise<Issue[]>().resolve([]);
    }
    const options = {
      text,
      language: this.languageIsoCode,
      includeSpelling: this.spellingCheck,
      includeGrammar: this.grammarCheck,
      includeStyle: this.grammarCheck,
      heavyGrammar: this.grammarCheck,
    };

    const checker = async (count = 0) => {
      let result;
      try {
        result = await rest.post('grammar/check').send({ options });
      } catch (e) {
        if (++count === 3) throw new Error('Unable to check this paragraph');
        return await checker(count);
      }

      let issues = result?.tags as Issue[];

      if (issues) {
        issues = issues.map(issue => {
          let { category, hint, ruleId, suggestions, startPos, endPos } = issue;
          endPos += 1;
          category = category.toLowerCase() as IssueCategory;
          if (suggestions && suggestions.length > MAX_SUGGESTION_LENGTH) suggestions.length = MAX_SUGGESTION_LENGTH;
          suggestions = suggestions.map(suggestion => (suggestion === '(omit)' ? '' : suggestion));
          issue = { category, hint, ruleId, suggestions, startPos, endPos };
          const word = text.slice(startPos, endPos);
          if (!word.includes(' ')) {
            issue.word = word;
          }
          return issue;
        });
      }

      return issues;
    };

    return runFirst ? this.queue.addToHead(checker) : this.queue.add(checker);
  }

  cancelPendingRequests() {
    this.queue.clear();
  }

  async getLanguages() {
    if (!BeyondGrammarApi.languages || !BeyondGrammarApi.languages.length) {
      BeyondGrammarApi.languages = await rest.get('grammar/languages').send() as Language[];
    }
    return BeyondGrammarApi.languages;
  }

  async getUILocales() {
    if (!BeyondGrammarApi.uiLocales || !BeyondGrammarApi.uiLocales.length) {
      const uiLanguages = await rest.get('grammar/languages').send() as Language[];
      BeyondGrammarApi.uiLocales = uiLanguages.filter((l: Language) => {
        return UILanguages.includes(l.languageCode);
      });
    }
    return BeyondGrammarApi.uiLocales;
  }

  async thesaurus(word: string):Promise<Synonym> {
    const query = `?language=${this.languageIsoCode}&word=${encodeURIComponent(word)}`;
    return await rest.get(`grammar/thesaurus${query}`).send();
  }

  async contextualThesaurus(text: string, start: number, end: number) {
    const query =
      `language=${this.languageIsoCode}` + `&text=${encodeURIComponent(text)}` + `&start=${start}&end=${end}`;
    const result = await rest.get(`grammar/contextual-thesaurus?${query}`).send();
    return result;
  }

  async addToDictionary(word: string, replace?: string) {
    return await rest.post('grammar/dictionary').send({ language: this.languageIsoCode, word, replace });
  }

  async removeFromDictionary(id: string) {
    return await rest.delete(`grammar/dictionary/${id}`).send();
  }

  async getDictionaryEntries(): Promise<DictionaryEntry[]> {
    const language = this.languageIsoCode;
    const result = await rest.get(`grammar/dictionary?language=${language}`).send() as DictionaryEntry[];
    return result;
  }

  async getHelpText(ruleId: string, text: string, suggestions: string) {
    const query = `?ruleId=${ruleId}&text=${text}&suggestions=${suggestions}`;
    return await rest.get(`grammar/help${query}`).send();
  }

  queuePendingSize() {
    return this.queue.getPendingLength();
  }
}
