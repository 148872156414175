<script lang="ts">
  import { featuresStore } from '@dabble/data/global-data';
  import { t } from '@dabble/data/intl';
  import { projectStore } from '@dabble/data/project-data';
  import { settingsStore } from '@dabble/data/settings';
  import { Doc } from '@dabble/data/types';
  import { getUnsplashImage } from '@dabble/plugins/content/components/UnsplashSearchDialog.svelte';
  import { saveFile } from '@dabble/plugins/content/content';
  import { DropOptions, droppable } from '@dabble/plugins/content/droppable';
  import { constrainImage } from '@dabble/plugins/content/images';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import { mdiClose, mdiImage, mdiImageSearch } from '@mdi/js';

  export let doc: Doc;
  export let canEdit = false;
  export let removable = false;
  let fileInput: HTMLInputElement;

  $: feature = settingsStore.getFor(doc.type).requiresFeature;
  $: editable = canEdit && (!feature || $featuresStore[feature]);

  async function upload(file: File) {
    if (!file) return;
    const resized = await constrainImage(file, 2000);
    const url = await saveFile(resized);
    projectStore.updateDoc(doc.id, { bannerUrl: url });
  }

  async function addFromUnsplash() {
    const image = await getUnsplashImage({ maxSize: 2000 });
    if (image) {
      projectStore.updateDoc(doc.id, { bannerUrl: image.url });
    }
  }

  async function remove() {
    projectStore.updateDoc(doc.id, { bannerUrl: removable && doc.bannerUrl ? '' : null });
  }

  const dropOptions: DropOptions = {
    dropEffect: 'copy',
    mimes: ['Files', 'image/png', 'image/jpeg', 'image/gif', 'image/webp', 'image/tiff'],
    drop(event, mime, image) {
      console.log('mime:', mime, image);
      const file = Array.from(event.dataTransfer.files).find(file => file.type.startsWith('image/'));
      upload(file);
    },
  };
</script>

<div class="banner" use:droppable={dropOptions}>
  {#if doc.bannerUrl}
    <img class="banner-img" src={doc.bannerUrl} alt="Banner" />
  {/if}
  {#if editable}
    <div class="actions">
      <button class="upload" on:click={() => fileInput.click()} use:tooltipTop={$t('change_banner_image')}>
        <input bind:this={fileInput} type="file" on:change={() => upload(fileInput.files[0])} />
        <Icon path={mdiImage} />
      </button>
      <button class="upload" on:click={addFromUnsplash} use:tooltipTop={$t('search_unsplash_banner')}>
        <Icon path={mdiImageSearch} />
      </button>
      {#if doc.bannerUrl || removable}
        <button
          class="remove"
          on:click={remove}
          use:tooltipTop={doc.bannerUrl ? $t('remove_banner_image') : $t('remove_banner')}
        >
          <Icon path={mdiClose} />
        </button>
      {/if}
    </div>
  {/if}
</div>

<style>
  .banner {
    position: relative;
    width: 100%;
    height: 100%;
    flex: 1;
  }
  .banner-img {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  :global(html[data-theme='dark']) .banner-img {
    filter: brightness(0.8);
  }
  .actions {
    display: none;
    position: absolute;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    inset: 0;
    color: #fff;
    font-size: 1.5rem;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0.9;
  }
  .banner:hover .actions {
    display: flex;
  }
  .actions button {
    display: flex;
    padding: 1rem;
    border-radius: 999rem;
    background: rgba(0, 0, 0, 0.5);
    border: none;
    cursor: pointer;
  }
  .actions button:hover {
    background: rgba(0, 0, 0, 0.25);
  }
  .actions button :global(svg) {
    filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
  }
  .upload input {
    opacity: 0;
    width: 0px;
    height: 0px;
  }
</style>
