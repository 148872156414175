import { Readable } from 'easy-signal';
import i18next from 'i18next';
const div = document.createElement('div');

// Triggers on the next change, ignoring the initial value
export function onNextChange<T>(store: Readable<T>, onChange: (value: T) => void) {
  let first = true;
  const unsub = store.subscribe(value => {
    if (first) return (first = false);
    unsub();
    onChange(value);
  });
}

/**
 * HTML escapes content. For use with other HTML-adding formatters such as autolink.
 */
export function escape(value: string) {
  div.textContent = value || '';
  return div.innerHTML;
}

export function unescape(value: string) {
  div.innerHTML = value;
  return div.textContent;
}

/**
 * Adds <br> tags in place of newlines characters.
 */
export function br(value: string) {
  const lines = (value || '').split(/\r?\n/);
  return lines.join('<br>\n');
}

export function unbr(value: string) {
  return value.replace(/<br>\r?\n?/g, '\n');
}

const urlExp =
  /(^|\s|\()((https?:\/\/|www\.)([-A-Z0-9]+)+\.\w{2,}[-A-Z0-9+\u0026@#/%?=()~_|!:,.;]*[-A-Z0-9+\u0026@#/%=~(_|])/gi;
const localExp = /^\s*(https?:\/\/)?(localhost|127\.0\.0\.1)/gi;
const tagOrContentsExp = /<[^>]+>|[^<]+/g; // Use this to skip over tags by selecting them

/**
 * Adds automatic links to escaped content (be sure to escape user content). Can be used on existing HTML content as it
 * will skip URLs within HTML tags. Passing a value in the second parameter will set the target to that value or
 * `_blank` if the value is `true`.
 */
export function autolink(value: string, target?: string | boolean) {
  let targetString = '';
  if (typeof target === 'string') {
    targetString = ' target="' + target + '"';
  } else if (target) {
    targetString = ' target="_blank"';
  }

  return ('' + value).replace(tagOrContentsExp, match => {
    // short-circuit if it's a localhost URL or in a tag
    if (match.charAt(0) === '<' || localExp.test(match)) {
      return match;
    }
    return match.replace(urlExp, function (_, char, url, start) {
      return char + '<a href="' + (start === 'www.' ? 'https://' : '') + url + '"' + targetString + '>' + url + '</a>';
    });
  });
}

export function number(value: number, decimalPoints = 0) {
  if (typeof value !== 'number') return undefined;
  return new Intl.NumberFormat(i18next.language, {
    minimumFractionDigits: decimalPoints,
    maximumFractionDigits: decimalPoints,
  }).format(value);
}

export function parseInteger(value: string) {
  if (!value) return undefined;
  return parseInt(value.replace(/\D/, ''));
}

export function price(value: number, currency = 'USD') {
  return new Intl.NumberFormat(i18next.language, { style: 'currency', currency }).format(value);
}

export function percent(value: number) {
  return new Intl.NumberFormat(i18next.language, { style: 'percent' }).format(value);
}
