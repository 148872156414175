import { getNow } from '@dabble/data/date';
import { docIdStore, uidStore } from '@dabble/data/ids';
import { plugins } from '@dabble/data/plugins';
import { projectStore } from '@dabble/data/project-data';
import ProjectPatch from '@dabble/data/project-patch';
import { locallyStoredWritable } from '@dabble/data/stores/locally-stored-writable';
import { ProjectStore } from '@dabble/data/stores/project';
import { Doc } from '@dabble/data/types';
import {
  hideLeftNavStore,
  hideRightNavStore,
  leftNavWidthStore,
  sidebarStore,
  sidebarWidthStore,
  workspaceWidthStore,
} from '@dabble/data/ui';
import { createId } from '@dabble/data/uuid';
import { getImmutableValue, makeChanges } from '@dabble/util/immutable';
import { Readable, derived, observe, signal, writable } from 'easy-signal';
import { isEqual } from 'lodash';
import { Delta, Editor, EditorRange, LineOp, Op, TextDocument } from 'typewriter-editor';

const EMPTY: CommentMap = {};
export interface CommentReply {
  uid: string;
  content: string;
  created: number;
}

export interface Comment extends CommentReply {
  id: string;
  docId: string;
  field: string;
  text: string; // The text this comment was first created on in the doc
  replies: CommentReply[];
  resolved?: boolean;
}

export interface CommentMap {
  [id: string]: Comment;
}

export interface CommentsStore extends Readable<CommentMap> {
  comment(editor: Editor, range: EditorRange, content: string, id?: string): Promise<void>;
  reply(id: string, content: string): Promise<void>;
  updateComment(id: string, content: string): Promise<void>;
  updateReply(id: string, index: number, content: string): Promise<void>;
  resolveComment(id: string, unresolve?: boolean): Promise<void>;
  deleteComment(id: string): Promise<void>;
  deleteReply(id: string, replyIndex?: number): Promise<void>;
}

export const [commentsStore, commentsInTextStore] = createCommentsStores(projectStore, uidStore, docIdStore);
export const showCommentsStore = locallyStoredWritable('showComments', false);
export const selectedCommentIdStore = writable<string>('');
export const chosenCommentIdStore = writable<string>('');
export const activeCommentIdStore = derived(() => chosenCommentIdStore.get() || selectedCommentIdStore.get());
export const updateCommentDisplay = signal();

plugins.register({
  commentsStore: commentsStore,
  commentsInTextStore,
  showCommentsStore,
  chosenCommentIdStore,
  activeCommentIdStore,
});

// Holds all the comments for currently displayed editors and their position
export function createCommentsStores(
  projectStore: ProjectStore,
  uidStore: Readable<string>,
  docId: Readable<string>
): [CommentsStore, Readable<Set<string>>] {
  const commentsInText = writable(new Set<string>());
  let comments: CommentMap = EMPTY;
  let lastDocId: string;

  // This store is a map of all comments by id in the current doc and all of its decendents (including virtual docs)
  const { get, subscribe } = derived(() => {
    const { docs, childrenLookup } = projectStore.get();
    const docId = docIdStore.get();
    if (docId !== lastDocId) {
      lastDocId = docId;
    }
    if (!docId) {
      return (comments = EMPTY);
    }
    const ids = new Set(Object.keys(comments));

    makeChanges(() => {
      const inText = new Set<string>();
      addComments(docs[docId]);
      if (ids.size) {
        comments = getImmutableValue(comments);
        ids.forEach(id => delete comments[id]);
      }
      if (!isEqual(inText, commentsInText.get())) {
        commentsInText.set(inText);
      }

      function addComments(doc: Doc) {
        if (!doc) return;
        if (doc.comments) {
          const fields = new Set<string>();
          Object.values(doc.comments as CommentMap).forEach(comment => {
            ids.delete(comment.id);
            fields.add(comment.field || 'body');
            if (comments[comment.id] !== comment) {
              // Workaround a bug from elsewhere where the docId of a comment may not be correct
              if (comment.docId !== doc.id) {
                comment.docId = doc.id;
              }
              // Doing it this way will make comments not change if no comments were updated in the project
              comments = getImmutableValue(comments);
              comments[comment.id] = comment;
            }
          });

          fields.forEach(field => {
            const text = doc[field];
            if (!(text instanceof TextDocument)) return;
            const iter = LineOp.iterator(text.lines);
            let op: Op;
            while ((op = iter.next()) && op.retain !== Infinity) {
              const ids = op.attributes?.comment;
              if (ids) {
                Object.keys(ids).forEach(id => inText.add(id));
              }
            }
          });
        }
        childrenLookup[doc.id]?.forEach(addComments);
      }
    });

    return comments;
  });

  async function comment(editor: Editor, range: EditorRange, content: string, id?: string) {
    const { id: docId, field } = editor.identifier;
    const doc = projectStore.getDoc(docId);

    const patch = projectStore.patch();
    if (!doc.comments) {
      patch.patch.add(`/docs/${docId}/comments`, {});
    }
    let text = editor.getText(range).trim();
    if (text.length > 80) {
      text = text.slice(0, 77) + '...';
    }
    const comment: Comment = {
      id: id || createId(4, doc.comments, docId),
      uid: uidStore.get(),
      docId,
      field,
      text,
      content,
      created: getNow(),
      replies: [],
    };
    patch.patch.add(`/docs/${docId}/comments/${comment.id}`, comment);
    await patch.save();
    editor.formatText({ comment: { [comment.id]: true } }, range);
    await projectStore.commitQueuedTextChanges(docId);
  }

  async function reply(id: string, content: string) {
    const { path, comment } = findComment(id);
    const uid = uidStore.get();
    const patch = projectStore.patch();
    patch.patch.add(`${path}/replies/${comment.replies.length}`, { uid, content, created: getNow() });
    await patch.save();
  }

  async function updateComment(id: string, content: string) {
    const { comment, path } = findComment(id);
    const uid = uidStore.get();
    if (comment.uid !== uid) throw new Error(`You do not have permission to modify this comment`);
    const patch = projectStore.patch();
    patch.patch.add(`${path}/content`, content);
    await patch.save();
  }

  async function updateReply(id: string, index: number, content: string) {
    const { comment, path } = findComment(id);
    const uid = uidStore.get();
    const reply = comment.replies[index];
    if (reply.uid !== uid) throw new Error(`You do not have permission to modify this comment`);
    const patch = projectStore.patch();
    patch.patch.add(`${path}/replies/${index}/content`, content);
    await patch.save();
  }

  async function resolveComment(id: string, unresolve = false) {
    const { comment, path, docId } = findComment(id);
    if (!comment) return;
    const patch = projectStore.patch();
    patch.patch.add(`${path}/resolved`, !unresolve);
    changeCommentRange(projectStore.getDoc(docId), comment.id, patch, unresolve);
    await patch.save();
    projectStore.forceTextUpdate();
  }

  async function deleteComment(id: string) {
    const { comment, path, docId } = findComment(id, true);
    if (!comment) return;
    const patch = projectStore.patch();
    patch.patch.remove(path);
    changeCommentRange(projectStore.getDoc(docId), comment.id, patch, null);

    await patch.save();
    projectStore.forceTextUpdate();
  }

  async function deleteReply(id: string, replyIndex?: number) {
    const { comment, path } = findComment(id, true);
    if (!comment) return;
    const patch = projectStore.patch();
    patch.patch.remove(`${path}/replies/${replyIndex}`);
    await patch.save();
  }

  function findComment(id: string, dontThrow?: boolean) {
    const comment = comments[id];
    const docId = comment?.docId;
    if ((!comment || !docId) && !dontThrow) throw new Error(`Comment ${id} does not exist`);
    const path = `/docs/${docId}/comments/${id}`;
    return { comment, path, docId };
  }

  function changeCommentRange(doc: Doc, id: string, patch: ProjectPatch, value: boolean | null) {
    // Find and remove reference in text document
    const keys = Object.keys(doc);
    for (const field of keys) {
      if (!(doc[field] instanceof TextDocument)) continue;

      const iter = LineOp.iterator(doc[field].lines);
      let op: Op,
        index = 0,
        from: number,
        to: number;

      // Find the comment in the text document if it exists
      while ((op = iter.next()) && op.retain !== Infinity) {
        const length = Op.length(op);
        const comments = op.attributes?.comment;
        if (comments && id in comments) {
          if (from === undefined) from = index;
          to = index + length;
        }
        index += length;
      }

      if (to) {
        const format = new Delta().retain(from).retain(to - from, { comment: { [id]: value } });
        patch.changeText(doc.id, field, format);
        break;
      }
    }
  }

  return [
    {
      comment,
      reply,
      updateComment,
      updateReply,
      resolveComment,
      deleteComment,
      deleteReply,
      get,
      subscribe,
    },
    commentsInText,
  ];
}

const PAGE_AND_COMMENT_WIDTH = 1118;

const docAreaWidthStore = derived(() => {
  const leftWidth = hideLeftNavStore.get() ? 0 : leftNavWidthStore.get();
  const rightWidth = hideRightNavStore.get() ? 0 : sidebarWidthStore.get() + 50; // 50 for the toolbar
  if (!workspaceWidthStore.get()) return workspaceWidthStore.get();
  return workspaceWidthStore.get() - leftWidth - rightWidth;
});

export const canShowCommentsAndSidebarStore = derived(
  () => !docAreaWidthStore.get() || docAreaWidthStore.get() > PAGE_AND_COMMENT_WIDTH
);

let lastChanged = 'sidebar';
observe(last => {
  const check = sidebarStore.get() || hideRightNavStore.get();
  if (check == last) {
    lastChanged = 'sidebar';
  }
  return check;
});

observe(last => {
  const check = showCommentsStore.get();
  if (check === last) {
    lastChanged = 'comments';
  }
  return check;
});

observe(() => {
  if (showCommentsStore.get() && sidebarStore.get() && !canShowCommentsAndSidebarStore.get()) {
    if (lastChanged === 'sidebar') {
      showCommentsStore.set(false);
    } else {
      sidebarStore.set('');
    }
  }
});
