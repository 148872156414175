import { agreeable } from '@dabble/data/agreeable';
import { delegate } from '@dabble/data/delegate';
import { User } from '@dabble/data/types';
import { Readable, derived, writable } from 'easy-signal';

export type DelegateUser = { uid: string; name: string; email: string };

export function createAccountsStore() {
  const { get, set, subscribe } = writable<User[]>([]);

  async function load(justOpenedAccount = false) {
    const accounts: User[] = await agreeable.call('getAccounts', justOpenedAccount);
    set(accounts);
    return accounts;
  }

  return { load, get, subscribe };
}

export function createCurrentUserStore(uidStore: Readable<string>, accountsStore: Readable<User[]>) {
  return derived(() => {
    const uid = uidStore.get();
    const accounts = accountsStore.get();
    return accounts.find(a => a.uid === uid);
  });
}

export function createDelegatesStore() {
  const { get, subscribe } = writable<DelegateUser[]>([], set => {
    addEventListener('storage', onStorage);
    refresh();

    function refresh() {
      set(
        String(localStorage.delegates || '')
          .split('|')
          .filter(Boolean)
          .map(d => {
            const [uid, name, email] = d.split(',');
            return { uid, name, email };
          })
      );
    }

    function onStorage(event: StorageEvent) {
      if (event.storageArea === localStorage && event.key === 'delegates' && event.oldValue !== event.newValue) {
        refresh();
      }
    }

    return () => {
      removeEventListener('storage', onStorage);
    };
  });

  function add(user: DelegateUser) {
    if (!delegate) return;
    let { uid, name, email } = user;
    name = name.replace(/,/g, '');
    localStorage.delegates = ((localStorage.delegates as string) || '')
      .split('|')
      .filter(d => !d.startsWith(delegate))
      .concat(`${uid},${name},${email}`)
      .join('|');
  }

  function remove(uid: string = delegate) {
    if (!uid) return;
    if (uid === delegate) {
      delete sessionStorage.delegate;
    }
    localStorage.delegates = ((localStorage.delegates as string) || '')
      .split('|')
      .filter(user => user.split(',')[0] !== delegate)
      .join('|');
  }

  return { add, remove, get, subscribe };
}
