<script lang="ts">
  import { agreeable } from '@dabble/data/agreeable';
  import { readonlyStore, syncingStore } from '@dabble/data/app-state';
  import { getNow } from '@dabble/data/date';
  import { t } from '@dabble/data/intl';
  import { createProject, creatingProject, deleteProject, projectMetasStore } from '@dabble/data/project-data';
  import { duplicateProject } from '@dabble/data/project-duplicate';
  import { settingsStore } from '@dabble/data/settings';
  import { confirm, inform } from '@dabble/data/ui';
  import { userProjectsStore } from '@dabble/data/user-data';
  import { selectProject } from '@dabble/data/user-project';
  import { getTitle } from '@dabble/data/util';
  import Alert from '@dabble/toolkit/Alert.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import Modal from '@dabble/toolkit/Modal.svelte';
  import { focus } from '@dabble/toolkit/focus';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import { mdiArchive, mdiChevronDown, mdiDelete, mdiLoading, mdiNotebookMultiple, mdiTextBoxMultiple } from '@mdi/js';
  import { tick } from 'svelte';
  import NewProjectButton from './NewProjectButton.svelte';
  import ProjectButton from './ProjectButton.svelte';

  let selectedId = '';
  let view = 'recent';
  let templatesElement: HTMLElement;
  let recentsElement: HTMLElement;
  let renaming: { id: string; type: string; title: string; subtitle: string; author: string } | null;
  let renameError: string;
  let templatesWidth: number;
  let showingMore: boolean;

  $: userProjects = Object.entries($userProjectsStore || {}).sort(
    ([, a], [, b]) => (b.lastOpened || 0) - (a.lastOpened || 0)
  );
  $: recentProjects = userProjects.filter(([id, p]) => !p.category && !$projectMetasStore[id]?.isTemplate);
  $: trashedProjects = userProjects.filter(([id, p]) => p.category === 'trash');
  $: archivedProjects = userProjects.filter(([id, p]) => p.category === 'archive');
  $: templates = userProjects.filter(([id, p]) => $projectMetasStore[id]?.isTemplate && !p.category);
  $: projects = recentProjects;
  $: {
    if (view === 'recent') projects = recentProjects;
    else if (view === 'trash') projects = trashedProjects;
    else if (view === 'archive') projects = archivedProjects;
  }

  function toggleShowTemplates() {
    showingMore = !showingMore;
    if (showingMore) {
      templatesElement.style.height = 'auto'; // let it be the size it wants to be
      const height = templatesElement.offsetHeight; // get the height
      templatesElement.style.height = ''; // set it back to the default height
      templatesElement.offsetHeight; // make the browser actually go back to the default height with this getter
      templatesElement.style.height = `${height}px`; // allow it to transition to the right size
      setTimeout(() => (templatesElement.style.height = 'auto'), 300); // Turn it to auto so if people add templates it grows automatically
    } else {
      const height = templatesElement.offsetHeight; // it's in auto, so set the height
      templatesElement.style.height = `${height}px`; // allow it to transition to the right size
      templatesElement.offsetHeight; // make the browser actually go back to the default height with this getter
      templatesElement.style.height = '';
    }
  }

  async function selectById(id: string) {
    selectedId = id;

    setTimeout(() => {
      selectProject(id);
    }, 200);
  }

  async function createFromTemplate(id: string) {
    if ($readonlyStore) return selectProject(id);
    // FIXME this is returning "any" TODO
    const { id: newId } = await duplicateProject(id);
    await runProjectAnimation(newId, 'in');
    selectById(newId);
    await userProjectsStore.update({ [newId]: { lastOpened: new Date().getTime() } });
  }

  async function duplicate(id: string) {
    if ($readonlyStore) return;
    const { id: newId } = await duplicateProject(id);
    await runProjectAnimation(newId, 'in');
  }

  async function renameProject(id: string) {
    const projectMeta = $projectMetasStore[id];
    if (!projectMeta) return alert($t('alert_projet_not_fully_loaded'));
    const { type, title, subtitle, author } = projectMeta;
    renaming = { id, type, title, subtitle, author };
  }

  async function rename() {
    const { title, subtitle, author } = renaming;
    try {
      await agreeable.projects(renaming.id).update({ title, subtitle, author });
    } catch (err) {
      renameError = err.message;
      return;
    }
    closeRename();
  }

  function closeRename() {
    renaming = null;
  }

  async function createNewProject() {
    const {
      project: { id },
    } = await createProject();
    await runProjectAnimation(id, 'in');
    selectById(id);
  }

  async function deleteTheProject(id: string) {
    const projectMeta = $projectMetasStore[id];
    const title = getTitle(projectMeta);
    console.log('Deleting project', title);
    if (!(await confirm($t('delete_confirm', { title }), { yesNo: true }))) return;

    await runProjectAnimation(id, 'out');
    await deleteProject(id);
    await tick();

    if (view === 'trash' && !trashedProjects.length) {
      view = 'recent';
    }
  }

  async function restoreProject(id: string) {
    await runProjectAnimation(id, 'out', true);
    await userProjectsStore.update({ [id]: { category: null, lastOpened: getNow() } });
    view = 'recent';
    await tick();
    await runProjectAnimation(id, 'in');
  }

  async function categorizeProject(id: string, category: 'archive' | 'trash') {
    await userProjectsStore.update({ [id]: { category } });
  }

  async function makeTemplate(id: string) {
    await projectMetasStore.update(id, { isTemplate: true });
  }

  async function shareTemplate(id: string) {
    navigator.clipboard.writeText(`https://app.dabblewriter.com/?importTemplate=${id}`);
    inform('success', $t('template_shared_link'));
  }

  async function runProjectAnimation(id: string, type: string, keepClassAfter?: boolean) {
    const node = recentsElement?.querySelector(`[data-id="${id}"]`);
    if (node) {
      node.classList.add(type);
      await waitForAnimation(node, 600);
      if (!keepClassAfter) node.classList.remove(type);
    }
  }

  function waitForAnimation(node: Element, duration: number) {
    return new Promise<void>(resolve => {
      function onEnd() {
        node.removeEventListener('animationend', onEnd);
        clearTimeout(id);
        resolve();
      }
      node.addEventListener('animationend', onEnd);
      const id = setTimeout(onEnd, duration);
    });
  }
</script>

{#if view === 'recent'}
  <div class="new-projects-strip" bind:this={templatesElement}>
    <div class="new-projects" class:showingMore>
      <h3>
        <Icon path={mdiTextBoxMultiple} highlight inline />
        {$t('start_new_project')}
      </h3>
      <div class="template-projects">
        {#if !templates.length}
          <p>{$t('home_templates_empty')}</p>
        {/if}
        <div class="templates" bind:offsetWidth={templatesWidth}>
          {#each templates as [id] (id)}
            <ProjectButton
              {id}
              selected={selectedId === id}
              on:select={() => createFromTemplate(id)}
              on:rename={() => renameProject(id)}
              on:delete={() => deleteTheProject(id)}
              on:edit={() => selectById(id)}
              on:category={event => categorizeProject(id, event.detail)}
              on:share={() => shareTemplate(id)}
              on:view={() => selectById(id)}
            />
          {/each}
        </div>
      </div>
      <div class="show-more">
        <button class="show-more-btn btn link" on:click={toggleShowTemplates}>
          {$t(showingMore ? 'hide_templates' : 'show_more_templates')}
          <Icon path={mdiChevronDown} inline />
        </button>
      </div>
    </div>
  </div>
{/if}
<div class="recent-projects" class:trash={view === 'trash'}>
  <h3>
    {#if view === 'trash'}
      <Icon path={mdiDelete} highlight /> {$t('trashed_projects')}
    {:else if view === 'archive'}
      <Icon path={mdiArchive} highlight /> {$t('archived_projects')}
    {:else}
      <Icon path={mdiNotebookMultiple} highlight inline /> {$t('recent_projects')}
    {/if}
    {#if $syncingStore}
      <Icon path={mdiLoading} spin highlight />
    {/if}
    {#if view !== 'recent'}
      <button on:click={() => (view = 'recent')} use:tooltipTop={$t('recent_projects')} class="icon pull-right switch">
        <Icon path={mdiNotebookMultiple} highlight />
      </button>
    {/if}
    {#if archivedProjects.length && view !== 'archive'}
      <button
        on:click={() => (view = 'archive')}
        use:tooltipTop={$t('archived_projects')}
        class="icon pull-right switch"
      >
        <Icon path={mdiArchive} highlight />
      </button>
    {/if}
    {#if trashedProjects.length && view !== 'trash'}
      <button on:click={() => (view = 'trash')} use:tooltipTop={$t('trashed_projects')} class="icon pull-right switch">
        <Icon path={mdiDelete} highlight />
      </button>
    {/if}
  </h3>

  <div bind:this={recentsElement} class="projects">
    {#if !trashedProjects.length && view === 'trash'}
      <h6 class="projects-message">{$t('home_trash_empty')}</h6>
    {/if}

    {#if view === 'recent' && !$readonlyStore}
      <NewProjectButton on:click={createNewProject} />
    {/if}

    {#each projects as [id] (id)}
      {#if $creatingProject !== id}
        <ProjectButton
          {id}
          selected={selectedId === id}
          on:select={() => selectById(id)}
          on:rename={() => renameProject(id)}
          on:delete={() => deleteTheProject(id)}
          on:restore={() => restoreProject(id)}
          on:duplicate={() => duplicate(id)}
          on:template={() => makeTemplate(id)}
          on:category={event => categorizeProject(id, event.detail)}
        />
      {/if}
    {/each}
  </div>
</div>
{#if renaming}
  <Modal title={$t('project_rename_header')} size="small" on:close={closeRename}>
    <form on:submit|preventDefault={rename}>
      <section>
        <p>{$t('project_rename_body')}</p>
        {#if renameError}
          <Alert type="danger" dismissible on:close={() => (renameError = null)}>
            <strong>{$t('error')}:</strong>
            {$t(renameError)}
          </Alert>
        {/if}
        <div class="form-group">
          <label for="recent-projects-title">{$t('home_title')}</label>
          <input
            id="recent-projects-title"
            type="text"
            class="form-control"
            placeholder={settingsStore.getPlaceholder(renaming, 'title')}
            bind:value={renaming.title}
            use:focus
          />
        </div>
        <div class="form-group">
          <label for="recent-projects-subtitle">{$t('home_subtitle')}</label>
          <input
            id="recent-projects-subtitle"
            type="text"
            class="form-control"
            placeholder={settingsStore.getPlaceholder(renaming, 'subtitle')}
            bind:value={renaming.subtitle}
          />
        </div>
        <div class="form-group">
          <label for="recent-projects-author">{$t('home_author')}</label>
          <input
            id="recent-projects-author"
            type="text"
            class="form-control"
            placeholder={settingsStore.getPlaceholder(renaming, 'author')}
            bind:value={renaming.author}
          />
        </div>
      </section>
      <footer>
        <button class="btn secondary" type="button" on:click={closeRename}>{$t('dialog_cancel')}</button>
        <button class="btn primary" type="submit">{$t('dialog_ok')}</button>
      </footer>
    </form>
  </Modal>
{/if}

<style>
  .new-projects-strip {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
    box-shadow: inset 0px -1px 1px -1px white;
    margin-bottom: 24px;
    height: 0px;
    transition: all 0.3s ease-in-out;
  }
  .new-projects {
    padding: 16px;
    width: 688px;
    overflow: hidden;
  }
  .templates {
    margin: 0 -16px;
    display: flex;
    flex-wrap: wrap;
  }
  .show-more {
    position: absolute;
    left: 50%;
    top: 100%;
  }
  .link.show-more-btn {
    padding: 8px;
    margin-left: -50%;
    color: var(--text-color-lighter);
    text-shadow: var(--text-highlight);
    text-decoration: none;
  }
  .show-more :global(.icon) {
    transition: all 0.3s ease-in-out;
  }
  .showingMore .show-more :global(.icon) {
    transform: rotate(180deg);
  }

  .recent-projects {
    padding: 0 16px;
    width: 688px;
  }
  .recent-projects .projects {
    margin: 0 -16px;
    display: flex;
    flex-wrap: wrap;
  }
  h3 {
    color: var(--text-color-lighter);
    text-shadow: var(--text-highlight);
    margin-top: 0;
    margin: 1em 0 1.4em;
    font-size: 1.2rem;
    line-height: 24px;
  }
  p {
    color: var(--text-color-lighter);
    text-shadow: var(--text-highlight);
  }
  .projects-message {
    flex: 1;
    margin: 2em 2em 6em;
    text-align: center;
    color: var(--text-color-lighterer);
    text-shadow: var(--text-highlight);
  }
  @media (max-width: 700px) {
    .new-projects {
      width: auto;
    }
    .recent-projects {
      width: auto;
    }
  }
</style>
