import { agreeable } from './agreeable';

export interface UserPublicData {
  uid: string;
  name: string;
  email?: string;
  photoUrl?: string;
}

export interface UsersMap {
  [uid: string]: UserPublicData;
}

export async function getUser(uid: string): Promise<UserPublicData | null> {
  const users: { [uid: string]: UserPublicData } = await agreeable.call('getUsersInfo', { uids: [uid] });
  return users[uid];
}
