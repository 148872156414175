<script lang="ts">
  import Interactions from '@dabble/toolkit/Interactions.svelte';
  import popperFrames from '@dabble/toolkit/popper-frames';
  import preventOverflow from '@dabble/toolkit/popper-preventOverflow';
  import { createPopper, Placement, Instance as Popper, VirtualElement } from '@popperjs/core';
  import { afterUpdate, createEventDispatcher, onDestroy, onMount, tick } from 'svelte';

  export let target: Element | VirtualElement = null;
  export let placement: Placement = 'bottom-start'; // see https://popper.js.org/docs/v1/#Popper.placements
  export let exposed = false;
  export let arrow = false;
  export let noFocus = false;
  export let offset: string | number[] = '';
  export let iconsOnly = false;
  export let ignoreClicks = false; // If true, the dropdown will not close when a click is detected outside of it

  const dispatch = createEventDispatcher();
  let container: HTMLElement;
  let popper: Popper;
  let className = '';
  let parentNode: HTMLElement;
  let nextSibling: HTMLElement;
  export { className as class };

  export function close(clicked?: boolean) {
    dispatch('close', { clicked });
  }
  export async function updatePosition() {
    await tick();
    if (popper) popper.forceUpdate();
  }

  const noop = () => {};
  let firstElement: HTMLElement;
  let justOpened = false;

  onMount(async () => {
    if (!target) target = container.previousElementSibling as HTMLElement;
    if (!target) throw new Error('Dropdown must have a target, put it directly after the target in the DOM');
    parentNode = container.parentNode as HTMLElement;
    nextSibling = container.nextSibling as HTMLElement;
    document.body.appendChild(container);
    const modifiers: any[] = [popperFrames, preventOverflow];
    if (offset) modifiers.push(getOffset());

    popper = createPopper(target, container, { placement, modifiers });

    if ('ontouchstart' in document.documentElement) {
      firstElement = document.body.firstElementChild as HTMLElement;
      firstElement.addEventListener('mouseover', noop);
    }

    container.classList.add('show');
    if (!noFocus) container.focus();
    justOpened = true;
    await new Promise(resolve => setTimeout(resolve, 300));
    justOpened = false;
  });

  afterUpdate(updatePosition);

  onDestroy(() => {
    try {
      popper.destroy(); // If the target is already detached and was in an iframe, this can throw an error
      popper = null;
    } catch (err) {}
    if (parentNode) parentNode.insertBefore(container, nextSibling);
    if (firstElement) firstElement.removeEventListener('mouseover', noop);
  });

  function onGlobalClick(event: MouseEvent | CustomEvent<MouseEvent>) {
    if (ignoreClicks) return;
    const mouseEvent = event.detail && typeof event.detail !== 'number' ? event.detail : (event as MouseEvent);
    if (mouseEvent?.button === 2) return;
    if (!justOpened && !(mouseEvent.target as HTMLElement).closest('.dropdown-item.show-submenu')) close();
  }

  function onClick(event: MouseEvent) {
    if (!(event.target as HTMLElement).closest('.dropdown-item.show-submenu')) close(true);
  }

  function closeFromEvent() {
    if (!justOpened) close();
  }

  function onMouseDown(event: MouseEvent) {
    if (justOpened) return;
    if (event.button === 2 || event.ctrlKey) {
      close();
    }
  }

  function getOffset() {
    if (typeof offset === 'string') {
      offset = offset.split(/[,\s]+/).map(n => parseInt(n));
    }
    return { name: 'offset', options: { offset } };
  }
</script>

<Interactions on:shortcut-Escape={closeFromEvent} on:click={onGlobalClick} on:contextmenu={closeFromEvent} />

<div
  role="menu"
  bind:this={container}
  class="dropdown-menu {className}"
  class:exposed
  class:with-arrow={arrow}
  class:icons-only={iconsOnly}
  on:click={onClick}
  on:keydown={() => {}}
  on:mouseenter
  on:mouseleave
  on:mousedown|stopPropagation={onMouseDown}
  on:contextmenu|preventDefault
  tabindex="-1"
>
  {#if arrow}
    <div class="dropdown-arrow" data-popper-arrow />
  {/if}
  <slot />
</div>
