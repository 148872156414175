<script lang="ts">
  import { featuresStore } from '@dabble/data/global-data';
  import { t } from '@dabble/data/intl';
  import ToolButton from '@dabble/toolkit/ToolButton.svelte';
  import { mdiBookSearchOutline } from '@mdi/js';
</script>

{#if $featuresStore.grammar}
  <div class="thesaurus-sidebar-button">
    <ToolButton
      class="thesaurus-button"
      icon={mdiBookSearchOutline}
      tooltip={$t('thesaurus_open')}
      sidebar="thesaurus"
    />
  </div>
{/if}

<style>
  .thesaurus-sidebar-button {
    order: 6;
  }
</style>
