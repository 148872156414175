import { DabbleDatabase } from '@dabble/data/old-types';
import { rest } from '@dabble/data/rest';
import { ImageInfo } from '@dabble/plugins/content/images';
import { ExtendAPI, Leader } from 'agreeable-client';
import Compress from 'compress.js';
import { DabbleAgreeableClient } from '../../data/agreeable/agreeable-client';

const MAX_IMAGE_SIZE = 10_000_000;
const MAX_IMAGE_PX = 5000;
const mimeTypeOverrides: Record<string, string> = {
  'image/vnd.microsoft.icon': 'ico',
  'image/svg+xml': 'svg',
  'image/jpeg': 'jpg',
};
const compress = new Compress();
type FileType = File | Blob;

export function contentLeaderExtension(
  client: DabbleAgreeableClient,
  db: DabbleDatabase,
  extendApi: ExtendAPI,
  leader: Leader
) {
  extendApi({ saveFile, constrainImage, getImagePlaceholder });

  async function saveFile(file: FileType, maxSize?: number) {
    const folderPath = file.type.split('/')[0] + 's';
    if (file.type.startsWith('image/')) {
      if (maxSize) {
        file = await constrainImage(file, maxSize, false);
      }
      file = await compressImageIfNeeded(file);
    }
    const name = await createFileName(file);
    const url = `https://files.dabblewriter.com/content/${folderPath}/${name}`;
    const response = await fetch(url).catch(() => null); // this is so no error is thrown except expected file doesn't exist error
    if (response && response.status === 200) {
      return url;
    }
    await cacheContent(file, url);
    // Add to database and then upload, remove from database once uploaded successfully
    await db.stores.content_uploads.put({ name, type: file.type, content: file });
    uploadContentFiles();
    return url;
  }

  async function getImagePlaceholder(file: FileType) {
    return await constrainImage(file, 5, true);
  }

  async function constrainImage(file: FileType, maxSize: number, returnImageInfo: true): Promise<ImageInfo>;
  async function constrainImage(file: FileType, maxSize: number, returnImageInfo?: false): Promise<Blob>;
  async function constrainImage(file: FileType, maxSize: number, returnImageInfo?: boolean): Promise<ImageInfo | Blob> {
    // Don't use compress.js as it always creates a JPEG (which is larger than PNG at small sizes)
    const img = document.createElement('img');

    return new Promise((resolve, reject) => {
      img.onload = async () => {
        if (!returnImageInfo && img.naturalWidth < maxSize && img.naturalHeight < maxSize) {
          return resolve(file);
        }
        const ratio = img.naturalWidth / img.naturalHeight;
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const w = (canvas.width = Math.ceil(Math.min(maxSize, ratio * maxSize)));
        const h = (canvas.height = Math.ceil(Math.min(maxSize, (1 / ratio) * maxSize)));
        ctx.drawImage(img, 0, 0, w, h);
        if (returnImageInfo) {
          const dataUrl = canvas.toDataURL(file.type);
          resolve({ dataUrl, width: img.naturalWidth, height: img.naturalHeight });
        } else {
          canvas.toBlob(blob => resolve(blob), file.type);
        }
      };
      img.onerror = err => reject(err);

      img.src = URL.createObjectURL(file);
    });
  }

  async function cacheContent(file: FileType, url: string) {
    const cache = await caches.open('content');
    const cachedContent = new Response(file);
    await cache.put(url, cachedContent);
  }

  async function compressImageIfNeeded(file: FileType) {
    if (!file.type.startsWith('image/') || file.size <= MAX_IMAGE_SIZE) {
      return file;
    }
    if (!(file instanceof File)) {
      file = new File([file], 'unnamed', { type: file.type });
    }
    const result = await compress.compress([file as File], {
      size: 10,
      quality: 1,
      maxWidth: MAX_IMAGE_PX,
      maxHeight: MAX_IMAGE_PX,
      // @ts-ignore rotate is needed for mobile compatibility but is not part of the CompressOption type
      rotate: true,
    });
    const img = result[0];
    const base64str = img.data;
    // Compress will always convert the image to a jpeg for best compression.
    return Compress.convertBase64ToFile(base64str, file.type);
  }

  async function uploadContentFiles() {
    const result = await db.stores.content_uploads.getAll();
    const files = result.map(({ name, type, content }) => new File([content], name, { type }));
    for (const file of files) {
      if (await uploadContent(file)) {
        await db.stores.content_uploads.delete(file.name);
      }
    }
  }

  async function uploadContent(file: File) {
    try {
      // sets the folder path to images, templates, etc then uploads
      const folderPath = file.type.split('/')[0] + 's';
      return await rest.put(`${folderPath}/${file.name}`).send(new Blob([file], { type: file.type }));
    } catch (e) {
      return false;
    }
  }

  async function createFileName(file: FileType) {
    const ext = getExtension(file);
    const buffer = await file.arrayBuffer();
    let name: string;

    if (crypto.subtle) {
      const hash = await crypto.subtle.digest('SHA-1', buffer);
      const hashArray = Array.from(new Uint8Array(hash));
      const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
      name = `${hashHex}.${ext}`;
    } else {
      name = `${client.createId(24)}.${ext}`;
    }
    return name;
  }

  function getExtension(file: FileType) {
    return mimeTypeOverrides[file.type] || file.type.replace(/^[^/]+\//, '').replace(/\+.+$/, '');
  }

  return uploadContentFiles;
}
