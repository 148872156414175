import { writable } from 'easy-signal';

export type LockableStore<T> = ReturnType<typeof lockable<T>>;

/**
 * A store that can be locked to prevent updates from being set.
 */
export function lockable<T>(value: T) {
  let lastValue: any;
  let locked = false;

  const { get, set, subscribe } = writable<T>(value);

  function conditionalSet(value: T) {
    if (locked) lastValue = value;
    else set(value);
  }

  function lock() {
    locked = true;
    lastValue = get();
  }

  function unlock(reset?: boolean) {
    locked = false;
    if (!reset) set(lastValue);
    lastValue = null;
  }

  return {
    get,
    set: conditionalSet,
    lock,
    unlock,
    subscribe,
  };
}
