<script lang="ts">
  import { projectStore } from '@dabble/data/project-data';
  import { getPlaceholderClass, getTitle } from '@dabble/data/util';
  import { truncateAtWord } from '@dabble/data/word-count';

  export let doc;
  export let field = 'title';
  export let tag = 'span';
  export let hiddenPlaceholder = false;
  let className = '';
  export { className as class };
  export let truncate = 50;
  let title;
  let theClass;

  $: empty = !(doc && doc[field]);
  $: title = truncateAtWord(getTitle(doc, field, $projectStore.docs), truncate);
  $: theClass = `${className} ${getPlaceholderClass(doc, field)}`.trim().split(/s+/).join(' ');
</script>

{#if !(empty && hiddenPlaceholder)}
  {#if tag === 'span'}
    <span class={theClass}>{title}</span>
  {:else if tag === 'div'}
    <div class={theClass}>{title}</div>
  {:else if tag === 'h1'}
    <h1 class={theClass}>{title}</h1>
  {:else if tag === 'h2'}
    <h2 class={theClass}>{title}</h2>
  {:else if tag === 'h3'}
    <h3 class={theClass}>{title}</h3>
  {:else if tag === 'h4'}
    <h4 class={theClass}>{title}</h4>
  {/if}
{/if}

<style>
  .placeholder {
    opacity: 0.5;
    font-style: italic;
  }
</style>
