<script lang="ts">
  import { signals } from '@dabble/data/app-state';
  import { t } from '@dabble/data/intl';
  import { projectMetaStore } from '@dabble/data/project-data';
  import { inform } from '@dabble/data/ui';
  import { getTitle } from '@dabble/data/util';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import { mdiInformationOutline } from '@mdi/js';
  import { AuthorInfo } from '../types';

  let renameError;
  let edits: AuthorInfo;

  signals.onScreenChange('ProjectPreferences');

  const defaults = {
    shortTitle: '',
    phone: '',
    address: '',
    sceneSeparator: '',
  };

  $: authorInfo = { ...defaults, ...$projectMetaStore?.authorInfo };
  $: edits = { ...authorInfo };

  async function saveProject() {
    try {
      await projectMetaStore.update({ authorInfo: { ...edits } });
      inform('success', $t('saved'));
    } catch (err) {
      renameError = err.message;
    }
  }

  function isEqual(obj1: AuthorInfo, obj2: AuthorInfo) {
    return Object.keys(obj1).every(key => obj1[key as keyof AuthorInfo] === obj2[key as keyof AuthorInfo]);
  }
</script>

<div class="section">
  <h2>{$t('export_settings')}</h2>
  <form class="novel-title" on:submit|preventDefault={saveProject}>
    <div class="form-group">
      <label for="export-short-title">{$t('export_short_title')}</label>
      <input
        id="export-short-title"
        type="text"
        class="form-control"
        placeholder={getTitle($projectMetaStore, 'title')}
        bind:value={edits.shortTitle}
      />
    </div>
    <div class="form-group">
      <label for="export-scene-separator">{$t('export_scene_separator')}</label>
      <input
        id="export-scene-separator"
        type="text"
        class="form-control"
        placeholder="#"
        bind:value={edits.sceneSeparator}
      />
    </div>
    <div class="form-group">
      <div class="phone-label-container">
        <label for="export-phone">{$t('export_phone')}</label>
        <span use:tooltipTop={$t('export_telephone')}><Icon path={mdiInformationOutline} inline /></span>
      </div>
      <input
        id="export-phone"
        type="text"
        class="form-control"
        placeholder={$t('export_phone')}
        bind:value={edits.phone}
      />
    </div>
    <div class="form-group">
      <label for="export-address">{$t('export_address')}</label>
      <textarea
        id="export-address"
        class="form-control"
        rows="2"
        placeholder={$t('export_address')}
        bind:value={edits.address}
      />
    </div>
    <button class="btn primary" type="submit" disabled={isEqual(edits, authorInfo)}>{$t('save')}</button>
  </form>
</div>
