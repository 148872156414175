import { getUrl, goto } from '@dabble/data/navigation';
import { projectStore } from '@dabble/data/project-data';
import { settingsStore } from '@dabble/data/settings';
import { Doc } from '@dabble/data/types';
import { viewport } from '@dabble/data/ui';
import { userDocsStore } from '@dabble/data/user-project';
import { createDuplicate } from './copy-paste-doc';

export async function createNewDoc(doc: Doc, parentId: string, index: number) {
  userDocsStore.update({ [parentId]: { opened: true } });
  return await projectStore.createDoc(doc, parentId, index);
}

export async function createFromTemplate(template: Doc, parentId: string, index: number) {
  const copy = createDuplicate(template, true);

  const id = projectStore.createDocId();
  await createNewDoc({ id, ...copy }, parentId, index);
  return id;
}

export async function scrollTo(doc: Doc, id: string) {
  if (!settingsStore.get()[doc.type].hideInNavigation && !projectStore.contains(doc, id)) {
    const url = getUrl(id, projectStore.get().projectId);
    goto(url);
  } else {
    await viewport.scrollIntoView({ id, field: 'body' });
  }
}
