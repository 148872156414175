<script lang="ts">
  import { settingsStore } from '@dabble/data/settings';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiClose, mdiFormatBold, mdiFormatItalic, mdiFormatQuoteOpen, mdiFormatSize, mdiLink } from '@mdi/js';
  import { writable } from 'easy-signal';
  import { afterUpdate, onDestroy, tick } from 'svelte';
  import { AttributeMap, BubbleMenu, Editor, EditorRange, selectionStore } from 'typewriter-editor';

  const NEXT_HEADER: Record<string, any> = { undefined: 2, 2: 3, 3: null };
  const NEXT_QUOTE: Record<string, any> = { undefined: 'alt', alt: true, true: null };

  export let editor: Editor;

  const editorStore = writable(editor);
  const selection = selectionStore(editorStore);
  let domBubbleMenu: Element;
  let parentNode: Node;
  let nextSibling: Node;
  let menu: HTMLElement;
  let input: HTMLInputElement;
  let href = '';
  let section = 'main';

  $: if (menu && section === 'main') {
    menu.style.minWidth = '';
    menu.style.height = '';
  } else if (menu) {
    menu.style.minWidth = `${menu.offsetWidth}px`;
    menu.style.height = `${menu.offsetHeight}px`;
  }
  $: $editorStore = editor;
  $: resetSection($selection);
  $: notMain = section !== 'main';
  $: if (notMain && editor) {
    editor.modules.selection.pause();
  } else if (editor) {
    editor.modules.selection.resume();
  }

  function getNode() {
    if (menu) {
      let element = menu.closest('.bubble-menu');
      return element;
    } else {
      return null;
    }
  }

  function unloadNode() {
    if (domBubbleMenu && parentNode) {
      parentNode.insertBefore(domBubbleMenu, nextSibling);
      domBubbleMenu = null;
      parentNode = null;
      nextSibling = null;
    }
  }

  function mountNode() {
    const tempBubbleMenu = getNode();
    if (domBubbleMenu && !tempBubbleMenu) {
      unloadNode();
    } else if (tempBubbleMenu) {
      domBubbleMenu = tempBubbleMenu;
      const parentContainer = domBubbleMenu.closest('.page-wrapper');
      if (!parentContainer || parentContainer === domBubbleMenu.parentNode) {
        return;
      }

      parentNode = domBubbleMenu.parentNode;
      nextSibling = domBubbleMenu.nextSibling;
      parentContainer.appendChild(domBubbleMenu);

      domBubbleMenu.classList.add('show');
    }
  }

  afterUpdate(() => {
    mountNode();
  });

  onDestroy(() => {
    unloadNode();
  });

  function resetSection(selection: EditorRange) {
    section = 'main';
  }

  function onVariableBlockClick(name: string, active: AttributeMap, NEXT: Record<string, any>) {
    const format = active[name];
    const nextFormat = { [name]: format in NEXT ? NEXT[format] : NEXT['undefined'] };
    editor.formatLine(nextFormat);
  }

  async function inputLink() {
    section = 'link';
    href = editor.doc.getTextFormat(editor?.doc?.selection).link || '';
    await tick();
    input.focus();
    await new Promise(resolve => setTimeout(resolve, 10));
    if (!input) return;
    // Ensure the window has focus, then the element
    input.ownerDocument.defaultView.focus();
    input.focus();
  }

  function exitInput() {
    section = 'main';
    href = '';
  }

  function onBlur(event: FocusEvent) {
    if (menu.contains(event.relatedTarget as Element)) return;
    createLink();
    menu.dispatchEvent(new Event('focusout', { bubbles: true }));
  }

  function createLink() {
    href = href.trim();
    if (href) {
      if (!href.startsWith('http')) {
        if (href.includes('.')) href = 'https://' + href;
      }
      editor.formatText({ link: href });
    }
    exitInput();
  }

  function onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      event.preventDefault();
      event.stopPropagation();
      exitInput();
    } else if (event.key === 'Enter') {
      event.preventDefault();
      createLink();
    }
  }

  function deleteLink() {
    if (editor.doc.getTextFormat(editor.doc.selection).link) {
      editor.formatText({ link: null });
    }
    exitInput();
  }
</script>

{#if editor}
  <BubbleMenu {editor} let:active let:commands let:placement let:selection offset={7}>
    <div class="menu" bind:this={menu}>
      <div data-arrow class="arrow {placement}" />
      {#if section === 'main'}
        <div class="items">
          {#if commands.bold}
            <button class="editor-menu-bold" class:active={active.bold} on:click={commands.bold}>
              <Icon path={mdiFormatBold} />
            </button>
          {/if}

          {#if commands.italic}
            <button class="editor-menu-italic" class:active={active.italic} on:click={commands.italic}>
              <Icon path={mdiFormatItalic} />
            </button>
          {/if}

          {#if commands.link}
            <button class="editor-menu-link" class:active={active.link} on:click={inputLink}>
              <Icon path={mdiLink} />
            </button>
          {/if}

          {#if (commands.bold || commands.italic || commands.link) && (commands.header2 || commands.blockquote)}
            <div class="separator format-sep" />
          {/if}

          {#if commands.header2}
            <button
              class="editor-menu-header"
              class:active={active.header === 2 || active.header === 3}
              class:active-first={active.header === 2}
              on:click={() => onVariableBlockClick('header', active, NEXT_HEADER)}
            >
              <Icon path={mdiFormatSize} class="display-format-size" />
              <Icon path={mdiFormatSize} class="display-format-size" />
            </button>
          {/if}

          {#if commands.blockquote}
            <button
              class="editor-menu-quote"
              class:active={active.blockquote}
              class:active-first={active.blockquote === 'alt'}
              on:click={() => onVariableBlockClick('blockquote', active, NEXT_QUOTE)}
            >
              <Icon path={mdiFormatQuoteOpen} class="display-format-quote-open" />
              <Icon path={mdiFormatQuoteOpen} class="display-format-quote-open" />
            </button>
          {/if}

          {#if (commands.bold || commands.italic || commands.link || commands.header2 || commands.blockquote) && settingsStore.getValuesFromPlugins('textBubbleMenu').length}
            <div class="separator editing-sep" />
          {/if}

          {#each settingsStore.getValuesFromPlugins('textBubbleMenu') as component}
            <svelte:component this={component} {editor} {active} {commands} {placement} {selection} bind:section />
          {/each}
        </div>
      {:else if section === 'link'}
        <div class="link-input">
          <input
            bind:this={input}
            bind:value={href}
            on:keydown={onKeyDown}
            on:blur={onBlur}
            placeholder="https://example.com/"
          />
          <div class="spacer" />
          <button on:click={deleteLink} class="closer" tabindex="-1"><Icon path={mdiClose} /></button>
        </div>
      {/if}
      {#each settingsStore.getValuesFromPlugins('textBubbleMenuSection') as component}
        <svelte:component this={component} {editor} {active} {commands} {placement} {selection} bind:section />
      {/each}
    </div>
  </BubbleMenu>
{/if}

<style>
  :global(.bubble-menu.active) {
    z-index: 100;
    transition: all 75ms ease-out;
  }
  .menu {
    background-image: linear-gradient(to bottom, rgba(49, 49, 47, 0.99), #262625);
    border-radius: 0.25rem;
    white-space: nowrap;
    animation: pop-upwards 180ms forwards linear;
  }
  .items {
    display: flex;
    align-items: center;
  }
  .editor-menu-bold {
    order: 10;
  }
  .editor-menu-italic {
    order: 20;
  }
  .editor-menu-link {
    order: 30;
  }
  .format-sep {
    order: 40;
  }
  .editor-menu-header {
    order: 50;
  }
  .editor-menu-quote {
    order: 60;
  }
  .editing-sep {
    order: 70;
  }
  .editing-sep:last-child {
    display: none;
  }
  .arrow {
    display: block;
    border: 7px solid transparent;
  }
  .arrow.top {
    bottom: -13px;
    border-top-color: #262625;
  }
  .arrow.bottom {
    top: -13px;
    border-bottom-color: rgba(49, 49, 47, 0.99);
  }
  .menu :global(button) {
    position: relative;
    height: 42px;
    padding: 9px;
    border: none;
    margin: 0;
    color: #fff;
    background: none;
    outline: none;
    cursor: pointer;
  }
  .menu :global(button:first-child) {
    padding-left: 14px;
  }
  .menu :global(button:last-child) {
    padding-right: 14px;
  }
  .menu :global(button.active .icon:first-child) {
    color: #74b6ff;
  }
  .menu :global(button[disabled]) {
    opacity: 0.5;
    cursor: default;
  }
  .menu :global(.icon) {
    font-size: 24px;
  }

  .menu :global(button:not(.active-first) .icon + .icon) {
    display: none;
  }
  .menu :global(button.active-first .icon:first-child) {
    position: absolute;
    top: 9px;
    left: 9px;
  }
  .menu :global(button.active-first .display-format-size:first-child) {
    clip-path: polygon(0 0, 70% 0, 70% 30%, 42% 30%, 42% 100%, 0 100%);
    z-index: 10;
  }
  .menu :global(button.active-first .display-format-quote-open:first-child) {
    clip-path: polygon(50% 0, 50% 100%, 100% 100%, 100% 0);
    z-index: 10;
  }

  .menu :global(.separator) {
    display: inline-block;
    vertical-align: middle;
    width: 1px;
    margin: 0 6px;
    height: 24px;
    background: rgba(0, 0, 0, 0.2);
    box-shadow: 1px 0 rgba(255, 255, 255, 0.2);
  }
  @keyframes pop-upwards {
    0% {
      transform: matrix(0.97, 0, 0, 1, 0, 12);
      opacity: 0;
    }
    20% {
      transform: matrix(0.99, 0, 0, 1, 0, 2);
      opacity: 0.7;
    }
    40% {
      transform: matrix(1, 0, 0, 1, 0, -1);
      opacity: 1;
    }
    70% {
      transform: matrix(1, 0, 0, 1, 0, 0);
      opacity: 1;
    }
    100% {
      transform: matrix(1, 0, 0, 1, 0, 0);
      opacity: 1;
    }
  }

  .link-input {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  .link-input input {
    flex: 1 1 100%;
    height: 100%;
    background: none;
    border: red;
    color: #f7f7f9;
    padding: 10px 12px;
    font-size: 0.875rem;
    outline: none;
    box-sizing: border-box;
  }
  .menu button.closer {
    color: #f7f7f9;
    opacity: 0.5;
  }
  .closer:hover {
    color: #f7f7f9;
    opacity: 1;
  }
</style>
