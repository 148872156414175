<script lang="ts">
  import { featuresStore } from '@dabble/data/global-data';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiCommentPlus } from '@mdi/js';

  export let commands;
  // leave these in even though they aren't used to keep warnings from screen.
  export let editor;
  export let active;
  export let placement;
  export let selection;
  export let section;
</script>

{#if $featuresStore.comments && commands.comment}
  <button class="editor-menu-comment" on:click={() => commands.comment()}>
    <Icon path={mdiCommentPlus} />
  </button>
{/if}

<style>
  .editor-menu-comment {
    order: 90;
  }
</style>
