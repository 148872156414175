import { appUpdateStore, loadingQuoteClearedStore } from './data/ui';
import { ServiceWorkerEvent, installServiceWorker } from './service-worker';
import { isProduction } from './version';

export let updateApp = () => {};

if (!isProduction) {
  updateApp = () => {};
} else if ('serviceWorker' in navigator) {
  const sw = installServiceWorker('/sw.js');

  sw.onState(state => {
    if (state === ServiceWorkerEvent.updating) {
      appUpdateStore.update(update => ({ ...update, state: 'downloading', location: 'web' }));
    } else if (state === ServiceWorkerEvent.updateReady) {
      appUpdateStore.update(update => ({ ...update, state: 'downloading', location: 'web' }));
      sw.applyUpdate();
    } else if (state === ServiceWorkerEvent.updated) {
      appUpdateStore.update(update => ({ ...update, state: 'installed', location: 'web' }));
      if (!loadingQuoteClearedStore.get()) {
        // If the loading quote is still up, reload the page immediately to get the new version
        window.location.reload();
      }
    } else if (state === ServiceWorkerEvent.updateFailed) {
      console.log('ServiceWorker Event:', 'updateFailed');
    }
  });
}
