import { StyleNames } from '../projects/novel';
import { Styles } from '../types';

export function initStyles(styles: Styles) {
  const inch6_5 = 6.5 * 72;
  styles.style(StyleNames.Normal).fontSize(12).color('#000000');
  styles.style(StyleNames.AuthorInfo).tabStop(inch6_5, 'end');
  styles.style(StyleNames.AuthorAddress).tabStop(inch6_5, 'end');
  styles.style(StyleNames.PageHeader).alignment('end');
  styles
    .style(StyleNames.NovelTitle)
    .defaultNext(StyleNames.NovelSubtitle)
    .lineSpacing(2)
    .spaceAbove(144)
    .alignment('center');
  styles.style(StyleNames.NovelSubtitle).defaultNext(StyleNames.NovelAuthor).lineSpacing(2).alignment('center');
  styles.style(StyleNames.NovelBy).defaultNext(StyleNames.Scene).spaceBelow(6).alignment('center');
  styles.style(StyleNames.NovelAuthor).lineSpacing(2).alignment('center');
  styles.style(StyleNames.TOCTitle).defaultNext(StyleNames.Scene).lineSpacing(2).alignment('center');
  styles
    .style(StyleNames.PrologueHeading)
    .defaultNext(StyleNames.Scene)
    .lineSpacing(2)
    .spaceAbove(2)
    .spaceBelow(6)
    .alignment('center');
  styles
    .style(StyleNames.Heading1)
    .defaultNext(StyleNames.Scene)
    .spaceAbove(120)
    .alignment('center')
    .spaceBelow(2)
    .lineSpacing(2);
  styles
    .style(StyleNames.Heading2)
    .defaultNext(StyleNames.Scene)
    .lineSpacing(2)
    .spaceAbove(2)
    .spaceBelow(6)
    .alignment('center');
  styles.style(StyleNames.Heading5).defaultNext(StyleNames.Scene).spaceBelow(6).alignment('center');
  styles.style(StyleNames.Heading6).defaultNext(StyleNames.Scene).spaceBelow(6).alignment('center');
  styles.style(StyleNames.Scene).defaultNext(StyleNames.Scene).lineSpacing(2).indent(36, 'firstLine');
  styles.style(StyleNames.SceneFirstParagraph, StyleNames.Scene).indent(0, 'firstLine');
  styles.style(StyleNames.SceneBreak).defaultNext(StyleNames.Scene).lineSpacing(2).alignment('center');
  styles.style(StyleNames.Note).defaultNext(StyleNames.Note).lineSpacing(2).spaceAbove(12).spaceBelow(12);
  styles.style(StyleNames.NoteFirstParagraph, StyleNames.Note);
  styles
    .style(StyleNames.NovelQuote)
    .defaultNext(StyleNames.Scene)
    .lineSpacing(2)
    .indent(36)
    .indent(36, 'firstLine')
    .italic();
  styles
    .style(StyleNames.NovelQuoteAlternate)
    .defaultNext(StyleNames.Scene)
    .lineSpacing(2)
    .indent(36)
    .indent(36, 'end')
    .alignment('center')
    .italic();
}
