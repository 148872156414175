import { projectMetaStore } from '@dabble/data/project-data';
import { EMPTY_OBJECT } from '@dabble/data/util';
import { StyleNames } from '../projects/novel';
import { Styles } from '../types';

const spacing: Record<string, number> = {
  double: 2,
  half: 1.5,
  single: 1,
};

export function initStyles(styles: Styles) {
  const font = projectMetaStore.get()?.settings?.font || EMPTY_OBJECT;
  // Note that we increase everything in the UI for readability by dividing by *0.75*, so 16pt font is 12pt in Word/Docs
  // Also, 1 inch is 96px in UI and 72pt in Word/Docs (same scale factor)
  const size = font.size || 12;
  styles.style(StyleNames.Normal).fontSize(size).lineSpacing(1).color('#000000');
  styles
    .style(StyleNames.NovelTitle)
    .defaultNext(StyleNames.NovelSubtitle)
    .spaceAbove(48)
    .spaceBelow(48)
    .fontSize(60)
    .alignment('center');
  styles
    .style(StyleNames.NovelSubtitle)
    .defaultNext(StyleNames.NovelAuthor)
    .fontSize(30)
    .spaceBelow(48)
    .alignment('center');
  styles
    .style(StyleNames.NovelBy)
    .defaultNext(StyleNames.Scene)
    .fontSize(10)
    .alignment('center')
    .allCaps(true)
    .characterSpacing(4);
  styles.style(StyleNames.NovelAuthor).fontSize(24).spaceAbove(48).spaceBelow(48).alignment('center');
  styles.style(StyleNames.TOCTitle).defaultNext(StyleNames.Scene).fontSize(16).alignment('center').lineSpacing(1);
  styles.style(StyleNames.AuthorInfo).fontSize(24).alignment('center');

  styles
    .style(StyleNames.PrologueHeading)
    .defaultNext(StyleNames.Scene)
    .spaceAbove(48)
    .spaceBelow(48)
    .fontSize(36)
    .alignment('center');
  styles
    .style(StyleNames.Heading1)
    .defaultNext(StyleNames.Scene)
    .spaceAbove(48)
    .spaceBelow(48)
    .fontSize(60)
    .alignment('center');
  styles
    .style(StyleNames.Heading2)
    .defaultNext(StyleNames.Scene)
    .fontSize(36)
    .alignment('center')
    .spaceAbove(20)
    .spaceBelow(48);
  styles.style(StyleNames.Heading3).fontSize(21).bold();
  styles.style(StyleNames.Heading4).fontSize(18).bold();
  styles
    .style(StyleNames.Heading5)
    .defaultNext(StyleNames.Scene)
    .fontSize(10)
    .spaceAbove(160)
    .alignment('center')
    .allCaps(true)
    .characterSpacing(4);
  styles
    .style(StyleNames.Heading6)
    .defaultNext(StyleNames.Scene)
    .fontSize(10)
    .alignment('center')
    .allCaps(true)
    .characterSpacing(4);
  styles.style(StyleNames.TableOfContents).lineSpacing(1);
  const scene = styles
    .style(StyleNames.Scene)
    .defaultNext(StyleNames.Scene)
    .lineSpacing(spacing[font.sceneSpacing || 'double']);
  const sceneFirst = styles.style(StyleNames.SceneFirstParagraph, StyleNames.Scene).defaultNext(StyleNames.Scene);
  styles.style(StyleNames.SceneBreak).defaultNext(StyleNames.Scene).alignment('center');

  const note = styles
    .style(StyleNames.Note)
    .defaultNext(StyleNames.Note)
    .lineSpacing(spacing[font.noteSpacing || 'half']);

  const lineHeight = spacing[font.sceneSpacing || 'double'] * 12;
  styles
    .style(StyleNames.NovelQuote)
    .defaultNext(StyleNames.Normal)
    .spaceAbove(lineHeight)
    .spaceBelow(lineHeight)
    .indent(36)
    .italic();
  styles
    .style(StyleNames.NovelQuoteAlternate)
    .defaultNext(StyleNames.Normal)
    .spaceAbove(lineHeight)
    .spaceBelow(lineHeight)
    .indent(36)
    .indent(36, 'end')
    .alignment('center')
    .italic();

  if (!font.sceneStyle || font.sceneStyle.startsWith('book')) {
    if (!font.sceneStyle || font.sceneStyle === 'book') {
      scene.indent(36, 'firstLine');
      sceneFirst.indent(0, 'firstLine');
    }
  } else if (font.sceneStyle === 'modern') {
    scene.spaceAbove(1.2 * size).spaceBelow(1.2 * size);
  }

  if (font.noteStyle?.startsWith('book')) {
    if (font.noteStyle === 'book') {
      note.indent(36, 'firstLine');
      sceneFirst.indent(0, 'firstLine');
    }
  } else if (font.noteStyle === 'modern') {
    scene.spaceAbove(1.2 * size).spaceBelow(1.2 * size);
  }
}
