import { agreeable } from '@dabble/data/agreeable';
import { load, readyStore, signals } from '@dabble/data/app-state';
import { delegate } from '@dabble/data/delegate';
import { ACCOUNT_URL } from '@dabble/data/global-constants';
import { uidStore } from '@dabble/data/ids';
import { $t } from '@dabble/data/intl';
import { routerStore } from '@dabble/data/navigation';
import { plugins } from '@dabble/data/plugins';
import { rest } from '@dabble/data/rest';
import { User } from '@dabble/data/types';
import { confirm } from '@dabble/data/ui';
import { derived } from 'easy-signal';
import { createAccountsStore, createCurrentUserStore, createDelegatesStore } from './account-stores';

export const accountsStore = createAccountsStore();
export const currentUserStore = createCurrentUserStore(uidStore, accountsStore);
export const delegatesStore = createDelegatesStore();

derived<User>(prevUser => {
  const user = currentUserStore.get();
  if (user && user.uid !== prevUser?.uid) {
    signals.onAuthed(user);
  }
  return user;
});

export async function init() {
  // Get the UID for this session
  const query = routerStore.getInitialQuery();
  const token = query.get('token');
  const as = query.get('as') || getUIDFromToken(token);
  let uid = as;
  const created = query.has('created') || sessionStorage.created;
  if (created) {
    delete sessionStorage.created;
    signals.onSignup();
  }

  if (!uid) {
    uid = delegate || sessionStorage.uid || localStorage.uid;
  }

  if (!uid || typeof uid !== 'string' || uid.length !== 28) {
    try {
      const user = await rest.get('/auth/user').send();
      uid = user.uid;
    } catch (err) {}
    if (!uid) {
      return gotoAccountLogin();
    }
  }

  if (delegate && uid !== delegate) {
    delete sessionStorage.delegate;
    location.reload();
    return;
  }

  sessionStorage.uid = uid;
  localStorage.uid = uid;

  load(uid);

  if (!localStorage.signedInBefore) {
    localStorage.signedInBefore = '1';
  }

  let accounts: User[] = [];

  try {
    accounts = await accountsStore.load(as && !delegate);
  } catch (err) {
    if (err.message === 'Authorization required') {
      return gotoAccountLogin();
    }
  }

  if (delegate && !accounts.find(a => a.uid === delegate)) {
    const [user] = (await agreeable.call('getUsers', [delegate])) as User[];
    if (user) {
      const { uid, name, email } = user;
      delegatesStore.add({ uid, name, email });
    }
  }

  readyStore.set(true);
}

agreeable.onMessage(message => {
  if (message.signOut && uidStore.get() === message.signOut) {
    gotoAccount(message.next);
  }
});

plugins.register({ currentUserStore, accountsStore });

export async function login() {
  gotoAccountLogin();
}

export async function signOut() {
  if (await agreeable.hasUncommittedChanges()) {
    const stop = !(await confirm($t('confirm_header_unsynced_data'), $t('confirm_unsynced_data'), {
      danger: true,
      yesNo: true,
    }));
    if (stop) return;
  }

  const nextUID = await agreeable.call('signOut');
  delegatesStore.remove();
  delete sessionStorage.uid;
  if (localStorage.uid === uidStore.get()) {
    delete localStorage.uid;
  }

  gotoAccount(localStorage.uid || nextUID);
}

// To signout from a delegate account that was left open by mistake
export async function singOutOfDelegate(uid: string) {
  agreeable.call('signOut', uid);
  delegatesStore.remove(uid);
}

export function gotoAccount(uid?: string) {
  if (uid) location.href = `/?as=${uid}&skipLoadingQuote`;
  else gotoAccountLogin();
}

function getUIDFromToken(token: string): string {
  if (!token) return null;
  try {
    return JSON.parse(atob(token.split('.')[1])).uid;
  } catch (err) {
    console.error(err);
  }
  return null;
}

function gotoAccountLogin() {
  // redirects to accounts.dabblewriter.com
  const page = localStorage.signedInBefore ? 'login' : 'signup';
  location.href = `${ACCOUNT_URL}auth/${page}?redirect=${getValidDomain()}`;
}

function getValidDomain() {
  const url = new URL(location.href);
  if (
    url.hostname === 'localhost' ||
    url.hostname.endsWith('.dabblewriter.com') ||
    url.hostname.endsWith('.dabble-writer.pages.dev')
  ) {
    return `${url.protocol}//${url.host}`;
  } else {
    return 'https://app.dabblewriter.com';
  }
}
