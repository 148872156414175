import { Readable, derived, writable } from 'easy-signal';
import { FeatureTags, UserAccountStore } from '../agreeable/agreeable-config';

/**
 * A module to manage the list of features available to the user based on their plan and tags.
 */

export type AvailableFeatures =
  | 'dark_mode'
  | 'nanowrimo'
  | 'unlimited_projects'
  | 'goals'
  | 'story_notes'
  | 'plotting'
  | 'spell_check'
  | 'grammar'
  | 'stickies'
  | 'comments'
  | 'images'
  | 'coauthoring'
  | 'support_low'
  | 'support_email'
  | 'support_chat';

export type UserFeatures = {
  [feature in AvailableFeatures]?: true;
};

export type FeaturesStore = ReturnType<typeof createFeaturesStore>;

const EMPTY_ARRAY: string[] = [];

/**
 * Creates a store that holds the user's features based on their plan and tags. The store's object contains the features
 * as keys and true as the value for easy lookup of whether a feature is available to the user.
 */
export function createFeaturesStore(globalFeaturesStore: Readable<FeatureTags>, userAccountStore: UserAccountStore) {
  const userPlan = derived(() => {
    const { plan, status } = userAccountStore.get();
    if (!status || status === 'paused' || status === 'inactive') return null;
    return plan === 'free' || plan === 'lifetime' ? 'premium' : plan;
  });
  const userTags = derived(() => userAccountStore.get().tags || EMPTY_ARRAY);
  const userFeatures = derived(() => userAccountStore.get().features || EMPTY_ARRAY);

  const { get, subscribe } = derived<UserFeatures>(() => {
    const allowed: UserFeatures = {};
    const allFeatures = globalFeaturesStore.get() || ({} as FeatureTags);
    const tags = ['global'];
    const plan = userPlan.get();
    if (plan) tags.push(plan);
    if (userTags.get()) tags.push(...userTags.get());
    const add = (f: string) => {
      f[0] === '-' ? delete allowed[f.slice(1) as keyof UserFeatures] : (allowed[f as keyof UserFeatures] = true);
    };

    tags.forEach(t => allFeatures[t]?.forEach(add));
    if (userFeatures.get()) {
      userFeatures.get().forEach(add);
    }

    return allowed;
  });

  const upgradeFeature = writable('');

  function promptUpgrade(feature: string) {
    upgradeFeature.set(feature);
  }

  return {
    upgradeFeature,
    promptUpgrade,
    get,
    subscribe,
  };
}
