import { Migration } from '@dabble/plugins/sync/types';
import { DocFromConfig, RolesDoc, Type, defineConfig, defineDoc } from 'agreeable-client';
import { AGREEABLE_DOMAIN } from '../global-constants';
import { Preferences, Project, ProjectMeta, ShareData } from '../types';

export type DabbleConfig = typeof config;

// Data Types
export type FeatureTags = {
  basic?: string[];
  standard?: string[];
  premium?: string[];
  beta?: string[];
  dev?: string[];
  [tag: string]: string[];
};

export type User = {
  name: string;
  email: string;
  created: number;
  lastOnline: number;
};

export type UserAccount = {
  plan: 'basic' | 'standard' | 'premium' | 'lifetime' | 'free';
  stripeId: string;
  status: 'active' | 'trialing' | 'delinquent' | 'paused' | 'inactive';
  external: ExternalData;
  migrations?: Migrations;
  tags?: string[];
  features?: string[];
};

export type Migrations = Record<string, Migration>;

export type ExternalData = {
  intercomHash: string;
  google: GoogleAuth;
};

export type DailyStats = {
  [day: string]: number;
};

// Users will store the hourly stats for total count by local time
export type HourlyStats = {
  // stored by the hour for more robust statistics
  [hour: string]: number;
};

// Projects will store the hourly stats for each document by epoch time
export type ProjectStats = {
  [hour: string]: {
    [docId: string]: number;
  };
};

export type DailyGoal = {
  goal?: number;
  lastWin?: string; // YYYY-MM-DD
  today?: { date: string; start: number };
  sendReminder?: boolean;
  lastReminder?: number;
};

export type NaNoWriMo = {
  participatedMonths?: string[];
  month?: string; // YYYY-MM
  auth?: string; // auth token
  participating?: boolean;
  paused?: boolean;
  projectId?: string;
  username?: string;
  novel?: NaNoNovel;
  excludeCountOffered?: boolean;
};

export interface NaNoNovel {
  id: number;
  active: boolean;
  title: string;
  wordcount: number;
}

export type Onboarding = {
  wordsAdded: number;
  startDate: number;
  completed?: number; // bit map of finished steps (5 steps means 31 is all done)
  hide?: boolean;
  rewarded?: boolean;
};

export type GoogleAuth = {
  access_token: string;
  scope: string;
  token_type: string;
  expiry_date: number;
  url: string;
};

export type UserData = {
  preferences: Preferences;
  timezone: string;
  daysOff: {
    [weekday: number]: true;
    [date: string]: boolean;
  };
  dailyGoal: DailyGoal;
  nanowrimo: NaNoWriMo;
  onboarding: Onboarding;
  googleAuth: GoogleAuth;
};

export type UserProjectDoc = {
  opened?: boolean;
};

export type UserProjectDocs = {
  [docId: string]: UserProjectDoc;
};

export type UserProjectGoal = {
  targetId?: string;
  start?: number;
  end?: number;
  deadline?: string; // YYYY-MM-DD
  lastWin?: string; // YYYY-MM-DD
  today?: { date: string; start: number };
};

export type UserProject = {
  lastOpened: number;
  lastSelected: string;
  category?: 'archive' | 'trash';
  isTemplate?: boolean;
  goal?: UserProjectGoal;
  docs?: UserProjectDocs;
};

export type UserProjects = {
  [projectId: string]: UserProject;
};

export type GlobalSettings = {
  minVersion: string;
  disabled: Record<string, boolean>;
};

export type GlobalData = {
  features: Record<string, string[]>;
  migrations: Record<string, Migration>;
  settings: GlobalSettings;
};

// Doc Types

export type GlobalStore = DocFromConfig<typeof global>;
export type UserStore = DocFromConfig<typeof users>;
export type UserAccountStore = DocFromConfig<typeof account>;
export type UserDataStore = DocFromConfig<typeof data>;
export type UserStatsStore = DocFromConfig<typeof stats>;
export type UserProjectsStore = DocFromConfig<typeof userProjects>;
export type ProjectMetaStore = DocFromConfig<typeof projects>;
export type ProjectDocStore = DocFromConfig<typeof doc>;
export type ProjectStatsStore = DocFromConfig<typeof projectStats>;
export type ProjectSharedStore = DocFromConfig<typeof shared>;

const global = defineDoc(Type<GlobalData>, {
  isCollection: true,
  versioned: false,
  type: 'last_write_wins',
  access: 'global_read',
});

const account = defineDoc(Type<UserAccount>, {
  type: 'last_write_wins',
});

const data = defineDoc(Type<UserData>, {
  type: 'last_write_wins',
  access: 'user_write',
});

const stats = defineDoc(Type<HourlyStats>, {
  isCollection: true,
  type: 'last_write_wins',
  access: 'user_write',
});

const userProjects = defineDoc(Type<UserProjects>, {
  type: 'last_write_wins',
  access: 'user_write',
});

const users = defineDoc(Type<User>, {
  isCollection: true,
  type: 'last_write_wins',
  access: 'user_read',
  children: {
    account,
    data,
    stats,
    projects: userProjects,
  },
});

const projectStats = defineDoc(Type<ProjectStats>, {
  isCollection: true,
  type: 'last_write_wins',
});

const shared = defineDoc(Type<Record<string, ShareData>>, {
  type: 'last_write_wins',
  access: 'user_write',
});

const doc = defineDoc(Type<Project>, {
  versioned: true,
  type: 'operational_transformation',
  access: 'user_write',
  hasText: 'TextDocument',
});

const roles = defineDoc(Type<Record<string, RolesDoc>>, {
  type: 'last_write_wins',
  access: 'user_write',
});

const projects = defineDoc(Type<ProjectMeta>, {
  isCollection: true,
  type: 'last_write_wins',
  access: 'role',
  children: {
    stats: projectStats,
    shared,
    doc,
    roles,
  },
});

export const config = defineConfig({
  name: 'dabble',
  domain: AGREEABLE_DOMAIN,
  children: {
    global,
    users,
    projects,
  },
});
