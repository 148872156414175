{
  "onboarding_getting_started": "Getting Started",
  "onboarding_create_project": "Create a Project",
  "onboarding_create_scene": "Create a New Scene",
  "onboarding_write_note": "Create a Note",
  "onboarding_create_plot_line": "Create a Plot line",
  "onboarding_write_1000": "Write 1000 Words",
  "onboarding_dismiss": "Hide",
  "onboarding_learn_more": "Learn More",
  "onboarding_title": "Welcome to Dabble!",
  "onboarding_coupon_title": "<span class=\"dollar\">$</span><span class=\"value\">20</span><span class=\"off\">off</span>",
  "onboarding_coupon_subtitle": "Your Dabble Subscription",
  "onboarding_get_paid": "Write 1000 words",
  "onboarding_write_we_pay": "Here at Dabble, we celebrate completing goals. ",
  "onboarding_objective": "If you write 1000 new words in the next 14 days, you'll get $20 off any Dabble subscription.",
  "onboarding_help_create_project_1": "Press the New Project Button (:mdiPlusThick:)",
  "onboarding_help_create_project_2": "Your new Project will open on the Title Page. Go ahead and title your Book!",
  "onboarding_help_create_scene_1": "Locate the Chapter over in the Left Navigation Bar.",
  "onboarding_help_create_scene_2": "Hovering over an existing Chapter in the Left Navigation Bar will reveal three vertical dots (:mdiDotsVertical:)",
  "onboarding_help_create_scene_3": "Click the three vertical dots (:mdiDotsVertical:) to open the Chapter Menu.",
  "onboarding_help_create_scene_4": "Select Add New Scene from the Chapter Menu",
  "onboarding_help_create_scene_5": "Your new Scene will be added at the end of the Chapter. Click on it to begin writing in that scene.",
  "onboarding_help_write_note_1": "Locate the Story Notes in the Left Navigation Bar.",
  "onboarding_help_write_note_2": "Hovering over Story Notes will reveal three vertical dots(:mdiDotsVertical:)",
  "onboarding_help_write_note_3": "Click the three vertical dots (:mdiDotsVertical:) to open the Story Notes Menu.",
  "onboarding_help_write_note_4": "Select Add New Note from the Story Notes Menu",
  "onboarding_help_write_note_5": "Your new Note will be added at the bottom of Story Notes. Click on it to begin writing in that Note.",
  "onboarding_help_create_plot_line_1": "Locate the <em>Plot for Untitled Book</em> Plot Grid in the Left Navigation Bar under Plot.",
  "onboarding_help_create_plot_line_2": "Click the Plot Grid name to open it in the Project Screen.",
  "onboarding_help_create_plot_line_3": "Hovering over any of the blank boxes in the Plot Grid will reveal the New Plot Point Button (:mdiPlusThick:)",
  "onboarding_help_create_plot_line_4": "In the third column, click the New Plot Point Button to create a new Plot line and a new Plot Point Card.",
  "onboarding_help_write_1000_1": "Locate the Chapter under Manuscript in the Left Navigation Bar and click on it.",
  "onboarding_help_write_1000_2": "In the Project Screen, click on <em>Once upon a time...<em> to place your cursor.",
  "onboarding_help_write_1000_3": "Begin writing!",
  "onboarding_survey_text": "We can customize Dabble for you a bit. What genre is your book?",
  "onboarding_survey_submit": "Get Writing!",
  "onboarding_genre_general_fiction": "General Fiction",
  "onboarding_genre_fantasy": "Fantasy",
  "onboarding_genre_sci_fi": "Sci-Fi",
  "onboarding_genre_romance": "Romance",
  "onboarding_genre_mystery": "Mystery",
  "onboarding_genre_historical": "Historical",
  "onboarding_genre_other": "Non-fiction/Other",

  "onboarding_find_us": "How did you find Dabble?",
  "onboarding_find_internet": "Internet Search <br/><small>(Google, Bing, etc.)</small>",
  "onboarding_find_social_media": "Social Media <br/><small>(Facebook, Twitter/X, Instagram, LinkedIn, etc.)</small>",
  "onboarding_find_referral": "Word of Mouth <br/><small>(Friends, Family, Colleagues)</small>",
  "onboarding_find_website": "Referral from another Website or Blog",
  "onboarding_find_dabble_u": "Through DabbleU <br/><small>(Our Articles on Writing)</small>",
  "onboarding_find_other": "Other <br/><small>(Please share where you found us in the text box)</small>",
  "onboarding_other": "How did you find us?",
  "onboarding_other_send": "Next"
}
