<script lang="ts">
  import { featuresStore } from '@dabble/data/global-data';
  import { t } from '@dabble/data/intl';
  import ToolButton from '@dabble/toolkit/ToolButton.svelte';
  import { mdiCommentHistory } from '@dabble/toolkit/custom-icons';
</script>

{#if $featuresStore.comments}
  <div class="comment-sidebar-button">
    <ToolButton
      class="comments-history-button"
      icon={mdiCommentHistory}
      tooltip={$t('comments_history_open')}
      sidebar="comment_history"
    />
  </div>
{/if}

<style>
  .comment-sidebar-button {
    order: 5;
  }
</style>
