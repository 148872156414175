import { Project, ProjectMeta } from '@dabble/data/types';
import { Delta, cloneDeep } from 'typewriter-editor';
import { agreeable } from './agreeable';
import { $t } from './intl';
import { createProject, creatingProject } from './project-data';

interface TextChange {
  docId: string;
  field: string;
  delta: Delta;
}

export async function duplicateProject(id: string) {
  const projectMetaStore = agreeable.projects(id);
  const projectDocStore = projectMetaStore.doc;
  const [meta, proj] = await Promise.all([projectMetaStore.load(), projectDocStore.load()]);
  projectDocStore.unload();

  const project = cloneDeep(proj) as Project;
  const projectMeta = cloneDeep(meta) as ProjectMeta;

  delete project.id;

  delete projectMeta.created;
  delete projectMeta.modified;
  delete projectMeta.isTemplate;
  delete projectMeta.templateCount;

  // Add copy to title
  const hasTitle = Boolean(projectMeta.title);
  projectMeta.title = projectMeta.title
    ? $t('project_title_copy', { title: projectMeta.title, interpolation: { escapeValue: false } })
    : $t('project_copy');

  if (!hasTitle) {
    const bookId = project.docs.manuscript.children[0];
    project.docs[bookId].title = projectMeta.title;
  }

  const textChanges: TextChange[] = [];

  Object.keys(project.docs).forEach(docId => {
    const doc = { ...project.docs[docId] };
    if (doc.description) {
      textChanges.push({ docId: doc.id, field: 'description', delta: doc.description as any as Delta });
      delete doc.description;
    }
    if (doc.body) {
      textChanges.push({ docId: doc.id, field: 'body', delta: doc.body as any as Delta });
      delete doc.body;
    }
    project.docs[docId] = doc;
  });

  return createDuplicate(project, projectMeta, textChanges);
}

async function createDuplicate(project: any, projectMeta: ProjectMeta, textChanges: TextChange[]) {
  const {
    project: { id },
    userProject,
  } = await createProject(project, projectMeta);
  creatingProject.set(id);
  const projectDocStore = agreeable.projects(id).doc;

  for (const { docId, field, delta } of textChanges) {
    await projectDocStore.update(patch => {
      patch.changeText(`/docs/${docId}/${field}`, delta.ops);
    });
  }

  creatingProject.set('');
  return { id, userProject };
}
