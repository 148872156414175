import { settingsStore } from '@dabble/data/settings';
import { init } from './accounts';
import AccountMenu from './components/AccountMenu.svelte';
import AccountRoutes from './components/AccountRoutes.svelte';
import HelpMenu from './components/HelpMenu.svelte';
import './locales';

settingsStore.configure('dabble', {
  headerRight: {
    AccountMenu,
    HelpMenu,
  },
  routes: { AccountRoutes },
});

init();
