<script lang="ts">
  import Note from '@dabble/plugins/novel/components/Note.svelte';
  import FullPageImage from './FullPageImage.svelte';

  export let doc;
</script>

<Note>
  <FullPageImage {doc} />
</Note>
