import { delegate } from '@dabble/data/delegate';
import { RequestAPI, createRestAPI } from '@dabble/rest-client';
import { REST_LOCAL_KEY } from 'env';
import { REST_URL } from './global-constants';
import { uidStore } from './ids';

export type REST = ReturnType<typeof createRestAPI> & { as(uid: string): REST };

class DabbleRestRequest extends RequestAPI {
  as(uid: string) {
    this.header('x-uid', uid);
    return this;
  }
  useLocal(uid: string) {
    this.header('Authorization', `Bearer ${REST_LOCAL_KEY} ${uid}`);
    return this;
  }
}

export const rest = createRestAPI(REST_URL, undefined, DabbleRestRequest);

rest.hook((req: DabbleRestRequest) => {
  if (req.url.hostname === 'localhost') {
    req.useLocal(uidStore.get() || '');
  }
  req.credentials('include');
  if (delegate) {
    req.as(delegate);
  }
});
