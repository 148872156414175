<script lang="ts">
  import { t } from '@dabble/data/intl';
  import { settingsStore } from '@dabble/data/settings';
  import { Component } from '@dabble/data/types';
  import { EMPTY_ARRAY } from '@dabble/data/util';
  import { desktop } from '@dabble/desktop';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import Modal from '@dabble/toolkit/Modal.svelte';
  import { mdiFormatDabble, mdiFormatManuscript } from '@dabble/toolkit/custom-icons';
  import { exportOptions, startExport } from '../exports';
  import ConnectGoogleOption from './ConnectGoogleOption.svelte';

  const exportFormats: Record<string, { icon: string }> = {
    manuscript: { icon: mdiFormatManuscript },
    dabble: { icon: mdiFormatDabble },
  };

  let googleConnected = false;

  $: docSettings = settingsStore.getFor($exportOptions?.doc.type);
  $: formats = docSettings.exportStyles || EMPTY_ARRAY;
  $: options = getOptions($exportOptions?.fileType);

  function getOptions(type: string): Component[] {
    const optionMap = docSettings.exportOptions?.[type];
    return optionMap ? Object.values(optionMap) : [];
  }

  function close() {
    $exportOptions = null;
  }

  function update(eventOrChanges: any) {
    const changes = eventOrChanges.detail || eventOrChanges;
    exportOptions.update(ops => ({ ...ops, ...changes }));
  }
</script>

{#if $exportOptions && $exportOptions.fileType !== 'html' && $exportOptions.fileType !== 'text'}
  <Modal title={$t('export_more')} on:close={close}>
    <section>
      <h3>{$t('export_options')}</h3>
      <div class="options">
        <div class="formats">
          <div class="button-list">
            {#each formats as name}
              <button
                on:click={() => update({ style: name })}
                class="export-button large-button"
                class:active={$exportOptions.style === name}
              >
                <Icon path={exportFormats[name].icon} inline />
                <div class="small-text">{$t(`export_format_${name}`)}</div>
              </button>
            {/each}
          </div>
        </div>
        {#if desktop.active}
          <div class="form-group">
            <label for="filename">Filename</label>
            <input class="form-control" id="filename" type="text" bind:value={$exportOptions.filename} />
          </div>
        {/if}
        {#each options as component}
          <div>
            <svelte:component this={component} options={$exportOptions} doc={$exportOptions.doc} on:update={update} />
          </div>
        {/each}
      </div>
    </section>
    <footer>
      {#if $exportOptions.fileType === 'google'}
        <div class="left">
          <ConnectGoogleOption bind:connected={googleConnected} />
        </div>
      {/if}
      <button
        class="btn primary"
        on:click={() => startExport(close)}
        disabled={!googleConnected && $exportOptions.fileType === 'google'}
      >
        {$t('export')}
      </button>
    </footer>
  </Modal>
{/if}

<style>
  .button-list,
  * :global(.button-list) {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 1rem;
  }
  .large-button,
  * :global(.large-button) {
    width: 100px;
    text-align: center;
    font-size: 3rem;
    padding: 0.5rem 0.25rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    color: var(--text-color-normal);
  }
  .small-text {
    font-size: 0.75rem;
  }
  .export-button,
  .options :global(.export-button) {
    border: 1px solid transparent;
    background: transparent;
    border-radius: 5px;
    color: var(--gray-light);
    cursor: pointer;
  }
  .export-button:hover,
  .options :global(.export-button:hover) {
    background-color: var(--gray-lightest);
  }
  .active,
  .options :global(.active) {
    border: 1px solid var(--dabble-blue);
    border-radius: var(--border-radius);
    background-color: rgba(var(--dabble-blue-rgb), 0.015);
  }
  label,
  .options :global(label) {
    display: block;
    font-weight: bold;
    color: inherit;
  }
  h5,
  .options :global(h5) {
    font-weight: bold;
    font-size: inherit;
    margin: 0 0 0.5rem;
  }
  .options {
    display: flex;
    flex-direction: column;
    color: var(--gray-light);
  }
</style>
