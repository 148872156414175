<script lang="ts">
  import Interactions from '@dabble/toolkit/Interactions.svelte';
  import { afterUpdate, createEventDispatcher } from 'svelte';
  import { fade } from 'svelte/transition';

  export let nofocus = false;
  export let size = ''; // small, large
  export let centered = false;
  let className = '';

  const dispatch = createEventDispatcher();
  let container: HTMLElement;
  let background: Element;

  export { className as class };

  export function close(event?: Event) {
    if (!event || event.target === container) {
      dispatch('close');
    }
  }

  function add(node: Element, event: string, handler: EventListenerOrEventListenerObject) {
    node.addEventListener(event, handler);
    return () => node.removeEventListener(event, handler);
  }

  function dispatchTap(node: Element, x: number, y: number) {
    node.dispatchEvent(
      new CustomEvent('tap', {
        detail: { x, y },
      })
    );
  }

  function tapThis(node: Element) {
    function handlePointerDown(event: MouseEvent) {
      const { clientX, clientY } = event;

      const remove_pointerup_handler = add(node, 'pointerup', (event: MouseEvent) => {
        if (event.target !== node) return;
        if (Math.abs(event.clientX - clientX) > 5) return;
        if (Math.abs(event.clientY - clientY) > 5) return;

        dispatchTap(node, event.clientX, event.clientY);
        remove_pointerup_handler();
      });

      setTimeout(remove_pointerup_handler, 300);
    }

    const remove_pointerdown_handler = add(node, 'pointerdown', handlePointerDown);

    return {
      destroy() {
        remove_pointerdown_handler();
      },
    };
  }

  afterUpdate(() => {
    if (container && container.parentNode !== document.body) {
      document.body.appendChild(background);
      document.body.appendChild(container);
      if (!nofocus) container.focus();
    }
  });
</script>

<Interactions on:shortcut-Escape={close} />

<div bind:this={background} class="modal-background" transition:fade={{ duration: 300 }} />
<div
  bind:this={container}
  class="modal-container{size ? ' ' + size : ''} {className}"
  class:centered
  tabindex="-1"
  role="dialog"
  use:tapThis
  on:tap={close}
>
  <slot />
</div>

<style>
  .centered {
    display: grid;
    place-items: center;
  }
</style>
