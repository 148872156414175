import waf from '@dabble/util/waf';
import { Readable, writable } from 'easy-signal';
import { isEqual } from 'typewriter-editor';
import { DESKTOP, sizeStore } from '../device';
import { Preferences } from '../types';
import { RouterStore } from './router';

export interface Focus {
  focusing: boolean;
  focusLock: boolean;
  preventFocusing: boolean;
  focused: boolean;
  autofocused: boolean;
}

export interface FocusStore extends Readable<Focus> {
  start: (lock?: boolean) => void;
  stop: (unlock?: boolean) => void;
  prevent: (on: boolean) => void;
  skipNextStop: () => void;
}

export const FOCUSING_TIME = 500;
export const FOCUSING_TIME_SLOW = 3000;

/**
 * Creates a store that manages the focus state of the app. Focus can be locked, unlocked, prevented, and autofocused.
 * Preferences may prevent focusing, and focusing will stop when leaving a project.
 */
export function createFocusStore(preferences: Readable<Preferences>, router: RouterStore): FocusStore {
  let focus: Focus = { focusing: false, focusLock: false, preventFocusing: false, focused: false, autofocused: false };
  let skipNextTimeout: any = 0;
  let focusingTimeout: any = 0;
  let focusedTimeout: any = 0;
  let continuedInputTimeout: any = 0;
  const { get, set, subscribe } = writable(focus, () => {
    return router.subscribe(router => {
      if (!router.path.startsWith('/p/') && focus.focusing) stop(true);
    });
  });

  function update(updates: Partial<Focus>) {
    const newFocus = { ...focus, ...updates };
    if (!isEqual(newFocus, focus)) {
      set((focus = newFocus));
    }
  }

  function start(lock?: boolean) {
    if (sizeStore.get() !== DESKTOP || (focus.preventFocusing && !lock) || (preferences.get().noAutofade && !lock)) {
      return;
    }
    if (lock === true) {
      clearTimeout(focusedTimeout);
      update({ focusing: true, focusLock: lock, preventFocusing: false });
      focusedTimeout = setTimeout(() => update({ focusing: true, focused: true }), FOCUSING_TIME);
    } else if (!focus.focusing && !focusingTimeout) {
      clearTimeout(focusedTimeout);
      focusingTimeout = setTimeout(() => {
        clearTimeout(continuedInputTimeout);
        focusingTimeout = null;
        update({ ...focus, focusing: true });
        focusedTimeout = setTimeout(() => update({ focusing: true, autofocused: true }), FOCUSING_TIME_SLOW);
      }, 6000);
    }
    if (lock !== true && focusingTimeout && !(focus.focusing || focus.autofocused || focus.focused)) {
      clearTimeout(continuedInputTimeout);
      continuedInputTimeout = setTimeout(stop, 1500);
    }
  }

  async function stop(unlock?: boolean) {
    if (skipNextTimeout) {
      // Don't count mousemoves triggered by the autoscroll
      skipNextTimeout = 0;
    } else if (unlock === true && focus.focusing) {
      clearTimeout(focusedTimeout);
      focusingTimeout = null;
      update({ focusing: false, focusLock: false, preventFocusing: false, focused: false, autofocused: false });
    } else if ((focusingTimeout || focus.focusing) && !focus.focusLock) {
      clearTimeout(focusingTimeout);
      focusingTimeout = null;
      await waf();
      update({ focusing: false, focused: false, autofocused: false });
    }
  }

  function prevent(on: boolean) {
    if (focus.preventFocusing !== on) {
      update({ preventFocusing: on });
    }
  }

  function skipNextStop() {
    skipNextTimeout = setTimeout(() => (skipNextTimeout = 0), 40);
  }

  return {
    start,
    stop,
    prevent,
    skipNextStop,
    get,
    subscribe,
  };
}
