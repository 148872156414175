// Fixes the plot line children order
import { projectStore } from '@dabble/data/project-data';
import isEqual from 'lodash/isEqual';

type Column = Array<string | null>;
type Grid = Column[];

export function fixPlotLines() {
  const gridFixPatch = projectStore.patch();
  const project = projectStore.get().project;

  Object.values(project.docs).forEach(async doc => {
    if (doc.type !== 'novel_plot') return;
    let errors = false;
    const { patch } = gridFixPatch;
    let grid = (doc.grid as Grid).map(col => col.slice());
    const firstColumn = grid && grid[0] && project.docs[grid[0][0]];
    const linkedGrid = firstColumn && firstColumn.type === 'novel_book';
    const offset = linkedGrid ? 1 : 0;

    const found = new Set<string>();
    let dups = false;

    grid = grid.map((col, x) =>
      col.map(id => {
        if (found.has(id)) dups = true;
        if (!id || found.has(id)) return null;
        found.add(id);
        return id;
      })
    );

    if (dups) {
      gridFixPatch.updateDoc(doc.id, { grid });
    }

    grid.slice(offset).map(col => {
      const line = project.docs[col[0]];
      const children = col
        .slice(1)
        .filter(Boolean)
        .filter(id => project.docs[id]);
      if (!isEqual(line.children, children)) {
        console.log('line', line.id, 'children out of order');
        patch.replace(`/docs/${line.id}/children`, children);
        errors = true;
      }
    });

    if (linkedGrid && !doc.oldParentId && !firstColumn.oldParentId) {
      // Fix links if any are missing
      grid.slice(1).forEach(col => {
        col.slice(1).forEach((id, i) => {
          const sceneId = grid[0][i + 1];
          if (!id) return;
          let hasLink = false;
          const links = Object.keys(project.links).filter(key => key.startsWith(`${id}:plot`));
          links.forEach(link => {
            const [from, _, to] = link.split(':');
            if (!project.docs[to]) {
              errors = true;
              console.log('missing doc linked from', id, to);
              patch.remove(`/links/${link}`);
            } else if (to === sceneId) {
              return (hasLink = true);
            } else {
              errors = true;
              patch.remove(`/links/${link}`);
              console.log('Removed bad link');
            }
          });
          if (!hasLink && sceneId && project.docs[sceneId]) {
            errors = true;
            patch.replace(`/links/${id}:plot:${sceneId}`, {});
            console.log('Added missing link');
          }
        });
      });
    }

    if (errors) {
      console.log('fixing', doc.id);
    }
  });

  if (gridFixPatch.patch.ops.length) {
    console.log('Saving plot lines');
    gridFixPatch.save();
  }
}
