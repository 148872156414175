import { isProduction } from '@dabble/version';
import { USE_ACCOUNT, USE_AGREEABLE, USE_LOCAL, USE_REST, USE_TEST } from 'env';

let agreeableDomain = 'db.dabblewriter.com';
let restURL = 'https://rest.dabblewriter.com/';
let accountURL = 'https://accounts.dabblewriter.com/';

if (!isProduction) {
  if (USE_LOCAL) {
    agreeableDomain = 'localhost:8787';
    restURL = 'http://localhost:35379/';
    accountURL = 'http://localhost:5174/';
  } else if (USE_TEST) {
    agreeableDomain = 'db.test.dabblewriter.com';
    restURL = 'https://rest.test.dabblewriter.com/';
    accountURL = 'http://accounts.test.dabblewriter.com/';
  }
  if (USE_AGREEABLE) agreeableDomain = 'localhost:8787';
  if (USE_REST) restURL = 'http://localhost:35379/';
  if (USE_ACCOUNT) accountURL = 'http://localhost:5174/';
}

export const AGREEABLE_DOMAIN = agreeableDomain;
export const REST_URL = restURL;
export const ACCOUNT_URL = accountURL;
