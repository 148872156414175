import { $t } from '@dabble/data/intl';
import { projectStore } from '@dabble/data/project-data';
import { settingsStore } from '@dabble/data/settings';
import { Doc, Project } from '@dabble/data/types';
import { getAllOfType } from '@dabble/data/util';
import isEqual from 'lodash/isEqual';
// TODO add this back in once the collab module is refactored. Should novel be dependent on collab? Can we make this
// work without this dependency?
// import addAuthorFormat from '../../collab/add-author-format';
// import authorTooltip from '../../collab/author-tooltip';

const bookScenesCache = new WeakMap<Doc, Doc>();

settingsStore.configure('dabble', {
  defaultProjectType: 'novel',
});

settingsStore.configure(
  'novel',
  {
    hideInNavigation: true,
    placeholders: {
      title: 'novel_untitled',
      author: 'author_unknown',
      subtitle: 'novel_book_unsubtitled',
    },
    validChildTypes: {},
    editorModules: {
      // authorTooltip,
      // addAuthorFormat,
    },
    menuTypes: [],
    createMenu: [
      'novel_scene',
      'novel_chapter',
      'novel_prologue',
      'novel_part',
      'novel_book',
      '-',
      'novel_plot_line',
      'novel_plot', // 'novel_character', 'novel_cast',
      '-',
      'novel_page',
      'novel_folder',
      '-',
      'novel_character',
      'novel_cast',
    ],
    templatesMenu: [
      'novel_scene',
      'novel_chapter',
      'novel_prologue',
      'novel_part',
      'novel_book',
      'novel_character',
      'novel_cast',
      'novel_page',
      'novel_folder',
    ],
    getDefaultDocument(novel: Project) {
      const children = projectStore.get().childrenLookup;
      const manuscript = children[novel.id]?.[0] || novel;
      return children[manuscript.id][0] || manuscript;
    },
    virtualDocs(novel: Project) {
      return Object.values(novel.docs)
        .filter(doc => doc.type === 'novel_book')
        .map(book => {
          const children = getAllOfType(book, novel.docs, 'novel_scene');
          let doc = bookScenesCache.get(book);
          if (!doc || !isEqual(doc.children, children)) {
            doc = { id: book.id + '-scenes', type: 'novel_book_scenes', children };
            bookScenesCache.set(book, doc);
          }
          return doc;
        });
    },
  },
  'project'
);

// new item structure

settingsStore.configure('novel', {
  migrations: {
    '2.5.0': async () => {
      const docs = projectStore.get().project.docs;
      const patch = projectStore.patch();
      if (!docs.templates) {
        patch.patch.add('/docs/templates', { id: 'templates', type: 'templates', children: [] }, { soft: true });
      }
      if (!docs.characters) {
        patch.patch.add(
          '/docs/characters',
          { id: 'characters', type: 'novel_characters', children: [] },
          { soft: true }
        );
        patch.patch.add('/children/2', 'characters', { soft: true });
      }
      if (patch.patch.ops.length) {
        await patch.save();
      }
    },
  },
  finalizeStructure(project: Project) {
    const plotId = project.docs.plots.children[0];
    // A project copy doen't need this, only a new project
    if (plotId && !project.docs[plotId].grid) {
      const bookId = project.docs.manuscript.children[0];
      const lineId = project.docs[plotId].children[0];
      const pointId = project.docs[lineId].children[0];
      const chapterId = project.docs[bookId].children[0];
      const sceneId = project.docs[chapterId].children[0];
      project.links[`${plotId}:plot:${bookId}`] = {};
      project.links[`${pointId}:plot:${sceneId}`] = {};
      project.docs[plotId].grid = [
        [bookId, sceneId],
        [lineId, pointId],
      ];
    }
  },
  structure: () => {
    return [
      {
        id: 'manuscript',
        type: 'novel_manuscript',
        children: [
          {
            type: 'novel_book',
            title: '',
            children: [
              {
                type: 'novel_chapter',
                children: [
                  {
                    type: 'novel_scene',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 'plots',
        type: 'novel_plots',
        children: [
          {
            type: 'novel_plot',
            children: [
              {
                type: 'novel_plot_line',
                children: [
                  {
                    type: 'novel_plot_point',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        id: 'characters',
        type: 'novel_characters',
        children: [
          {
            type: 'novel_cast',
            title: $t('novel_main_characters'),
            children: [
              {
                type: 'novel_character',
              },
            ],
          },
          {
            type: 'novel_cast',
            title: $t('novel_secondary_characters'),
            children: [
              {
                type: 'novel_character',
              },
            ],
          },
        ],
      },
      {
        id: 'notes',
        type: 'novel_notes',
        children: [
          {
            type: 'novel_folder',
            title: $t('novel_world_building'),
            children: [
              {
                type: 'novel_page',
              },
            ],
          },
        ],
      },
    ];
  },
});
