{
  "editor_upload_image": "Insertar Imagen",
  "editor_unsplash_image": "Buscar e Insertar Imagen Unsplash",
  "unsplash_search": "Buscar Imágenes de Unsplash",
  "content_paste_images_removed_title": "Imágenes Eliminadas",
  "content_paste_images_removed_text": "Se encontraron una o más imágenes en el contenido que se está pegando. Deberá descargarlos a su computadora y cargarlos en Dabble para usarlos.",
  "content_not_document": "Imagen no cargada",
  "load_more": "Carga más",
  "no_more_results": "No hay más resultados",
  "photo_by": "Foto por",
  "photos_at": "Fotos de",
  "unsplash_timeout": "La búsqueda de Unsplash se ha agotado.Puede estar fuera de línea."
}
