
import { settingsStore } from '@dabble/data/settings';
import { TypesetTypes } from 'typewriter-editor';
import Dictionary from './components/Dictionary.svelte';
import GrammarSettings from './components/GrammarSettings.svelte';
import GrammarToggle from './components/GrammarToggle.svelte';
import WordToolButton from './components/WordToolButton.svelte';
import WordTools from './components/WordTools.svelte';
import './grammar-format';
import { grammarModule } from './grammar-module';
import './locales';
import './styles/grammar.css';
import './styles/styles.css';

settingsStore.configure('dabble', {
  editorModules: {
    grammar: grammarModule, // Display grammar issues
  },
  editorTypes: {
    grammar: (typeset: TypesetTypes) => {
      const { formats } = typeset;
      const after = formats.indexOf('comment') + 1 || formats.indexOf('link') + 1;
      return { ...typeset, formats: [...formats.slice(0, after), 'grammar', ...formats.slice(after)] } as TypesetTypes;
    },
  },
  uiToggleMenu: { GrammarToggle },
  projectSettings: { GrammarSettings },
  plugins: {
    account: {
      preferences: {
        Dictionary,
      },
    },
  },
  sidebarButtons: { WordToolButton },
  sidebar: {
    thesaurus: { WordTools },
  },
});
