<script lang="ts">
  import { readonlyStore } from '@dabble/data/app-state';
  import { t } from '@dabble/data/intl';
  import { getUrl, goto } from '@dabble/data/navigation';
  import { projectStore } from '@dabble/data/project-data';
  import { Doc, Project } from '@dabble/data/types';
  import { EMPTY_ARRAY } from '@dabble/data/util';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import Title from '@dabble/toolkit/Title.svelte';
  import { mdiPlusThick } from '@mdi/js';

  export let doc: Doc;

  $: books = getBooks($projectStore.project);

  function getBooks(project: Project) {
    if (!project) return [];
    const manuscriptSection = projectStore.getChildren(project.id)[0];
    return projectStore.getChildren(manuscriptSection.id) || EMPTY_ARRAY;
  }

  async function createPlot(book?: Doc) {
    const plotId = projectStore.createDocId();
    const patch = projectStore.patch();
    patch.createDoc({ id: plotId, type: 'novel_plot' }, doc.id);
    if (book) patch.linkDocs(plotId, 'plot', book.id);
    await patch.save();
    goto(getUrl(plotId));
  }
</script>

<div class="plot-section">
  <div class="plot-management">
    <h1>{$t('plot_grid_header')}</h1>
    <h5>{$t('plot_grid_create_generic')}</h5>
    <button on:click={() => createPlot()} class="btn secondary darker add-child" disabled={$readonlyStore}>
      <Icon path={mdiPlusThick} />
    </button>
    {$t('plot_grid_create_new')}

    <h5>{$t('plot_grid_create_for_book')}</h5>

    {#each books as book}
      <p>
        <button on:click={() => createPlot(book)} class="btn secondary darker add-child" disabled={$readonlyStore}>
          <Icon path={mdiPlusThick} />
        </button>
        “<em><Title doc={book} /></em>”
      </p>
    {/each}
  </div>
  <div class="plot-instruction">
    <h1>{$t('plot_grid_introducing')}</h1>
    {@html $t('plot_grid_explanation')}

    <h2>{$t('plot_grid_grid')}</h2>

    <div class="mini-plot-grid" />

    <p>{$t('plot_grid_a_plot_grid')}</p>

    <ul>
      {@html $t('plot_grid_bullet_points')}
    </ul>

    <h2>{$t('plot_grid_plot_line')}</h2>

    <div class="mini-plot-line" />

    {@html $t('plot_grid_plot_line_explanation')}

    <h2 style="clear:right">{$t('plot_grid_plot_point')}</h2>

    {@html $t('plot_grid_plot_grid_plot_point_explanation')}
  </div>
</div>

<style>
  .plot-section {
    display: flex;
    flex-wrap: wrap;
  }
  h1 {
    color: var(--text-color-lighter);
    text-shadow: var(--text-highlight);
    text-align: center;
    box-shadow: var(--horizontal-bevel);
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  .plot-management {
    width: 496px;
    margin: 0 20px;
    color: var(--text-color-light);
    text-shadow: var(--text-highlight);
  }
  .plot-instruction {
    flex: 0 0 auto;
    margin: 0 20px;
    width: 496px;
  }
  .plot-instruction h1,
  .plot-instruction h2,
  .plot-instruction > p,
  .plot-instruction > ul {
    color: var(--text-color-light);
    text-shadow: var(--text-highlight);
  }
  .plot-instruction > p,
  .plot-instruction > ul {
    color: var(--text-color-normal);
  }
  .mini-plot-grid {
    float: right;
    margin-left: 10px;
    background: url(../images/plot-grid.jpg) no-repeat;
    background-size: contain;
    width: 140px;
    height: 105px;
  }
  .mini-plot-line {
    float: right;
    background: url(../images/plot-line.jpg) no-repeat;
    background-size: contain;
    width: 128px;
    height: 180px;
  }
</style>
