import { projectStore } from '@dabble/data/project-data';
import { Doc } from '@dabble/data/types';
import { userRoleStore } from '@dabble/data/user-project';
import { mdiBookOpenOutline } from '@mdi/js';

export const addChapterHeadings = [
  {
    key: 'novel_add_chapter_headings',
    icon: mdiBookOpenOutline,
    action: setAddChapterHeadings,
    hide: (doc: Doc) => (doc.hideChapterHeadings ? false : true),
    disabled: () => !userRoleStore.get(),
  },
];

export const removeChapterHeadings = [
  {
    key: 'novel_remove_chapter_headings',
    icon: mdiBookOpenOutline,
    action: setRemoveChapterHeadings,
    hide: (doc: Doc) => (doc.hideChapterHeadings ? true : false),
    disabled: () => !userRoleStore.get(),
  },
];

async function setAddChapterHeadings(doc: Doc) {
  await projectStore.updateDoc(doc.id, { hideChapterHeadings: false });
  projectStore.forceTextUpdate();
}

async function setRemoveChapterHeadings(doc: Doc) {
  await projectStore.updateDoc(doc.id, { hideChapterHeadings: true });
  projectStore.forceTextUpdate();
}
