import { settingsStore } from '@dabble/data/settings';
import { ImageStyleDefinition } from '@dabble/data/types';
import {
  mdiImageFillWidth,
  mdiImageFullPage,
  mdiImageOutsetCenter,
  mdiImageOutsetLeft,
} from '@dabble/toolkit/custom-icons';
import { onSceneBodyPaste } from '../novel/paste-import';
import ImageBubbleMenu from './components/ImageBubbleMenu.svelte';
import UnsplashSearchDialog from './components/UnsplashSearchDialog.svelte';
import { removeEmbededImagesOnPaste } from './images';
import './locales';
import './typewriter-image';

const MIN_FULL_SIZE = 720; //1192;

settingsStore.configure('dabble', {
  globals: { UnsplashSearchDialog },
  editableMenus: { ImageBubbleMenu },
  imageStyleDefinitions: {
    /*
    'inset-center': { // DEFAULT STYLE
      icon: mdiImageInsetCenter,
      shouldDisplay: () => true,
    } as ImageStyleDefinition,*/
    'outset-left': {
      icon: mdiImageOutsetLeft,
      shouldDisplay: () => true,
    } as ImageStyleDefinition,
    'outset-center': {
      icon: mdiImageOutsetCenter,
      shouldDisplay: (imageData: any) => imageData.width > MIN_FULL_SIZE,
    } as ImageStyleDefinition,
    'fill-width': {
      icon: mdiImageFillWidth,
      shouldDisplay: (imageData: any) => imageData.width > MIN_FULL_SIZE,
    } as ImageStyleDefinition,
    'full-page': {
      icon: mdiImageFullPage,
      shouldDisplay: (imageData: any) => imageData.width > MIN_FULL_SIZE,
    } as ImageStyleDefinition,
  },
});

settingsStore.configure('novel_scene', {
  onPasteSanitize: { images: removeEmbededImagesOnPaste },
});

settingsStore.configure('novel_page', {
  onPaste: { body: onSceneBodyPaste },
  onPasteSanitize: { images: removeEmbededImagesOnPaste },
});
