{
  "app_cache": "Cache Management",
  "app_cache_clear": "Clear Application Cache",
  "app_cache_clear_description": "Remove Dabble’s application files from this device, reload, and reinstall.",
  "app_cache_image_clear": "Clear Image Cache",
  "app_cache_image_clear_description": "Remove your image files from this device. They will be cached when next viewed.",
  "app_cache_images_cleared": "Image Cache has been cleared",
  "app_db_delete": "Delete Database",
  "app_db_delete_description": "Delete your local database from this device, reload, recreate, and synced (if online).",
  "data_clear_space": "Free up space on your computer! Dabble needs more room than this. Please.",
  "data_used_available": "<strong>{{used}}</strong> used / <strong>{{available}}</strong> available",
  "data_used_header": "Data Used",
  "data_export_db": "Export Database",
  "data_export_db_description": "Backup the entire local database to a JSON file for later recovery.",
  "data_export_db_header": "Export Entire Local Database",
  "data_export_description": "Pure data backup of projects from your local machine or our server. Really only useful for disaster recovery. But if you are the overly cautious type…",
  "data_export_header": "Export Project Data",
  "data_export_project": "Export Project Data",
  "data_export_slow": "This may take a minute.",
  "data_import_db": "Import Database",
  "data_management": "Data Management",
  "data_select_project": "Select a project…",
  "data_disconnected": "You’ve got to be connected to the internet to export server data! And Dabble is not. Connected. Right now.",
  "save_error": "Error saving",
  "save_error_message": "There is an error with your data. Please contact support so you don't lose any progress.",
  "save_pending": "Pending changes",
  "save_saved": "Saved locally",
  "save_saving": "Saving…",
  "syncing_syncing": "Syncing",
  "syncing_complete": "Synced to your account",
  "syncing_offline": "Disconnected",
  "syncing_error": "Error syncing: {{error}}",
  "syncing_error_message": "There was an error syncing. If refreshing/restarting doesn't fix it, contact support. \nError: {{- error}}",
  "syncing_off": "Syncing off",
  "sync_status": "Your project’s current save and sync status",
  "update_apply": "Apply Update",
  "update_installed": "An update has been installed.",
  "update_message": "A mandatory update to Dabble {{version}} is required.",
  "update_required": "Update Required",
  "update_restart": "Restart Dabble",
  "update_updating": "Updating…",
  "update_wait": "Please wait while an update downloads and installs.",
  "clear_title": "Clear Cache",
  "clear_warning": "Warning!",
  "clear_data_loss": "Warning! Clicking the button below will delete your local database if it exists.  If you fear you might not be logged in please download your local database above.  Your data is safe in the cloud and when you are redirected back to Dabble your database will resync correcting the issue you had.",
  "clear_download": "Download Local Database",
  "clear_data": "Clear local Data on this device",
  "clear_loading": "Loading…",
  "clear_no_database": "No database found",
  "clear_starting": "Starting to clear data.",
  "clear_database_deleted": "Database deleted",
  "clear_cache_cleared": "Cache cleared",
  "clear_unregister_failed": "Service Worker unregister failed.",
  "clear_service_worker_unregistered": "Service Worker unregistered",
  "clear_local_storage_cleared": "Local storage cleared",
  "clear_session_storage_cleared": "Session storage cleared"
}
