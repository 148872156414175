import { DabbleDatabase } from '@dabble/data/old-types';
import { DabbleAgreeableClient } from '@dabble/data/types';
import { ExtendAPI, Leader } from 'agreeable-client';
import { Browserbase, ObjectStore } from 'browserbase';

export function syncLeaderExtension(
  client: DabbleAgreeableClient,
  dabbleDB: DabbleDatabase,
  extendApi: ExtendAPI,
  leader: Leader
) {
  // Add API methods to the leader to be called by client.call('method', ...args)
  extendApi({ exportDatabase, exportProjectData, importDatabase });

  async function exportDatabase() {
    const agreeableDB = leader.getDB();
    const allData: any = { dabble: {}, agreeable: {} };
    await Promise.all([
      ...Object.values(dabbleDB.stores).map(async store => {
        allData.dabble[store.name] = await store.getAll();
      }),
      ...Object.values(agreeableDB.stores).map(async store => {
        allData.agreeable[store.name] = await store.getAll();
      }),
    ]);
    return allData;
  }

  async function exportProjectData(projectId: string) {
    const agreeableDB = leader.getDB();
    const pathName = `projects/${projectId}/doc`;
    const doc = await agreeableDB.stores.docs.get(pathName);
    const changes = await agreeableDB.stores.changes.where().startsWith([pathName]).getAll();
    return { doc, changes };
  }

  // TODO: Jacob needs to fix this because the keys are out of line, much like your teenage son always was.

  async function importDatabase(file: { text: () => string | PromiseLike<string> }) {
    leader.disconnect();
    const agreeableDB = leader.getDB();
    const data = JSON.parse(await file.text());
    if (!data.agreeable || !data.dabble) {
      throw new Error('Invalid or old data format');
    }
    await Promise.all([importDB(data.agreeable, agreeableDB), importDB(data.dabble, dabbleDB)]);
  }

  async function importDB(data: any, db: Browserbase) {
    const storeNames = Object.keys(data);
    for (let i = 0; i < storeNames.length; i++) {
      const name = storeNames[i] as keyof typeof db.stores;
      const values = data[name];
      const store = db.stores[name] as ObjectStore;
      if (!store) {
        console.warn('store', name, 'does not exist');
        continue;
      }

      await store.deleteAll();
      await store.putAll(values);
    }
  }
}
