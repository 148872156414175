import { EditorElement } from '@dabble/data/editables';
import { viewport } from '@dabble/data/ui';
import { Editor, EditorRange, getIndexFromNodeAndOffset } from 'typewriter-editor';
import { Comment, commentsStore } from '../comments-store';

export interface CommentData {
  id: string;
  comment: Comment;
  index: number;
  origin: number;
  top: number;
  height: number;
}

export interface CommentDataMap {
  [id: string]: CommentData;
}

export interface CommentEditorData {
  editor: Editor;
  range: EditorRange;
}

const DEFAULT_HEIGHT = 80;
const SELECTOR = '.comment.decoration[data-ids*="ID"], format-comment[data-all-ids*="ID"]';
const RULE = '.editable-content format-comment[data-all-ids*="ID"]';

export function getSelector(id: string, selector = SELECTOR) {
  return selector.replace(/ID/g, id);
}

export function highlightComment(page: HTMLElement, id: string, scrollTo?: boolean) {
  const rule = findRule(page);
  if (rule) {
    rule.selectorText = getSelector(id, RULE);
    if (scrollTo) {
      const { editor, range } = getSaveDataForComment(page, id) as CommentEditorData;
      if (editor) {
        viewport.scrollIntoView(editor.identifier, range[0]);
      }
    }
  }
}

export function getEditorForComment(page: HTMLElement, id: string) {
  return getEditorForNode(getNode(page, id));
}

export function getSaveDataForComment(page: HTMLElement, commentId: string): CommentEditorData | {} {
  const elems = getNodes(page, commentId);
  const root = elems[0]?.closest('.typewriter-editor') as EditorElement;
  if (!root) return {};
  const editor = root.editor;
  const first = elems[0];
  const last = elems[elems.length - 1];
  const until = last.childNodes.length;

  const range = [getIndexFromNodeAndOffset(editor, first, 0), getIndexFromNodeAndOffset(editor, last, until)];

  return { editor, range };
}

export function getPageComments(page: HTMLElement) {
  const commentMap: CommentDataMap = {};
  const comments = commentsStore.get();
  const offset = page.nextElementSibling.getBoundingClientRect().top;

  page.querySelectorAll('.comment.decoration, format-comment:not([data-ids=""]').forEach((node: HTMLElement) => {
    const top = node.getBoundingClientRect().top - offset;
    const origin = top;
    const { ids } = node.dataset;
    ids.split(',').forEach(id => {
      // The first node for a comment will be its desired top position.
      if (!(id in commentMap)) {
        const index = getIndexFromNodeAndOffset(getEditorForNode(node), node, 0);
        const comment = comments[id];
        commentMap[id] = { id, comment, index, origin, top, height: DEFAULT_HEIGHT };
      }
    });
  });

  const now = Date.now();

  return Object.values(commentMap).sort((a, b) => {
    if (a.top !== b.top) return a.top - b.top;
    if (a.index !== b.index) return a.index - b.index;
    const aCreated = (a.comment && a.comment.created) || now;
    const bCreated = (b.comment && b.comment.created) || now;
    return aCreated - bCreated;
  });
}

export function selectComment(page: HTMLElement, id: string, startOnly?: boolean) {
  const elems = getNodes(page, id);

  if (!elems.length) return;
  const doc = page.ownerDocument;
  const selection = doc.getSelection();
  const first = elems[0];
  const last = startOnly ? first : elems[elems.length - 1];
  const until = startOnly ? 0 : last.childNodes.length;
  selection.setBaseAndExtent(first, 0, last, until);
}

function getNode(page: HTMLElement, id: string) {
  return page.querySelector(getSelector(id)) as HTMLElement;
}

function getNodes(page: HTMLElement, id: string) {
  return page.querySelectorAll(getSelector(id)) as NodeListOf<HTMLElement>;
}

function getEditorForNode(node: HTMLElement) {
  return (node?.closest('.typewriter-editor') as EditorElement)?.editor;
}

function findRule(page: HTMLElement) {
  if (!page) return;
  const doc = page.ownerDocument;
  for (let i = 0; i < doc.styleSheets.length; i++) {
    const sheet = doc.styleSheets[i];
    try {
      if (!sheet.cssRules) continue;
    } catch (e) {
      continue;
    }

    for (let j = 0; j < sheet.cssRules.length; j++) {
      const rule = sheet.cssRules[j] as any;
      if (rule.selectorText && rule.selectorText.startsWith(RULE.split('ID')[0])) {
        return rule;
      }
    }
  }
}
