<script lang="ts">
  import { docStore } from '@dabble/data/doc-data';
  import { projectSettingsStore, projectStore } from '@dabble/data/project-data';
  import { settingsStore } from '@dabble/data/settings';
  import { Doc } from '@dabble/data/types';
  import { readToMeDocIdStore } from '@dabble/data/ui';
  import { EMPTY_ARRAY } from '@dabble/data/util';
  import { tick } from 'svelte';
  import NavItem from './NavItem.svelte';

  export let scrolled = false;
  export let visibleNav: Set<string> | null = null;
  let projectChildren: Doc[];
  let scrollContainer: HTMLElement;
  const scrollPadding = 16;

  $: project = $projectStore.project;
  $: projectChildren = (project && projectStore.getChildren(project.id)) || EMPTY_ARRAY;
  $: children = (projectChildren && filterChildren(projectChildren)) || EMPTY_ARRAY;
  $: if ($docStore) onCurrentDocChange();

  function onScroll() {
    scrolled = this.scrollTop > 0;
  }

  function filterChildren(children: Doc[]) {
    const exists = new Set();
    return children.filter(child => {
      if (settingsStore.getFor(child).hideInNavigation || exists.has(child.id)) return false;
      exists.add(child.id);
      return true;
    });
  }

  async function onCurrentDocChange() {
    await tick();
    const selected = scrollContainer.querySelector('.selected > .nav-item-header, .child-selected > .nav-item-header');
    if (selected) {
      $readToMeDocIdStore = $docStore?.id;
      scrollIntoViewIfNeeded(scrollContainer, selected);
    }
  }

  function scrollIntoViewIfNeeded(container: Element, element: Element) {
    let rect = element.getBoundingClientRect();
    if (rect) {
      const relative = container.getBoundingClientRect();
      const leftOffset = container.scrollLeft - relative.x;
      const topOffset = container.scrollTop - relative.y;
      rect = new DOMRect(rect.x + leftOffset, rect.y + topOffset, rect.width, rect.height);
    }
    const { scrollTop, clientHeight } = container;
    const scrollBottom = scrollTop + clientHeight;
    const centered = rect.bottom - Math.floor(clientHeight / 2);
    if (rect.top < scrollTop) {
      if (rect.bottom > scrollTop) {
        container.scrollTop = rect.top - scrollPadding;
      } else {
        container.scrollTop = centered;
      }
    } else if (rect.bottom > scrollBottom) {
      if (rect.top < scrollBottom) {
        container.scrollTop = rect.bottom - container.clientHeight + scrollPadding;
      } else {
        container.scrollTop = centered;
      }
    }
  }
</script>

<div on:scroll={onScroll} bind:this={scrollContainer} class="main-nav nav-container">
  {#if project}
    {#if !$projectSettingsStore.hideInNavigation}
      <NavItem doc={project} {visibleNav} noChildren />
    {/if}
    {#each children as doc (doc.id)}
      <NavItem {doc} {visibleNav} />
    {/each}
    {#if project.docs?.templates && (!visibleNav || visibleNav.has('templates'))}
      <NavItem doc={project.docs.templates} {visibleNav} />
    {/if}
    {#if (!visibleNav || visibleNav.has('trash')) && project.docs?.trash}
      <NavItem doc={project.docs.trash} {visibleNav} />
    {/if}
  {/if}
</div>

<style>
  .main-nav {
    position: relative;
    flex: 1 1 100%;
    margin-top: 0;
    padding-top: 4px;
    padding-bottom: calc(var(--side-nav-item-height) * 4);
    overflow-y: auto;
    -webkit-overflow-scrolling: auto;
  }
</style>
