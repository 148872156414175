import { settingsStore } from '@dabble/data/settings';
import './author-format';
import CollabSettings from './components/CollabSettings.svelte';
import CollabToggleButton from './components/CollabToggleButton.svelte';
import InviteAcceptance from './components/InviteAcceptance.svelte';
import './locales';

settingsStore.configure('dabble', {
  projectSettings: { CollabSettings },
  globals: { InviteAcceptance },
  uiToggleMenu: { CollabToggleButton },
  editorTypes: {
    author: {
      formats: ['author'],
    },
  },
});
