import { settingsStore } from '@dabble/data/settings';
import { mdiImageText } from '@mdi/js';
// import AcceptSharedTemplate from './components/AcceptSharedTemplate.svelte';
import CardPopup from './components/CardPopup.svelte';
import Counts from './components/Counts.svelte';
import FileView from './components/FileView.svelte';
import FolderView from './components/FolderView.svelte';
import ProfileFolderView from './components/ProfileFolderView.svelte';
import ProfileView from './components/ProfileView.svelte';
import ProjectView from './components/ProjectView.svelte';
import './locales';

settingsStore.configure('project', {
  view: {
    component: ProjectView,
    searchableFields: ['title'],
  },
});
settingsStore.configure('folder', {
  view: {
    component: FolderView,
    searchableFields: ['title'],
  },
});
settingsStore.configure('file', {
  view: {
    component: FileView,
    searchableFields: ['title', 'body'],
  },
});
settingsStore.configure('profile_folder', {
  view: {
    component: ProfileFolderView,
    searchableFields: ['title'],
  },
});
settingsStore.configure('profile', {
  icon: mdiImageText,
  view: {
    component: ProfileView,
    searchableFields: ['title', 'description', 'body'],
  },
});
settingsStore.configure('dabble', {
  // globals: { CardPopup, AcceptSharedTemplate }, // TODO decide where templates go and fix this up (probably a separate plugin)
  globals: { CardPopup },
  statusBarRight: { Counts },
});

// TODO this shouldn't be in the core plugin, should it? And it shouldn't be in the index file if it should. Import it
// from a templates file.
// // lock and unlock uneditable templates
// observe([projectStore, projectMetasStore], ([projectStore, projectMetas]) => {
//   readonly.removeLock('template');
//   const id = projectStore.projectId;
//   const projectMeta = projectMetas[id];
//   const uid = plugins.stores.uid.get();
//   const canEdit = authed.get() && projectMeta?.roles[uid];
//   if (!canEdit && projectMeta?.isTemplate) readonly.addLock('template');
// });
