{
  "CREDENTIAL_TOO_OLD_LOGIN_AGAIN": "Debes verificar tu antigua contraseña para cambiarla.",
  "EMAIL_EXISTS": "Este email está siendo utilizado por otra cuenta.",
  "EMAIL_NOT_FOUND": "Ingresó un email incorrecto.",
  "FORBIDDEN": "No se le permite hacer esto.",
  "INVALID_ARGUMENTS": "Debe proporcionar su nombre, email y contraseña.",
  "INVALID_CUSTOM_TOKEN": "Las credenciales de usuario ya no son válidas. Inicie sesión de nuevo.",
  "INVALID_DATA": "No se proporcionaron cambios de perfil.",
  "INVALID_ID_TOKEN": "Las credenciales de usuario ya no son válidas. Inicie sesión de nuevo.",
  "INVALID_PASSWORD": "Ingresó una contraseña incorrecta.",
  "TOO_MANY_ATTEMPTS_TRY_LATER": "Lo ha intentado demasiadas veces. Las solicitudes se están bloqueando para evitar actividades sospechosas. Vuelva a intentarlo más tarde.",
  "USER_DISABLED": "Su cuenta ha sido deshabilitada.",
  "UNAUTHORIZED": "Debe iniciar sesión para hacer esto.",
  "USER_NOT_FOUND": "Usuario no encontrado.",
  "WEAK_PASSWORD": "La contraseña debe tener 6 caracteres o más.",
  "account_user_interface": "Interfaz de Usuario",
  "account_disconnected": "Desconectado",
  "account_get_help_tooltip": "Funciones, errores o ayuda",
  "account_help": "Ayuda",
  "account_help_center": "Centro de Ayuda",
  "account_login": "Iniciar sesión",
  "account_signup": "Crear cuenta Dabble",
  "account_login_another": "Ingresa con Otra Cuenta",
  "account_logout": "Desconectar",
  "account_offline": "Desconectado",
  "account_release_notes": "Notas de Lanzamiento",
  "account_report_bug": "Reportar un Error",
  "account_request_feature": "Solicitar una Característica",
  "account_screen_billing": "Facturación",
  "account_screen_preferences": "Preferencias",
  "account_screen_profile": "Perfil",
  "account_switch_account": "Cambiar Cuenta",
  "account_update_info_tooltip": "Actualiza tu información",
  "account_ui_language": "Idioma de U/I",
  "account_set_ui_language": "Establecer Idioma de U/I",
  "auth_disconnected": "No estás <strong>conectado</strong> al servidor. :(<br>¿Comprobar tu conexión? ¿Intentar de nuevo más tarde?",
  "auth_signin": "Iniciar Sesión en su Cuenta",
  "auth_signup": "Crear una cuenta Dabble",
  "auth_reauth": "Volver a Autenticar",
  "auth_forgot_password": "¿No recuerda su contraseña?",
  "auth_not_registered": "¿No registrado?",
  "auth_create_account": "¡Crea una cuenta!",
  "auth_signin_here": "Iniciar sesión aquí.",
  "auth_already_registered": "¿Ya registrado?",
  "auth_cancel": "Cancelar",
  "auth_send_reset": "Enviar email de restablecimiento",
  "auth_terms": "Cuando inicia sesión y usa Dabble, acepta nuestros <br><a href=\"https://www.dabblewriter.com/terms.html\" target=\"_blank\" class=\"external\" >términos de servicio</a> y <a href=\"https://www.dabblewriter.com/privacy.html\" target=\"_blank\" class=\"external\">política de privacidad</a >.",
  "auth_email": "Email",
  "auth_name": "Nombre",
  "auth_new_password": "Nueva Contraseña",
  "auth_password": "Contraseña",
  "dictionary": "Mantenimiento de Diccionarios",
  "preferences_autofade_description": "Desvanece lo no esencial al escribir. Elegante. Fácil. Increíble.",
  "preferences_autofade_label": "Desvanecimiento Automático",
  "preferences_autoscroll_description": "Desplace la página hacia arriba a medida que el cursor se mueve hacia abajo. Como una máquina de escribir. No es necesario quitar las manos del teclado.",
  "preferences_autoscroll_label": "Desplazamiento de la Máquina de Escribir",
  "preferences_extra_space_label": "Prevenir Espacios Adicionales",
  "preferences_extra_space_description": "Evite que se agreguen 2 espacios uno al lado del otro para mantener limpio el manuscrito. Ayuda contra ese mal hábito de doble espacio después de los períodos.",
  "preferences_holidays_label": "Temas del Día Festivo",
  "preferences_holidays_description": "Agregue un poco más de alegría a Dabble en las fiestas populares.",
  "preferences_loading_quote_label": "Mostrar Cotizaciones al Inicio",
  "preferences_loading_quote_description": "Le encantan las citas de escritura inspiradoras. O no.",
  "preferences_touch_ui_label": "Preferir Interfaz de Usuario Táctil",
  "preferences_touch_ui_description": "Preferir Interfaz de Usuario Táctil",
  "preferences_native_find_description": "Utilice Buscar y reemplazar de Dabble en lugar del navegador. Si quiere.",
  "preferences_native_find_label": "Utilice Buscar/Reemplazar de Dabble",
  "preferences_native_context_menu_description": "Utilice el menú contextual de Dabble para la edición de texto.",
  "preferences_native_context_menu_label": "Usar el Menú Contextual de Dabble",
  "profile_password_updated": "Contraseña actualizada con éxito.",
  "profile_update": "Actualización del perfil",
  "profile_updated": "Perfil actualizado con éxito.",
  "profile_update_description": "Cambie su nombre, actualice su correo electrónico y/o elija una nueva contraseña. Solo las cosas regulares.",
  "preferences_no_mobile_label": "Preferir la Interfaz de Usuario de Escritorio",
  "preferences_no_mobile_description": "Prefiere mantener la interfaz de usuario del escritorio cuando está en una tableta en modo horizontal",
  "no_url": "Los nombres no pueden ser variaciones de URL",
  "min_3": "Los nombres deben tener al menos tres caracteres",
  "invalid_email": "Dirección de correo electrónico no válida",
  "1_symbol": "Su contraseña debe tener al menos 1 símbolo.",
  "1_lowercase": "Su contraseña debe tener al menos 1 letra minúscula.",
  "1_uppercase": "Su contraseña debe tener al menos 1 letra mayúscula.",
  "1_digit": "Su contraseña debe tener al menos 1 número.",
  "8_32_characters": "Su contraseña debe tener entre 8 y 32 caracteres.",
  "doc_print": "Imprimir",
  "doc_underline": "Subrayar",
  "doc_escape": "Clara Selección/Ocultar cursor",
  "rule_characters": "entre 8 y 32 caracteres",
  "rule_symbol": "1 símbolo",
  "rule_lower": "1 letra minúscula",
  "rule_upper": "1 letra mayúscula",
  "rule_digit": "1 número"
}
