import { settingsStore } from '@dabble/data/settings';
import ChecklistHelp from './components/ChecklistHelp.svelte';
import ChecklistToggle from './components/ChecklistToggle.svelte';
import LearnMore from './components/LearnMore.svelte';
import OnboardingModal from './components/OnboardingModal.svelte';
import './locales';
import './onboarding';

settingsStore.configure('dabble', {
  globals: { OnboardingModal, LearnMore, ChecklistHelp },
  uiToggleMenu: { ChecklistToggle },
});
