<script lang="ts">
  /**
   * Use like this:
   * {#if showSuccess}
   * <Alert type="success" dismissible on:close={() => showSuccess = false}>
   *   <strong>Well done!</strong> You've successfully done it!
   * </Alert>
   * {/if}
   */
  import { createEventDispatcher } from 'svelte';
  import { fadeDown, slide } from './transitions';

  export let dismissible = false;
  export let type = '';
  export let global = false;
  export let hovered = false;
  let className = '';
  export { className as class };
  let container;
  let transition;

  $: transition = global ? fadeDown : slide;

  export function close() {
    dispatch('close');
  }

  const dispatch = createEventDispatcher();

  function onMouseEnter() {
    hovered = true;
  }

  function onMouseLeave() {
    hovered = false;
    dispatch('unhovered');
  }
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<div
  bind:this={container}
  class="alert {type} {className}"
  class:alert-dismissible={dismissible}
  class:global
  on:mouseenter={onMouseEnter}
  on:mouseleave={onMouseLeave}
  transition:transition={{ duration: 200 }}
>
  {#if dismissible}
    <button type="button" class="close" on:click={close}>&times;</button>
  {/if}
  <slot />
</div>

<style>
  .alert {
    transition: all 0.2s ease-in-out;
  }
  .alert.global {
    position: fixed;
    z-index: 1060;
    top: 50px;
    left: 10px;
    right: 10px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  }
  @media (min-width: 544px) {
    .global.alert {
      width: 440px;
      left: 50%;
      margin-left: -220px;
      right: auto;
    }
  }
</style>
