import { getImmutable, getImmutableValue, makeChanges } from '@dabble/util/immutable';
import { Browserbase } from 'browserbase';
import { Readable, Subscriber, Unsubscriber, writable } from 'easy-signal';
import { Delta, Op, TextDocument } from 'typewriter-editor';
import log from '../util/log';
import { DabbleDatabase, OldChange, OldProject } from './old-types';

const TEXT_FIELDS = { body: true, description: true };

export interface DatabaseStore extends Readable<DabbleDatabase> {
  // Register is *like* subscribe, but the open function will await on the return of each registered function.
  register(listener: Subscriber<DabbleDatabase>): Unsubscriber;
  open(name: string): Promise<void>;
  close(deleteDatabase?: boolean): void;
}

export function createDatabaseStore(): DatabaseStore {
  let db: DabbleDatabase;
  let closing: boolean;
  const registered = new Set<Subscriber<DabbleDatabase>>();
  const { get, set, subscribe } = writable<DabbleDatabase>(null);

  function register(listener: Subscriber<DabbleDatabase>) {
    registered.add(listener);
    return () => registered.delete(listener);
  }

  async function open(uid: string) {
    db = await openDatabase(uid);
    db.addEventListener('close', () => !closing && close()); // If the database is closed, close the store
    set(db);
    await Promise.all(Array.from(registered).map(run => run(db)));
  }

  function close(deleteDatabase?: boolean) {
    if (!db) return;
    closing = true;
    Array.from(registered).map(run => run(null));
    db.close();
    if (deleteDatabase === true) {
      db.deleteDatabase();
    }
    closing = false;
    set((db = null));
  }

  return {
    get,
    register,
    open,
    close,
    subscribe,
  };
}

export async function deleteDatabase(uid: string) {
  Browserbase.deleteDatabase(`dabble/${uid}`);
}

export async function openDatabase(uid: string) {
  // Because I screwed up, first try to load the database by uid, but if it doesn't exist, use dabble/uid as the correct
  // database name
  const name = `dabble/${uid}`;
  const db = new Browserbase(name) as DabbleDatabase;

  db.version(1, {
    project_changes: '[projectId+version], [projectId+committed+version], created',
    project_snapshots: '[id+version], [id+committed+version]',
    user_docs: '[projectId+docId], modified',
    user_stats_by_date: '[date+projectId], modified',
    user_projects: 'id, modified',
    user_goals: 'id, modified',
    user_meta: 'id, modified', // to store user meta data
    other: 'id', // a flexible bucket to store other local data for plugins not handled by core
  })
    .version(5, {
      analytics: '++id',
    })
    .version(6, {
      content_uploads: 'name',
    })
    .version(7, {
      project_metas: 'committed',
    })
    .version(8, {
      usersInfo: ' ',
    })
    // The current version which will be used instead of running 1-latest as a shortcut for new databases (also great doc)
    .version(0, {
      analytics: '++id',
      project_changes: '[projectId+version], [projectId+committed+version], created',
      project_snapshots: '[id+version], [id+committed+version]',
      project_metas: 'id, modified, committed',
      user_docs: '[projectId+docId], modified',
      user_stats_by_date: '[date+projectId], modified',
      user_projects: 'projectId, modified',
      user_goals: 'projectId, modified',
      user_meta: 'type, modified', // to store user meta data
      content_uploads: 'name',
      other: 'id', // a flexible bucket to store other local data for plugins not handled by core
      global: 'id, modified', // to store global meta data
      usersInfo: ' ',
    });

  await db.open();

  log.tagColor('Load', '#444', 'Opened database', db.name);

  db.stores.project_snapshots.store = storeText;
  db.stores.project_snapshots.revive = reviveText;
  db.stores.project_changes.revive = reviveTextInChange;

  return db;
}

function storeText(snapshot: OldProject) {
  if (!snapshot) return snapshot;
  return makeChanges(() => {
    let docs = snapshot.docs;
    Object.keys(docs).forEach(docId => {
      let doc = docs[docId];
      Object.keys(doc).forEach(key => {
        const value = doc[key];
        if (value instanceof TextDocument) {
          snapshot = getImmutableValue(snapshot);
          docs = getImmutable(snapshot, 'docs');
          doc = getImmutable(docs, docId);
          doc[key] = { ops: value.toDelta().ops };
        }
      });
    });
    return snapshot;
  });
}

function reviveText(snapshot: OldProject) {
  if (!snapshot || !snapshot.docs) return snapshot;
  Object.keys(snapshot.docs).forEach(docId => {
    const doc = snapshot.docs[docId];
    Object.keys(doc).forEach(key => {
      const value = doc[key];
      if (!value) return;
      if (Array.isArray(value.ops)) {
        doc[key] = opsToDocument(value.ops);
      } else if (key in TEXT_FIELDS && Array.isArray(value)) {
        doc[key] = opsToDocument(value as Op[]);
      }
    });
  });
  return snapshot;
}

function reviveTextInChange(change: OldChange) {
  if (!change || !change.ops) return change;
  change.ops.forEach(op => {
    if (op.path === '' && (op.op === 'add' || op.op === 'replace')) {
      if (op.value && typeof op.value === 'object' && op.value.docs) {
        reviveText(op.value);
      }
    }
  });
  return change;
}

// Remove all fields except for those listedn in the set
export function removeFieldsExcept(obj: any, fields: Set<string>) {
  Object.keys(obj).forEach(key => {
    if (!fields.has(key)) {
      delete (obj as any)[key];
    }
  });
  return obj;
}

(window as any).dbExists = dbExists;

function dbExists(dbname: string) {
  return new Promise(resolve => {
    const req = indexedDB.open(dbname);
    let exists = true;
    function onFinish() {
      if (req.result && req.result.close) req.result.close();
      // If it got created, delete it
      if (!exists) indexedDB.deleteDatabase(dbname);
      resolve(exists);
    }
    req.onsuccess = onFinish;
    req.onblocked = onFinish;
    req.onerror = onFinish;
    req.onupgradeneeded = (event: IDBVersionChangeEvent) => {
      const oldVersion = event.oldVersion > Math.pow(2, 62) ? 0 : event.oldVersion; // Safari 8 fix.
      if (oldVersion === 0) {
        exists = false;
        req.transaction.abort();
      }
    };
  });
}

function opsToDocument(ops: Op[]) {
  ops.forEach(op => {
    if (op.attributes?.id) delete op.attributes.id;
  });
  return new TextDocument(new Delta(ops));
}
