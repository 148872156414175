<script lang="ts">
  import { routerStore } from '@dabble/data/navigation';
  import SyncStatus from './SyncStatus.svelte';

  $: hide = /^\/(p|auth)\//.test($routerStore.path);
</script>

{#if !hide}
  <div class="app-sync">
    <SyncStatus showOne />
  </div>
{/if}

<style>
  .app-sync {
    display: flex;
    margin: 0 4px;
    padding: 5px 6px;
    order: -10;
  }
</style>
