<script lang="ts">
  import { t } from '@dabble/data/intl';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiCheckboxBlankCircleOutline, mdiCheckboxMarkedCircle, mdiHelpCircleOutline } from '@mdi/js';
  import { ChecklistItem, isItemComplete, items, onboardingStore, showHelpStore } from '../onboarding';

  $: current = items.find((_, i) => !isItemComplete($onboardingStore, i));

  function openHelp(item: ChecklistItem) {
    $showHelpStore = item;
  }
</script>

<ul class="onboarding-checklist">
  {#each items as item, i}
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <!-- svelte-ignore a11y-no-noninteractive-element-interactions -->
    <li
      class="checklist-item"
      class:current={current === item}
      class:finished={isItemComplete($onboardingStore, i)}
      on:click={() => openHelp(item)}
    >
      <div class="icon">
        {#if isItemComplete($onboardingStore, i)}
          <Icon path={mdiCheckboxMarkedCircle} />
        {:else}
          <Icon path={mdiCheckboxBlankCircleOutline} />
        {/if}
      </div>
      <div class="item">
        {$t(item.text)}
      </div>
      <div class="help">
        <Icon path={mdiHelpCircleOutline} />
      </div>
    </li>
  {/each}
</ul>

<style>
  .current {
    color: var(--dabble-blue);
    font-weight: bold;
  }

  .icon {
    margin-right: 0.5rem;
    margin-top: 0.24rem;
  }
  .item {
    flex-grow: 1;
  }

  .finished {
    color: var(--gray-light);
  }

  .finished .icon {
    color: var(--brand-primary);
  }

  .onboarding-checklist {
    list-style: none;
    margin: 0;
    padding: 0.25rem 0;
  }
  .checklist-item {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0.25rem 0.5rem;
    border-radius: 0.5rem;
  }
  .checklist-item:hover,
  .checklist-item:focus {
    background-color: var(--gray-lightest);
  }
</style>
