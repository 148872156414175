{
  "onboarding_getting_started": "Empezando",
  "onboarding_create_project": "Crea un Proyecto",
  "onboarding_create_scene": "Crea una Nueva Escena",
  "onboarding_write_note": "Crear una Nota",
  "onboarding_create_plot_line": "Crear una Nueva Línea de Trama",
  "onboarding_write_1000": "Escribe 1000 Palabras",
  "onboarding_dismiss": "Ocultar",
  "onboarding_learn_more": "Aprende más",
  "onboarding_title": "¡Bienvenido a Dabble!",
  "onboarding_coupon_title": "<span class=\"dollar\">$</span><span class=\"value\">20</span><span class=\"off\">apagada</span>",
  "onboarding_coupon_subtitle": "Su suscripción a Dabble",
  "onboarding_get_paid": "Escribe 1000 palabras",
  "onboarding_write_we_pay": "Aquí en Dabble, celebramos completar los objetivos. ",
  "onboarding_objective": "Si escribe 1000 palabras nuevas en los próximos 14 días, obtendrá $ 20 de descuento en cualquier suscripción a Dabble.",
  "onboarding_help_create_project_1": "Presione el botón Nuevo proyecto (:mdiPlusThick:)",
  "onboarding_help_create_project_2": "Su nuevo proyecto se abrirá en la página de título.¡Adelante y titula tu libro!",
  "onboarding_help_create_scene_1": "Localice el capítulo en la barra de navegación izquierda.",
  "onboarding_help_create_scene_2": "Rovering sobre un capítulo existente en la barra de navegación izquierda revelará tres puntos verticales (:mdiDotsVertical:)",
  "onboarding_help_create_scene_3": "Haga clic en los tres puntos verticales (:mdiDotsVertical:) Para abrir el menú del capítulo.",
  "onboarding_help_create_scene_4": "Seleccione Agregar nueva escena en el menú del capítulo",
  "onboarding_help_create_scene_5": "Su nueva escena se agregará al final del capítulo.Haga clic en él para comenzar a escribir en esa escena.",
  "onboarding_help_write_note_1": "Localice las notas de la historia en la barra de navegación izquierda.",
  "onboarding_help_write_note_2": "Hovering Over Story Notes revelará tres puntos verticales (:mdiDotsVertical:)",
  "onboarding_help_write_note_3": "Haga clic en los tres puntos verticales (:mdiDotsVertical:) Para abrir el menú de notas de la historia.",
  "onboarding_help_write_note_4": "Seleccione Agregar nueva nota en el menú Notas de la historia",
  "onboarding_help_write_note_5": "Su nueva nota se agregará en la parte inferior de las notas de la historia.Haga clic en él para comenzar a escribir en esa nota.",
  "onboarding_help_create_plot_line_1": "Localice la parcela <em> para la cuadrícula de trama de libro sin título </em> en la barra de navegación izquierda en la trama.",
  "onboarding_help_create_plot_line_2": "Haga clic en el nombre de la cuadrícula de trazado para abrirlo en la pantalla del proyecto.",
  "onboarding_help_create_plot_line_3": "HSobre cualquiera de las cajas en blanco en la cuadrícula de la trama revelará el nuevo botón de punto de la trama(:mdiPlusThick:)",
  "onboarding_help_create_plot_line_4": "En la tercera columna, haga clic en el nuevo botón Punto de trazado para crear una nueva línea de trama y una nueva tarjeta de punto de trazado.",
  "onboarding_help_write_1000_1": "Localice el capítulo en Manuscrito en la barra de navegación izquierda y haga clic en él.",
  "onboarding_help_write_1000_2": "En la pantalla del proyecto, haga clic en <em> Once Upon a Time ... <em> para colocar su cursor.",
  "onboarding_help_write_1000_3": "¡Empiece a escribir!",
  "onboarding_survey_text": "Podemos personalizar un poco para ti.¿Qué género es tu libro?",
  "onboarding_survey_submit": "¡Obtén la escritura!",
  "onboarding_genre_general_fiction": "Ficción General",
  "onboarding_genre_fantasy": "Fantasía",
  "onboarding_genre_sci_fi": "Ciencia ficción",
  "onboarding_genre_romance": "Novela romántica",
  "onboarding_genre_mystery": "Misterio",
  "onboarding_genre_historical": "Histórica",
  "onboarding_genre_other": "No ficción/otro",

  "onboarding_find_us": "¿Cómo encontraste a Dabble?",
  "onboarding_find_internet": "Busqueda De Internet<br/><small>(Google, Bing, etc.)</small>",
  "onboarding_find_social_media": "Medios de comunicación social <br/><small>(Facebook, Twitter/X, Instagram, LinkedIn, etc.)</small>",
  "onboarding_find_referral": "Boca a boca <br/><small>(Amigos, familiares, colegas)</small>",
  "onboarding_find_website": "Referencia de otro sitio web o blog",
  "onboarding_find_dabble_u": "A través de dabbleu<br/><small>(Nuestros artículos sobre escritura)</small>",
  "onboarding_find_other": "Otra<br/><small>(Por favor comparta donde nos encontró en el cuadro de texto)</small>",
  "onboarding_other": "¿Cómo se enteró de nosotros?",
  "onboarding_other_send": "Próxima"
}
