<script lang="ts">
  import NavItemMenu from '@dabble/components/workspace/NavItemMenu.svelte';
  import { sizeStore } from '@dabble/data/device';
  import { t } from '@dabble/data/intl';
  import { Doc } from '@dabble/data/types';
  import Dropdown from '@dabble/toolkit/Dropdown.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { tooltipRight } from '@dabble/toolkit/tooltip';
  import { mdiDotsVertical } from '@mdi/js';

  export let doc: Doc;

  let menuOpen = false;
</script>

<div class="actions">
  <button
    class="icon menu-opener"
    on:click={() => (menuOpen = true)}
    use:tooltipRight={$sizeStore === 'desktop' && $t('nav_item_actions', { type: doc.type })}
  >
    <Icon path={mdiDotsVertical} />
  </button>
  {#if menuOpen}
    <Dropdown
      placement={$sizeStore !== 'desktop' ? 'left-start' : 'right-start'}
      arrow
      on:close={() => (menuOpen = false)}
    >
      <NavItemMenu {doc} on:editTitle />
    </Dropdown>
  {/if}
</div>

<style>
  .actions {
    display: none;
    position: absolute;
    bottom: 0.25rem;
    right: 0;
  }
  :global([data-id]:not(:has([data-id] .actions)):hover) .actions {
    display: block;
  }
</style>
