{
  "collab_accept": "Accept",
  "collab_authors": "Co-authors",
  "collab_author_name": "Name",
  "collab_author_me": "(me)",
  "collab_author_role": "Role",
  "collab_author_unloaded": "Name Not Loaded",
  "collab_decline": "Decline",
  "collab_error_DECLINED": "This invitation has already been declined",
  "collab_error_EXPIRED": "This invitation has expired",
  "collab_error_NOT_FOUND": "This invitation no longer exists",
  "collab_explainer": "Co-Authoring is a feature that allows you to work with other authors on a project. Those other authors need a Dabble account and will have the same functionality and features with your shared project as they do for their other projects, dependant on their subscription. E.g. if your co-author is not subscribed, they will have access to your project in read-only mode like they would with their own projects. If they are on the Basic plan, they will be able to see the plot and will have write access to the manuscript. There is a limit of {{limit}} collaborators on a project.",
  "collab_header": "Co-Authoring",
  "collab_author_email": "Email of Co-author",
  "collab_invite_author": "Invite a Co-author",
  "collab_author_deleted": "Author Deleted",
  "collab_success_decline": "You have declined the invitation",
  "collab_success_accept": "Have fun collaborating on your new project!",
  "collab_invite": "Invite",
  "collab_invite_sent": "Invite sent",
  "collab_invite_delete": "Delete Invitation",
  "collab_invite_deleted": "Invitation Deleted",
  "collab_invite_link_copy": "Copy Link",
  "collab_invite_link_copied": "Copied link to clipboard",
  "collab_invites": "Invitations",
  "collab_invited_you": "<strong>{{inviter}}</strong> has invited you to co-author a project <strong><em>{{title}}</em></strong> in Dabble.",
  "collab_invite_email_message": "{{inviter}} has invited you to co-author a project titled {{title}} in Dabble.",
  "collab_invite_login": "You are logged in under <strong>{{email}}</strong>. Would you like to accept this invitation?",
  "collab_invite_accept": "You are logged in under <strong>{{email}}</strong>. Would you like to accept this invitation?",
  "collab_invite_email": "Email",
  "collab_invite_status": "Status",
  "collab_invite_status_sent": "Pending",
  "collab_invite_status_declined": "Declined",
  "collab_invite_status_expired": "Expired",
  "collab_full_project": "The project is full, only {{limit}} people can use or be invited to the project.",
  "collab_only_author_invites": "Only the owner can invite others.",
  "collab_remove": "Remove {{role}}",
  "collab_set_role": "Set role to {{role}}",
  "collab_role_changed": "Role successfully changed.",
  "collab_signin_first_message": "To accept this invitation, you must <strong>create an account</strong> or <strong>sign in</strong> to an existing account first.",
  "collab_track_changes_button": "Track Author Changes",
  "collab_track_changes_button_description": "Keep track of who wrote what. Allows different text colors for each author.",
  "collab_track_changes_success": "Successfully updated author tracking settings",
  "collab_type_author": "Co-author",
  "collab_type_write": "Co-author",
  "collab_type_owner": "Owner",
  "collab_type_edit": "Editor",
  "collab_type_comment": "For Comment",
  "collab_type_view": "View Only",
  "collab_type_declined": "Declined",
  "collab_invite_self": "You can't invite yourself to a project."
}
