import { settingsStore } from '@dabble/data/settings';
import { TypesetTypes } from 'typewriter-editor';
import './highlight-format';
import HighlightButton from './HighlightButton.svelte';
import HighlightMenuSection from './HighlightMenuSection.svelte';

settingsStore.configure('dabble', {
  textBubbleMenu: { HighlightButton },
  textBubbleMenuSection: { HighlightMenuSection },
  editorTypes: {
    strike: {
      formats: ['strike'],
    },
    highlighter: (types: TypesetTypes) => {
      if (!types.formats) types.formats = [];
      if (types.formats.includes('highlight')) return types;
      types.formats.unshift('highlight');
      return types;
    },
  },
});
