<script lang="ts">
  import { isMobileMode, sizeStore } from '@dabble/data/device';
  import { featuresStore } from '@dabble/data/global-data';
  import { plugins } from '@dabble/data/plugins';
  import { viewport } from '@dabble/data/ui';
  import CustomModal from '@dabble/toolkit/CustomModal.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiClose } from '@mdi/js';
  import { Editor } from 'typewriter-editor';
  import { chosenCommentIdStore, commentsStore, showCommentsStore } from '../comments-store';
  import CommentEditor from './CommentEditor.svelte';
  import CommentEntry from './CommentEntry.svelte';
  import CommentHeader from './CommentHeader.svelte';
  import { CommentEditorData, getSaveDataForComment } from './display-helpers';

  // This component is the list of comments at the bottom when on mobile

  const uid = plugins.stores.uid;
  let input: CommentEditor;
  let editor: Editor;

  $: show = $featuresStore.comments && $showCommentsStore;
  $: commentId = $sizeStore !== 'desktop' && isMobileMode && show && $chosenCommentIdStore;
  $: comment = $commentsStore[commentId];
  $: if (!comment && input) {
    setTimeout(() => input.focus());
  }

  async function save({ detail: { content } }: CustomEvent) {
    if (comment) {
      commentsStore.reply(comment.id, content);
    } else {
      const pageElement = viewport.container.get();
      const { editor: editor_, range } = getSaveDataForComment(pageElement, commentId) as CommentEditorData;
      editor = editor_;
      if (editor) {
        editor.modules.decorations.getDecorator('comments').clear().apply();
        await commentsStore.comment(editor, range, content, commentId);
      }
    }
  }

  function close() {
    $chosenCommentIdStore = '';
    $showCommentsStore = false;
  }

  function onCancel({ detail: { hadInput } }: CustomEvent) {
    !hadInput && close();
  }
</script>

{#if commentId}
  <CustomModal on:close={close}>
    <div class="thread">
      <div class="closer">
        <button class="icon close-btn" on:click={close}>
          <Icon path={mdiClose} />
        </button>
      </div>
      <div class="comments">
        {#if comment}
          <CommentEntry {comment} on:resolve={close} />
          {#each comment.replies as reply}
            <CommentEntry {comment} {reply} />
          {/each}
        {:else}
          <div class="new-comment">
            <CommentHeader uid={$uid} />
          </div>
        {/if}
      </div>
      <div class="reply" class:borderless={!comment}>
        <CommentEditor
          bind:this={input}
          on:update
          on:cancel={onCancel}
          on:save={save}
          style={comment ? 'reply' : 'comment'}
        />
      </div>
    </div>
  </CustomModal>
{/if}

<style>
  .thread {
    --comment-padding: 10px;
    position: relative;
    padding: 0 10px;
    margin: 60px auto 20px;
    max-width: 320px;
    border-radius: 5px;
    border: 1px solid var(--dabble-gray);
    background-color: var(--white);
    box-shadow:
      0 1px 3px rgba(0, 0, 0, 0.2),
      0 4px 12px rgba(0, 0, 0, 0.2);
  }
  .closer {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 100;
  }
  .icon.close-btn,
  :global(.size-desktop) button.icon:not([disabled]):hover {
    font-size: 30px;
    background: var(--home-background);
    border-radius: 50%;
    box-shadow: var(--flat-box-shadow);
  }
  .reply {
    margin: 0 -8px;
    padding: var(--comment-padding) 8px;
    border-top: var(--form-border);
  }
  .reply.borderless {
    border-top: none;
  }
  .new-comment {
    padding-top: 8px;
  }
  :global(.dropdown-menu.comment-dropdown) {
    z-index: 1050;
  }
</style>
