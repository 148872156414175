import { Change, JSONPatch, JSONPatchOp, ReadableDoc, WritableDoc } from 'agreeable-client';
import { Readable, Updater, derived } from 'easy-signal';
import { DeepPartial } from '../types';

type ReadableType<R extends Readable<any>> = R extends Readable<infer T> ? T : never;

/**
 * A store creator that pulls together the data from multiple stores into a single store. Provide a function to get keys
 * and a function to create a store for a given key. If `getKeys` uses stores, it will be re-run whenever those stores
 * change, keeping the list of derived stores up-to-date. The output will be an object of the keys and stores' data.
 *
 * If the derived stores are Agreeable documents, they will be automatically loaded and unloaded as they are added and
 * removed from the list. And you can patch and update individual documents by key.
 */
export function createDerivedStore<R extends Readable<any>>(getKeys: () => string[], storeCreator: (key: string) => R) {
  let keys = new Set<string>();

  // A store of the stores
  const stores = derived<Record<string, R>>(oldValue => {
    const latestKeys = getKeys();

    // Get old and new
    const oldKeys = new Set(keys);
    latestKeys.forEach(oldKeys.delete, oldKeys);

    const newKeys = new Set(latestKeys);
    keys.forEach(newKeys.delete, newKeys);

    if (oldValue && !oldKeys.size && !newKeys.size) {
      return oldValue;
    }

    oldKeys.forEach(key => {
      const store = storeCreator(key);
      (store as any as ReadableDoc).unsync?.();
      (store as any as ReadableDoc).unload?.();
    });

    keys = new Set(latestKeys);

    // Load current stores and return object with all of them
    return Object.fromEntries(
      latestKeys.map(key => {
        const store = oldValue[key] || storeCreator(key);
        if (newKeys.has(key)) {
          (store as any as ReadableDoc).load?.();
        }
        return [key, store];
      })
    );
  });

  // A store of the stores' data
  const { get, subscribe } = derived<Record<string, ReadableType<R>>>(() => {
    return Object.fromEntries(
      Object.entries(stores.get()).map(([key, store]) => [key, store.get() as ReadableType<R>])
    );
  });

  function getStore(key: string) {
    if (!keys.has(key)) throw new Error('Store not found ' + key);
    return stores.get()[key];
  }

  // Adjusted APIs to proxy calls on to the correct store.
  return {
    get,
    subscribe,
    getStore,
    patch(key: string, patch: JSONPatch | JSONPatchOp[]): Promise<Change[]> {
      return (getStore(key) as any as WritableDoc).patch?.(patch);
    },
    update(key: string, updater: Updater<ReadableType<R>> | DeepPartial<ReadableType<R>>): Promise<Change[]> {
      return (getStore(key) as any as WritableDoc).update?.(updater as any);
    },
  };
}
