<script context="module" lang="ts">
  // Keep once across all instances so we don't have to recalculate the height again when switching away and coming back
  const heightCache = new WeakMap<any, number>();
</script>

<script lang="ts">
  import { printReadyStore } from '@dabble/data/app-state';
  import { onDestroy, onMount, tick } from 'svelte';

  // props
  export let renderAll = false;
  export let items: any[] = [];
  export let itemHeight: number = undefined;
  export let visible: any[] = [];
  export const heightMap: number[] = [];

  // read-only, but visible to consumers via bind:start
  export let start = 0;
  export let end = 0;

  export async function scrollTo(item: any) {
    let i = items.indexOf(item);
    if (i === -1) return;
    viewport.scrollTop = getHeight(i, BEFORE) + contents_top;
    await handle_scroll();
    const top = getHeight(i, BEFORE) + contents_top;
    if (viewport.scrollTop !== top) {
      viewport.scrollTop = top;
      await handle_scroll();
      await new Promise(resolve => setTimeout(resolve));
    }
  }

  const BEFORE = -1;
  const AFTER = 1;

  // local state
  let oldItems: any[];
  let rows: HTMLCollection;
  let viewport: HTMLElement;
  let contents: HTMLElement;
  let viewport_height = 0;
  let contents_top = 0;
  let mounted: boolean;
  let updating: boolean;

  let top = 0;
  let bottom = 0;
  let average_height: number;

  $: if (renderAll) {
    start = 0;
    end = items.length;
  } else {
    handle_scroll();
  }
  $: visible = items.slice(start, end).map((data, i) => {
    return { index: i + start, data };
  });

  // whenever `items` changes, invalidate the current heightMap and update the bottom padding
  $: if (mounted) refresh(items, viewport_height, itemHeight);
  $: oldItems = items;

  async function refresh(items: any[], viewport_height: number, itemHeight: number) {
    const { scrollTop } = viewport;

    resetHeightMap();

    await tick(); // wait until the DOM is up to date

    let content_height = top - scrollTop + contents_top;
    let i = start;

    while (content_height < viewport_height && i < items.length) {
      let row = rows[i - start];

      if (!row) {
        end = i + 1;
        await tick(); // render the newly visible row
        row = rows[i - start];
      }

      const row_height = (heightMap[i] = itemHeight || (row as HTMLElement).offsetHeight);
      content_height += row_height;
      i += 1;
    }

    end = i;

    average_height = getAverage();
    bottom = getHeight(end - 1, AFTER);
  }

  async function handle_scroll() {
    if (!viewport) return;
    const { scrollTop } = viewport;

    const old_start = start;
    const old_end = end;

    let i = 0;
    let y = 0;

    if (renderAll) {
      start = 0;
      end = items.length;
    } else {
      // Find the (possibly) new start and top
      while (i < items.length) {
        const row_height = heightMap[i] || average_height;
        if (y + row_height + contents_top > scrollTop) {
          start = i;
          top = y;

          break;
        }

        y += row_height;
        i += 1;
      }

      // Find the (possibly) new end
      while (i < items.length) {
        y += heightMap[i] || average_height;
        i += 1;

        if (y > scrollTop - contents_top + viewport_height) break;
      }

      end = i;
    }

    // If nothing changed, do nothing
    if (old_end === end && old_start === start) return;

    bottom = getHeight(end - 1, AFTER);
    const oldAverageHeight = average_height;
    const oldHeightMap = heightMap.slice();
    updating = true;

    await tick();

    updateHeightMap();
    bottom = getHeight(end - 1, AFTER);

    updating = false;

    await tick();

    // prevent jumping when scrolling up into unknown territory
    if (start < old_start) {
      let expected_height = 0;
      let actual_height = 0;

      for (let i = 0; i < old_start + 1; i++) {
        expected_height += oldHeightMap[i] || oldAverageHeight;
        actual_height += heightMap[i] || average_height;
      }

      const d = actual_height - expected_height;
      if (d) {
        viewport.scrollTop = scrollTop + d;
        viewport.scrollTop = scrollTop + d;
        await new Promise(resolve => setTimeout(resolve, 10)); // Fixing incorrect placement on Chrome
        viewport.scrollTop = scrollTop + d;
      }
    }
  }

  function updateViewportHeight() {
    viewport_height = (viewport && viewport.offsetHeight) || 0;
    contents_top = 0;
    let node = contents;
    while (node && node !== viewport) {
      contents_top += node.offsetTop;
      node = node.offsetParent as HTMLElement;
    }
  }

  function getViewport() {
    let viewport = contents;
    while (viewport && viewport.nodeType !== Node.DOCUMENT_NODE) {
      const styles = getComputedStyle(viewport);
      const overflow = styles && styles.overflowY;
      if (overflow === 'auto' || overflow === 'scroll') {
        break;
      }
      viewport = viewport.parentNode as HTMLElement;
    }
    return viewport;
  }

  function resetHeightMap() {
    heightMap.length = items.length;

    for (let i = 0; i < items.length; i++) {
      if (items[i] !== oldItems[i] && heightMap[i]) {
        delete heightMap[i];
      } else if (items[i] === oldItems[i] && !heightMap[i]) {
        const height = heightCache.get(items[i]);
        if (height) heightMap[i] = height;
      }
    }
  }

  function updateHeightMap() {
    if (itemHeight) {
      heightMap.fill(itemHeight);
    } else {
      items.forEach((item, i) => {
        const height = heightCache.get(item);
        if (height) heightMap[i] = height;
      });
      for (let i = 0; i < rows.length; i += 1) {
        const height = (heightMap[start + i] = (rows[i] as HTMLElement).offsetHeight);
        heightCache.set(items[start + i], height);
      }
    }
    average_height = getAverage();
  }

  function getAverage() {
    const defined = heightMap.filter(Boolean);
    return defined.length ? Math.round(defined.reduce((a, b) => a + b) / defined.length) : 0;
  }

  function getHeight(index: number, side: 1 | -1) {
    let height = 0;
    index += side;
    while (index >= 0 && index < items.length) {
      height += heightMap[index] || average_height;
      index += side;
    }
    return Math.round(height);
  }

  // trigger initial refresh
  onMount(() => {
    rows = contents.children;
    mounted = true;

    viewport = getViewport();
    if (!viewport) return;

    viewport.addEventListener('scroll', handle_scroll);
    updateViewportHeight();
  });

  function togglePrintReady() {
    $printReadyStore = !$printReadyStore;
  }

  onDestroy(() => viewport && viewport.removeEventListener('scroll', handle_scroll));
</script>

<svelte:window on:resize={updateViewportHeight} on:beforeprint={togglePrintReady} on:afterprint={togglePrintReady} />

<div class="contents" bind:this={contents} style="padding-top: {top}px; padding-bottom: {bottom}px;">
  {#each visible as row, i (row.index)}
    <div class="row" style="height:{updating && heightMap[start + i] ? heightMap[start + i] + 'px' : 'auto'}">
      <slot item={row.data}>Missing template</slot>
    </div>
  {/each}
</div>
