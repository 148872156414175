<script lang="ts">
  import { t } from '@dabble/data/intl';
  import { projectMetaStore, projectStore } from '@dabble/data/project-data';
  import { Doc } from '@dabble/data/types';
  import { preferencesStore } from '@dabble/data/user-data';
  import { userRoleStore } from '@dabble/data/user-project';
  import { EMPTY_OBJECT } from '@dabble/data/util';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import SearchInput from '@dabble/toolkit/SearchInput.svelte';
  import { mdiArrowLeft, mdiCog, mdiFilter } from '@mdi/js';
  import CreateButton from './CreateButton.svelte';
  import Navigation from './Navigation.svelte';

  export let loading = false;
  let filterText = '';
  let visibleNav: Set<string> | null = null;
  let scrolled = false;

  $: onFilterChange(filterText);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function onFilterChange(_: any): void {
    const {
      project,
      parentsLookup,
      childrenLookup,
      docs,
      texts: { textWithQueued },
    } = $projectStore;
    if (!filterText) return (visibleNav = null);

    visibleNav = new Set();

    function check(doc: Doc) {
      const texts = textWithQueued[doc.id] || EMPTY_OBJECT;
      const docText = [doc.title || '', texts.body || '', texts.description || ''].join(' ').toLowerCase();

      if (~docText.indexOf(filterText.trim().toLowerCase())) {
        visibleNav.add(doc.id);
        let parent = parentsLookup[doc.id];
        while (parent) {
          visibleNav.add(parent.id);
          parent = parentsLookup[parent.id];
        }
      }
      if (childrenLookup[doc.id]) childrenLookup[doc.id].forEach(check);
    }

    check(project);
    check(docs.trash);
  }

  function clearFilter() {
    filterText = '';
    onFilterChange(filterText);
  }
</script>

<div class="side-nav focus-fade" class:prefer-desktop={$preferencesStore.preferNoMobile}>
  <!-- <div class="nav-header">
    Dabble
  </div> -->
  <a class="top-link" href="/">
    <Icon path={mdiArrowLeft} />
    <span>{$t('back_home')}</span>
  </a>
  <a class="top-link" href={`/p/${$projectStore.projectId}/settings`} class:disabled={!$userRoleStore || null}>
    <Icon path={mdiCog} />
    <span>{$t($projectMetaStore?.isTemplate ? 'template_settings' : 'project_settings')}</span>
  </a>
  <div class="filter-box" class:shadowed={scrolled}>
    <SearchInput
      bind:value={filterText}
      placeholder={$t('filter_placeholder')}
      icon={mdiFilter}
      tooltip={$t('filter_tooltip')}
      on:submit={onFilterChange}
      on:clear={clearFilter}
    />
    <div class="separator" />
    <CreateButton />
  </div>
  {#if loading}
    <div style="margin: 10px;">
      <div class="loading-placeholder" style="width:130px" />
      <div class="loading-placeholder" style="width:80px" />
      <div class="loading-placeholder" style="width:110px" />
      <div class="loading-placeholder" style="width:90px" />
      <div class="loading-placeholder" style="width:150px" />
      <div class="loading-placeholder" style="width:100px" />
    </div>
  {:else}
    <Navigation {visibleNav} bind:scrolled />
  {/if}
</div>

<style>
  .side-nav {
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    background: var(--side-nav-background);
    -webkit-user-select: none;
    user-select: none;
    contain: strict;
  }
  :global(body) > .side-nav {
    height: 100vh;
  }
  .top-link {
    display: flex;
    align-items: center;
    flex: 0 0 36px;
    color: var(--text-color-lighter);
    background: none;
    font-size: 14px;
    text-decoration: none;
    padding: 8px;
    border: none;
    outline: none;
    cursor: pointer;
    white-space: nowrap;
  }
  .top-link :global(.icon) {
    font-size: 20px;
    margin-right: 8px;
  }
  .top-link span {
    overflow: hidden;
  }
  :global(body.size-desktop) .top-link:hover {
    color: var(--text-color-normal);
    background-color: var(--side-nav-color-light);
    text-decoration: none;
    box-shadow: inset 0 0 0 1px var(--side-nav-color-mid);
  }
  .filter-box {
    display: flex;
    flex: 0 0 40px;
    padding: 4px 6px 8px 8px;
    background-color: var(--side-nav-background);
    transition: 0.15s box-shadow linear;
  }
  .filter-box.shadowed {
    box-shadow: var(--top-down-shadow);
  }
  .separator {
    margin: 0 2px 0 8px;
    width: 1px;
    box-shadow: var(--vertical-bevel);
  }
</style>
