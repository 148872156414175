<script lang="ts">
  import { delegate } from '@dabble/data/delegate';
  import { t } from '@dabble/data/intl';
  import { projectStore } from '@dabble/data/project-data';
  import { Doc } from '@dabble/data/types';
  import { confirm } from '@dabble/data/ui';
  // TODO move image/content to core? Or change this to use plogins.stores.content?.imageApproved
  // TODO check all imports for /plugins/* to fix cross importing from plugins? Or should we allow it. Makes it more brittle
  import { imageApproved } from '@dabble/plugins/content/classifyImages';
  import { saveFile } from '@dabble/plugins/content/content';
  // TODO move image/content to core? Or change this to use plogins.stores.content?.getImagePlaceholder (check other files)
  import { getImagePlaceholder } from '@dabble/plugins/content/images';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiImage } from '@mdi/js';
  import Dropzone from 'svelte-file-dropzone';

  export let doc: Doc;

  $: img = (doc && doc.image && doc.image.url) || '';
  $: blur = delegate && doc.image && !imageApproved(doc.image.url);
  $: imageHeightAspect =
    doc && doc.image && doc.image.height ? Math.round((doc.image.height / doc.image.width) * 10000) / 10000 : 1.5;

  async function handleImageDrop(event: CustomEvent<{ acceptedFiles: FileList }>) {
    const { acceptedFiles } = event.detail;
    if (acceptedFiles.length === 1) {
      const info = await getImagePlaceholder(acceptedFiles[0]);
      const url = await saveFile(acceptedFiles[0]);
      projectStore.updateDoc(doc.id, {
        image: { url, ...info },
      });
    } else {
      await confirm($t('novel_image_unsupported'), $t('novel_image_unsupported_message'));
    }
  }
</script>

<div class="full-page-image-container {!img && ' empty-image'}">
  <div class="image {blur}" style="--background-src: url({img});--image-height-aspect:{imageHeightAspect};">
    <Dropzone on:drop={handleImageDrop} accept={['.png', '.jpeg', '.jpg', '.gif', '.webp', '.tiff']}>
      {#if !img}
        <div class="dropzone-text">
          <div class="cover-icon">
            <Icon path={mdiImage} />
          </div>
          <h2 class="drop-modal-text">{$t('novel_image_drop_text')}</h2>
          <h3 class="drop-modal-sub-text">{$t('novel_image_drop_sub_text')}</h3>
          <h3 class="or-line"><span>{$t('novel_modal_drop_or_divider')}</span></h3>
          <button class="browse-button btn">{$t('novel_modal_drop_browse_button')}</button>
        </div>
      {/if}
    </Dropzone>
  </div>
</div>

<style>
  .full-page-image-container {
    width: 100%;
    max-width: var(--page-width);
    position: relative;
    margin: auto; /* centers on print doesn't hurt */
  }
  .image {
    background: var(--background-src);
    padding-bottom: calc(100% * var(--image-height-aspect));
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .dropzone-text {
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  .drop-modal-text {
    color: var(--text-color-lightest);
    margin-top: 16px;
    margin-bottom: 20px;
    font-size: 32px;
  }
  .drop-modal-sub-text {
    color: var(--text-color-lightest);
    font-size: 16px;
    margin-bottom: 30px;
  }
  .browse-button {
    margin: 40px auto 10px auto;
    width: 230px;
    padding: 20px;
    border: none;
    border-radius: 10px;
    display: block;
  }
  .browse-button:hover {
    cursor: pointer;
    transition: background-color 0.2s;
  }
  .browse-button:focus {
    box-shadow: none;
  }
  .or-line {
    width: 60%;
    text-align: center;
    border-bottom: 1px solid var(--text-color-lightest);
    line-height: 0.1em;
    margin: auto;
    padding-top: 4px;
    color: var(--text-color-lightest);
  }
  .or-line span {
    background: var(--white);
    padding: 0 10px;
    font-size: 22px;
  }
  .cover-icon {
    font-size: 60px;
    text-align: center;
  }
  .blur {
    filter: blur(50px);
  }
  :global(.image .dropzone) {
    position: absolute;
    top: 50%;
    border: none;
    left: 50%;
    font-size: 30px;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    line-height: 1;
    cursor: pointer;
    background: transparent;
  }
  :global(.full-page-image-container .dropzone:hover) {
    outline: 4px solid var(--selection-color-light);
    outline-offset: 2px;
  }
  :global(.empty-image .dropzone, .empty-image .dropzone:focus) {
    outline: solid 4px var(--gray-lighter);
  }
  :global(.empty-image .dropzone:hover) {
    outline: 4px solid var(--selection-color-light);
    outline-offset: 2px;
  }
</style>
