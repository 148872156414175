<script lang="ts">
  import { t } from '@dabble/data/intl';
  import { CommentReply, commentsStore } from '../comments-store';
  import CommentHistoryItem from './CommentHistoryItem.svelte';

  // This component is the list of all comments in the sidebar when comment history is open

  let shadowed = false;

  $: allComments = Object.values($commentsStore);
  $: open = allComments.filter(comment => !comment.resolved).sort(sortByDate);
  $: closed = allComments.filter(comment => comment.resolved).sort(sortByDate);

  function sortByDate(a: CommentReply, b: CommentReply) {
    return b.created - a.created;
  }

  function onScroll(event: Event) {
    shadowed = (event.target as HTMLElement).scrollTop > 0;
  }
</script>

<div class="comment-history">
  <h3 class:shadowed class="section-header">{$t('comments_history')}</h3>

  <div class="comment-history-list" on:scroll={onScroll}>
    {#if open.length}
      <h4>{$t('comments_open')}</h4>
      {#each open as comment (comment.id)}
        <CommentHistoryItem {comment} />
      {/each}
    {/if}
    {#if closed.length}
      <h4>{$t('comments_resolved')}</h4>
      {#each closed as comment (comment.id)}
        <CommentHistoryItem {comment} />
      {/each}
    {/if}
    {#if !allComments.length}
      <p>{$t('comments_none')}</p>
    {/if}
  </div>
</div>

<style>
  .comment-history,
  .comment-history-list {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    color: var(--text-color-lighter);
    font-size: var(--font-size-sm);
  }
  .section-header {
    padding: 10px;
  }
  .comment-history-list {
    overflow-y: auto;
    padding: 10px;
  }
  .comment-history-list > *:first-child {
    margin-top: 0;
  }
  h4 {
    font-size: inherit;
    border-bottom: var(--form-border);
    padding: 2px 0;
  }
</style>
