import { settingsStore } from '@dabble/data/settings';
import BookOptions from '@dabble/plugins/export/components/BookOptions.svelte';

settingsStore.configure('novel_book', {
  exportFileTypes: ['word', 'text', 'html', 'google'],
  exportStyles: ['manuscript', 'dabble'],
  exportOptions: {
    word: { BookOptions },
    google: { BookOptions },
    html: { BookOptions },
  },
});
settingsStore.configure('novel_part', {
  exportFileTypes: ['word', 'text', 'html', 'google'],
  exportStyles: ['manuscript', 'dabble'],
});
settingsStore.configure('novel_chapter', {
  exportFileTypes: ['word', 'text', 'html', 'google'],
  exportStyles: ['manuscript', 'dabble'],
});
settingsStore.configure('novel_prologue', {
  exportFileTypes: ['word', 'text', 'html', 'google'],
  exportStyles: ['manuscript', 'dabble'],
});
settingsStore.configure('novel_scene', {
  exportFileTypes: ['word', 'text', 'html', 'google'],
  exportStyles: ['manuscript', 'dabble'],
});

settingsStore.configure('novel_book_scenes', {
  exportFileTypes: ['word', 'google'],
  exportStyles: ['dabble'],
  exportDescriptions: true,
});
settingsStore.configure('novel_plot', {
  exportFileTypes: ['word', 'google'],
  exportStyles: ['dabble'],
  exportDescriptions: true,
});
settingsStore.configure('novel_plot_line', {
  exportFileTypes: ['word', 'google'],
  exportStyles: ['dabble'],
  exportDescriptions: true,
});

settingsStore.configure('novel_folder', {
  exportFileTypes: ['word', 'google'],
  exportStyles: ['dabble'],
});
settingsStore.configure('novel_page', {
  exportFileTypes: ['word', 'google'],
  exportStyles: ['dabble'],
});

settingsStore.configure('novel_cast', {
  exportFileTypes: ['word', 'google'],
  exportStyles: ['dabble'],
});
settingsStore.configure('novel_character', {
  exportFileTypes: ['word', 'google'],
  exportStyles: ['dabble'],
});
