<script lang="ts">
  import { t } from '@dabble/data/intl';
  import { appUpdateStore } from '@dabble/data/ui';
  import { desktop } from '@dabble/desktop';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { tooltipBottom } from '@dabble/toolkit/tooltip';
  import { mdiArrowUpBoldCircle } from '@mdi/js';

  function relaunch(updateLocation: 'desktop' | 'web') {
    if (updateLocation === 'desktop') {
      desktop.quitAndInstall();
    } else {
      window.location.reload();
    }
  }
</script>

{#if $appUpdateStore.state === 'downloading'}
  <div
    use:tooltipBottom={$t($appUpdateStore.progress ? 'update_installing_percent' : 'update_installing', {
      percent: $appUpdateStore.progress,
    })}
  >
    <button disabled class="icon updating-indicator">
      <Icon path={mdiArrowUpBoldCircle} />
    </button>
  </div>
{:else if $appUpdateStore.state === 'installed'}
  <button
    class="icon update-indicator"
    use:tooltipBottom={$t('update_installed', { version: $appUpdateStore.newVersion })}
    on:click={() => relaunch($appUpdateStore.location)}
  >
    <Icon path={mdiArrowUpBoldCircle} />
  </button>
{/if}

<style>
  :global(.app-header) button.icon.update-indicator {
    color: var(--green-dark);
    animation: app-update-ring 8s ease infinite;
  }
  :global(.app-header) button.icon.update-indicator:hover {
    color: var(--green-dark);
    animation: none;
  }

  @keyframes app-update-ring {
    0% {
      transform: scale(1);
    }
    2% {
      transform: scale(1.4);
    }
    4% {
      transform: scale(1);
    }
    6% {
      transform: scale(1.3);
    }
    8% {
      transform: scale(1);
    }
    10% {
      transform: scale(1.2);
    }
    12%,
    100% {
      transform: scale(1);
    }
  }
</style>
