<script lang="ts">
  import { signals } from '@dabble/data/app-state';
  import { t } from '@dabble/data/intl';
  import { projectMetasStore, projectStore } from '@dabble/data/project-data';
  import { settingsStore } from '@dabble/data/settings';
  import { ProjectMeta } from '@dabble/data/types';
  import { inform } from '@dabble/data/ui';
  import Alert from '@dabble/toolkit/Alert.svelte';
  import BlankScreen from '@dabble/toolkit/BlankScreen.svelte';
  import { isEqual } from 'typewriter-editor';

  let renameError: string;
  let saving: boolean;
  let edits: { title: string; subtitle: string; author: string };

  signals.onScreenChange('ProjectPreferences');

  $: project = $projectStore.project;
  $: projectId = $projectStore.projectId;
  $: meta = $projectMetasStore[projectId] || ({ type: 'project' } as ProjectMeta);
  $: updateEdits(meta);

  async function saveProject() {
    try {
      await projectMetasStore.update(projectId, edits);
      inform('success', $t('saved'));
    } catch (err) {
      renameError = err.message;
    }
  }

  function updateEdits({ title, subtitle, author }: ProjectMeta) {
    edits = { title, subtitle, author };
  }
</script>

<BlankScreen {project} {saving}>
  <svelte:fragment slot="title">{$t('project_settings')}</svelte:fragment>

  <div class="section">
    <h2>{$t('project_rename_header')}</h2>

    {#if renameError}
      <Alert type="danger" dismissible on:close={() => (renameError = null)}>
        <strong>{$t('error')}:</strong>
        {$t(renameError)}
      </Alert>
    {/if}

    <form class="novel-title" on:submit|preventDefault={saveProject}>
      <fieldset disabled={isEqual(edits, meta)}>
        <div class="form-group">
          <label for="project-title">{$t('novel_title')}</label>
          <input
            id="project-title"
            type="text"
            class="form-control"
            placeholder={settingsStore.getPlaceholder(meta, 'title')}
            bind:value={edits.title}
          />
        </div>
        <div class="form-group">
          <label for="project-subtitle">{$t('novel_book_unsubtitled')}</label>
          <input
            id="project-subtitle"
            type="text"
            class="form-control"
            placeholder={settingsStore.getPlaceholder(meta, 'subtitle')}
            bind:value={edits.subtitle}
          />
        </div>
        <div class="form-group">
          <label for="project-author">{$t('novel_author')}</label>
          <input
            id="project-author"
            type="text"
            class="form-control"
            placeholder={settingsStore.getPlaceholder(meta, 'author')}
            bind:value={edits.author}
          />
        </div>
        <button class="btn primary" type="submit" disabled={isEqual(edits, meta)}>{$t('save')}</button>
      </fieldset>
    </form>
  </div>

  {#each settingsStore.getValuesFromPlugins('projectSettings', project) as section}
    <svelte:component this={section} />
  {/each}
</BlankScreen>
