import { readonlyStore } from '@dabble/data/app-state';
import { projectMetaStore, projectStore } from '@dabble/data/project-data';
import { Project } from '@dabble/data/types';

type Titled = { title?: string; subtitle?: string; author?: string };
const fields: (keyof Titled)[] = ['title', 'subtitle', 'author'];

let currentProject = projectStore.get().project;
let current = getBook(currentProject);

// Update userProject when book title changes
projectStore.subscribe(({ project }) => {
  if (readonlyStore.get()) return;
  const oldProject = currentProject;
  currentProject = project;
  const old = current;
  current = getBook(project);

  if (!oldProject || !project || project.type !== 'novel') return;
  if (!current || (old && isSame(old, current))) return;
  if (!isSame(old, projectMetaStore.get())) return; // If the project title was changed from the book title, don't update

  const updates: any = {};
  fields.forEach(field => (updates[field] = current[field]));
  projectMetaStore.update(updates);
});

function getBook(project: Project) {
  let manuscript;
  return (
    project &&
    project.type === 'novel' &&
    (manuscript = project.docs[project.children[0]]) &&
    project.docs[manuscript.children[0]]
  );
}

function isSame(oldBook: Titled, newBook: Titled) {
  if (!oldBook !== !newBook) return false;
  return (
    oldBook === newBook ||
    fields.every(field => (!oldBook[field] && !newBook[field]) || oldBook[field] === newBook[field])
  );
}
