import { Readable, writable } from 'easy-signal';

export function createStorageStore(storage: Storage): Readable<Record<string, string>> {
  const { get, subscribe } = writable<Record<string, string>>({}, set => {
    addEventListener('storage', onStorage);
    set({ ...storage });

    function onStorage(event: StorageEvent) {
      if (event.storageArea === storage) {
        set({ ...storage });
      }
    }

    return () => {
      removeEventListener('storage', onStorage);
    };
  });

  return { get, subscribe };
}
