<script lang="ts">
  import { featuresStore } from '@dabble/data/global-data';
  import { t } from '@dabble/data/intl';
  import { routerStore } from '@dabble/data/navigation';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiPlayCircle, mdiStopCircle } from '@mdi/js';
  import { readingStore } from '../reading';
  import SpeedControls from './SpeedControls.svelte';
  import VoiceSelect from './VoiceSelect.svelte';

  $: hasFeature = $featuresStore.grammar;
</script>

<div class="reading">
  <div class="reading-label">{$t('read_to_me')}</div>
  {#if hasFeature}
    {#if window.speechSynthesis}
      <div class="reading-controls">
        <button class="control-btn icon" on:click={() => ($readingStore ? readingStore.stop() : readingStore.start())}>
          <Icon path={$readingStore ? mdiStopCircle : mdiPlayCircle} size="30" />
        </button>
        <div class="divider" />
        <SpeedControls />
      </div>
      <VoiceSelect />
    {:else}
      {$t('voice_unavailable')}
    {/if}
  {:else}
    <p>{@html $t('feature_upgrade', { feature: $t('read_to_me') })}</p>
    <button type="button" class="btn primary" on:click={() => routerStore.navigate('/checkout/plans')}
      >{$t('feature_upgrade_now')}</button
    >
  {/if}
</div>

<style>
  .reading-controls {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
  }
  .control-btn {
    font-size: 2rem;
  }
  .divider {
    border-left: var(--form-border);
    height: 1rem;
    margin: 0 0.25rem;
  }
  .reading {
    border-top: var(--side-nav-border);
    padding: 10px;
    margin-bottom: 10px;
    color: var(--text-color-lighter);
  }
  .reading-label {
    flex: 1;
    font-weight: bold;
    font-size: var(--font-size-sm);
  }
</style>
