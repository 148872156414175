import { $t } from '@dabble/data/intl';
import { projectStore } from '@dabble/data/project-data';
import { settingsStore } from '@dabble/data/settings';
import { Doc, Project } from '@dabble/data/types';
import { getTitle } from '@dabble/data/util';
import { mdiCardTextOutline, mdiTableColumn, mdiViewColumn } from '@mdi/js';
import i18next from 'i18next';

settingsStore.configure('novel_plot', {
  hasChildren: true,
  openByDefault: true,
  icon: mdiViewColumn,
  hideRightNav: true,
  hideWordCount: true,
  findSettings: {
    skip: true,
    visibleFields: {
      novel_scene: {},
      novel_plot_line: {},
      novel_plot_point: {},
    },
  },
  convertToTemplate: true,
  placeholders: {
    title: (doc: Doc) => {
      const [bookLink] = projectStore.linksFrom(doc.id, 'plot');
      const book = bookLink && bookLink.to;
      return book ? i18next.t('novel_plot_for', { bookTitle: getTitle(book) }) : i18next.t('novel_plot_untitled');
    },
  },
  unstyledPlaceholders: {
    title: (doc: Doc) => {
      const [bookLink] = projectStore.linksFrom(doc.id, 'plot');
      const book = bookLink && bookLink.to;
      return Boolean(book || doc.title);
    },
  },
  disableFocusMode: true,
  hoverFolderToggle: true,
  menuTypes: ['novel_plot_line'],
  validChildTypes: {
    novel_plot_line: true,
    novel_scenes: true,
  },
  getChildren(doc: Doc, project: Project) {
    const link = Object.keys(project.links).find(key => key.startsWith(`${doc.id}:plot:`));
    const book = link && project.docs[link.split(':')[2]];
    if (book) return [book.id + '-scenes'].concat(doc.children);
    return doc.children;
  },
  requiresFeature: 'plotting',
  structure: [
    {
      type: 'novel_plot_line',
      children: [{ type: 'novel_plot_point' }],
    },
  ],
});

settingsStore.configure('novel_plot_line', {
  hasChildren: true,
  icon: mdiTableColumn,
  placeholders: {
    title: 'novel_plot_line_untitled',
  },
  hideFolderToggle: true,
  hideWordCount: true,
  defaultChildType: 'novel_plot_point',
  menuTypes: [],
  validChildTypes: {
    novel_plot_point: true,
  },
  findSettings: {
    group: true,
  },
  requiresFeature: 'plotting',
  structure: [
    {
      type: 'novel_plot_point',
    },
  ],
  convertToTemplate: true,
});

settingsStore.configure('novel_plot_point', {
  icon: mdiCardTextOutline,
  hideRightNav: true,
  hideWordCount: true,
  placeholders: {
    title: 'novel_plot_point_untitled',
  },
  requiresFeature: 'plotting',
  convertToTemplate: true,
});

settingsStore.configure('novel_book_scenes', {
  noDeleteInNavigation: true,
  undraggable: true,
  uneditable: true,
  hasChildren: true,
  icon: mdiTableColumn,
  defaultChildType: 'novel_scene',
  navTitlePostfix: doc => $t('novel_book_scenes_count', { count: doc.children.length }),
  placeholders: {
    title: 'novel_book_scenes',
  },
  unstyledPlaceholders: {
    title: true,
  },
  hideFolderToggle: true,
  hideWordCount: true,
  menuTypes: [],
  validChildTypes: {
    novel_scene: true,
  },
  findSettings: {
    group: true,
    visibleFields: {
      novel_scene: { title: true, description: true },
    },
  },
  requiresFeature: 'plotting',
});
