import { signal, writable } from 'easy-signal';
import { agreeable } from './agreeable';
import { UserProject } from './agreeable/agreeable-config';
import { uidStore } from './ids';
import { unloadProject } from './project-data';
import { createReadOnlyStore } from './stores/readonly';
import { Doc, Project, ProjectMeta, User } from './types';
import { userAccountStore, userDataStore, userProjectsStore, userStore } from './user-data';

export const readonlyStore = createReadOnlyStore();
export const readyStore = writable(false);
export const readingNovelStore = writable(false);
export const printReadyStore = writable(false);
export const syncingStore = writable(false);
export const fromRemote = new Set<any>();

export const signals = {
  onFirstLoad: signal<() => void>(),
  ready: signal<() => void>(),
  finishedSyncing: signal<(initialSessionSync: boolean) => void>(),
  finishedProjectLoad: signal<() => void>(),
  onProjectCreated: signal<(data: { project: Project; userProject: UserProject; projectMeta: ProjectMeta }) => void>(),
  onDocCreated: signal<(doc: Partial<Doc>) => void>(),
  onScreenChange: signal<(name: string) => void>(),
  onSignup: signal<() => void>(),
  onNaNoWordCount: signal<(wordcount: number) => void>(),
  onAuthed: signal<(user: User) => void>(),
};

export async function load(uid: string) {
  readyStore.set(false);
  uidStore.set(uid);
  await Promise.all([userStore.load(), userAccountStore.load(), userDataStore.load(), userProjectsStore.load()]);
  readyStore.set(true);
  signals.ready();
}

export function unload(shouldDeleteDb = false, uid?: string) {
  if (shouldDeleteDb && uid) {
    agreeable.call('deleteDB');
  }

  if (readyStore.get()) {
    readyStore.set(false);
    unloadProject();
    agreeable.close();
  }
}

export function deleteDatabase() {
  return agreeable.call('deleteDB');
}

export type Platform =
  | 'aix'
  | 'android'
  | 'darwin'
  | 'freebsd'
  | 'haiku'
  | 'linux'
  | 'openbsd'
  | 'sunos'
  | 'win32'
  | 'cygwin'
  | 'netbsd';
