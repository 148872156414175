import { setupFindReplace } from '@dabble/find/find-setup';
import { docStore } from './doc-data';
import { getUrl, routerStore } from './navigation';
import { projectStore } from './project-data';
import { settingsStore } from './settings';
import { createFindReplaceStore } from './stores/find-replace';
import { viewport } from './ui';
import { preferencesStore } from './user-data';

export const findReplaceStore = createFindReplaceStore(
  settingsStore,
  projectStore,
  docStore,
  routerStore,
  getUrl,
  viewport
);

setupFindReplace(findReplaceStore, preferencesStore, routerStore);
