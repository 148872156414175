<script lang="ts">
  import { delegate } from '@dabble/data/delegate';
  import { ACCOUNT_URL } from '@dabble/data/global-constants';
  import { t } from '@dabble/data/intl';
  import { url } from '@dabble/data/navigation';
  import { projectStore } from '@dabble/data/project-data';
  import { settingsStore } from '@dabble/data/settings';
  import { User } from '@dabble/data/types';
  import { userAccountStore } from '@dabble/data/user-data';
  import Dropdown from '@dabble/toolkit/Dropdown.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { tooltipLeft } from '@dabble/toolkit/tooltip';
  import {
    mdiAccountCircle,
    mdiAccountMultiple,
    mdiAccountMultiplePlus,
    mdiAccountSupervisorCircle,
    mdiArrowRightBoldCircle,
    mdiArrowUpBoldCircle,
    mdiCardAccountDetails,
    mdiCreditCard,
    mdiLogoutVariant,
    mdiTune,
  } from '@mdi/js';
  import {
    accountsStore,
    signOut as authSignOut,
    currentUserStore,
    delegatesStore,
    gotoAccount,
    login,
    singOutOfDelegate,
  } from '../accounts';

  let dropdownVisible = false;

  $: otherAccounts = $accountsStore.filter(a => a.uid !== $currentUserStore?.uid);
  $: otherDelegateAccounts = $delegatesStore.filter(a => a.uid !== $currentUserStore?.uid);
  $: accountMenuItems = settingsStore.getValuesFromPlugins('plugins.account.menuItems', $projectStore.project);
  $: ({ status, plan } = $userAccountStore);
  $: subscribed = status === 'active' || status === 'delinquent';
  $: canUpgrade = subscribed && plan && (plan.includes('Basic') || plan.includes('Standard'));
  $: canSubscribe = (status === 'trialing' || status === 'inactive') && !plan.includes('Lifetime');

  function signIn() {
    login();
    dropdownVisible = false;
  }

  function signOut() {
    authSignOut();
    dropdownVisible = false;
  }

  function firstName(user: User) {
    return (user.name && user.name.split(/\s+/)[0]) || user.email;
  }
</script>

{#if delegate && $currentUserStore}
  <span class="delegate-message">Signed in as <span class="delegate-name">{firstName($currentUserStore)}</span></span>
{/if}
{#if $currentUserStore}
  <button
    class="btn dropdown-toggle focus-fade"
    class:active={dropdownVisible}
    on:click={() => (dropdownVisible = true)}
  >
    <Icon path={delegate ? mdiAccountSupervisorCircle : mdiAccountCircle} />
  </button>
  {#if dropdownVisible}
    <Dropdown on:close={() => (dropdownVisible = false)} placement="bottom-end">
      <a class="dropdown-item" href="/account/preferences">
        <span><Icon path={mdiTune} inline />{$t('account_screen_preferences')}</span>
      </a>
      <a class="dropdown-item" href="{ACCOUNT_URL}profile?redirect={encodeURIComponent($url)}">
        <span><Icon path={mdiCardAccountDetails} inline />{$t('account_screen_profile')}</span>
      </a>
      <a class="dropdown-item" href="{ACCOUNT_URL}billing/portal?redirect={encodeURIComponent($url)}">
        <Icon path={mdiCreditCard} />
        {$t('account_screen_billing')}
      </a>
      {#if canUpgrade || canSubscribe}
        <a class="dropdown-item inactive" href="{ACCOUNT_URL}billing/plans?redirect={encodeURIComponent($url)}">
          <button class="small btn primary upgrade-button"
            ><Icon path={canSubscribe ? mdiArrowRightBoldCircle : mdiArrowUpBoldCircle} />
            {$t(canSubscribe ? 'billing_subscribe' : 'billing_upgrade')}</button
          >
        </a>
      {/if}
      {#each accountMenuItems as component}
        <svelte:component this={component} />
      {/each}
      <hr />
      {#if otherDelegateAccounts.length}
        <div>
          <header>Open Delegate Accounts</header>
          {#each otherDelegateAccounts as account}
            <button on:click={() => gotoAccount(account.uid)} class="dropdown-item switch-account">
              <Icon path={mdiAccountMultiple} />
              <span class="name">{account.name || account.email}</span>
              <button
                on:click|stopPropagation={() => singOutOfDelegate(account.uid)}
                class="icon"
                use:tooltipLeft={'Sign out from this account'}
              >
                <Icon path={mdiLogoutVariant} />
              </button>
            </button>
          {/each}
          <hr />
        </div>
      {/if}
      {#if otherAccounts.length}
        <div>
          <header>{$t('account_switch_account')}</header>
          {#each otherAccounts as account}
            <button on:click={() => gotoAccount(account.uid)} class="dropdown-item switch-account">
              <Icon path={mdiAccountMultiple} />
              <span class="name">{account.name || account.email}</span>
            </button>
          {/each}
          <hr />
        </div>
      {/if}
      <button on:click={signIn} class="dropdown-item">
        <Icon path={mdiAccountMultiplePlus} />
        <span>{$t('account_login_another')}</span>
      </button>
      <button on:click={signOut} class="dropdown-item">
        <Icon path={mdiLogoutVariant} />
        {$t('account_logout')}
        {#if delegate}
          from&nbsp;<span class="delegate-name">{$currentUserStore.name || $currentUserStore.email}</span>
        {/if}
      </button>
    </Dropdown>
  {/if}
{/if}

<style>
  .upgrade-button {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  }
  .delegate-message {
    font-size: var(--font-size-xs);
    color: var(--text-color-lightest);
    white-space: nowrap;
  }
  .delegate-message .delegate-name {
    color: var(--text-color-lighterer);
  }
  .dropdown-item .delegate-name {
    font-weight: bold;
  }
  .dropdown-item .name {
    flex: 1;
  }
</style>
