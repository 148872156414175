<script lang="ts">
  import { t } from '@dabble/data/intl';
  import { settingsStore } from '@dabble/data/settings';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import { mdiPlusThick } from '@mdi/js';

  $: type = $settingsStore.dabble.defaultProjectType;
</script>

<div class="project-outer">
  <button class="new-project" on:click use:tooltipTop={$t('new_project', { type: $t(type) })} tabindex="-1">
    <Icon path={mdiPlusThick} highlight />
  </button>
</div>

<style>
  .project-outer {
    margin-bottom: 32px;
    padding: 0 16px;
  }
  .new-project {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 140px;
    height: 180px;
    border: 1px dashed var(--text-color-lighterer);
    border-radius: 3px;
    color: var(--text-color-lightest);
    outline: none;
    cursor: pointer;
    background: none;
    transition: border-color 0.15s ease-in-out;
  }
  .new-project::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 3px;
    background-color: var(--background);
    opacity: 0.3;
    transition: background-color 0.15s ease-in-out;
  }
  .new-project:hover {
    border-color: var(--text-color-lighterer);
  }
  .new-project:hover::before {
    background-color: var(--page-background);
  }
  .new-project :global(.icon) {
    position: relative;
    font-size: 50px;
    color: var(--text-color-lighterer);
    margin-left: 1px;
    transition: color 0.15s ease-in-out;
  }
  .new-project:hover :global(.icon) {
    color: var(--text-color-lighter);
  }

  @media (max-width: 600px) {
    .project-outer {
      transform: scale(0.8, 0.8);
      transform-origin: top;
      margin-bottom: 0;
      margin-right: -16px;
    }
  }
</style>
