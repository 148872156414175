<script lang="ts">
  import { chosenCommentIdStore, selectedCommentIdStore } from '../comments-store';
  import CommentEntry from './CommentEntry.svelte';

  export let comment;
  let node;
</script>

<!-- svelte-ignore a11y-no-static-element-interactions -->
<div
  class="comment"
  tabindex="-1"
  data-id={comment.id}
  bind:this={node}
  on:focus={() => ($chosenCommentIdStore = comment.id)}
  on:blur={() => ($chosenCommentIdStore = '')}
  on:mouseenter={() => ($selectedCommentIdStore = comment.id)}
  on:mouseleave={() => ($selectedCommentIdStore = '')}
>
  <CommentEntry {comment} />
  {#each comment.replies as reply}
    <CommentEntry {comment} {reply} />
  {/each}
</div>

<style>
  .comment {
    --comment-padding: 8px;
    position: relative;
    padding: 0 6px;
    margin-bottom: 8px;
    border-radius: 5px;
    border: 1px solid var(--dabble-gray);
    background-color: var(--white);
    cursor: pointer;
  }
  .comment:focus {
    border-color: var(--input-focus-border);
    outline: none;
    box-shadow:
      inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 6px var(--input-focus-shadow);
  }
</style>
