import { iconVariations } from '@dabble/toolkit/icons';
import { tick } from 'svelte';
import * as computed from './computed';
import { docIdStore } from './ids';
import { getUrl, goto } from './navigation';
import { projectMetasStore, projectStore } from './project-data';
import { settingsStore } from './settings';
import { Docs } from './stores/project/docs';
import { getSetting } from './stores/settings';
import { Doc, ObjectWithSettings, Project, ProjectDocs } from './types';
export { EMPTY_ARRAY, EMPTY_OBJECT } from 'agreeable-client';

export function isDocEmpty(docId: string | Doc): boolean {
  const doc = typeof docId === 'string' ? projectStore.get().docs[docId] : docId;
  return computed.isDocEmpty(doc, projectStore.get().docs);
}

export function deleteTooltipText(docId: string | Doc) {
  const doc = typeof docId === 'string' ? projectStore.get().docs[docId] : docId;
  return isDocEmpty(doc) ? 'delete_item' : 'send_to_trash';
}

// If the doc is the project, return the project meta for its title/etc to be set.
function getProjectMetaForSettings(doc: ObjectWithSettings): ObjectWithSettings {
  const project = doc as Project;
  if (project && ((project.docs && project.links) || projectStore.get().projectId === project.id)) {
    return projectMetasStore.get()[project.id];
  }
  return doc;
}

export function getPlaceholderClass(doc: ObjectWithSettings, field = 'title'): string {
  doc = getProjectMetaForSettings(doc);
  if (!doc || (doc as any)[field]) return '';
  return settingsStore.getPlaceholderClass(doc, field);
}

export function getTitle(doc: ObjectWithSettings, field = 'title', docs?: Docs): string {
  if (!doc) return '';
  doc = getProjectMetaForSettings(doc);
  return (doc as any)[field] || settingsStore.getPlaceholder(doc, field) || '';
}
export function getIcon(doc: Doc): string {
  return (doc.icon && iconVariations[doc.type]?.[doc.icon]) || getSetting(settingsStore.getFor(doc)?.icon, doc);
}

export function getAllOfType(within: Doc, docs: ProjectDocs, type: string, getDocs: true): Doc[];
export function getAllOfType(within: Doc, docs: ProjectDocs, type: string, getDocs?: false): string[];
export function getAllOfType(within: Doc, docs: ProjectDocs, type: string, getDocs = false): string[] | Doc[] {
  if (within.type === type) return getDocs ? [within] : [within.id];
  if (!within.children) return [];
  return within.children.reduce(
    (all, childId) => (docs[childId] ? all.concat(getAllOfType(docs[childId], docs, type, getDocs as any)) : all),
    []
  );
}

export async function trashOrDeleteDoc(docId: string, deletePermanently = false) {
  if (!docId) return;

  if (docId === docIdStore.get()) {
    const project = projectStore.get();
    goto(getUrl(project.parentsLookup[docId]));
  }

  await tick();

  if (deletePermanently) {
    projectStore.deleteDoc(docId);
  } else {
    projectStore.trashDoc(docId);
  }
}
