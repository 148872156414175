import { routerStore } from '@dabble/data/navigation';
import { Editor, EditorChangeEvent } from 'typewriter-editor';

export default function links(editor: Editor) {
  let anchor: HTMLAnchorElement;

  function onClick(event: MouseEvent) {
    const target = (event.target as Element)?.closest('a[href]') as HTMLAnchorElement;

    if (target) {
      event.preventDefault();
      if (dabbleLink(target)) {
        const url = target.pathname;
        if (routerStore.get().url !== url) {
          routerStore.navigate(url);
        }
      } else {
        window.open(target.href, target.target);
      }
    }
  }

  // Rewrite any full-path links within Dabble to their local version
  function onChanging({ change }: EditorChangeEvent) {
    if (!change || !change.contentChanged) return;
    change.delta.ops.forEach(op => {
      if (op.attributes && op.attributes.link) {
        if (!anchor) anchor = editor.root.ownerDocument.createElement('a');
        anchor.href = op.attributes.link;
        if (dabbleLink(anchor) && op.attributes.link.startsWith('http')) {
          op.attributes.link = anchor.pathname;
        }
      }
    });
  }

  return {
    init() {
      editor.root.addEventListener('click', onClick);
      editor.on('changing', onChanging);
    },
    destroy() {
      editor.root.removeEventListener('click', onClick);
      editor.off('changing', onChanging);
    },
  };
}

function dabbleLink(anchor: HTMLAnchorElement) {
  return anchor.host === window.location.host;
}
