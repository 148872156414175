<script lang="ts">
  import { t } from '@dabble/data/intl';
  import { getUrl } from '@dabble/data/navigation';
  import { Doc } from '@dabble/data/types';

  export let doc: Doc;
  export let number: number;
  export let displayTitle: string;
  export let displayNumbers: boolean = false;
  let className = '';
  export { className as class };

  function gotoTocEntry() {
    const url = getUrl(doc);
    return url;
  }
</script>

<a class={className + ' toc-entry'} href={gotoTocEntry()}>
  {#if displayNumbers}
    <span class="toc-chapter-number">
      {doc.type === 'novel_part' ? $t('novel_part_number', { number }) : $t('novel_chapter_number', { number })}.
    </span>
  {/if}
  <span class="toc-chapter-title">{displayTitle}</span>
</a>

<style>
  .toc-entry {
    text-decoration: none;
    color: inherit;
    font-size: 1em;
    height: 2em;
    font-family: var(--font-family-header);
  }

  .toc-level-1 {
    display: flex;
    align-items: left;
    padding: 0.5em 0;
  }

  .toc-level-2 {
    display: flex;
    align-items: left;
    padding: 0.5em 0;
    margin-left: 2em;
  }

  .toc-chapter-number {
    font-family: var(--font-family-header);
    font-size: 1em;
    margin-right: 0.5em;
  }

  .toc-chapter-title {
    font-family: var(--font-family-header);
    font-size: 1em;
  }
</style>
