import { Readable, writable } from 'easy-signal';
import { RouterStore } from './router';

/**
 * Stores the last URL that was visited. Helpful for redirecting back to the last page.
 */
export function createLastUrl(router: RouterStore): Readable<string> {
  let currentUrl: string = sessionStorage.lastUrl || '/';
  const { get, set, subscribe } = writable<string>(currentUrl);

  router.subscribe(router => {
    sessionStorage.lastUrl = currentUrl;
    set(currentUrl);
    currentUrl = router.url;
  });

  return {
    get,
    subscribe,
  };
}

/**
 * Stores the last project's URL that was visited. Helpful for redirecting back to the last project page.
 */
export function createLastProjectUrl(lastUrl: Readable<string>): Readable<string> {
  const { get, set, subscribe } = writable<string>(sessionStorage.lastProductUrl || '/');

  lastUrl.subscribe(lastUrl => {
    if (/^(\/$|\/p\/)/.test(lastUrl)) {
      sessionStorage.lastProductUrl = lastUrl;
      set(lastUrl);
    }
  });

  return {
    get,
    subscribe,
  };
}
