import { openDatabase } from '@dabble/data/dabble-database';
import { DabbleDatabase } from '@dabble/data/old-types';
import { DabbleAgreeableClient } from '@dabble/data/types';
import { accountsLeaderExtension } from '@dabble/plugins/account/accounts-leader';
import { analyticsLeaderExtension } from '@dabble/plugins/analytics/analytics-leader';
import { contentLeaderExtension } from '@dabble/plugins/content/content-leader';
import { syncLeaderExtension } from '@dabble/plugins/sync/sync-leader';
import { AgreeableClientOptions, AgreeableState, Config, ExtendAPI, Leader, agreeableClient } from 'agreeable-client';
import { Readable } from 'easy-signal';
import { appLeaderExtension } from '../app-leader';

/**
 * A type that can be a promise or a value allowing for functions to be async or not
 */
export type PossiblePromise<T> = T | Promise<T>;

/**
 * A function that will be called when syncing starts
 */
export type SyncFunction = () => PossiblePromise<CleanupFunction | void>;

/**
 * A function that will be called to clean up any resources after syncing is done
 */
export type CleanupFunction = () => void;

/**
 * A module that will be loaded by the leader and run to set up syncing and other logic
 */
export type LeaderModule = (
  client: DabbleAgreeableClient,
  db: DabbleDatabase,
  extendApi: ExtendAPI,
  leader: Leader
) => PossiblePromise<void | SyncFunction>;

/**
 * Set up the leader module for extending the API and adding our own syncing logic.
 */
export async function leaderModule(
  config: Config,
  options: AgreeableClientOptions,
  extendApi: ExtendAPI,
  leader: Leader
) {
  // Create a client for state and date
  const client = agreeableClient(config) as any as DabbleAgreeableClient;
  client.open({ ...options, workerUrl: undefined }); // Don't let this worker open another worker

  const db = await openDatabase(options.uid);

  // Extend the API and get syncers for each
  const results = await Promise.all([
    appLeaderExtension(client, db, extendApi, leader),
    accountsLeaderExtension(client, db, extendApi, leader),
    analyticsLeaderExtension(client, db, extendApi, leader),
    contentLeaderExtension(client, db, extendApi, leader),
    syncLeaderExtension(client, db, extendApi, leader),
  ]);
  const syncFunctions = results.filter(r => typeof r === 'function') as SyncFunction[];

  sync(client.state, syncFunctions);
}

/**
 * Sync extensions to leader that need syncing
 */
function sync(state: Readable<AgreeableState>, syncers: SyncFunction[]) {
  let authed = state.get().authed;
  let stopSyncing: () => void;

  // Listen for when online and authed to sync data
  state.subscribe(state => {
    if (state.authed && !authed) {
      authed = true;
      stopSyncing = startSyncing();
    } else if (!state.authed && authed) {
      authed = false;
      if (stopSyncing) stopSyncing();
    }
  });

  function startSyncing() {
    const cleanup = syncers.map(syncer => typeof syncer === 'function' && syncer());

    // Any cleanup needed, do here
    return () => {
      cleanup.forEach(async p => {
        const c = await p;
        if (typeof c === 'function') {
          c();
        }
      });
    };
  }
}
