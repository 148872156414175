import { t } from '@dabble/data/intl';
import { projectStore } from '@dabble/data/project-data';
import { settingsStore } from '@dabble/data/settings';
import { Docs } from '@dabble/data/stores/project/docs';
import { Doc } from '@dabble/data/types';
import { truncateAtWord } from '@dabble/data/word-count';
import {
  mdiBook,
  mdiBookOpenBlankVariant,
  mdiBookOpenBlankVariantOutline,
  mdiBookOpenPageVariant,
  mdiFileImageOutline,
  mdiFileOutline,
  mdiImageAlbum,
} from '@mdi/js';
import i18next from 'i18next';
import { addChapterHeadings, removeChapterHeadings } from '../chapter-heading-menu-items';
import { removeCoverArt, setCoverArt } from '../cover-art-menu-item';
import splitShortcut from '../editor-modules/split-shortcut';
import { joinSceneMenuItems } from '../join-scenes';
import { removeTitleImage, setTitleImage } from '../title-image-menu-item';
import { createToc, removeToc } from '../toc-menu-items';

const autoNumbersCache = new WeakMap<Docs, Map<Doc, number>>();
const hasTitlesCache = new WeakMap<Docs, Map<string, boolean>>();
t.subscribe(() => autoNumbersCache.delete(projectStore.get().docs));

function populateCache() {
  const { childrenLookup, docs } = projectStore.get();
  // create cache to avoid extra processing with many lookups, esp. for projects with many chapters
  let cache = autoNumbersCache.set(docs, new Map()).get(docs);
  let titlesCache = hasTitlesCache
    .set(
      docs,
      new Map([
        ['novel_chapter', false],
        ['novel_part', false],
        ['novel_prologue', false],
      ])
    )
    .get(docs);
  Object.values(docs).forEach(doc => {
    if (doc.type !== 'novel_book') return;
    let partNumber = 0;
    let chapterNumber = 0;
    childrenLookup[doc.id].forEach(function count(child) {
      if (child.title && child.type !== 'novel_prologue') {
        titlesCache.set(child.type, true);
      }
      if (child.type === 'novel_chapter' && !child.unnumbered) {
        cache.set(child, ++chapterNumber);
      } else if (child.type === 'novel_part') {
        cache.set(child, ++partNumber);
        childrenLookup[child.id].forEach(count);
      }
    });
  });
  return cache;
}

export function getCachedAutoNumber(doc: Doc) {
  const { docs } = projectStore.get();
  if (!autoNumbersCache.has(docs)) {
    populateCache();
  }
  return autoNumbersCache.get(docs).get(doc);
}

export function getHasTitles(doc: Doc) {
  const { docs } = projectStore.get();
  if (!hasTitlesCache.has(docs)) {
    populateCache();
  }
  return hasTitlesCache.get(docs).get(doc.type);
}

function getCachedPlaceholder(doc: Doc) {
  const number = getCachedAutoNumber(doc);
  if (number === undefined) {
    return i18next.t(doc.type);
  } else if (doc.type === 'novel_chapter' && !doc.unnumbered) {
    return i18next.t('novel_chapter_number', { number: number });
  } else if (doc.type === 'novel_part') {
    return i18next.t('novel_part_number', { number: number });
  }
}

function getDefaultTitle(titleKey: string): string {
  return t.get()(titleKey);
}

function fixupIndex(doc: Doc, index: number, newType?: string) {
  if (settingsStore.getFor(newType).preferTopInsertion) {
    if (index === undefined) index = 0;
    else if (settingsStore.getFor(newType).preferTopInsertion && index > 0) {
      index--;
    }
  }
  return index;
}

settingsStore.configure('novel_book', {
  hasChildren: true,
  openByDefault: true,
  icon: doc => (doc.image ? mdiImageAlbum : mdiBook),
  menuItems: { setCoverArt, removeCoverArt, createToc, removeToc, addChapterHeadings, removeChapterHeadings },
  placeholders: {
    title: 'novel_book_untitled',
    subtitle: 'novel_book_unsubtitled',
    author: 'author_unknown',
  },
  hoverFolderToggle: true,
  validChildTypes: {
    novel_prologue: fixupIndex,
    novel_chapter: true,
    novel_part: true,
    novel_image: true,
  },
  structure: [
    {
      type: 'novel_chapter',
      children: [
        {
          type: 'novel_scene',
        },
      ],
    },
  ],
});

settingsStore.configure('novel_prologue', {
  hasChildren: true,
  openByDefault: true,
  preferTopInsertion: true,
  defaultTitle: getDefaultTitle('novel_prologue'),
  icon: mdiBookOpenBlankVariantOutline,
  menuItems: { setTitleImage, removeTitleImage },
  hoverFolderToggle: true,
  placeholders: {
    title: 'novel_prologue_untitled',
  },
  validChildTypes: {
    novel_scene: true,
    novel_image: true,
  },
  structure: [
    {
      type: 'novel_scene',
    },
  ],
  convertToTemplate: true,
});

settingsStore.configure('novel_part', {
  hasChildren: true,
  openByDefault: true,
  icon: mdiBookOpenPageVariant,
  menuItems: { setTitleImage, removeTitleImage },
  placeholders: {
    title: (part: Doc) => getCachedPlaceholder(part),
  },
  unstyledPlaceholders: {
    title: true,
  },
  hoverFolderToggle: true,
  validChildTypes: {
    novel_chapter: true,
    novel_image: true,
  },
  structure: [
    {
      type: 'novel_chapter',
      children: [
        {
          type: 'novel_scene',
        },
      ],
    },
  ],
  convertToTemplate: true,
});

settingsStore.configure('novel_chapter', {
  hasChildren: true,
  openByDefault: true,
  icon: mdiBookOpenBlankVariant,
  menuItems: { setTitleImage, removeTitleImage },
  placeholders: {
    title: (chapter: Doc) => getCachedPlaceholder(chapter),
  },
  unstyledPlaceholders: {
    title: true,
  },
  unselectable: false,
  hoverFolderToggle: true,
  defaultChildType: 'novel_scene',
  mentionsMenu: true,
  validChildTypes: {
    novel_scene: true,
    novel_image: true,
  },
  structure: [
    {
      type: 'novel_scene',
    },
  ],
  convertToTemplate: true,
});

settingsStore.configure('novel_scene', {
  icon: mdiFileOutline,
  menuItems: { joinScenes: joinSceneMenuItems },
  placeholders: {
    title: (doc: Doc) => {
      const content = (projectStore.textFor(doc.id, 'body') || '').replace(/\n+/g, ' ').trim();
      const firstLine = truncateAtWord(content, 50);
      if (firstLine) return firstLine;
      return isFirstScene(doc) ? i18next.t('novel_scene_body_first') : i18next.t('novel_scene_title_empty');
    },
    body: (doc: Doc) => (isFirstScene(doc) ? i18next.t('novel_scene_body_first') : i18next.t('novel_scene_body_empty')),
    description: 'novel_scene_description_empty',
  },
  editorOptions: {
    body: {
      typeset: {
        lines: ['paragraph', 'blockquote', 'image'],
        formats: ['highlight', 'comment', 'bold', 'italic', 'underline'],
        embeds: ['image', 'br', 'decoration'],
      },
    },
  },
  editorModules: {
    splitShortcut,
  },
  mentionsMenu: true,
  imageStyles: ['outset-left', 'full-page'],
  convertToTemplate: true,
});

settingsStore.configure('novel_image', {
  icon: mdiFileImageOutline,
  hideWordCount: false,
  placeholders: {
    title: 'novel_image',
  },
  unstyledPlaceholders: {
    title: true,
  },
});

function isFirstScene(scene: Doc) {
  const { parentsLookup, childrenLookup } = projectStore.get();
  const chapter = parentsLookup[scene.id];
  if (!chapter || chapter.type !== 'novel_chapter' || childrenLookup[chapter.id][0] !== scene) return false;
  const book = parentsLookup[chapter.id];
  if (!book || (book.type !== 'novel_book' && book.type !== 'novel_part') || childrenLookup[book.id][0] !== chapter)
    return false;
  return true;
}
