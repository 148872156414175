<script lang="ts">
  import { readyStore, syncingStore } from '@dabble/data/app-state';
  import { getNow } from '@dabble/data/date';
  import { delegate } from '@dabble/data/delegate';
  import { selectDoc } from '@dabble/data/doc-data';
  import { getUrl, goto } from '@dabble/data/navigation';
  import { loadProject, projectMetasStore, projectStore, unloadProject } from '@dabble/data/project-data';
  import { settingsStore } from '@dabble/data/settings';
  import { InTrash } from '@dabble/data/stores/project/in-trash';
  import { Doc } from '@dabble/data/types';
  import { userProjectsStore } from '@dabble/data/user-data';
  import log from '@dabble/util/log';
  import { observe, Unsubscriber } from 'easy-signal';
  import { onDestroy } from 'svelte';
  import ProjectSettings from './ProjectSettings.svelte';
  import Workspace from './Workspace.svelte';

  export let projectId: string;
  export let docId: string;
  let loading = true;
  let stopObserver: Unsubscriber;

  $: $readyStore && startNewLoad(projectId);

  $: doc = $projectStore.docs && docId ? $projectStore.docs[docId] : $projectStore.project;
  $: onDoc(doc);

  onDestroy(() => {
    cancelLoad();
  });

  function startNewLoad(projectId: string) {
    cancelLoad();
    loadThisProject(projectId, docId);
  }

  function cancelLoad() {
    if (stopObserver) {
      stopObserver();
      stopObserver = null;
    }
    unloadProject();
  }

  async function loadThisProject(projectId: string, docId: string) {
    loading = true;

    // If the project is not on this machine, wait for it to be synced, then load it
    if (!$userProjectsStore[projectId] || !$projectMetasStore[projectId]) {
      stopObserver = observe(() => {
        // Once we finish syncing, try again
        if (!syncingStore.get()) {
          cancelLoad();
          if (userProjectsStore.get()[projectId] && projectMetasStore.get()[projectId]) {
            loadThisProject(projectId, docId);
          } else if (Object.keys(userProjectsStore.get()).length) {
            unloadProject();
            loading = false;
          }
        }
      });
      return;
    }

    const startLoading = Date.now();
    await loadProject(projectId);
    if (!delegate) {
      userProjectsStore.update({ [projectId]: { lastOpened: getNow() } });
    }
    log.tagColor('Load', '#444', 'Took', Date.now() - startLoading, 'ms to load the project', projectId);

    if ($projectStore.project && docId && docId !== 'settings' && !$projectStore.docs[docId]) {
      goto(getUrl($projectStore.projectId), true);
    }
    loading = false;
  }

  function onDoc(doc: Doc) {
    const { project, inTrash } = $projectStore;
    if (!project) return;
    // If a doc is selected that can't be viewable find the nearest child that is, or use the project or the project's
    // nearest viewable child
    if (doc && !selectable(doc, inTrash)) {
      let viewable = findViewableDoc(doc, inTrash);

      if (!viewable) {
        viewable = selectable(project, inTrash) ? project : findViewableDoc(project, inTrash);
      }

      if (viewable) {
        goto(getUrl(viewable.id, project.id), true);
      }
    }
    selectDoc(doc && doc.id);
  }

  function selectable(doc: Doc, inTrash: InTrash) {
    const docSettings = settingsStore.getFor(doc);
    return (
      docSettings && (!docSettings.hideInNavigation || inTrash[doc.id]) && !docSettings.unselectable && docSettings.view
    );
  }

  function findViewableDoc(doc: Doc, inTrash: InTrash) {
    const docSettings = settingsStore.getFor(doc) as ProjectSettings;
    if (!docSettings) return;
    let viewable;

    if (typeof docSettings.getDefaultDocument === 'function') {
      viewable = docSettings.getDefaultDocument(doc);
      if (selectable(viewable, inTrash)) {
        return viewable;
      } else {
        viewable = undefined;
      }
    }

    const children = $projectStore.childrenLookup[doc.id];

    if (children) {
      children.some(child => {
        if (selectable(child, inTrash)) {
          viewable = child;
          return true;
        }
        child = findViewableDoc(child, inTrash);
        if (child) {
          viewable = child;
          return true;
        }
      });
    }
    return viewable;
  }
</script>

{#if docId === 'settings'}
  <ProjectSettings />
{:else}
  <Workspace {loading} />
{/if}
