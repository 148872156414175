<script lang="ts">
  import { isApple, sizeStore } from '@dabble/data/device';
  import { routerStore } from '@dabble/data/navigation';
  import { projectMetaStore } from '@dabble/data/project-data';
  import { documentsStore } from '@dabble/data/ui';
  import { desktop } from '@dabble/desktop';
  import { Unsubscriber } from 'easy-signal';
  import { ComponentType, onDestroy, onMount, SvelteComponent, tick } from 'svelte';
  import ContextMenu from './ContextMenu.svelte';
  import InputContextMenu from './InputContextMenu.svelte';
  import { listen, unlisten } from './interactions';

  const isIOSChrome = navigator.userAgent.includes('CriOS');
  const isChrome = !isIOSChrome && !!(globalThis as any).chrome;
  const usePage = isChrome;
  const src = usePage ? '/blank.html' : undefined;

  export let component: ComponentType;
  export let autofocus = false;
  let className = '';
  export { className as class };

  let frame: HTMLIFrameElement;
  let win: Window;
  let doc: Document;
  let head: HTMLElement;
  let body: HTMLElement;
  let content: SvelteComponent;
  let routerListener: Unsubscriber;

  $: font = $projectMetaStore?.settings?.font || {};
  $: body &&
    (body.className =
      `${isApple ? 'isApple ' : ''}${desktop.inApp ? 'inDesktop ' : ''}` +
      ` size-${$sizeStore}` +
      ` font-${font.family || 'general'}`);
  $: body &&
    (font.size ? body.setAttribute('style', `--font-size: ${font.size / 12}rem`) : body.removeAttribute('style'));
  $: {
    if (content) {
      const { component, autofocus, ...props } = $$props;
      delete props.class;
      content.$set(props);
    }
  }

  function loadHandler() {
    win = frame.contentWindow;
    doc = frame.contentDocument;
    head = doc.head;
    body = doc.body;

    if (!usePage) {
      // set default body styles
      const styleElement = doc.createElement('style');
      styleElement.innerHTML = `html,body {
      background: none !important;
      margin: 0 !important;
      padding:0 !important;
      overflow: hidden !important;
      height: 100vh !important;
    }
    body { display: flex; flex-direction: column; }`;
      head.appendChild(styleElement);
    }

    const rules = Array.from(document.styleSheets)
      .map(sheet => Array.from(sheet.cssRules))
      .flat();
    const newSheet = head.appendChild(document.createElement('style')).sheet;
    rules.forEach(rule => newSheet.insertRule(rule.cssText, newSheet.cssRules.length));

    listen(doc);
    routerListener = routerStore.listen(win);

    if (component) {
      const { component, autofocus, className, ...props } = $$props;
      delete props.class;
      content = new component({
        target: body,
        immutable: true,
        props,
      });
    }

    if (autofocus) {
      win.focus();
    }

    documentsStore.add(doc);
  }

  function inputElement(event: Event) {
    if ((event.target as HTMLElement).nodeName === 'INPUT' || (event.target as HTMLElement).nodeName === 'TEXTAREA') {
      return event.target as HTMLElement;
    }
  }

  onMount(async () => {
    await tick();
    if (!frame) return; // unmounted already
    if (
      !usePage &&
      frame.contentDocument &&
      frame.contentDocument.readyState === 'complete' &&
      frame.contentDocument.defaultView
    ) {
      loadHandler();
    } else {
      frame.addEventListener('load', loadHandler);
    }
  });

  onDestroy(() => {
    if (frame) frame.removeEventListener('load', loadHandler);
    if (content) content.$destroy();
    if (routerListener) routerListener();
    if (doc) {
      unlisten(doc);
      documentsStore.remove(doc);
    }
  });
</script>

<iframe class={className} bind:this={frame} {src} title="frame" />

<ContextMenu target={body} targetFilter={inputElement} let:target>
  <InputContextMenu {target} />
</ContextMenu>

<style>
  iframe {
    border: none;
    width: 100%;
    height: 100%;
  }
</style>
