<script lang="ts">
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiClose } from '@mdi/js';
  import { cubicOut } from 'svelte/easing';
  import CustomModal from './CustomModal.svelte';

  export let nofocus = false;
  export let size = ''; // small, large
  export let title = '';
  export let noClose = false;
  let className = '';
  let modal: CustomModal;

  export { className as class };

  function close() {
    modal.close();
  }

  function settle(node: Node) {
    return {
      easing: cubicOut,
      delay: 150,
      duration: 150,
      css: (t: number, rt: number) => `transform: translate(0, -${rt * 25}px); opacity: ${t}`,
    };
  }
</script>

<CustomModal bind:this={modal} {size} class={className} on:close {nofocus}>
  <div class="modal-dialog" role="document" transition:settle|local>
    <div class="modal-content">
      {#if title}
        <header>
          {#if size === 'large'}
            <h2>{title}</h2>
          {:else if size === 'small'}
            <h4>{title}</h4>
          {:else}
            <h3>{title}</h3>
          {/if}
        </header>
      {/if}
      <slot>
        <section />
        <footer />
      </slot>
      {#if !noClose}
        <button class="icon display-icon-close" on:click={close}>
          <Icon path={mdiClose} />
        </button>
      {/if}
    </div>
  </div>
</CustomModal>

<style>
  .display-icon-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 30px;
  }
</style>
