/**
 * This is the old grid algorithm that creates a grid with the inputs of:
 * 1. plot children
 * 2. existing grid
 * It will create a new grid if none exists and will update an existing grid if the data doesn't align, trying to match
 * as close as possible to the existing grid.
 *
 * TODO the grid-updater will keep grids up-to-date with changes. Use this to ensure they are starting from an
 * up-to-date place when this patch first comes out, and whenever syncing happens to ensure everything synced correctly.
 */

import { projectStore } from '@dabble/data/project-data';
import ProjectPatch from '@dabble/data/project-patch';
import { Project } from '@dabble/data/types';
import { getAllOfType } from '@dabble/data/util';
import isEqual from 'lodash/isEqual';
import { Grid } from './grid-updater';

type DocCounts = { [docId: string]: number };
type DocLookup = { [docId: string]: true };

export function getGridFromPlot(project: Project, plotId: string): Grid {
  let max = 0;
  const children = project.docs[plotId].children.map(id => project.docs[id]).filter(Boolean);
  const link = Object.keys(project.links).find(key => key.startsWith(`${plotId}:plot:`));
  if (link) {
    const book = project.docs[link.split(':')[2]];
    children.unshift({
      id: book.id + '-scenes',
      type: 'novel_book_scenes',
      children: getAllOfType(book, project.docs, 'novel_scene'),
    });
  }

  const grid: Grid = children.map(child => {
    max = Math.max(max, child.children.length + 1);
    const id = child.type === 'novel_book_scenes' ? child.id.split('-')[0] : child.id;
    return [id].concat(child.children);
  });

  grid.forEach(column => {
    while (column.length < max) column.push(null);
  });

  return grid;
}

export function updateGrid(
  project: Project,
  oldGrid: Grid,
  newGrid: Grid,
  isFirstColumnMaster?: boolean,
  noop?: boolean
) {
  if (!oldGrid) return newGrid;
  let grid = oldGrid;

  // Update column list if needed. The old columns will be kept the same if not changed
  const colChange = oldGrid.length !== newGrid.length || !oldGrid.every((col, i) => col[0] === newGrid[i][0]);
  if (colChange) {
    grid = newGrid.map(col => oldGrid.find(oldCol => oldCol[0] === col[0]) || col);
  }

  // Remove deleted docs first, giving a chance for new items to fill their spot
  grid = removeDeletedPoints(grid, newGrid);
  grid = updateLinePoints(project, grid, newGrid, isFirstColumnMaster, noop);

  return grid;
}

export function removeDeletedPoints(grid: Grid, newGrid: Grid) {
  let gridChanged = false;
  const newDocs: DocCounts = {};
  newGrid.forEach(col => col.forEach(id => id && (newDocs[id] = (newDocs[id] || 0) + 1)));

  const updatedGrid = grid.map((col, i) => {
    // If this column is new it doesn't need processing
    if (col === newGrid[i]) return col;
    let colChanged = false;
    const updatedCol = col.map(id => (!id || newDocs[id]-- ? id : (colChanged = true) && null));
    if (colChanged) gridChanged = true;
    return colChanged ? updatedCol : col;
  });

  return gridChanged ? updatedGrid : grid;
}

/**
 * Adjust the existing grid with new or removed items from the new/derived grid.
 *
 * @param {Array} grid Existing grid with empty spots etc
 * @param {Array} newGrid A grid generated from the actual data without any empty spots except at the end as needed
 * @param {Boolean} isFirstColumnMaster Whether the first column represents a master column
 */
export function updateLinePoints(
  project: Project,
  grid: Grid,
  newGrid: Grid,
  isFirstColumnMaster?: boolean,
  noop?: boolean
) {
  if (isEqual(compact(grid), compact(newGrid))) {
    const max = grid.reduce((max, col) => Math.max(max, col.length), 0);
    if (!grid.every(col => col.length === max)) {
      grid = grid.map(col => {
        if (col.length < max) col = col.slice();
        while (col.length < max) col.push(null);
        return col;
      });
    }
    return grid;
  }

  // Every column will be changed if they are not equal, clone them now and update in-place later
  grid = grid.map(col => col.map(id => id || null));
  let max = 0;

  // Make all changes in one change, if there are any changes
  const patch = !noop && projectStore.patch();
  const { docs } = project;

  // Update each column in turn
  grid.forEach((col, x) => {
    const newCol = newGrid[x];
    const compactCol = col.filter(Boolean);
    const compactNewCol = newCol.filter(Boolean);
    // If this particular column is equal, move on
    if (isEqual(compactCol, compactNewCol)) return;

    // Whether this is the master column
    const isMaster = isFirstColumnMaster && x === 0;
    const oldDocs = compactCol.reduce(addIdsToLookup, {});
    const newDocs = compactNewCol.reduce(addIdsToLookup, {});

    // Go through each cell in the column, comparing it with the next cell in the derived column
    // y is the next derived column's index, i will be the existing column's index.
    let y = 1; // The first cell is the column id and can be ignored
    for (let i = 1; i < col.length && y < compactNewCol.length; i++) {
      const id = col[i]; // May be empty depending on gaps in the column
      const newId = compactNewCol[y]; // Will never be empty until the end of the column

      // They are either:
      // 1. no change (equal)
      // 2. new one added
      // 3. old one deleted
      // 4. moved position if master (you can't move plot points except in the grid, so it should already be updated)

      // If the two are equal, we don't need to do anything
      if (id === newId) {
        y++;
        continue;
      }

      // If this cell is empty, and the next id is new, add it here, otherwise jump over it
      if (!id) {
        if (newId && !oldDocs[newId]) {
          // If it is the master and some of the rows are not empty, add a row
          if (isMaster && grid.some(col => col[i])) addRowInPlace(grid, i);
          col[i] = newId;
          y++;
        }
        continue;
      }

      // If the id was deleted, empty the cell
      if (!newDocs[id]) {
        col[i] = null;
        continue;
      }

      // We should never get to the end of the new column and still have ids left because newDocs[id] will be falsey
      // if (!newId)...

      // If this is the master column, and if the id existed, it moved. Swap the whole row to the new place.
      // The children in the other columns will need to be moved to match.
      if (isMaster && oldDocs[newId]) {
        y++;
        // Swap the row into its new place, everything remaining lined up
        swapRowInPlace(grid, col.indexOf(newId), i);

        // Update the plot lines' children to match the new position
        grid.slice(1).forEach(col => {
          const pointId = col[i];
          if (!pointId) return;
          const parentId = col[0];
          const index = Math.min(col.slice(1, i).filter(Boolean).length, docs[parentId].children.length);
          if (docs[parentId].children.indexOf(pointId) !== index) {
            // patch.moveDoc(pointId, parentId, index);
          }
        });
      } else if (oldDocs[newId]) {
        // This just moved in a row-swap operation so it is in the correct place already
      } else {
        // This must be a new id so we need to make room for it
        if (i < 1000) {
          y++;
          addRowInPlace(grid, i);
          col[i] = newId;
          // if (i > 100) debugger;
        } else {
          throw new Error('Bug in grid re-creation causing an infinite loop');
        }
      }
    }

    for (; y < compactNewCol.length; y++) {
      col.push(compactNewCol[y]);
    }

    max = Math.max(max, col.length);
  });

  grid.forEach(col => {
    while (col.length < max) col.push(null);
  });

  if (!noop) {
    updateLinks(patch, grid, isFirstColumnMaster);
    patch.save();
  }

  return grid;
}

export function addRow(grid: Grid, i: number): Grid {
  return grid.map(col => [...col.slice(0, i), null, ...col.slice(i)]);
}

export function addColumn(grid: Grid): Grid {
  const col = grid.length ? grid[0].map(() => null) : [];
  return grid.concat([col]);
}

export function deleteRow(grid: Grid, i: number): Grid {
  return grid.map(col => [...col.slice(0, i), ...col.slice(i + 1)]);
}

export function addRowInPlace(grid: Grid, i: number) {
  grid.forEach(col => col.splice(i, 0, null));
}

export function swapRowInPlace(grid: Grid, from: number, to: number) {
  grid.forEach(col => {
    const cell = col.splice(from, 1)[0];
    col.splice(to, 0, cell);
  });
}

export function addIdsToLookup(lookup: DocLookup, id: string) {
  lookup[id] = true;
  return lookup;
}

export function compact(grid: Grid): Grid {
  return grid.map(col => col.filter(Boolean));
}

export function setGridValue(grid: Grid, x: number, y: number, value: string): Grid {
  const col = grid[x];
  if (grid[0].length <= y) {
    const pad = new Array(y - grid[0].length + 1).fill(null);
    grid = grid.map(col => col.concat(pad));
  }
  if (grid.length <= x) {
    const pad = new Array(x - grid.length + 1).fill(null).map(() => new Array(grid[0].length).fill(null));
    grid = grid.concat(pad);
  }
  // Make sure we aren't mutating the original value
  if (grid[x] === col) grid = grid.map((col, i) => (i === x ? [...col] : col));
  grid[x][y] = value;
  return grid;
}

export function getGridIndexesOf(grid: Grid, value: string): [number?, number?] {
  let result: [number?, number?];
  grid.some((col, x) => {
    return col.some((cell, y) => {
      if (cell === value) {
        result = [x, y];
        return true;
      }
    });
  });
  return result || [];
}

export function updateLinks(patch: ProjectPatch, grid: Grid, hasBook?: boolean) {
  if (!hasBook) return;
  grid.forEach((col, x) =>
    col.forEach((pointId, y) => {
      if (x === 0 || y === 0 || !pointId) return; // skip scenes, just work with points
      let links = projectStore.linksFrom(pointId, 'plot');
      const sceneId = grid[0][y];
      links = links.filter(link => {
        if (link.to.id !== sceneId) {
          patch.unlinkDocs(pointId, 'plot', link.to.id);
          return false;
        }
        return true;
      });
      if (sceneId && !links.length) {
        patch.linkDocs(pointId, 'plot', sceneId);
      }
    })
  );
}
