import { Focus } from '@dabble/data/stores/focus';
import { focusStore } from '@dabble/data/ui';
import { tick } from 'svelte';

const mutationOptions = {
  characterData: true,
  subtree: true,
  childList: true,
  attributes: true,
};

let ignoreNext = false;

export function ignoreNextAutoscroll() {
  ignoreNext = true;
}

/**
 * The autoscroll binder allows a writer to place the cursor anywhere they desire on the screen by scrolling up/down and
 * keeps it in that position by scrolling the page as the writer types. This allows the writer to keep the cursor where
 * it is most comfortable for them in the most intuitive way possible. No settings, options, buttons, or modes.
 */
export function autoscroll(node: Element, enabled = true) {
  const doc = node.ownerDocument;
  let lastCursorPosition: number = null;
  let observer: MutationObserver;
  let focusUnsubscribe: Function;
  let lastFocused: boolean;

  function updateScroll() {
    const position = getCursorPosition();
    const diff = position !== null && lastCursorPosition !== null ? lastCursorPosition - position : 0;
    if (Math.abs(diff) < 4) {
      // Not big enough to warrent a scroll
      lastCursorPosition = position;
      return;
    }
    const scroller = node.querySelector('.focus-scroll') || node;
    scroller.scrollTop -= diff;
    focusStore.skipNextStop();
    storeCursorPosition();
  }

  function onMutate() {
    if (ignoreNext) {
      storeCursorPosition();
      return (ignoreNext = false);
    }
    if (!doc.defaultView) return;
    const selection = doc.defaultView.getSelection();
    let element = selection && (selection.anchorNode as Element);
    if (!element) return;
    const isCollapsed = element === selection.focusNode && selection.anchorOffset === selection.focusOffset;
    if (!isCollapsed) return;
    if (element.nodeType !== Node.ELEMENT_NODE) element = element.parentNode as Element;
    if (!node.contains(element) || !element.closest('.page')) return;
    updateScroll();
  }

  function storeCursorPosition() {
    lastCursorPosition = getCursorPosition();
  }

  function getCursorPosition() {
    if (!doc.defaultView) return;
    const selection = doc.defaultView.getSelection();
    const range = selection.rangeCount ? selection.getRangeAt(0).cloneRange() : null;
    const end = range && range.endContainer;
    if (!end || !node.contains(end)) return null;
    const element = (end.nodeType === Node.ELEMENT_NODE ? end : end.parentNode) as Element;
    if (!element.closest('[contenteditable="true"]') || !element.closest('.page')) return null;
    if (end && end.nodeType === Node.ELEMENT_NODE && end.childNodes.length > range.endOffset) {
      range.setEnd(range.endContainer, range.endOffset + 1);
    }
    const rect = range.getClientRects()[0];
    if (!rect) return null;
    return Math.round(rect.top);
  }

  async function onFocusChange({ focused, autofocused }: Focus) {
    const isFocused = focused || autofocused;
    if (lastFocused === undefined) lastFocused = isFocused;
    if (lastFocused !== isFocused) {
      lastFocused = isFocused;
      await tick();
      storeCursorPosition();
    }
  }

  function start() {
    if (!observer) observer = new MutationObserver(onMutate);
    observer.observe(node, mutationOptions);
    doc.addEventListener('selectionchange', storeCursorPosition);
    node.addEventListener('scroll', storeCursorPosition);
    focusUnsubscribe = focusStore.subscribe(onFocusChange);
  }

  function stop() {
    if (observer) observer.disconnect();
    doc.removeEventListener('selectionchange', storeCursorPosition);
    node.removeEventListener('scroll', storeCursorPosition);
    if (focusUnsubscribe) focusUnsubscribe();
    lastFocused = undefined;
  }

  enabled ? start() : stop();

  return {
    update(enabled: boolean) {
      enabled ? start() : stop();
    },
    destroy() {
      stop();
    },
  };
}
