import { Locale } from 'date-fns';
import { enUS, es } from 'date-fns/locale';
import { readable, writable } from 'easy-signal';
import i18next, { TFunction } from 'i18next';
import lngDetector from 'i18next-browser-languagedetector';

const supportedDateLocales: { [language: string]: Locale } = {
  'en-US': enUS,
  'es-ES': es,
};

i18next.use(lngDetector).init({
  fallbackLng: 'en',
  joinArrays: '\n',
});

i18next.on('languageChanged', lng => {
  const locale = getDateLocale(lng);

  dateOptions = { locale };
  localesStore.set(Object.keys(i18next.services.resourceStore.data));
});

function getDateLocale(lng: string): Locale {
  return supportedDateLocales[lng] || (lng && supportedDateLocales[lng.split('-')[0]]);
}

export let dateOptions = { locale: getDateLocale(i18next.language) };

export const localesStore = writable<string[]>(Object.keys(i18next.services.resourceStore.data));

export const localeStore = (function () {
  const { get, set, subscribe } = writable<string>(i18next.language);
  i18next.on('languageChanged', set);

  return {
    get,
    set: promisifyCallback<string, TFunction>(i18next.changeLanguage, i18next),
    subscribe,
  };
})();

export const t = readable<TFunction<['translation', ...string[]], undefined>>(i18next.t.bind(i18next), set => {
  i18next.on('languageChanged', () => set(i18next.t.bind(i18next)));
});

export let $t = t.get();
t.subscribe(value => ($t = value));

export const escapedT = function (str: string, params: any) {
  if (typeof params !== 'string') params.interpolation = { escapeValue: true };
  return i18next.t(str, params);
} as TFunction;

export function promisifyCallback<T, R>(fn: Function, thisArg: any): (value: T) => Promise<R> {
  return function (...args: any[]) {
    return new Promise((resolve, reject) => {
      args.push((err: Error | null, result: any) => (err ? reject(err) : resolve(result)));
      fn.apply(thisArg, args);
    });
  };
}
