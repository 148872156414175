<script lang="ts">
    import { featuresStore } from '@dabble/data/global-data';
    import { t } from '@dabble/data/intl';
    import { projectMetaSettingsStore } from '@dabble/data/project-data';
    import { ProjectMetaSettings } from '@dabble/data/types';
    import Icon from '@dabble/toolkit/Icon.svelte';
    import { mdiSpellcheck } from '@mdi/js';

  function toggle(field: keyof ProjectMetaSettings) {
    if (!$featuresStore.spell_check && field === 'spellingCheck') {
      featuresStore.promptUpgrade('spell_check');
    } else if (!$featuresStore.grammar && field === 'grammarCheck') {
      featuresStore.promptUpgrade('grammar');
    } else {
      if ($projectMetaSettingsStore) {
        const value =
          field === 'spellingCheck' && $projectMetaSettingsStore.spellingCheck === undefined
            ? false
            : !$projectMetaSettingsStore?.[field];
        projectMetaSettingsStore.update({ [field]: value });
      }
    }
  }
</script>

<button
  class="dropdown-item"
  class:checked={$featuresStore.spell_check &&
    ($projectMetaSettingsStore.spellingCheck || $projectMetaSettingsStore.spellingCheck === undefined)}
  on:click|stopPropagation={() => toggle('spellingCheck')}
>
  <Icon path={mdiSpellcheck} />
  {$t('preferences_spellcheck_label')}
</button>
<button
  class="dropdown-item"
  class:checked={$featuresStore.grammar && $projectMetaSettingsStore.grammarCheck}
  on:click|stopPropagation={() => toggle('grammarCheck')}
>
  <Icon path={mdiSpellcheck} />
  {$t('preferences_grammarcheck_label')}
</button>
