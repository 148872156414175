<script lang="ts">
  import { isApple } from '@dabble/data/device';
  import { createEventDispatcher, onDestroy, onMount } from 'svelte';
  import { ShortcutEvent } from 'typewriter-editor';
  import interactions, { events } from './interactions';

  const metaKey = isApple ? 'Cmd' : 'Ctrl';

  const dispatch = createEventDispatcher();

  function onShortcut(event: ShortcutEvent) {
    dispatch(`shortcut-${event.shortcut}`, event);
    if (~event.shortcut.indexOf(metaKey)) {
      dispatch(`shortcut-${event.shortcut.replace(metaKey, 'Meta')}`, event);
    }
    dispatch('shortcut', event);
  }

  function onEvent(event: Event) {
    dispatch(event.type, event);
  }

  onMount(() => {
    interactions.on('shortcut', onShortcut);
    events.forEach(event => interactions.on(event, onEvent));
  });

  onDestroy(() => {
    interactions.off('shortcut', onShortcut);
    events.forEach(event => interactions.off(event, onEvent));
  });
</script>
