import { format, parseISO, startOfDay, startOfMinute } from 'date-fns';
import { derived, readable } from 'easy-signal';

export const minuteStore = readable(getMinute(), set => {
  const interval = setInterval(() => {
    set(getMinute());
  }, 1000);

  set(getMinute());
  return () => clearInterval(interval);
});

export const todayStore = derived(() => startOfDay(minuteStore.get()));
export const todaysDateStore = derived(() => getDateString(todayStore.get()));

export function getMonthString(date: Date = getDate()) {
  return date && format(date, 'yyyy-MM');
}

export function getDateString(date: Date = getDate()) {
  return date && format(date, 'yyyy-MM-dd');
}

export function parseDateString(date: string): Date {
  return date && parseISO(date);
}

export function getDate() {
  return new Date(getNow());
}

export function getMinute() {
  return startOfMinute(getDate());
}

export function getNow() {
  return Date.now() + (parseInt(localStorage.timeOffset) || 0);
}
