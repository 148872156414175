import App from '@dabble/components/App.svelte';
// import '@dabble/data/data-migrations';
import * as data from '@dabble/data/admin';
import '@dabble/data/date';
import { delegate } from '@dabble/data/delegate';
import '@dabble/data/device';
import '@dabble/data/intl';
import { plugins } from '@dabble/data/plugins';
import '@dabble/error-reporting';
import '@dabble/errors';
import '@dabble/locales';
import '@dabble/offline';
import '@dabble/plugins';
import '@dabble/startup';
import '@dabble/styles';
import '@dabble/util/extensions';
import log from '@dabble/util/log';
import { isProduction, version } from '@dabble/version';

const ui = new App({ target: document.body });

declare global {
  var data: any;
  var ui: App;
}

setTimeout(() => {
  log.tagColor('Load', '#444', 'Dabble Web v' + version);
}, 500);

if (delegate || !isProduction) {
  setupDebugging();
} else {
  data.isAdminStore.subscribe(isAdmin => {
    if (isAdmin && !globalThis.data) {
      setupDebugging();
    }
  });
}

function setupDebugging() {
  globalThis.data = { ...data, ...plugins.stores };
  Object.keys(globalThis.data).forEach(key => {
    const store = globalThis.data[key];
    if (store && typeof store.subscribe === 'function') {
      if (key[0] !== '$') {
        store.subscribe(
          (value: any) => (globalThis.data[key.endsWith('Store') ? key.replace(/Store$/, '') : `$${key}`] = value)
        );
      }
    }
  });
}

if (process.env.NODE_ENV !== 'production') {
  new EventSource('/esbuild').addEventListener('change', e => {
    const { added, removed, updated } = JSON.parse(e.data);

    if (!added.length && !removed.length && updated.length === 1) {
      for (const link of document.getElementsByTagName('link')) {
        const url = new URL(link.href);

        if (url.host === location.host && url.pathname === updated[0]) {
          const next = link.cloneNode() as HTMLLinkElement;
          next.href = updated[0] + '?' + Math.random().toString(36).slice(2);
          next.onload = () => link.remove();
          link.parentNode.insertBefore(next, link.nextSibling);
          return;
        }
      }
    }

    location.reload();
  });
}
