<script lang="ts">
  import { isTouchStore } from '@dabble/data/ui';
  import waf from '@dabble/util/waf';
  import { VirtualElement } from '@popperjs/core';
  import { onDestroy } from 'svelte';
  import Dropdown from './Dropdown.svelte';
  import { frameOffsets } from './popper-frames';

  export let target: Element | Document | HTMLElement;
  export let targetFilter: (event: Event) => HTMLElement | undefined = null;
  export let activeClass = '';
  export let isOpen = false;
  type Point = { x: number; y: number };
  let dropdown;
  let oldTarget: Element | Document = null;
  let realTarget: Element = null;
  let dropdownTarget: Element | VirtualElement = null;
  let classAdded = '';
  let lastClick: Point;
  let lastClickTimeout: ReturnType<typeof setTimeout>;

  $: updateTarget(target);
  $: isOpen = !!dropdownTarget;

  function updateTarget(target: Element | Document) {
    if (oldTarget) {
      oldTarget.removeEventListener('contextmenu', onContextMenu);
    }
    if (target) {
      target.addEventListener('contextmenu', onContextMenu);
    }
    oldTarget = target;
  }

  async function onContextMenu(event: MouseEvent) {
    if (target instanceof Document && !targetFilter) {
      throw new Error('Context menu must have a target filter when target is document');
    }
    realTarget = targetFilter ? targetFilter(event) : (target as HTMLElement);
    if (!realTarget || $isTouchStore || (event as any).menuHandled) return;
    event.preventDefault();
    (event as any).menuHandled = true;
    if (activeClass && !realTarget.classList.contains(activeClass)) {
      realTarget.classList.add((classAdded = activeClass));
    }
    // If the click happens again in the same place (roughly) the toggle closed
    const click = { x: event.pageX, y: event.pageY };
    if (lastClick && Math.abs(lastClick.x - click.x) < 10 && Math.abs(lastClick.y - click.y) < 10) {
      onClose();
    } else {
      await waf();
      dropdownTarget = getDropdownTarget(event);
      lastClick = click;
      clearTimeout(lastClickTimeout);
    }
  }

  function onClose() {
    if (classAdded) {
      realTarget.classList.remove(classAdded);
      classAdded = '';
    }
    dropdownTarget = realTarget = null;
    lastClickTimeout = setTimeout(() => (lastClick = null), 100);
  }

  function getDropdownTarget(event: MouseEvent) {
    let left = event.pageX;
    let top = event.pageY;
    const { x, y } = frameOffsets(event.target as Element);
    left += x;
    top += y;

    return {
      getBoundingClientRect() {
        return new DOMRect(left, top, 0, 0);
      },
    };
  }

  onDestroy(() => updateTarget(null));
</script>

{#if dropdownTarget}
  <Dropdown bind:this={dropdown} target={dropdownTarget} on:close={onClose} noFocus>
    <slot target={realTarget} />
  </Dropdown>
{/if}
