<script lang="ts">
  import { t } from '@dabble/data/intl';
  import Interactions from '@dabble/toolkit/Interactions.svelte';
  import { createEventDispatcher, onMount } from 'svelte';

  const dispatch = createEventDispatcher();
  let mounted: boolean;
  let node: HTMLElement;

  function confirmed() {
    dispatch('confirmed');
  }

  function close() {
    const thread: HTMLElement = node && node.closest('.thread.selected');
    if (thread) thread.focus();
    dispatch('close');
  }

  function isButton(node: HTMLElement) {
    return node.matches && node.matches('.confirm-delete .btn');
  }

  onMount(() => setTimeout(() => (mounted = true)));
</script>

<div class="confirm-delete" on:focusout|stopPropagation bind:this={node}>
  <div class="background" />
  <div class="foreground">
    <div class="message">
      <slot />
    </div>
    <div class="buttons">
      <button class="btn primary small" on:click={confirmed}>{$t('delete')}</button>
      <button class="btn secondary small" on:click={close}>{$t('cancel')}</button>
    </div>
  </div>
</div>

<Interactions on:shortcut-Escape={close} on:click={event => mounted && !isButton(event.detail.target) && close()} />

<style>
  .confirm-delete {
    position: absolute;
    z-index: 2;
    left: 2px;
    right: 2px;
    top: 2px;
    bottom: 2px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: var(--comment-padding) 4px;
    font-weight: bold;
    font-size: var(--font-size-sm);
    color: var(--text-color-lighter);
    text-shadow: var(--text-highlight);
  }
  :global(.comment-entry) .confirm-delete {
    left: 0;
    right: 0;
  }
  .confirm-delete .background {
    content: '';
    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 3px;
    background: var(--background);
  }
  .confirm-delete .foreground {
    position: relative;
    z-index: 1;
  }
  .message {
    margin-bottom: 8px;
  }
</style>
