{
  "comments": "Comments",
  "comments_accept": "Mark as resolved",
  "comments_comment": "Comment",
  "comments_comment_placeholder": "Comment",
  "comments_delete_reply": "Delete this comment?",
  "comments_delete_thread": "Delete this thread?",
  "comments_edit_comment": "Update",
  "comments_edit_reply": "Update",
  "comments_edit_comment_placeholder": "Edit your comment",
  "comments_edit_reply_placeholder": "Edit your reply",
  "comments_hide": "Hide Comments",
  "comments_history": "Comment History",
  "comments_history_open": "Comment History",
  "comments_none": "There are no comments yet",
  "comments_open": "Open Comments",
  "comments_reopen": "Re-open",
  "comments_reply": "Reply",
  "comments_reply_placeholder": "Reply",
  "comments_resolved": "Resolved Comments",
  "comments_show": "Show Comments",
  "comments_show_all_replies": "Show all {{count}} replies"
}
