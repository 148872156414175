{
  "export_count": "¿Cuántos?",
  "export_failed": "$t(export_file_{{fileType}}) falló.",
  "export_file_word": "Word",
  "export_file_html": "Web",
  "export_file_text": "Texto",
  "export_file_google": "Google Docs",
  "export_format_manuscript": "Formato manuscrito",
  "export_format_dabble": "Formateo de Dabble",
  "export_images": "¿Exportar imágenes?",
  "export_more": "Exportar a",
  "export_none": "No hay opciones de exportación disponibles para este tipo de documento.",
  "export_options": "Opción",
  "export_options_limit_none": "Exportar todo el libro",
  "export_options_limit_chapters": "Exportar algunos capítulos",
  "export_options_limit_pages": "Exportar algunas páginas",
  "export_pages_info": "La exportación de páginas puede dar lugar a más páginas de las solicitadas para evitar romper a mitad de la escena.",
  "export_short_name_label": "Título corto",
  "export_started": "$t(export_file_{{fileType}}) comenzó.",
  "export_what": "¿Qué?",
  "export_settings": "Configuración de exportación",
  "export_telephone": "Su número de teléfono no se venderá ni se compartirá excepto en las exportaciones de manuscritos",
  "export_scene_separator": "Separador de escenas",
  "export_short_title": "Título corto (aparece en el encabezado de cada página)",
  "export_address": "Dirección",
  "export_phone": "Teléfono",
  "shared_no_email": "No se proporciona ninguna dirección de correo electrónico."
}
