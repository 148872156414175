import { Readable, derived } from 'easy-signal';
import { Doc } from '../types';
import { ProjectStore } from './project';

export type DocStore = ReturnType<typeof createDocStore>;

/**
 * Creates a store that will contain the current doc or null so others can be notified when it exists. The current doc
 * is derived from the docId.
 */
export function createDocStore(projectStore: ProjectStore, docId: Readable<string>) {
  return derived<Doc>(() => {
    const id = docId.get();
    const docs = projectStore.get().docs;
    if (!docs.project || !id) return null;
    if (id === docs.project.id) return docs.project;
    return docs[id] || null;
  });
}
