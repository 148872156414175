import { Readable, writable } from 'easy-signal';

// Adds/removes readonly locks. When no locks are present, the app is not readonly.
export interface ReadOnlyStore extends Readable<boolean> {
  addLock(name: string): void;
  removeLock(name: string): void;
  toggleLock(name: string, value: boolean): void;
}

export function createReadOnlyStore(): ReadOnlyStore {
  const locks = new Set<string>();
  const { get, set, subscribe } = writable(false);

  function addLock(name: string) {
    locks.add(name);
    set(true);
  }

  function removeLock(name: string) {
    locks.delete(name);
    if (!locks.size) set(false);
  }

  function toggleLock(name: string, value: boolean) {
    value ? addLock(name) : removeLock(name);
  }

  return {
    addLock,
    removeLock,
    toggleLock,
    get,
    subscribe,
  };
}
