import { agreeable } from './agreeable';
import { createAgreeablePrefixedStore } from './agreeable/agreeable-stores';
import { uidStore } from './ids';
import { createUserDailyStats } from './stores/stats';

export const userStore = agreeable.users(uidStore);
export const userAccountStore = userStore.account;
export const userDataStore = userStore.data;
export const preferencesStore = createAgreeablePrefixedStore(userDataStore, 'preferences');
export const dailyGoalStore = createAgreeablePrefixedStore(userDataStore, 'dailyGoal');
export const daysOffStore = createAgreeablePrefixedStore(userDataStore, 'daysOff');

export const userStatsStore = createUserDailyStats(userStore);

export const userProjectsStore = userStore.projects;

// Sync projects we have access to
const syncedProjectIds = new Set<string>();
userProjectsStore.subscribe(projects => {
  const removedProjects = new Set(syncedProjectIds);
  const ids = Object.keys(projects);
  ids.forEach(id => {
    if (!syncedProjectIds.has(id)) {
      syncedProjectIds.add(id);
      agreeable.projects(id).sync();
      agreeable.projects(id).roles.sync();
      agreeable.projects(id).doc.sync();
    }
    removedProjects.delete(id);
  });
  removedProjects.forEach(id => {
    syncedProjectIds.delete(id);
    agreeable.projects(id).doc.unsync();
    agreeable.projects(id).roles.unsync();
    agreeable.projects(id).unsync();
  });
});
