<script lang="ts">
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiFormatColorHighlight, mdiFormatStrikethrough } from '@mdi/js';
  import { Commands } from 'typewriter-editor';

  export let section: string;
  export let commands: Commands;
  export let active: Commands;
  // leave these in even though they aren't used to keep warnings from screen.
  export let editor;
  export let placement;
  export let selection;
</script>

{#if commands.strike}
  <button class="editor-menu-strike" class:active={active.strike} on:click={() => commands.strike()}>
    <Icon path={mdiFormatStrikethrough} />
  </button>
{/if}
{#if commands.highlight}
  <button class="editor-menu-highlight" class:active={active.highlight} on:click={() => (section = 'highlight')}>
    <Icon path={mdiFormatColorHighlight} />
  </button>
{/if}

<style>
  .editor-menu-strike {
    order: 70;
  }
  .editor-menu-highlight {
    order: 80;
  }
</style>
