import { Onboarding } from '@dabble/data/agreeable/agreeable-config';
import { createAgreeablePrefixedStore } from '@dabble/data/agreeable/agreeable-stores';
import { signals } from '@dabble/data/app-state';
import { todayStore } from '@dabble/data/date';
import { plugins } from '@dabble/data/plugins';
import { userDataStore } from '@dabble/data/user-data';
import { derived, writable } from 'easy-signal';
import { currentUserStore } from '../account/accounts';

const TWO_WEEKS = 1000 * 60 * 60 * 24 * 14;

export type ChecklistItem = {
  name: string;
  text: string;
  help: string;
  steps: number;
};

export const onboardingStore = createAgreeablePrefixedStore(userDataStore, 'onboarding');

export const showOnboardingLearnMoreStore = writable(false);

export const onboardingAvailableStore = derived(
  () =>
    currentUserStore.get()?.createdAt > todayStore.get().getTime() - TWO_WEEKS &&
    onboardingStore.get().completed !== Math.pow(2, items.length) - 1 // all items completed
);

plugins.register({ onboardingStore, onboardingAvailableStore, showOnboardingLearnMoreStore });

export const showHelpStore = writable<ChecklistItem>(null);

export function isItemComplete(onboarding: Onboarding, index: number) {
  return ((onboarding.completed || 0) & (1 << index)) !== 0;
}

export async function markItemComplete(index: number) {
  const completed = onboardingStore.get().completed || 0;
  await onboardingStore.update(patch => {
    patch.bit('/completed', index, true);
    if (completed === Math.pow(2, items.length) - 1) {
      patch.replace('/completed', completed);
    }
  });
}

/*
  Specific to Novel Projects
  Could remove to settings at some point
*/
export const items: ChecklistItem[] = [
  {
    name: 'createProject',
    text: 'onboarding_create_project',
    help: 'onboarding_help_create_project',
    steps: 2,
  },
  {
    name: 'createScene',
    text: 'onboarding_create_scene',
    help: 'onboarding_help_create_scene',
    steps: 5,
  },
  {
    name: 'writeNote',
    text: 'onboarding_write_note',
    help: 'onboarding_help_write_note',
    steps: 5,
  },
  {
    name: 'createPlotLine',
    text: 'onboarding_create_plot_line',
    help: 'onboarding_help_create_plot_line',
    steps: 4,
  },
  {
    name: 'write1000',
    text: 'onboarding_write_1000',
    help: 'onboarding_help_write_1000',
    steps: 3,
  },
];

signals.onFirstLoad(() => {
  showOnboardingLearnMoreStore.set(true);
});

signals.onProjectCreated(() => {
  if (!onboardingAvailableStore.get()) return;
  markItemComplete(0);
});

signals.onDocCreated(({ type }) => {
  if (!onboardingAvailableStore.get()) return;
  if (type === 'novel_scene') {
    markItemComplete(1);
  } else if (type === 'novel_page') {
    markItemComplete(2);
  } else if (type === 'novel_plot_line') {
    markItemComplete(3);
  }
});

// // TODO: Once we refactor the goals plugin, we can come back and update this section.
// window.addEventListener('reached-reward-goal', () => {
//   if (plugins.stores.writeGoal.get().readme === 'open-write-1000-for-20') {
//     markItemComplete(4);
//   }
// });

// // TODO: Ensure the reward is being applied in billing, rest, server, or somewhere. This should be updated by that
// // process
// window.addEventListener('reward-applied', () => {
//   if (plugins.stores.writeGoal.get().readme === 'open-write-1000-for-20') {
//     onboardingStore.update({
//       rewarded: true,
//     });
//   }
// });
/* End Novel Specific Setup */
