{
  "grammar_dictionary_add": "Agregar al Diccionario",
  "grammar_dismiss": "Descartar",
  "preferences_grammarcheck_label": "Verificación de Estilo y Gramática",
  "preferences_grammarcheck_description": "Detectar errores gramaticales y estilísticos. Para que sus lectores no tengan que hacerlo.",
  "preferences_select_language": "Selecciona un idioma…",
  "preferences_select_voice": "Seleccione una voz…",
  "preferences_spellcheck_label": "Corrección Ortográfica",
  "preferences_spellcheck_description": "Proporcione sugerencias de ortografía para las palabras mal escritas. Está bien, nadie es perfecto.",
  "preferences_spellcheck_poweredby": "La revisión ortográfica y de estilo/gramática está impulsada por la increíble <a href=\"https://prowritingaid.com?afid=6159\" target=\"_blank\"><strong>ProWritingAid</strong></a>.",
  "preferences_spelling_header": "Ortografía y Gramática",
  "preferences_spelling_language_header": "Idioma",
  "preferences_spelling_voice_header": "Voz",
  "read_to_me": "Leerme",
  "thesaurus_open": "Herramientas de Palabras",
  "thesaurus": "Tesauro",
  "thesaurus_search": "Buscar en el Diccionario de Sinónimos",
  "lookup_placeholder": "Ingrese la Palabra para Buscar",
  "no_results": "No se han encontrado resultados...",
  "display_all_entries": "Buscar Todo",
  "display_contextual_entries": "Búsqueda Contextual",
  "in_context": "(En Contexto)",
  "part_of_speech_noun": "Sustantivo",
  "part_of_speech_pronoun": "Pronombre",
  "part_of_speech_verb": "Verbo",
  "part_of_speech_adverb": "Adverbio",
  "part_of_speech_adjective": "Adjetivo",
  "part_of_speech_preposition": "Preposición",
  "part_of_speech_conjunction": "Conjunción",
  "part_of_speech_original_word": "Palabra Original",
  "selected_grammar_language_not_supported": "En este momento, el tesauro no admite idiomas distintos del inglés.",
  "account_screen_dictionary": "Mantenimiento de Diccionarios",
  "delete_dictionary_entries": "Eliminar sus Entradas de Diccionario",
  "delete_all_dictionary_entries": "Eliminar Todo",
  "grammar_ext_details": "Tiene una extensión de gramática instalada y la utilidad incorporada de Dabble habilitada.Para el mejor rendimiento, recomendamos ejecutar solo uno a la vez.\n\n¿Te gustaría deshabilitar la función incorporada de Dabble?",
  "grammar_ext": "Extensión de gramática instalada",
  "voice_unavailable": "Léame las voces no disponibles."
}
