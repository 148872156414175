<script lang="ts">
  import { t } from '@dabble/data/intl';
  import { projectStore } from '@dabble/data/project-data';
  import { Doc } from '@dabble/data/types';
  import Page from '@dabble/toolkit/Page.svelte';
  import { getCachedAutoNumber, getHasTitles } from '../model/manuscript';
  import TableOfContentsEntry from './TableOfContentsEntry.svelte';

  export let doc: Doc;

  function getDisplayTitle(displayDoc: Doc) {
    const number = getCachedAutoNumber(displayDoc);
    if (displayDoc.title && doc.hideChapterHeadings) {
      return displayDoc.title;
    } else if (displayDoc.type === 'novel_part') {
      return displayDoc.title ? displayDoc.title : $t('novel_part_number', { number });
    } else if (displayDoc.type === 'novel_chapter') {
      return displayDoc.title ? displayDoc.title : $t('novel_chapter_number', { number });
    } else if (displayDoc.type === 'novel_prologue') {
      return displayDoc.title;
    }
  }

  function displayNumbers(numberDoc: Doc) {
    if (getHasTitles(numberDoc) && !doc.hideChapterHeadings) {
      return true;
    }
    return;
  }
</script>

<Page {doc} class="toc-view">
  <div class="toc-header">
    <h2>{$t('novel_book_toc')}</h2>
  </div>
  {#each $projectStore.childrenLookup[doc.id] as childDoc}
    {#if childDoc.type === 'novel_part'}
      <TableOfContentsEntry
        doc={childDoc}
        displayNumbers={displayNumbers(childDoc)}
        number={getCachedAutoNumber(childDoc)}
        displayTitle={getDisplayTitle(childDoc)}
        class="toc-level-1"
      />
      {#if childDoc.children}
        {#each $projectStore.childrenLookup[childDoc.id] as chapterDoc}
          <TableOfContentsEntry
            doc={chapterDoc}
            displayNumbers={displayNumbers(chapterDoc)}
            number={getCachedAutoNumber(chapterDoc)}
            displayTitle={getDisplayTitle(chapterDoc)}
            class="toc-level-2"
          />
        {/each}
      {/if}
    {:else}
      <TableOfContentsEntry
        doc={childDoc}
        displayNumbers={displayNumbers(childDoc)}
        number={getCachedAutoNumber(childDoc)}
        displayTitle={getDisplayTitle(childDoc)}
        class="toc-level-1"
      />
    {/if}
  {/each}
</Page>

<style>
  .toc-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
</style>
