import { subDays } from 'date-fns';
import { Readable, derived } from 'easy-signal';
import { DailyStats, HourlyStats, ProjectMetaStore, ProjectStats, UserStore } from '../agreeable/agreeable-config';
import { getDateString, getMonthString, todayStore } from '../date';
import { ProjectStore } from './project';

/**
 * Provides the last 30 days of word counts for the user. The counts are aggregated by day and will be undefined for
 * days with no data. The counts are in the user's local timezone. They do not include unsaved changes.
 */
export function createUserDailyStats(userStore: UserStore) {
  const userMonthlyStats = getStatsForMonths(userStore);

  const { get, subscribe } = derived(() => {
    const stats: DailyStats = {};

    // Sum up the hourly stats into daily stats
    const firstDay = getDateString(subDays(todayStore.get(), 30));
    for (const [hour, count] of Object.entries(userMonthlyStats.get())) {
      if (hour < firstDay) continue;
      const day = hour.slice(0, 10);
      stats[day] = (stats[day] || 0) + count;
    }

    return stats;
  });

  function getForDay(day: string) {
    return get()[day] || 0;
  }

  return { getForDay, get, subscribe };
}

/**
 * Provides the last 30 days of word counts for the project. The counts are aggregated by day and will be undefined for
 * days with no data. The counts are in the user's local timezone. They do not include unsaved changes.
 * $stats[day]?.[docId] || 0
 */
export function createProjectDailyStats(projectMetaStore: ProjectMetaStore, projectStore: ProjectStore) {
  const projectMonthlyStats = getStatsForMonths(projectMetaStore);
  const countsStore = derived(() => projectStore.get().counts);

  const { get, subscribe } = derived(() => {
    const stats: ProjectStats = {};

    // Sum up the hourly stats into daily stats, but project stats are in UTC, so translate for the current timezone
    const firstDay = getDateString(subDays(todayStore.get(), 30));
    const today = getDateString(todayStore.get());
    const monthlyStats = projectMonthlyStats.get();
    const counts = countsStore.get();

    // Sum up the hourly stats into daily stats
    for (const [hour, docCounts] of Object.entries(monthlyStats)) {
      const day = getDateString(new Date(`${hour.slice(0, 13)}:00:00Z`));
      if (day < firstDay) continue;
      for (const [docId, count] of Object.entries(docCounts)) {
        if (!stats[day]) stats[day] = {};
        stats[day][docId] = (stats[day][docId] || 0) + count;
      }
    }

    // Add in the queued word count
    for (const [docId, docCounts] of Object.entries(counts)) {
      if (!docCounts.queuedCount) continue;
      if (!stats[today]) stats[today] = {};
      stats[today][docId] = (stats[today][docId] || 0) + docCounts.queuedCount;
    }

    return stats;
  });

  function getForDay(day: string, docId: string) {
    return get()[day]?.[docId] || 0;
  }

  return { getForDay, get, subscribe };
}

type Month<T> = T extends UserStore ? HourlyStats : T extends ProjectMetaStore ? ProjectStats : never;

/**
 * Takes a user or project doc and returns the last 1-2 months of stats in one object covering 30 days. This method is
 * a little bit convoluted because it first must listen to the user or project doc being loaded, then it must listen to
 * the stats docs for 1 or 2 months. And it needs to unsubscribe from any of these when no longer needed.
 */
function getStatsForMonths<T extends UserStore | ProjectMetaStore>(doc: T): Readable<Month<T>> {
  return derived<Month<T>>(() => {
    let stats = {} as Month<T>;
    if (!doc.get()) {
      return stats;
    }

    const month = doc.stats(getMonthString(todayStore.get()));
    const prior = doc.stats(getMonthString(subDays(todayStore.get(), 30)));

    if (prior !== month) {
      prior.load();
      stats = { ...(prior.get() as Month<T>) };
    }
    month.load();
    stats = { ...(month.get() as Month<T>) };

    return stats;
  });
}
