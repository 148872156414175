import { ExtendAPI, Leader } from 'agreeable-client';
import { DabbleDatabase } from './old-types';
import { DabbleAgreeableClient } from './types';

/**
 * Leader extension for app-wide actions like deleting the databases
 */
export function appLeaderExtension(
  client: DabbleAgreeableClient,
  db: DabbleDatabase,
  extendApi: ExtendAPI,
  leader: Leader
) {
  extendApi({
    deleteDB,
  });

  // Deletes the agreeable and the dabble databases and clears all the caches
  async function deleteDB() {
    await Promise.all([leader.deleteDatabase(), db.deleteDatabase(), caches.delete('content'), caches.delete('app')]);
  }
}
