<script lang="ts">
  import { featuresStore } from '@dabble/data/global-data';
  import { tick } from 'svelte';
  import { showStickyNotes, StickyNote, StickyNoteMap, stickyNotesStore } from '../stickies-store';
  import Sticky from './Sticky.svelte';

  export let pageElement;
  let notes: StickyNote[] = [];

  $: showNotes = $featuresStore.stickies && $showStickyNotes;
  $: showNotes && findNotes(pageElement, $stickyNotesStore);
  $: if (!showNotes) notes = [];

  async function findNotes(pageElement: HTMLElement, allNotes: StickyNoteMap | ArrayLike<StickyNote>): Promise<undefined> {
    await tick();
    if (!pageElement) return;
    const docIds = new Set();
    Array.from(pageElement.querySelectorAll('[data-id][data-field="body"]')).forEach((node: HTMLElement) =>
      docIds.add(node.dataset.id)
    );
    notes = Object.values(allNotes) as StickyNote[];
    notes = notes.filter((sticky: StickyNote) => docIds.has(sticky.docId));
  }
</script>

{#if showNotes && notes.length}
  <div class="stickies">
    {#each notes as note (note.id)}
      <Sticky {pageElement} {note} />
    {/each}
  </div>
{/if}

<style>
  .stickies {
    position: relative;
    z-index: 1;
  }
</style>
