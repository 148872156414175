<script lang="ts">
  import { todaysDateStore } from '@dabble/data/date';
  import { sizeStore } from '@dabble/data/device';
  import { docSettingsStore, docStore } from '@dabble/data/doc-data';
  import { t } from '@dabble/data/intl';
  import { projectStatsStore, projectStore } from '@dabble/data/project-data';
  import { selectedCountStore } from '@dabble/data/ui';
  import { number } from '@dabble/toolkit/helpers';
  import { tooltipTop } from '@dabble/toolkit/tooltip';

  let pageCount = 0;
  let wordCount = 0;
  let todaysCount = 0;

  $: pageCount = ($docStore && $projectStore.counts[$docStore.id].pageCount) || 0;
  $: wordCount = ($docStore && $projectStore.counts[$docStore.id].wordCount) || 0;
  $: todaysCount = $docStore && $projectStatsStore && projectStatsStore.getForDay($docStore.id, $todaysDateStore);
</script>

{#if !$docSettingsStore?.hideWordCount}
  <div class="counts">
    {#if $selectedCountStore && $sizeStore === 'desktop'}
      <span class="count" use:tooltipTop={$t('counts_selection_tooltip')}
        >{number($selectedCountStore)} {$t('counts_selection', { count: $selectedCountStore })}</span
      > |
    {/if}
    {#if $sizeStore === 'desktop'}
      <span class="count page" use:tooltipTop={$t('counts_pages_tooltip')}
        >{number(pageCount)} {$t('counts_pages', { count: pageCount })}</span
      > |
    {/if}
    <span class="count" use:tooltipTop={$t('counts_words_tooltip')}
      >{number(wordCount)} {$t('counts_words', { count: wordCount })}</span
    >
    |
    <span class="count" use:tooltipTop={$t('counts_today_tooltip')}>{number(todaysCount)} {$t('counts_today')}</span>
  </div>
{/if}

<style>
  .counts {
    margin-right: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
