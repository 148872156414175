import { Doc } from '@dabble/data/types';
import { getTitle } from '@dabble/data/util';
import { format } from 'date-fns';
import { Delta, Editor, inlineToHTML } from 'typewriter-editor';
import { ImageAttributes } from '../content/images';
import { Counts } from './types';

const PIXELS_PER_INCH = 72;
const PAGE_WIDTH = 8.5 * PIXELS_PER_INCH;
const INNER_WIDTH = PAGE_WIDTH - 2 * PIXELS_PER_INCH;
const INNER_HEIGHT = 11 * PIXELS_PER_INCH - 2 * PIXELS_PER_INCH;

export const editor = new Editor({
  types: {
    lines: ['paragraph', 'header', 'list', 'blockquote', 'hr', 'dlh', 'dl', 'image'],
    formats: ['link', 'ins', 'del', 'highlight', 'bold', 'italic', 'strike', 'underline'],
    embeds: ['br'],
  },
});

export function deltaToHTML(delta: Delta) {
  return delta ? inlineToHTML(editor, delta) : '';
}

export function createFileName(doc: Doc, shortName?: string) {
  const title = (shortName || getTitle(doc, 'title')).replace(' ', '-').toLowerCase();
  return `${title}-${format(new Date(), "yyyy-MM-dd'T'HH:mm:ss")}`;
}

export function getDocName(counts: Counts, item: Doc, skipInc?: boolean) {
  const count = counts[item.type as keyof Counts] || 1;
  if (!skipInc) counts[item.type as keyof Counts] = count + 1;
  return capitalize(`${item.type.replace('novel_', '')} ${count}`);
}

export function getImageInfo(image: ImageAttributes) {
  image = { ...image };

  const aspect = image.width / image.height;
  let maxWidth = INNER_WIDTH;
  if (image.style === 'outset-left') maxWidth = INNER_WIDTH / 2;
  else if (image.style === 'center') maxWidth = INNER_WIDTH / 4;

  if (image.style === 'fill-page') {
    if (aspect > INNER_WIDTH / INNER_HEIGHT) {
      image.width = INNER_WIDTH;
      image.height = INNER_WIDTH / aspect;
    } else {
      image.height = INNER_HEIGHT;
      image.width = INNER_HEIGHT * aspect;
    }
  } else if (image.width > maxWidth) {
    image.width = maxWidth;
    image.height = image.width / aspect;
  }

  return image;
}

function capitalize(str: string) {
  return str.replace(/^\w| \w/g, char => char.toUpperCase());
}
