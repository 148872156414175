<script lang="ts">
  import { signals } from '@dabble/data/app-state';
  import { todaysDateStore } from '@dabble/data/date';
  import { isApple, isIOS, sizeStore } from '@dabble/data/device';
  import { projectTemplatesStore } from '@dabble/data/global-data';
  import { routerStore } from '@dabble/data/navigation';
  import { createProject, projectMetaStore, projectStore } from '@dabble/data/project-data';
  import { duplicateProject } from '@dabble/data/project-duplicate';
  import { settingsStore } from '@dabble/data/settings';
  import { focusStore, hideMainStore } from '@dabble/data/ui';
  import { preferencesStore, userProjectsStore } from '@dabble/data/user-data';
  import { selectProject } from '@dabble/data/user-project';
  import { desktop } from '@dabble/desktop';
  import ContextMenu from '@dabble/toolkit/ContextMenu.svelte';
  import Globals from '@dabble/toolkit/Globals.svelte';
  import InputContextMenu from '@dabble/toolkit/InputContextMenu.svelte';
  import Route from '@dabble/toolkit/Route.svelte';
  import { detectExtensions } from '@dabble/util/extensions';
  import { onMount, tick } from 'svelte';
  import AppHeader from './AppHeader.svelte';
  import DesktopNav from './DesktopNav.svelte';
  import FeatureUpgrade from './FeatureUpgrade.svelte';
  import Home from './home/Home.svelte';
  import Project from './workspace/Project.svelte';

  let isHolidays = false;
  let focusing: boolean;
  routerStore.listen();

  $: hideHolidays = $preferencesStore.hideHolidays || localStorage.hideHolidays;
  $: font = $projectMetaStore?.settings?.font || {};
  $: isHolidays = !hideHolidays && $todaysDateStore.slice(5) >= '12-01' && $todaysDateStore.slice(5) < '12-26';
  $: document.body.classList.toggle('isApple', isApple);
  $: document.body.classList.toggle('inDesktop', desktop.inApp); // in the Desktop app
  $: document.body.className =
    document.body.className.replace(/ (size|font)-\w+/g, '') +
    ` size-${$sizeStore}` +
    ` font-${font.family || 'general'}`;
  $: font.size
    ? document.body.setAttribute('style', `--font-size: ${font.size / 12}rem`)
    : document.body.removeAttribute('style');
  $: onFocusing($focusStore.focusing);

  interface GenreMap {
    [key: string]: string;
  }
  let genreMap: GenreMap;
  $: genreMap = $projectTemplatesStore || ({} as GenreMap);

  async function createInitialProject(genre: keyof GenreMap): Promise<void> {
    const projects = $userProjectsStore;
    const templateProjectId = Object.keys(projects).find((p: string) => p === genreMap[genre]);
    const userProjectId = templateProjectId
      ? (await duplicateProject(templateProjectId)).id
      : (await createProject()).project.id;
    selectProject(userProjectId);
    if (templateProjectId)
      await userProjectsStore.update({ [templateProjectId]: { lastOpened: new Date().getTime() } });

    // TODO: Fix this: Should not be imported directly, uncomment to open modal after onboarding is fixed
    // open Learn More Reward modal
    //$showOnboardingLearnMore = true;
  }

  // Fix bug in iOS for Safari
  if (isIOS && ((navigator as any).standalone || window.matchMedia('(display-mode: standalone)').matches)) {
    document.head.style.height = '100%';
  }

  function inputElement(event: InputEvent): HTMLElement {
    const target = event.target as Element;
    if (target.nodeName === 'INPUT' || target.nodeName === 'TEXTAREA') {
      return target as HTMLElement;
    }
  }

  async function onFocusing(value: boolean) {
    if (value) return (focusing = true);
    await tick();
    focusing = false;
  }

  onMount(() => {
    const genre = sessionStorage.getItem('genre');
    if (genre) {
      signals.onFirstLoad();
      setTimeout(async () => {
        await createInitialProject(genre);
        sessionStorage.removeItem('genre');
      }, 1000);
    }
    setTimeout(() => {
      detectExtensions();
    }, 5000); // give them a few seconds before springing any popups on them.
  });
</script>

<div
  class="dabble-app"
  class:focusing
  class:focus-slowly={$focusStore.focusing && !$focusStore.focusLock}
  class:holidays={isHolidays}
>
  <AppHeader />
  {#each settingsStore.getValuesFromPlugins('headers') as header}
    <svelte:component this={header} />
  {/each}

  {#if !$hideMainStore}
    {#each settingsStore.getValuesFromPlugins('routes') as routes}
      <svelte:component this={routes} />
    {/each}
    <Route path="/" component={Home} />
    <!-- TODO -->
    <Route path="/p/:projectId/:docId?" component={Project} />
  {/if}
  <FeatureUpgrade />
  <Globals />
  <DesktopNav />
  {#each settingsStore.getValuesFromPlugins('globals', $projectStore.project) as global}
    <svelte:component this={global} />
  {/each}
</div>

<ContextMenu target={document} targetFilter={inputElement} let:target>
  <InputContextMenu {target} />
</ContextMenu>

<style>
  :global(body) {
    background-color: var(--background);
  }
  .dabble-app {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    color: var(--text-color-normal);
  }
</style>
