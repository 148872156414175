<script>
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { grow } from '@dabble/toolkit/transitions';
  import { mdiChevronDown } from '@mdi/js';

  let openMenu = false;
  let defaultWidth = 0;
  let submenuParent;

  $: if (submenuParent && !defaultWidth) defaultWidth = submenuParent.offsetWidth;
</script>

<div class="submenu" class:opened={openMenu}>
  <button
    bind:this={submenuParent}
    class="dropdown-item nav-item-menu show-submenu"
    on:click={() => (openMenu = !openMenu)}
  >
    <div class="content">
      <slot name="submenu-button" />
    </div>
    <div class="submenu-icon"><Icon path={mdiChevronDown} /></div>
  </button>

  {#if openMenu}
    <div class="submenu-items" transition:grow={{ defaultWidth }}>
      <slot />
    </div>
  {/if}
</div>

<style>
  .submenu {
    border-radius: var(--border-radius);
  }
  .content {
    display: flex;
    align-items: center;
    flex: 1 1 0%;
    white-space: nowrap;
  }
  .submenu.opened {
    background-color: var(--text-color-faint);
  }
  .submenu-items {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .submenu-items :global(.dropdown-item) {
    margin: 0;
  }
  .submenu-items :global(hr) {
    margin: 0 8px;
  }
  .submenu-icon {
    display: flex;
    margin-left: 6px;
    margin-right: -0.75rem;
    transition: transform 0.15s;
  }
  .opened .submenu-icon {
    transform: rotate(-180deg);
  }
  :global(.dropdown-menu .dropdown-item) .submenu-icon :global(.icon) {
    margin: 0;
  }
</style>
