<script lang="ts">
  import { t } from '@dabble/data/intl';
  import { isOnlineStore } from '@dabble/data/ui';
  import { getImagePlaceholder } from '@dabble/plugins/content/images';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import { mdiImage, mdiImageSearch } from '@mdi/js';
  import { Editor } from 'typewriter-editor';
  import { saveFile } from '../content';
  import { getUnsplashImage } from './UnsplashSearchDialog.svelte';

  export let editor: Editor;
  let file: HTMLInputElement;

  export async function addImage(event: Event) {
    const images = Array.from((event.target as HTMLInputElement).files).filter(file => file.type.startsWith('image/'));

    await Promise.all(
      images.map(async file => {
        const info = await getImagePlaceholder(file);
        const url = await saveFile(file);

        editor.insert('\n', { image: url, ...info });
      })
    );
  }

  async function getFromUnsplash() {
    const info = await getUnsplashImage();
    if (info) {
      const { url, ...rest } = info;
      const attr = { ...rest, image: url };
      editor.insert('\n', attr);
    }
  }
</script>

<button
  use:tooltipTop={$t('editor_upload_image')}
  class="menu-button icon button"
  on:click={() => {
    file.click();
  }}
>
  <Icon path={mdiImage} />
  <input style="display:none" type="file" on:change={addImage} bind:this={file} />
</button>

<button
  use:tooltipTop={$t('editor_unsplash_image')}
  class="menu-button icon"
  on:click={getFromUnsplash}
  disabled={!$isOnlineStore}
>
  <Icon path={mdiImageSearch} />
</button>
