<script lang="ts">
  import { sizeStore } from '@dabble/data/device';
  import { featuresStore } from '@dabble/data/global-data';
  import { t } from '@dabble/data/intl';
  import ToolButton from '@dabble/toolkit/ToolButton.svelte';
  import { mdiComment, mdiCommentOff } from '@mdi/js';
  import { showCommentsStore } from '../comments-store';

  function toggle() {
    if (!$featuresStore.comments) {
      featuresStore.promptUpgrade('comments');
    } else {
      $showCommentsStore = !$showCommentsStore;
    }
  }
</script>

{#if $sizeStore === 'desktop'}
  <ToolButton
    icon={$showCommentsStore ? mdiComment : mdiCommentOff}
    tooltip={$t(`comments_${$showCommentsStore ? 'hide' : 'show'}`)}
    on:click={toggle}
  />
{/if}
