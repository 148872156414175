<script lang="ts">
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiClose } from '@mdi/js';
  import { Editor } from 'typewriter-editor';
  import { CreateStickyNote, getBoundedPosition, showStickyNotes, SIZE, stickyNotesStore } from '../stickies-store';

  export let editor: Editor;
  export let section: string;
  // leave these in even though they aren't used to keep warnings from screen.
  export let active;
  export let placement;
  export let selection;
  export let commands;

  const colors = ['yellow', 'green', 'blue', 'pink'];

  async function sticky(color: string) {
    $showStickyNotes = true;
    const bounds = editor.getBounds(editor.doc.selection, editor.root);
    const centerY = bounds.top + bounds.height / 2;
    const centerX = bounds.left + bounds.width / 2;
    const { id } = editor.identifier;
    const pos = getBoundedPosition(
      centerY - SIZE / 2,
      centerX - SIZE / 2,
      editor.root.offsetHeight,
      editor.root.offsetWidth
    );
    const note: CreateStickyNote = {
        docId: id,
        color,
        ...pos
    };
    const stickyId = await stickyNotesStore.createStickyNote(note);
    editor.select(null);
    const element: HTMLElement = editor.root.ownerDocument.querySelector(`.sticky-note[data-id="${stickyId}"] .content`);
    if (element) {
      element.focus();
    }
  }
</script>

{#if section === 'sticky'}
  <div class="sticky-section">
    {#each colors as color}
      <button class="swatch-btn" on:click={() => sticky(color)}>
        <div class="swatch {color}" />
      </button>
    {/each}
    <div class="spacer" />
    <button on:click={() => (section = 'main')} class="closer"><Icon path={mdiClose} /></button>
  </div>
{/if}

<style>
  .sticky-section {
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
  }
  button.swatch-btn {
    padding: 3px;
  }
  .swatch {
    width: 24px;
    height: 24px;
    border-radius: 2px;
    border: 1px solid #222222;
    box-shadow: 0 0 0 1px #929292;
    background: #444;
  }
  .spacer {
    flex: 1;
  }
  :global(.menu) button.closer {
    color: #f7f7f9;
    opacity: 0.5;
  }
  .closer:hover {
    color: #f7f7f9;
    opacity: 1;
  }

  .swatch.yellow {
    background-color: #f1dd68;
    /* border-color: #f1dd68; */
  }
  .swatch.green {
    background-color: #bfe5a5;
    /* border-color: #bfe5a5; */
  }
  .swatch.blue {
    background-color: #afdaee;
    /* border-color: #afdaee; */
  }
  .swatch.pink {
    background-color: #f4c9d0;
    /* border-color: #f4c9d0; */
  }
  .swatch.cancel::before {
    content: '';
    display: block;
    height: 4px;
    width: 200%;
    background-color: var(--dabble-red);
    transform: rotate(-42deg);
  }
</style>
