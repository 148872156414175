<script lang="ts">
  import { projectMetaStore } from '@dabble/data/project-data';
  import { EMPTY_OBJECT } from '@dabble/data/util';
  import Page from '@dabble/toolkit/Page.svelte';
  import SceneContents from './SceneContents.svelte';

  export let doc;

  $: font = $projectMetaStore.settings?.font || EMPTY_OBJECT;
  $: className =
    'scene-view fill fill-page' +
    ` paragraph-spacing-${font.sceneSpacing || 'double'}` +
    ` paragraph-style-${font.sceneStyle || 'book'}`;
</script>

<Page {doc} titleless>
  <SceneContents {doc} {className} />
</Page>
