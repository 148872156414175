import { alert } from './data/ui';

// function onError(err: Error | ErrorEvent) {
//   console.error(err);
//   alert($t('alert_header_error_occured'), $t('alert_error_occured_message', { error_message: err.message }));
// }

import { $t } from './data/intl';
import { projectStore } from './data/project-data';

function onProjectError(err: Error | ErrorEvent) {
  console.error(err);
  alert($t('project_error_title'), $t('project_error_body', { error: err.message }));
}

// observe([ready, dbStore], async ([ready, db]) => {
//   if (ready && !db) {
//     await alert($t('alert_header_database_closed'), $t('alert_database_closed'));
//     location.reload();
//   }
// });

projectStore.onError(onProjectError);

// window.addEventListener('error', onError);
// window.addEventListener('unhandledrejection', event => {
//   if (event.reason instanceof Error) onError(event.reason);
// });
