<script lang="ts">
  import { sizeStore } from '@dabble/data/device';
  import { t } from '@dabble/data/intl';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { slide } from '@dabble/toolkit/transitions';
  import { mdiChevronDown, mdiChevronUp } from '@mdi/js';
  import {
    isItemComplete,
    items,
    onboardingAvailableStore,
    onboardingStore,
    showOnboardingLearnMoreStore,
  } from '../onboarding';
  import Checklist from './Checklist.svelte';

  let open = true;

  $: isMobile = $sizeStore !== 'desktop';
  $: count = items.filter((_, i) => isItemComplete($onboardingStore, i)).length;
  $: openIcon = open ? (isMobile ? mdiChevronUp : mdiChevronDown) : isMobile ? mdiChevronDown : mdiChevronUp;

  function toggleChecklist() {
    open = !open;
  }

  function dismissChecklist() {
    onboardingStore.update({ hide: true });
  }

  function learnMore() {
    $showOnboardingLearnMoreStore = true;
  }
</script>

{#if $onboardingAvailableStore && !$onboardingStore.hide}
  <div id="onboarding-checklist" class:isMobile>
    <div class="checklist-container">
      <button class="header" on:click={toggleChecklist}>
        <div class="icon">
          <Icon path={openIcon} inline />
        </div>
        <div class="title">{$t('onboarding_getting_started')}</div>
        <div class="count">{count}/{items.length}</div>
      </button>

      {#if open}
        <div class="list" transition:slide={{ duration: 200 }}>
          <Checklist />

          <div class="buttons">
            <button on:click={learnMore} class="btn link">{$t('onboarding_learn_more')}</button>
            <button on:click={dismissChecklist} class="btn link">{$t('onboarding_dismiss')}</button>
          </div>
        </div>
      {/if}
    </div>
  </div>
{/if}

<style>
  #onboarding-checklist {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 250px;
    background: white;
    padding: 0.25rem 0.5rem;
    border-radius: 5px 5px 0 0;
    box-shadow: var(--modal-shadow);
    /* border: 1px solid var(--brand-primary); */
    margin-left: 10px;
    cursor: pointer;
  }

  #onboarding-checklist.isMobile {
    top: var(--app-header-height);
    left: 50%;
    margin-left: -125px;
    bottom: auto;
    border-radius: 0px 0px 5px 5px;
  }

  .checklist-container .header {
    border: none;
    background: none;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    font-weight: 500;
    text-transform: uppercase;
  }

  .list {
    padding-top: 4px;
    border-top: 1px solid var(--gray-lighter);
  }

  #onboarding-checklist.isMobile .header {
    border-bottom-width: 0px;
  }

  .checklist-container .icon {
    margin-right: 0.5rem;
    color: var(--text-color-lighter);
    font-size: 20px;
    font-weight: bold;
  }
  .checklist-container .title {
    flex-grow: 1;
    margin-right: 0.5rem;
  }

  .buttons {
    border-top: 1px solid var(--gray-lighter);
    margin-top: 0.5rem;
    padding: 0.5rem;
    display: flex;
    justify-content: space-between;
    font-size: var(--font-size-sm);
  }

  .buttons button {
    cursor: pointer;
  }
</style>
