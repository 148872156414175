<script lang="ts">
  import NavItemEdit from '@dabble/components/workspace/NavItemEdit.svelte';
  import NavItemMenu from '@dabble/components/workspace/NavItemMenu.svelte';
  import { readonlyStore } from '@dabble/data/app-state';
  import { getUrl } from '@dabble/data/navigation';
  import { settingsStore } from '@dabble/data/settings';
  import { Doc, DocSettings } from '@dabble/data/types';
  import { getIcon } from '@dabble/data/util';
  import ContextMenu from '@dabble/toolkit/ContextMenu.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import Title from '@dabble/toolkit/Title.svelte';
  import ItemActions from './ItemActions.svelte';

  export let doc: Doc;

  let editing = false;
  let menuOpen = false;
  let header: Element;

  $: docSettings = settingsStore.getFor(doc) as DocSettings;

  function onClick(event: Event) {
    if (typeof docSettings.onSelect === 'function') {
      event.preventDefault();
      docSettings.onSelect(doc);
    }
  }

  function editTitle() {
    if (!docSettings.uneditable && !$readonlyStore) {
      editing = true;
    }
  }
</script>

<div data-id={doc.id} class="folder nav-item {doc.type}" class:virtual={doc.virtual}>
  <header class="nav-item-header" bind:this={header} class:menu-open={menuOpen} class:editing>
    <a class="title" href={getUrl(doc)}>
      {#if docSettings.icon}
        <Icon path={getIcon(doc)} />
      {/if}
    </a>

    {#if !editing}
      <div
        role="button"
        tabindex="0"
        on:click
        class="title"
        on:click={onClick}
        on:dblclick={editTitle}
        on:keypress={onClick}
      >
        <Title class={'text'} {doc} />
        {#if docSettings.navTitlePostfix}
          <span class="nav-title-postfix">
            {docSettings.navTitlePostfix(doc)}
          </span>
        {/if}
      </div>
    {:else}
      <NavItemEdit {doc} on:done={() => (editing = false)} />
    {/if}

    <ItemActions {doc} on:editTitle={editTitle} />
  </header>
</div>

<ContextMenu target={header} activeClass="menu-open">
  <NavItemMenu {doc} on:editTitle={editTitle} />
</ContextMenu>

<style>
  .folder {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 16rem;
    max-height: 16rem;
    border-radius: 0.5rem;
    background: var(--white);
    padding: 1rem;
    text-align: center;
    overflow: hidden;
    color: var(--text-color-light);
    box-shadow: var(--flat-box-shadow);
  }
  .nav-item-header {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    margin: -1rem;
    margin-bottom: 0;
  }
  .folder:hover {
    color: var(--text-color-lighter);
  }
  .folder a {
    color: inherit;
  }
  .folder .title :global(.icon) {
    font-size: 7rem;
  }
</style>
