<script lang="ts">
  import { DESKTOP, sizeStore } from '@dabble/data/device';
  import { docStore } from '@dabble/data/doc-data';
  import { t } from '@dabble/data/intl';
  import { settingsStore } from '@dabble/data/settings';
  import { Focus } from '@dabble/data/stores/focus';
  import { DocSettings } from '@dabble/data/types';
  import { focusStore, hideRightNavStore } from '@dabble/data/ui';
  import { preferencesStore } from '@dabble/data/user-data';
  import { desktop } from '@dabble/desktop';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import interactions from '@dabble/toolkit/interactions';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import { mdiEye, mdiEyeOff, mdiEyeOffOutline, mdiEyeOutline, mdiFullscreen, mdiFullscreenExit } from '@mdi/js';
  import { onDestroy, onMount } from 'svelte';

  let ctrlPressed = false;
  let fullscreen = desktop.isFullscreen;
  let docSettings: DocSettings;

  $: docSettings = $docStore && settingsStore.getFor($docStore);
  $: rightNavHidden = $hideRightNavStore || (docSettings && docSettings.hideRightNav) || $sizeStore !== DESKTOP;

  function checkCtrlPressed(event: KeyboardEvent | MouseEvent) {
    ctrlPressed = event.ctrlKey || event.metaKey;
  }

  function onFocusToggleClick(event: MouseEvent) {
    checkCtrlPressed(event);
    if ((ctrlPressed && !$preferencesStore.noAutofade) || $focusStore.preventFocusing) {
      stop();
      togglePreventFocus();
    } else {
      $focusStore.focusing ? stop() : start();
    }
  }

  async function onFullscreenToggleClick() {
    desktop.toggleFullscreen();
  }

  function togglePreventFocus() {
    focusStore.prevent(!$focusStore.preventFocusing);
  }

  function start() {
    focusStore.start(true);
  }

  function stop() {
    focusStore.stop(true);
  }

  function getIcon(focus: Focus, ctrlPressed: boolean, noAutofade: boolean) {
    if (focus.preventFocusing || (!focus.focusing && noAutofade)) return mdiEyeOff;
    if (ctrlPressed && !noAutofade) return mdiEyeOffOutline;
    if (focus.focusing) return mdiEye;
    return mdiEyeOutline;
  }

  function getTooltip(focus: Focus, ctrlPressed: boolean, noAutofade: boolean) {
    if (focus.preventFocusing) return $t('focus_enable');
    if (ctrlPressed && !noAutofade) return $t('focus_disable');
    if (focus.focusLock) return $t('focus_exit');
    return $t('focus_enter');
  }

  onMount(() => {
    window.addEventListener('focus', checkCtrlPressed);
    window.addEventListener('blur', checkCtrlPressed);
    interactions.on('shortcut:Escape', stop);
    interactions.on('keydown', checkCtrlPressed);
    interactions.on('keyup', checkCtrlPressed);
  });

  onDestroy(() => {
    window.removeEventListener('focus', checkCtrlPressed);
    window.removeEventListener('blur', checkCtrlPressed);
    interactions.off('shortcut:Escape', stop);
    interactions.off('keydown', checkCtrlPressed);
    interactions.off('keyup', checkCtrlPressed);
  });
</script>

<button
  class="icon focus-button"
  use:tooltipTop={$fullscreen ? $t('fullscreen_exit') : $t('fullscreen_enter')}
  on:click={onFullscreenToggleClick}
>
  <Icon path={$fullscreen ? mdiFullscreenExit : mdiFullscreen} />
</button>
<button
  class="icon focus-button"
  use:tooltipTop={getTooltip($focusStore, ctrlPressed, $preferencesStore.noAutofade)}
  on:mouseenter={checkCtrlPressed}
  on:click={onFocusToggleClick}><Icon path={getIcon($focusStore, ctrlPressed, $preferencesStore.noAutofade)} /></button
>
