<script lang="ts">
  import { t } from '@dabble/data/intl';
  import { projectStore } from '@dabble/data/project-data';
  import { settingsStore } from '@dabble/data/settings';
  import { Doc } from '@dabble/data/types';
  import { EMPTY_ARRAY, EMPTY_OBJECT, getTitle } from '@dabble/data/util';
  import { DropOptions, droppable } from '@dabble/plugins/content/droppable';
  import Card from '@dabble/toolkit/Card.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import { mdiPlusThick } from '@mdi/js';
  import { createEventDispatcher } from 'svelte';

  export let doc: Doc;
  export let column;
  export let readonly;

  const dispatch = createEventDispatcher();
  const extraRows = [0, 1, 2, 3];

  let highlightedId: number;
  let highlightedY: number;

  $: docSettings = $settingsStore[doc.type];
  $: defaultChildType = docSettings.defaultChildType;
  $: children = $projectStore.childrenLookup[doc.id];
  $: childMap =
    (children && children.reduce((map, child) => (map[child.id] = child) && map, {} as Record<string, Doc>)) ||
    EMPTY_OBJECT;
  $: hasChildren = children && children.length > 0;
  $: isPlotLine = doc.type === 'novel_plot_line';

  function highlightCell(node?: HTMLElement, y?: number, id?: number) {
    highlightedId = id;
    highlightedY = y;
    let highlight;
    if (node) {
      highlight = { left: node.offsetLeft, top: node.offsetTop, y };
    }
    dispatch('highlight', highlight);
  }

  const dropOptions: DropOptions = {
    mimes: ['application/json+dabble-doc'],
    dropEffect: 'move',
    acceptable(event) {
      let target = event.target as HTMLElement;
      const cell = target && target.closest && (target.closest('.plot-grid-cell') as HTMLElement);
      if (!cell || cell.querySelector('.notecard')) return false;
      const type = (event.dataTransfer.types.find(mime => mime.startsWith('dabble-type/')) || '').split('/')[1];
      return type && (!docSettings.validChildTypes || docSettings.validChildTypes[type]);
    },
    drop(event, mime, doc) {
      let target = event.target as HTMLElement;
      const cell = target && target.closest && (target.closest('.plot-grid-cell') as HTMLElement);
      if (!cell) return;
      let y = parseInt(cell.dataset.y) + 1;
      dispatch('drop', { doc, y });
    },
  };
</script>

<div data-id={doc.id} class="plot-grid-line" class:header={!isPlotLine}>
  {#each column || EMPTY_ARRAY as id, y}
    {#if !isPlotLine && id && $projectStore.parentsLookup[id]?.children && $projectStore.parentsLookup[id]?.children[0] === id}
      <p class="chapter-ribbon">
        {getTitle($projectStore.parentsLookup[id])}
      </p>
    {/if}
    <!-- svelte-ignore a11y-no-static-element-interactions -->
    <div
      class="plot-grid-cell"
      data-y={y}
      use:droppable={!readonly && dropOptions}
      on:mouseenter={event => highlightCell(event.currentTarget, y, id)}
      on:mouseleave={() => highlightCell()}
    >
      {#if id && childMap[id]}
        <Card
          doc={childMap[id]}
          small
          showDelete
          draggable
          events="delete"
          on:delete={() => dispatch('delete', { y: y + 1 })}
          {readonly}
        />
      {:else if !readonly && doc && (isPlotLine || hasChildren) && highlightedId === id && highlightedY === y}
        <div class="new-plot-point-btn">
          <button
            class="icon plus-thick-button"
            on:click={() => dispatch('create', { y: y + 1 })}
            use:tooltipTop={$t('new_document', { type: defaultChildType })}
          >
            <Icon path={mdiPlusThick} />
          </button>
        </div>
      {/if}
    </div>
  {/each}

  {#each extraRows as extra, y}
    <!-- svelte-ignore a11y-no-static-element-interactions -->
    <div
      class="plot-grid-cell grid-pad"
      data-y={column.length + y}
      use:droppable={!readonly && dropOptions}
      on:mouseenter={event => highlightCell(event.currentTarget, column.length + y, y)}
      on:mouseleave={() => highlightCell()}
    >
      {#if !readonly && highlightedId === y}
        <div class="new-plot-point-btn">
          <button
            class="icon plus-thick-button"
            on:click={() => dispatch('create', { y: column.length + y + 1 })}
            use:tooltipTop={$t('new_document', { type: defaultChildType })}
          >
            <Icon path={mdiPlusThick} />
          </button>
        </div>
      {/if}
    </div>
  {/each}
</div>

<style>
  .plot-grid-line {
    flex: 0 0 auto;
    width: calc(var(--card-width-sm) + 20px);
    padding: 5px 0;
  }
  .plot-grid-line.header {
    position: relative; /* for browsers that don't support sticky */
    position: sticky;
    left: 0;
    z-index: 1;
    box-shadow:
      inset -1px 0 3px rgba(0, 0, 0, 0.2),
      1px 0 0 rgba(255, 255, 255, 0.1);
    background: var(--plot-first-line-highlight);
    margin-left: -20px;
    padding-left: 20px;
    width: calc(var(--card-width-sm) + 40px);
  }
  .plot-grid-cell {
    position: relative;
    width: var(--card-width-sm);
    height: var(--card-height-sm);
    padding: 10px;
    box-sizing: content-box;
  }
  .plot-grid-line :global(.plot-grid-cell.dropping::before) {
    content: '';
    display: block;
    position: absolute;
    top: 10px;
    width: 160px;
    height: 96px;
    border: none;
    background: var(--background-darkened);
    box-shadow:
      inset 1px 2px 2px rgba(0, 0, 0, 0.2),
      1px 1px 1px rgba(255, 255, 255, 0.4);
    border-radius: 3px;
  }

  .new-plot-point-btn {
    display: flex;
    display: none;
    justify-content: center;
    align-items: center;
    width: var(--card-width-sm);
    height: var(--card-height-sm);
  }
  .new-plot-point-btn .plus-thick-button {
    font-size: 40px;
    width: 56px;
    height: 56px;
    border-radius: 28px;
  }
  .new-plot-point-btn .plus-thick-button:hover {
    background: var(--background-darkened);
  }
  .plot-grid-cell:not(.drag-placeholder):hover .new-plot-point-btn {
    display: flex;
  }
  /* chapter ribbon */
  .chapter-ribbon {
    font-size: var(--font-size-xxs);
    position: absolute;
    z-index: 1;
    margin-top: 1px;
    color: var(--text-color-normal);
    right: 16px;
    left: 24px;
    background: var(--gray-lighter);
    border-radius: 2px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    padding: 0.5em 0.8em;
    line-height: 1.2em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
  }
</style>
