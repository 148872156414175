<script lang="ts">
  import { readingStore } from '../reading';
  import ReadToMe from './ReadToMe.svelte';
  import Thesaurus from './Thesaurus.svelte';

  readingStore.stop(); /* Stops reading after play, navigate away, then navigate back */
</script>

<Thesaurus />
<ReadToMe />
