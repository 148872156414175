import { Change } from 'agreeable-client';
import { Unsubscriber, signal, writable } from 'easy-signal';
import { Delta, TextDocument } from 'typewriter-editor';
import { agreeable } from '../agreeable';
import { ProjectDocStore } from '../agreeable/agreeable-config';
import { projectIdStore } from '../ids';
import ProjectPatch from '../project-patch';
import { createTextQueue } from '../text-queue';
import { Doc, Project } from '../types';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '../util';
import { createDocId, createProjectId } from '../uuid';
import { Children, emptyChildren, getChildrenLookup } from './project/children';
import { Counts, emptyCounts, getCounts } from './project/counts';
import { DocTexts, emptyTexts, getTexts } from './project/doc-texts';
import { Docs, emptyDocs, getDocs } from './project/docs';
import { InTrash, emptyInTrash, getInTrash } from './project/in-trash';
import { Link, LinkSet, emptyLinkSet, getLinks } from './project/links';
import { Parents, emptyParents, getParentsLookup } from './project/parents';
import { SettingsStore } from './settings';

export const PENDING = 'pending';
export const SAVING = 'saving';
export const SAVED = 'saved';
export const ERROR = 'error';
export const EMPTY_TEXT_DOCUMENT = new TextDocument();
export type SaveStatus = 'pending' | 'saving' | 'saved' | 'error';

export interface ProjectData {
  projectId: string;
  project: Project;
  docs: Docs;
  childrenLookup: Children;
  parentsLookup: Parents;
  inTrash: InTrash;
  links: LinkSet;
  texts: DocTexts;
  counts: Counts;
  textQueued: boolean;
  change: Change;
  currentVersion: number;
  previous: Project;
}

export interface Comment {
  uid: string;
  content: string;
  resolved?: boolean;
  created: number;
  replies?: Comment[];
}

export const emptyLinks: Link[] = [];
export const emptyProjectData: ProjectData = {
  projectId: null,
  project: null,
  docs: emptyDocs,
  parentsLookup: emptyParents,
  childrenLookup: emptyChildren,
  inTrash: emptyInTrash,
  links: emptyLinkSet,
  texts: emptyTexts,
  counts: emptyCounts,
  textQueued: false,
  change: null,
  currentVersion: null,
  previous: null,
};

export type ProjectStore = ReturnType<typeof createProjectStore>;

export function createProjectStore(projectDocStore: ProjectDocStore, settingsStore: SettingsStore) {
  const textQueue = createTextQueue({
    onSave: onCommitQueuedTextChange,
    onCancel: onCancelQueuedTextChange,
  });
  let data: ProjectData = emptyProjectData;
  let changesJustReceived = false;
  const onPatch = signal<(patch: ProjectPatch) => any>();
  const forceTextUpdate = signal<(key?: string) => any>();
  const saveText = signal<(text?: string) => any>();
  const onError = signal<(err: Error) => any>();
  const status = writable<SaveStatus>(SAVED);
  const pathCache = new WeakMap<Doc, number[]>();
  let projectId: string | null = null;
  let unsubDoc: Unsubscriber;

  const { get, set, subscribe } = writable<ProjectData>(data);

  /**
   * Load the project by id.
   */
  async function load(id: string) {
    projectIdStore.set((projectId = id));
    await projectDocStore.load();
    projectDocStore.subscribe(updateData);
  }

  /**
   * Unload the currently loaded project.
   */
  async function unload() {
    if (data.project) await commitQueuedTextChanges();
    projectDocStore.unload();
    projectIdStore.set((projectId = null));
    unsubDoc?.();
    updateData(null);
  }

  /**
   * Reload the currently loaded project.
   */
  async function reload() {
    if (!data.projectId) return;
    projectDocStore.unload();
    updateData(await projectDocStore.load());
    forceTextUpdate();
  }

  function scheduleTextUpdate() {
    Promise.resolve().then(() => forceTextUpdate());
  }

  function patch() {
    return new ProjectPatch(data.project, settingsStore, savePatch);
  }

  async function savePatch(patch: ProjectPatch): Promise<Project> {
    if (patch.isEmpty() || data.currentVersion !== null) return null;

    // Allow any changes to be made
    onPatch(patch);

    if (patch.isEmpty()) return null;

    status.set(SAVING);

    const promise = projectDocStore.patch(patch.patch);

    promise.then(
      () => status.set(SAVED),
      async err => {
        onError(err);
        status.set(ERROR);
        // Revert to last committed version
        try {
          const project = await projectDocStore.load();
          updateData(project);
          scheduleTextUpdate();
          status.set(SAVED);
        } catch (err) {
          scheduleTextUpdate();
        }
      }
    );

    await promise;
    return projectDocStore.get();
  }

  function newDocId() {
    return createDocId(data.docs);
  }

  function updateData(
    project: Project,
    change: Change = null,
    currentVersion: number = null,
    previous: Project = null
  ) {
    if (data.project === project) return;
    // Ensure the basic structure of the novel exists (it may be empty before fully loaded from the server)
    project = project && {
      children: EMPTY_ARRAY,
      docs: EMPTY_OBJECT,
      links: EMPTY_OBJECT,
      type: settingsStore.getFor('dabble').defaultProjectType,
      ...project,
      id: projectId,
    };
    const settings = settingsStore.get();

    data = { ...data, projectId: projectId, project, change, currentVersion, previous };

    data.docs = getDocs(project, settings);
    data.childrenLookup = getChildrenLookup(data.docs, settings);
    data.parentsLookup = getParentsLookup(data.docs, data.childrenLookup);
    data.inTrash = getInTrash(data.childrenLookup);
    data.links = getLinks(project, data.inTrash);
    data.texts = getTexts(data.docs, textQueue, data.texts);
    data.counts = getCounts(data.texts, data.childrenLookup, data.counts);

    set(data);
  }

  // Project API

  /**
   * Create a new project patch which will create a project.
   */
  async function createProject(newProject: Partial<Project>) {
    const patch = ProjectPatch.createProject(newProject, settingsStore);
    const newProjectDocStore = agreeable.projects(patch.project.id).doc;
    await newProjectDocStore.patch(patch.patch);
    return newProjectDocStore.get();
  }

  /**
   * Updates the loaded project, returning a promise when the changes are saved. This will update the project and
   * user project because the metadata duplicates a portion of project data for caching.
   */
  function updateProject(updates: any) {
    return patch().updateProject(updates).save();
  }

  /**
   * Creates a new doc within the loaded project, within the parent folder, at the given index.
   */
  function createDoc(newDoc: Partial<Doc>, parentId: string, index?: number, structure = true) {
    return patch().createDoc(newDoc, parentId, index, structure).save();
  }

  /**
   * Updates a doc with the given updates. The doc must belong to the loaded project.
   */
  function updateDoc(docId: string, updates: any) {
    return patch().updateDoc(docId, updates).save();
  }

  /**
   * Move a doc from one location to another in the loaded project.
   */
  function moveDoc(docId: string, newParentId: string, index?: number) {
    return patch().moveDoc(docId, newParentId, index).save();
  }

  /**
   * Put a doc into the trash. This is the same as a move with parentId of 'trash'.
   */
  function trashDoc(docId: string) {
    return patch().trashDoc(docId).save();
  }

  /**
   * Restore a doc from the trash to its previous location.
   */
  function restoreDoc(docId: string) {
    return patch().restoreDoc(docId).save();
  }

  /**
   * Delete a doc from the loaded project. This is not removal from trash, but complete deletion. Delete doc texts as
   * well.
   */
  function deleteDoc(docId: string) {
    return patch().deleteDoc(docId).save();
  }

  /**
   * Completely delete all docs in the trash of the loaded project.
   */
  function emptyTrash() {
    return patch().emptyTrash().save();
  }

  /**
   * Links two docs together (or updates their existing link) with the given metadata.
   */
  function linkDocs(from: string, rel: string, to: string, metadata = {}) {
    return patch().linkDocs(from, rel, to, metadata).save();
  }

  /**
   * Removes the link between two docs.
   */
  function unlinkDocs(from: string, rel: string, to: string) {
    return patch().unlinkDocs(from, rel, to).save();
  }

  /**
   * Change the text in a doc with the given delta.
   */
  function changeText(docId: string, field: string, delta: Delta) {
    return patch().changeText(docId, field, delta).save();
  }

  /**
   * Returns the closest parent of the given type.
   */
  function closest(docId: Doc | string, type: string) {
    let doc = typeof docId === 'string' ? data.docs[docId] : docId;
    while (doc) {
      if (doc.type === type) return doc;
      doc = data.parentsLookup[doc.id];
    }
  }

  /**
   * Returns whether the parent is or contains the provided doc.
   */
  function contains(parent: Doc | string, doc: Doc | string) {
    if (!parent || !doc) return false;
    const parentId = typeof parent === 'string' ? parent : parent.id;
    let docId = typeof doc === 'string' ? doc : doc.id;
    docId = data.parentsLookup[docId] && data.parentsLookup[docId].id;
    while (docId && docId !== parentId) {
      const parent = data.parentsLookup[docId];
      docId = parent && parent.id;
    }
    return docId === parentId;
  }

  /**
   * Returns all links coming in to the given doc, optionally filtered by rel.
   */
  function getDoc(docId: string) {
    return data.docs[docId];
  }

  /**
   * Returns all docs (or ids) of a certain type within a doc.
   */
  function getDocsOfType(within: Doc, type: string, getDocs: true): Doc[];
  function getDocsOfType(within: Doc, type: string, getDocs?: false): string[];
  function getDocsOfType(within: Doc, type: string, getDocs = false): string[] | Doc[] {
    if (within.type === type) return getDocs ? [within] : [within.id];
    if (!within.children) return [];
    return within.children.reduce(
      (all, childId) =>
        data.docs[childId] ? all.concat(getDocsOfType(data.docs[childId], type, getDocs as any)) : all,
      []
    );
  }

  /**
   * Flattens all the children inside a doc, optionally stopping at given types
   */
  function flatten(within: Doc, untilType?: string[]): Doc[] {
    const all: Doc[] = [];
    if (!within.children) return all;
    within.children.forEach(childId => {
      const doc = data.docs[childId];
      doc && all.push(doc);
      if (!untilType || !untilType.includes(doc.type)) {
        all.push(...flatten(doc, untilType));
      }
    });
    return all;
  }

  /**
   * Returns all links coming in to the given doc, optionally filtered by rel.
   */
  function getParent(docId: string) {
    return data.parentsLookup[docId];
  }

  /**
   * Returns all links coming in to the given doc, optionally filtered by rel.
   */
  function getChildren(docId: string) {
    return data.childrenLookup[docId];
  }

  /**
   * Returns the indexed path from the root of the project to the given doc.
   */
  function getPath(docId: string): number[] {
    let doc = getDoc(docId);
    const path: number[] = pathCache.get(doc) || EMPTY_ARRAY;
    if (path.length) return path; // Cached, skip the lookup (improves sort speed)
    let parent: Doc;

    while ((parent = getParent(doc.id))) {
      const children = getChildren(parent.id);
      const index = children.indexOf(doc);
      path.push(index);
      doc = parent;
    }

    if (doc.type === 'trash') {
      path[path.length] = getChildren(data.project.id).length;
    }

    return path.reverse();
  }

  /**
   * A sort algorithm for sorting docs or docIds by their order in the heirarchy
   */
  function pathSort(docA: string | Doc, docB: string | Doc): number {
    const pathA = getPath(typeof docA === 'string' ? docA : docA.id);
    const pathB = getPath(typeof docB === 'string' ? docB : docB.id);
    for (let i = 0, length = Math.min(pathA.length, pathB.length); i < length; i++) {
      if (pathA[i] !== pathB[i]) {
        return pathA[i] - pathB[i];
      }
    }
    return 0;
  }

  /**
   * Returns all links going out from the given doc, optionally filtered by rel.
   */
  function linksFrom(docId: string, rel = '', includeTrashed = false) {
    const from = includeTrashed ? data.links.all.from : data.links.from;
    return from[`${docId}:${rel}`] || emptyLinks;
  }

  /**
   * Returns all links coming in to the given doc, optionally filtered by rel.
   */
  function linksTo(docId: string, rel = '', includeTrashed = false) {
    const to = includeTrashed ? data.links.all.to : data.links.to;
    return to[`${docId}:${rel}`] || emptyLinks;
  }

  /**
   * Returns all links for given doc, to and from, optionally filtered by rel.
   */
  function linksFor(docId: string, rel = '', includeTrashed = false) {
    return linksFrom(docId, rel, includeTrashed).concat(linksTo(docId, rel, includeTrashed));
  }

  /**
   * Get the text for the given doc and field.
   */
  function textFor(docId: string, field: string) {
    return data.texts.textWithQueued[docId] && data.texts.textWithQueued[docId][field];
  }

  async function onCommitQueuedTextChange(delta: Delta, key: string) {
    const [projectId, docId, field] = key.split(':');
    if (data.projectId !== projectId || !data.docs[docId]) return;
    const contents = (data.docs[docId][field] as TextDocument) || EMPTY_TEXT_DOCUMENT;
    let isChanged: boolean;
    try {
      isChanged = contents.apply(delta) !== contents;
    } catch (err) {
      forceTextUpdate(key);
    }
    if (isChanged) {
      // A change actually happened (e.g. it wasn't a delete then undo operation)
      await changeText(docId, field, delta);
    }
    updateQueuedData();
  }

  function onCancelQueuedTextChange(key: string) {
    const [projectId] = key.split(':');
    if (data.projectId !== projectId) return;
    updateQueuedData();
  }

  function getQueueKey(docId?: string, field?: string, projectId: string = data.projectId) {
    if (!projectId) throw new Error('Must have a project loaded to alter the queue');
    let key = projectId + ':';
    if (docId) {
      key += docId + ':';
      if (field) {
        key += field;
      }
    }
    return key;
  }

  function getTextDocumentByKey(key: string) {
    const [projectId, docId, field] = key.split(':');
    if (data.projectId !== projectId) return;
    return (data.docs[docId]?.[field] as TextDocument) || EMPTY_TEXT_DOCUMENT;
  }

  function updateQueuedData() {
    const queued = data.textQueued;
    const texts = getTexts(data.docs, textQueue, data.texts);

    if (texts !== data.texts) {
      data = { ...data, texts, textQueued: textQueue.hasQueued() };
      data.counts = getCounts(data.texts, data.childrenLookup, data.counts);
      set(data);
    } else if (queued !== textQueue.hasQueued()) {
      set((data = { ...data, textQueued: textQueue.hasQueued() }));
    }

    if (!queued && data.textQueued) {
      status.set(PENDING);
    } else if (queued && !data.textQueued) {
      status.set(SAVED);
    }
  }

  function queueTextChange(docId: string, field: string, delta: Delta, dontExtendDelay?: boolean): void {
    if (data.projectId === docId) return; // Don't queue changes to the project itself
    if (data.currentVersion !== null) return;
    textQueue.add(getQueueKey(docId, field), delta, dontExtendDelay);
    updateQueuedData();
  }

  function transformAgainstQueuedTextChanges(docId: string, field: string, delta: Delta): Delta {
    delta = textQueue.transform(getQueueKey(docId, field), delta);
    updateQueuedData();
    return delta;
  }

  function commitQueuedTextChanges(docId?: string, field?: string): Promise<void> {
    return textQueue.finish(getQueueKey(docId, field)); // update will run inside onCommitQueuedTextChange
  }

  function cancelQueuedTextChanges(docId?: string, field?: string): void {
    textQueue.cancel(getQueueKey(docId, field));
  }

  return {
    onPatch,
    onChange: projectDocStore?.onChange,
    onTextChange: projectDocStore?.onTextChange,
    forceTextUpdate,
    saveText,
    onError,
    status,
    load,
    unload,
    reload,
    createProject,
    updateProject,
    createDoc,
    updateDoc,
    moveDoc,
    trashDoc,
    restoreDoc,
    deleteDoc,
    emptyTrash,
    linkDocs,
    unlinkDocs,
    changeText,
    patch,
    createProjectId,
    createDocId: newDocId,
    closest,
    contains,
    getDoc,
    getDocsOfType,
    flatten,
    getParent,
    getChildren,
    getPath,
    pathSort,
    linksFrom,
    linksTo,
    linksFor,
    textFor,
    queueTextChange,
    transformAgainstQueuedTextChanges,
    commitQueuedTextChanges,
    cancelQueuedTextChanges,
    getQueueKey,
    getTextDocumentByKey,
    textQueue,
    get changesJustReceived() {
      return changesJustReceived;
    },
    updateData(project: Project) {
      if (projectDocStore) throw new Error('Cannot use updateData with an active project');
      return updateData(project);
    },
    set(projectData: ProjectData) {
      if (projectDocStore) throw new Error('Cannot use set with an active project');
      return set((data = projectData));
    },
    get,
    subscribe,
  };
}

export async function cleanupProject(projectStore: ProjectStore) {
  const project = projectStore.get().project;
  if (!project) return;
  const patch = projectStore.patch();

  // Clean up old links
  Object.keys(project.links).forEach(key => {
    const [from, rel, to] = key.split(':');
    if ((!project.docs[from] && from !== project.id) || (!project.docs[to] && to !== project.id)) {
      patch.unlinkDocs(from, rel, to);
    }
  });

  // Clean up duplicate children
  const hasParent: { [id: string]: string } = {};

  checkChildren(project.id);
  Object.keys(project.docs).forEach(parentId => parentId !== 'trash' && checkChildren(parentId));
  checkChildren('trash');

  function checkChildren(parentId: string) {
    const parent = project.docs[parentId] || (parentId === project.id && project);
    if (!parent || !parent.children) return;

    for (let i = parent.children.length - 1; i >= 0; i--) {
      const childId = parent.children[i];
      if (hasParent[childId]) {
        // Already has a parent, remove the duplicate
        if (parent === project) {
          patch.patch.remove(`/children/${i}`);
        } else {
          patch.patch.remove(`/docs/${parentId}/children/${i}`);
        }
      }
      hasParent[childId] = parentId;
    }
  }

  await patch.save();
}
