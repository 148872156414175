<script lang="ts">
  import { sizeStore } from '@dabble/data/device';
  import { featuresStore } from '@dabble/data/global-data';
  import { t } from '@dabble/data/intl';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiAccountGroup } from '@mdi/js';
  import { showCoauthorsStore } from '../collab-stores';

  function toggle() {
    if (!$featuresStore.comments) {
      featuresStore.promptUpgrade('comments');
    } else {
      $showCoauthorsStore = !$showCoauthorsStore;
    }
  }
</script>

{#if $sizeStore === 'desktop'}
  <button
    class="dropdown-item"
    class:checked={$featuresStore.comments && $showCoauthorsStore}
    on:click|stopPropagation={toggle}
  >
    <Icon path={mdiAccountGroup} />
    {$t('collab_authors')}
  </button>
{/if}
