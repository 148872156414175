import { getUrl, goto } from '@dabble/data/navigation';
import { projectStore } from '@dabble/data/project-data';
import { viewport } from '@dabble/data/ui';
import { Editor, KeyboardEventWithShortcut } from 'typewriter-editor';
import { splitChapter, splitScene } from '../split-scene';

function isNewLine(editor: Editor) {
  const selection = editor.doc.selection;
  const line = editor.doc.getLineAt(selection[0]);

  return line.content.ops.length === 0;
}

export default function splitShortcut() {
  return (editor: Editor) => {
    async function onKeyboardShortCut(event: KeyboardEventWithShortcut) {
      // TODO: Figure out why modShortcut and other variations of text short isn't working
      //if (event.modShortcut === 'Mod+Shift+Enter') {
      if (event.key === 'Enter' && (event.ctrlKey || event.metaKey) && event.shiftKey) {
        await projectStore.commitQueuedTextChanges();
        const doc = projectStore.getDoc(editor.identifier.id);
        const selection = editor.doc.selection;
        const is_new_line = isNewLine(editor);
        const newDocId = await splitChapter(doc, selection, is_new_line);
        goto(getUrl(newDocId));
        await viewport.waitForLoading();
        await viewport.select({ id: newDocId, field: 'body' }, [0, 0]);
      }
      //else if (event.modShortcut === 'Mod+Enter') {
      else if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
        await projectStore.commitQueuedTextChanges();
        const doc = projectStore.getDoc(editor.identifier.id);
        const selection = editor.doc.selection;
        const is_new_line = isNewLine(editor);
        const newDocId = await splitScene(doc, selection, is_new_line);
        goto(getUrl(newDocId));
        await viewport.waitForLoading();
        await viewport.select({ id: newDocId, field: 'body' }, [0, 0]);
      }
    }

    return {
      init() {
        editor.addEventListener('keydown', onKeyboardShortCut);
      },
      destroy() {
        editor.removeEventListener('keydown', onKeyboardShortCut);
      },
    };
  };
}
