import { StoredWritable } from '@dabble/data/stores/locally-stored-writable';
import { Unsubscriber } from 'easy-signal';

interface ResizeOptions {
  store?: StoredWritable<number>;
  reverse?: boolean;
  vertical?: boolean;
  min?: number;
  onMin?: Function;
  max?: number;
  getMax?: Function;
}

export default function resize(node: HTMLElement, options: ResizeOptions = { min: 20 }) {
  let { store, reverse, vertical, onMin } = options;
  const point = vertical ? 'clientY' : 'clientX';
  const size = vertical ? 'height' : 'width';
  const min = 'min' in options ? options.min : 40;
  const max = 'max' in options ? options.max : null;
  const getMax = 'getMax' in options ? options.getMax : null;
  const parent = node.parentElement;

  let mouseStart: number;
  let nodeSize: number;
  let unsubscribe: Unsubscriber;
  let doc: Document;
  node.addEventListener('dblclick', onDblClick);
  node.addEventListener('mousedown', onMouseDown);
  update(options);

  function update(options: ResizeOptions) {
    if (unsubscribe) unsubscribe();
    store = options.store;
    reverse = options.reverse;
    vertical = options.vertical;
    onMin = options.onMin;
    if (store) unsubscribe = store.subscribe(updateSize);
    updateSize();
  }

  function updateSize() {
    nodeSize = (store && !store.isDefault() && store.get()) || null;
    parent.style[size] = nodeSize ? `${nodeSize}px` : '';
  }

  function onMouseDown(event: MouseEvent) {
    event.preventDefault();
    node.classList.add('gripped');
    mouseStart = event[point];
    const offset = `offset${size[0].toUpperCase() + size.slice(1)}` as 'offsetWidth' | 'offsetHeight';
    nodeSize = parent[offset];
    node.addEventListener('mousemove', onMouseMove);
    doc = node.ownerDocument;
    doc.addEventListener('mouseup', onMouseUp);
  }

  function onMouseUp(event: MouseEvent) {
    event.preventDefault();
    node.classList.remove('gripped');
    node.removeEventListener('mousemove', onMouseMove);
    doc.removeEventListener('mouseup', onMouseUp);
    doc = null;
    const delta = getDelta(event);
    nodeSize = Math.max(min, nodeSize + delta);

    let currMax = max;
    if (getMax) {
      currMax = getMax();
    }
    if (currMax && nodeSize > currMax) {
      nodeSize = currMax;
    }
    store && store.set(nodeSize);
  }

  function onMouseMove(event: MouseEvent) {
    event.preventDefault();
    const delta = getDelta(event);
    const length = Math.max(min, nodeSize + delta);
    let currMax = max;
    if (getMax) {
      currMax = getMax();
    }
    if (nodeSize + delta < min && onMin) {
      node.classList.remove('gripped');
      node.removeEventListener('mousemove', onMouseMove);
      onMin();
    } else if (currMax && nodeSize + delta > currMax) {
      parent.style[size] = `${currMax}px`;
    } else {
      parent.style[size] = `${length}px`;
    }
  }

  function onDblClick() {
    nodeSize = null;
    parent.style[size] = '';
    store && store.set(null);
  }

  function getDelta(event: MouseEvent) {
    return reverse ? mouseStart - event[point] : event[point] - mouseStart;
  }

  return {
    update,
    destroy() {
      unsubscribe && unsubscribe();
      node.removeEventListener('mousedown', onMouseDown);
      doc?.removeEventListener('mouseup', onMouseUp);
      node.removeEventListener('mousemove', onMouseMove);
      doc = null;
    },
  };
}
