{
  "grammar_dictionary_add": "Add to Dictionary",
  "grammar_dismiss": "Dismiss",
  "preferences_grammarcheck_label": "Style & Grammar Checking",
  "preferences_grammarcheck_description": "Catch grammatical and stylistic errors. So your readers don’t have to.",
  "preferences_select_language": "Select a language…",
  "preferences_select_voice": "Select a Voice…",
  "preferences_spellcheck_label": "Spell Checking",
  "preferences_spellcheck_description": "Provide spelling suggestions for mispelled words. It’s ok, nobody’s perfect.",
  "preferences_spellcheck_poweredby": "Spell and style/grammar checking are powered by the amazing <a href=\"https://prowritingaid.com?afid=6159\" target=\"_blank\"><strong>ProWritingAid</strong></a>.",
  "preferences_spelling_header": "Spelling & Grammar",
  "preferences_spelling_language_header": "Language",
  "preferences_spelling_voice_header": "Voice",
  "read_to_me": "Read to Me",
  "thesaurus_open": "Word Tools",
  "thesaurus": "Thesaurus",
  "thesaurus_search": "Search the Thesaurus",
  "lookup_placeholder": "Search thesaurus",
  "no_results": "No results found...",
  "display_all_entries": "Lookup All",
  "display_contextual_entries": "Contextual Lookup",
  "in_context": "(In Context)",
  "part_of_speech_noun": "Noun",
  "part_of_speech_pronoun": "Pronoun",
  "part_of_speech_verb": "Verb",
  "part_of_speech_adverb": "Adverb",
  "part_of_speech_adjective": "Adjective",
  "part_of_speech_preposition": "Preposition",
  "part_of_speech_conjunction": "Conjunction",
  "part_of_speech_original_word": "Original Word",
  "selected_grammar_language_not_supported": "At the current time, non-English languages are not supported by the thesaurus.",
  "account_screen_dictionary": "Dictionary Maintenance",
  "delete_dictionary_entries": "Remove Your Dictionary Entries",
  "delete_all_dictionary_entries": "Remove All",
  "grammar_ext_details": "You have a Grammar Extension installed and Dabble's built in utility enabled.  For the best performance we recommend running only one at a time. \n\nWould you like to disable Dabble's built in feature?",
  "grammar_ext": "Grammar Extension Installed",
  "voice_unavailable": "Read to me voices unavailable."
}
