<script lang="ts">
  import { routerStore } from '@dabble/data/navigation';
  import { projectStore } from '@dabble/data/project-data';
  import { Doc } from '@dabble/data/types';
  import { shownCardStore } from '@dabble/data/ui';
  import Card from '@dabble/toolkit/Card.svelte';
  import CustomModal from '@dabble/toolkit/CustomModal.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiClose } from '@mdi/js';
  import { onDestroy } from 'svelte';
  import { cubicOut } from 'svelte/easing';

  let doc: Doc;
  let target;
  const unsubscribeRouterChange = routerStore.beforeChange(() => shownCardStore.set(null));

  $: doc = $shownCardStore && $projectStore.docs && $projectStore.docs[$shownCardStore.docId];
  $: target = $shownCardStore && $shownCardStore.target;
  $: readonly = $shownCardStore && $shownCardStore.readonly;

  onDestroy(unsubscribeRouterChange);

  function close() {
    shownCardStore.set(null);
  }

  function zoomFrom(node: Element, target: Element) {
    // Handle iframes if inside one
    let offsetTop = 0,
      offsetLeft = 0;
    if (target.ownerDocument !== node.ownerDocument && target.ownerDocument.defaultView.frameElement) {
      let frame = target.ownerDocument.defaultView.frameElement as HTMLElement;
      while (frame.offsetParent) {
        offsetTop += frame.offsetTop;
        offsetLeft += frame.offsetLeft;
        frame = frame.offsetParent as HTMLElement;
      }
    }

    const targetRect = target.getBoundingClientRect();
    const nodeRect = node.getBoundingClientRect();
    const x = targetRect.left - nodeRect.left + offsetLeft;
    const y = targetRect.top - nodeRect.top + offsetTop;
    const sx = targetRect.width / nodeRect.width;
    const sy = targetRect.height / nodeRect.height;
    return {
      easing: cubicOut,
      duration: 300,
      css: (t: number, rt: number) =>
        `opacity: ${t};` +
        `transform: translate(${rt * x}px, ${rt * y}px) scale(${sx + t * (1 - sx)}, ${sy + t * (1 - sy)});` +
        `transform-origin: 0 0;`,
    };
  }
</script>

{#if $shownCardStore}
  <CustomModal class="card-modal" on:close={close}>
    <div class="closer">
      <button class="icon close-btn" on:click={close}>
        <Icon path={mdiClose} />
      </button>
    </div>
    <div class="modal-dialog" transition:zoomFrom={target}>
      <Card {doc} autoselect {readonly} />
    </div>
  </CustomModal>
{/if}

<style>
  :global(.card-modal.modal-container) {
    z-index: 960;
    contain: layout;
  }
  :global(.card-modal.modal-container) .modal-dialog {
    max-width: var(--card-width);
    padding: 0;
    background: transparent;
    border-radius: 2px;
    contain: layout;
    margin-bottom: 80px;
  }
  :global(.card-modal.modal-container) .modal-dialog :global(.notecard) {
    margin: 0;
    box-shadow: none;
  }
  .closer {
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 100;
  }
  .icon.close,
  :global(.size-desktop) button.icon:not([disabled]):hover {
    font-size: 30px;
    background: var(--home-background);
    border-radius: 50%;
    box-shadow: var(--flat-box-shadow);
  }
  @media (min-width: 500px) {
    :global(.card-modal.modal-container) .modal-dialog {
      margin: 80px auto;
    }
  }
</style>
