import { Doc, Project, ProjectDocs } from '../../types';
import { AllSettings } from '../settings';

export interface Docs extends ProjectDocs {
  project?: Project;
  [id: string]: Doc;
}

export type VirtualDocsFunction = (project: Project) => Doc[];

export const emptyDocs: Docs = {
  trash: {
    id: 'trash',
    type: 'trash',
    children: [],
  },
};

/**
 * Returns a lookup of all docs for the project, including the project itself and any virtual docs.
 */
export function getDocs(project: Project, settings: AllSettings) {
  if (!project) return emptyDocs;
  const docsLookup: Docs = { project, [project.id]: project, ...project.docs };

  const virtualDocs: VirtualDocsFunction = settings[project.type] && settings[project.type].virtualDocs;
  if (virtualDocs) {
    virtualDocs(project).forEach(doc => {
      doc.virtual = true;
      docsLookup[doc.id] = doc;
    });
  }

  return docsLookup;
}
