import { readable, Readable, Subscriber, Unsubscriber } from 'easy-signal';
import { tick } from 'svelte';

export interface Router {
  url: string;
  path: string;
  query: URLSearchParams;
  matches(path: string): any;
}

export interface RouterStore extends Readable<Router> {
  beforeChange(listener: Subscriber<Router>): Unsubscriber;
  getUrl(): string;
  getInitialQuery(): URLSearchParams;
  getQuery(): URLSearchParams;
  navigate: (path: string, replace?: boolean) => Promise<void>;
  listen: (win?: Window) => Unsubscriber;
}

export function createRouterStore(base = '', useHash = false): RouterStore {
  const listeners = new Set<Subscriber<Router>>();
  let url = getCurrentUrl(base, useHash);
  let path = getCurrentPath(base, useHash);
  let query = getCurrentQuery(useHash);
  const initialQuery = query;
  const baseExp = new RegExp('^' + base);

  const { get, subscribe } = readable<Router>({ url, path, query, matches }, set => {
    function onURLChange() {
      url = getCurrentUrl(base, useHash);
      path = getCurrentPath(base, useHash);
      query = getCurrentQuery(useHash);
      set({ url, path, query, matches });
    }

    addEventListener('popstate', onURLChange);
    addEventListener('pushstate', onURLChange);
    addEventListener('replacestate', onURLChange);
    return () => {
      removeEventListener('popstate', onURLChange);
      removeEventListener('pushstate', onURLChange);
      removeEventListener('replacestate', onURLChange);
    };
  });

  function matches(somePath: string) {
    const route = getRoute(somePath);
    let match;
    if ((match = route.pattern.exec(path))) {
      const params: any = {};
      for (let i = 0; i < route.keys.length; ) {
        params[route.keys[i]] = match[++i] || null;
      }
      return params;
    } else {
      return null;
    }
  }

  function beforeChange(listener: Subscriber<Router>) {
    listeners.add(listener);
    return () => listeners.delete(listener);
  }

  async function navigate(path: string, replace?: boolean) {
    if (useHash) path = path.replace(/^#/, '');
    if (path[0] !== '/') path = normalizeUrl(path, base, useHash);
    listeners.forEach(listener => listener(get()));
    await tick();
    const method = ((replace ? 'replace' : 'push') + 'State') as 'pushState' | 'replaceState';
    history[method](path, null, (useHash ? '#' : '') + base + path);
    dispatchEvent(new Event(method.toLowerCase()));
  }

  function click(event: MouseEvent) {
    const a = (event.target as Element).closest('a'),
      href = a && a.getAttribute('href').replace(/^#/, '').replace(baseExp, '');
    if (a?.hasAttribute('disabled') || a?.classList?.contains('disabled')) return event.preventDefault();
    if (event.ctrlKey || event.metaKey || event.altKey || event.shiftKey || event.button || event.defaultPrevented)
      return;
    if (!href || a.target || a.host !== location.host || a.hasAttribute('ignore')) return;
    event.preventDefault();
    navigate(href, a.hasAttribute('replace'));
  }

  function listen(win = window) {
    win.addEventListener('click', click);
    return () => win.removeEventListener('click', click);
  }

  function getUrl() {
    return url;
  }

  function getInitialQuery() {
    return initialQuery;
  }

  function getQuery() {
    return query;
  }

  return {
    getUrl,
    getInitialQuery,
    getQuery,
    beforeChange,
    navigate,
    listen,
    get,
    subscribe,
  };
}

// CURRENT AND RELATIVE URLs

function getCurrentUrl(base: string, useHash?: boolean) {
  return (useHash ? location.hash.replace(/^#?\/?/, '/') : location.pathname + location.search).replace(base, '');
}

function getCurrentPath(base: string, useHash?: boolean) {
  return (useHash ? location.hash.replace(/^#?\/?/, '/').replace(/\?.*$/, '') : location.pathname).replace(base, '');
}

function getCurrentQuery(useHash?: boolean) {
  return new URLSearchParams(useHash ? location.hash.replace(/^#?[^?]*\??/g, '') : location.search);
}

// Alter relative hash links to absolute links
const doc = document.implementation.createHTMLDocument();
const baseElement = doc.createElement('base');
const anchor = doc.createElement('a');
doc.head.append(baseElement);
doc.body.appendChild(anchor);

function normalizeUrl(path: string, base: string, useHash?: boolean) {
  baseElement.href = base + getCurrentUrl(base, useHash);
  anchor.setAttribute('href', path);
  return anchor.href.split(anchor.host)[1].replace(base, '');
}

// ROUTES

interface Route {
  keys: string[];
  pattern: RegExp;
}

const cachedRoutes: { [path: string]: Route } = {};

function getRoute(path: string) {
  return cachedRoutes[path] || (cachedRoutes[path] = createRoute(path));
}

// Borrowed from regexparam, altered to support "/path/?*" to match "/path" AND "/path/something"
function createRoute(path: string | RegExp): Route {
  if (path instanceof RegExp) return { keys: null, pattern: path };

  const keys = [];
  let pattern = '';
  const arr = path.split('/');
  arr[0] || arr.shift();
  let part;

  while ((part = arr.shift())) {
    const char = part[0];
    if (char === '*' || (char === '?' && part[1] === '*')) {
      keys.push('wild');
      pattern += char === '?' ? '(?:/(.*))?' : '/(.*)';
    } else if (char === ':') {
      const opt = part.indexOf('?', 1);
      const ext = part.indexOf('.', 1);
      keys.push(part.substring(1, ~opt ? opt : ~ext ? ext : part.length));
      pattern += ~opt && !~ext ? '(?:/([^/]+?))?' : '/([^/]+?)';
      if (~ext) pattern += (~opt ? '?' : '') + '\\' + part.substring(ext);
    } else {
      pattern += '/' + part;
    }
  }

  return {
    keys,
    pattern: new RegExp('^' + pattern + '/?$', 'i'),
  };
}
