import { derived, whenMatches, writable } from 'easy-signal';
import { agreeable } from './agreeable';
import { createAgreeablePrefixedStore } from './agreeable/agreeable-stores';
import { signals } from './app-state';
import { getNow } from './date';
import { projectIdStore, uidStore } from './ids';
import { settingsStore } from './settings';
import { createProjectStore } from './stores/project';
import { createProjectMetasStore, createRolesStore } from './stores/project-metas';
import { createProjectDailyStats } from './stores/stats';
import { Project, ProjectMeta, ProjectSettings } from './types';
import { userProjectsStore } from './user-data';
import { EMPTY_OBJECT } from './util';

export const projectMetasStore = createProjectMetasStore(agreeable, userProjectsStore);
export const projectsRolesStore = createRolesStore(agreeable, userProjectsStore);

export const projectMetaStore = agreeable.projects(projectIdStore);
export const projectMetaSettingsStore = createAgreeablePrefixedStore(projectMetaStore, 'settings');
export const projectSharedStore = projectMetaStore.shared;

export const projectSettingsStore = derived(
  () => (settingsStore.get()[projectMetaStore.get()?.type] || EMPTY_OBJECT) as ProjectSettings
);
export const projectRolesStore = projectMetaStore.roles;
projectRolesStore.load(); // Call load now, once the id is set it will load

export const projectDocStore = projectMetaStore.doc;
export const projectStore = createProjectStore(projectDocStore, settingsStore);

export const projectStatsStore = createProjectDailyStats(projectMetaStore, projectStore);

export const creatingProject = writable('');

export async function loadProject(projectId: string) {
  await projectStore.load(projectId);
  // If a project is empty, it isn't fully loaded from the server. Wait for it to sync.
  if (!projectStore.get().project?.children?.length) {
    await whenMatches(projectStore, ({ project }) => !!project?.children?.length);
  }
  await Promise.all([projectMetaStore.load(), projectRolesStore.load(), projectSharedStore.load()]);
  await signals.finishedProjectLoad();
}

export async function unloadProject() {
  await projectStore.unload();
}

export async function createProject(newProject?: Partial<Project>, meta?: Partial<ProjectMeta>) {
  // Create a new project by starting with the doc (this gives us an id, so we do it first)
  const newProjectObj = await projectStore.createProject(newProject);
  creatingProject.set(newProjectObj.id);

  // Create the project meta
  const projectMetaStore = agreeable.projects(newProjectObj.id);
  const { type } = newProjectObj;
  await projectMetaStore.update({ ...meta, type, creator: uidStore.get() });
  const projectMeta = projectMetaStore.get();

  // Update our user project store
  await userProjectsStore.update({ [newProjectObj.id]: { lastOpened: getNow() } });
  const userProject = userProjectsStore.get()[newProjectObj.id];
  const detail = { project: newProjectObj, userProject, projectMeta };
  signals.onProjectCreated(detail);
  creatingProject.set('');
  return detail;
}

export async function deleteProject(projectId: string, forceDelete = false) {
  const userProject = userProjectsStore.get()[projectId];

  if (userProject.category === 'trash') {
    const rolesStore = agreeable.projects(projectId).roles;
    const roles = await rolesStore.load();
    const isOwner = roles.users[uidStore.get()]?.role !== 'owner';
    await userProjectsStore.update(patch => patch.remove(`/${projectId}`));
    if (isOwner) {
      await agreeable.projects(projectId).delete();
    }
  } else {
    await userProjectsStore.update(patch => patch.replace(`/${projectId}/category`, 'trash'));
  }
}
