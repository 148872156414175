import { Doc, Project } from '../../types';
import { InTrash } from './in-trash';

/**
 * This module helps working with links in the project.
 */

export interface Link {
  from: Doc;
  rel: string;
  to: Doc;
  data: any;
}

export interface LinkMap {
  [docId: string]: Link[];
}

export interface LinkSet {
  from: LinkMap;
  to: LinkMap;
  all: {
    from: LinkMap;
    to: LinkMap;
  };
}

export const emptyLinkSet: LinkSet = { from: {}, to: {}, all: { from: {}, to: {} } };

/**
 * Creates an efficient lookup of all links in the project, including the from and to links for each doc.
 */
export function getLinks(project: Project, inTrash: InTrash) {
  if (!project?.links) return emptyLinkSet;
  const linkSet = { from: {}, to: {}, all: { from: {}, to: {} } };

  Object.keys(project.links).forEach(key => {
    const data = project.links[key];
    const [fromId, rel, toId] = key.split(':');
    const from = project.docs[fromId];
    const to = project.docs[toId];
    if (!from || !to) return;
    const link: Link = { from, rel, to, data };
    addLink(linkSet.all.from, `${fromId}:`, link);
    addLink(linkSet.all.from, `${fromId}:${rel}`, link);
    addLink(linkSet.all.to, `${toId}:`, link);
    addLink(linkSet.all.to, `${toId}:${rel}`, link);
    if (!inTrash[fromId] && !inTrash[toId]) {
      addLink(linkSet.from, `${fromId}:`, link);
      addLink(linkSet.from, `${fromId}:${rel}`, link);
      addLink(linkSet.to, `${toId}:`, link);
      addLink(linkSet.to, `${toId}:${rel}`, link);
    }
  });

  return linkSet;
}

function addLink(map: LinkMap, key: string, link: Link) {
  (map[key] || (map[key] = [])).push(link);
}
