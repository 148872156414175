import { readable } from 'easy-signal';

export function whenInViewport(element: Element, onVisible: () => void) {
  const observer = new IntersectionObserver(entries => {
    if (entries[0].isIntersecting) {
      onVisible();
    }
  });

  observer.observe(element);
  return {
    destroy: () => observer.disconnect(),
  };
}

export function isInViewport(element: Element) {
  const rect = element.getBoundingClientRect();
  return rect.bottom > 0 && rect.right > 0 && rect.top < window.innerHeight && rect.left < window.innerWidth;
}

export function createInViewportStore(element: Element) {
  return readable(false, set => {
    set(isInViewport(element));
    const observer = new IntersectionObserver(entries => set(entries[0].isIntersecting));
    observer.observe(element);
    return () => observer.disconnect();
  });
}
