import { version } from '@dabble/version';
import { derived, readable, writable } from 'easy-signal';
import { tick } from 'svelte';
import { DESKTOP, isMobileMode, sizeStore, touchEnabled } from './device';
import { docSettingsStore, docStore } from './doc-data';
import { routerStore } from './navigation';
import { projectStore } from './project-data';
import { settingsStore } from './settings';
import { createDocumentStore } from './stores/documents';
import { createFocusStore } from './stores/focus';
import { createLastProjectUrl, createLastUrl } from './stores/history';
import { dynamicStoredWritable, locallyStoredWritable } from './stores/locally-stored-writable';
import { createViewport } from './stores/viewport';
import { AppUpdate, Doc } from './types';
import { preferencesStore } from './user-data';
export * from '../toolkit/Globals.svelte';

const MAX_COMPONENT_DEPTH = 20;

export interface ShownCard {
  docId: string;
  target: HTMLElement;
  readonly: boolean;
}

export type AppHeaderData = { hideLogo: boolean };

export const documentsStore = createDocumentStore();
export const loadingQuoteClearedStore = writable(false);
export const hideMainStore = writable(false);
export const hideLeftNavStore = locallyStoredWritable('hideLeftNav', false);
export const hideRightNavStore = locallyStoredWritable('hideRightNav', false);
export const sidebarStore = locallyStoredWritable('sidebar', 'goals'); // The visible sidebar (e.g. 'goals')
export const leftNavWidthStore = locallyStoredWritable<number>('leftNavWidth', 227);
if (leftNavWidthStore.get() < 180) leftNavWidthStore.set(null);
export const sidebarWidthKeyStore = derived(() => `sidebar-size-${sidebarStore.get()}`);
export const sidebarWidthStore = dynamicStoredWritable<number>(sidebarWidthKeyStore, 227, value =>
  value < 180 ? null : value
);
export const workspaceWidthStore = writable(0);
export const mobileShowNavStore = writable<'left' | 'right' | null>(null);
export const focusStore = createFocusStore(preferencesStore, routerStore);
export const writingStore = writable(false);
export const virtualVisibleStore = writable<any[]>(null);
export const displayedDocIdStore = writable<string>(null); // Will dispatch after the doc is finished displaying for the first time only
export const displayedDocStore = writable<Doc>(null); // Will dispatch after the doc is finished displaying
export const displayingDocStore = writable<boolean>(false); // True while the doc is displaying
export const viewport = createViewport(displayedDocIdStore);
export const selectedCountStore = writable(0);
export const appUpdateStore = writable<AppUpdate>({ version });
export const appHeaderStore = writable({ hideLogo: false });
export const shownCardStore = writable<ShownCard>(null);
export const shownModalStore = writable<string>(null);
export const lastUrlStore = createLastUrl(routerStore);
export const lastProjectUrlStore = createLastProjectUrl(lastUrlStore);
export const fontStore = writable<string>('');
export const readToMeDocIdStore = writable(null);
export const isTouchStore = writable(touchEnabled);
export const editingModeStore = writable<0 | 1 | 2>(isMobileMode ? 1 : 0); // 0 not mobile, 1 mobile not-editing, 2 mobile editing
export const showLeftPaneStore = derived(
  () =>
    !focusStore.get().focused &&
    !(hideLeftNavStore.get() || (sizeStore.get() !== DESKTOP && mobileShowNavStore.get() !== 'left')) &&
    editingModeStore.get() !== 2
);
export const showRightPaneStore = derived(
  () =>
    !focusStore.get().focused &&
    !(
      hideRightNavStore.get() ||
      docSettingsStore.get()?.hideRightNav ||
      (sizeStore.get() !== DESKTOP && mobileShowNavStore.get() !== 'right')
    ) &&
    editingModeStore.get() !== 2
);
export const showRightNavStore = derived(
  () =>
    showRightPaneStore.get() &&
    settingsStore.getValuesFromPlugins('sidebar.' + sidebarStore.get(), projectStore.get().project, docStore.get())
      .length > 0
);
export const sideToolbarWidth = 49;
export const rightPaneTotalWidthStore = derived(
  () => ((showRightNavStore.get() && sidebarWidthStore.get()) || 0) + sideToolbarWidth
);

sizeStore.subscribe(size => {
  if (size === 'desktop' && editingModeStore.get()) {
    editingModeStore.set(0);
  } else if (isMobileMode && !editingModeStore.get()) {
    editingModeStore.set(1);
  }
});

export async function afterAllUpdates(callback: Function) {
  await waitForUpdates();
  callback();
}

export async function waitForUpdates() {
  for (let i = 0; i < MAX_COMPONENT_DEPTH; i++) {
    await tick();
  }
}

export const isOnlineStore = readable(window.navigator.onLine, set => {
  set(window.navigator.onLine);
  const on = () => set(true);
  const off = () => set(false);
  globalThis.addEventListener('online', on);
  globalThis.addEventListener('offline', off);
  return () => {
    globalThis.removeEventListener('online', on);
    globalThis.removeEventListener('offline', off);
  };
});
