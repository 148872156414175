<script lang="ts">
  import { t } from '@dabble/data/intl';
  import { goto } from '@dabble/data/navigation';
  import { Project } from '@dabble/data/types';
  import { lastProjectUrlStore } from '@dabble/data/ui';
  import { getProjectUrl } from '@dabble/data/user-project';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiArrowLeft, mdiClose } from '@mdi/js';

  export let project: Project = undefined;
  export let saving = false;
  let saved = false;

  $: redirect = (project ? getProjectUrl(project.id) : $lastProjectUrlStore) || '/';
  $: onSaved(saving);

  function onSaved(saving: boolean) {
    if (saving) saved = true;
    else if (saved)
      setTimeout(() => {
        saved = false;
      }, 2000);
  }
</script>

<div class="screen">
  <div class="inner-screen">
    <div class="closer">
      <button class="icon dislay-icon-close" on:click={() => goto(redirect)}>
        <Icon path={mdiClose} />
      </button>
    </div>
    <a class="back-btn btn text" href={redirect}>
      <div class="inside">
        <Icon path={mdiArrowLeft} />
        {redirect === '/' ? $t('back_home') : $t('back_project')}
      </div>
    </a>
    <div class="content">
      {#if $$slots.title}
        <div class="section">
          <h1 class="screen-title">
            <slot name="title" />
          </h1>
        </div>
      {/if}
      <slot />
      {#if saving || saved}
        <span class="offline-indicator badge success">{$t(saving ? 'saving' : 'saved')}</span>
      {/if}
    </div>
  </div>
</div>

<style>
  .screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    overflow-y: auto;
  }
  .inner-screen {
    position: relative;
    width: 684px;
    padding: 0 8px;
  }

  .closer {
    position: sticky;
    float: right;
    top: 10px;
    height: 0;
    z-index: 100;
    margin-right: -54px;
  }
  .dislay-icon-close {
    font-size: 30px;
    background: var(--home-background);
    border-radius: 100px;
    box-shadow: var(--flat-box-shadow);
  }
  .back-btn.btn.text {
    position: relative;
    display: inline-block;
    padding: 20px 20px 20px 0;
    margin-bottom: -20px;
    align-self: flex-start;
  }
  .back-btn.btn.text > .inside {
    display: flex;
    align-items: center;
    color: var(--text-color-lighter);
  }
  .screen-title {
    font-size: var(--font-size-xxl);
    padding-bottom: 16px;
    margin: 16px 0 0;
  }
  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    margin: 40px 0;
  }
  .screen :global(h2) {
    font-size: var(--font-size-xl);
    margin: 3rem 0 2rem;
  }
  .screen :global(h3) {
    font-size: var(--font-size-lg);
  }
  .screen :global(h4) {
    font-size: var(--font-size-base);
  }
  .screen :global(.section) {
    position: relative;
    padding: 20px;
    margin-bottom: 24px;
    border-radius: 5px;
    background-color: var(--white);
    box-shadow: var(--flat-box-shadow);
  }
  .screen :global(.section h2) {
    font-size: var(--font-size-xl);
    border-bottom: 1px solid var(--gray-lighter);
    padding-bottom: 8px;
    margin: 0 0 16px;
  }
  .screen :global(.small-section-label) {
    color: var(--text-color-lightest);
  }
  .screen :global(.description) {
    font-size: var(--font-size-sm);
    color: var(--text-color-lighterer);
  }
  .screen :global(.note) {
    font-size: var(--font-size-xs);
    color: var(--text-color-lighterer);
    font-style: italic;
  }
  .screen :global(.offline-indicator) {
    position: absolute;
    top: -6px;
    right: 0;
    z-index: 1;
    box-shadow: var(--top-down-shadow);
  }
  @media (max-width: 794px) {
    .closer {
      top: 30px;
      margin-right: 18px;
    }
  }
  @media (max-width: 688px) {
    .inner-screen {
      width: 100%;
    }
  }
  @media (max-width: 600px) {
    .content {
      padding: 10px 0;
    }
    .screen :global(.section) {
      margin-bottom: 10px;
    }
  }
</style>
