import { projectStore } from '@dabble/data/project-data';
import { waitForUpdates } from '@dabble/data/ui';
import { addListener } from '@dabble/toolkit/listen';
import { Readable, Unsubscriber, Writable, writable } from 'easy-signal';
import throttle from 'lodash/throttle';
import { Editor, EditorRange, isEqual } from 'typewriter-editor';
import { EditorElement } from '../editables';
import { Doc } from '../types';
import { LockableStore, lockable } from './lockable';

const editableSelector = '.editable-content[data-id][data-field]';
const docIndicatorSelector = `${editableSelector}, .doc[data-id]`;

const emptySelection: [Field, Editor, EditorRange] = [{ id: null, field: null }, null, null];
const scrollPadding = 16;

/**
 * Use the ViewportStore to restore scroll and selection and clean up and improve findReplace & VirtualList.
 * This should be tied closely with a custom VirtualList for the main viewport. The VL should render documents and store
 * updated data here. It should use this for placement information. All systems should use the viewport store to access
 * or manipulate data in the viewport.
 *
 * A virtual model of what is rendered. Have to know:
 * 1. what pages are in the view
 * 2. what text is rendered on those pages
 * 3. how long the text is estimated to be
 * 4. how long the pages are estimated to be
 * 5. the scroll position of a given paragraph
 *
 * Find/replace must use this virtual model to find and jump to each location within Dabble. Project-wide find/replace
 * should jump to each scene individually and not worry about the current selected document (unless that is easier).
 *
 * Find within the current doc should search the current virtual model.
 *
 * Some views are virtual, others are not. Find must work with both, or every view must have its virtual version, even
 * if it renders all the content as well.
 *
 * The views which are virtualized should use specialized components within them, rather than putting it in DocSection
 * and the configs.
 *
 * Each View needs to provide an API for its model for find. This allows a virtualized views and regular views to both
 * work with the same interface for find/replace. Also, plot grid may be able to open/close cards with an API like this.
 *
 *
 *
          bind:items={virtualItems}
          bind:heightMap={findView.virtualHeights}
          bind:scrollTo={findView.virtualScrollTo}
          bind:visible={$visibleDocs}
          let:item={doc}
 *
 */

export class ViewportSelection {
  constructor(
    public field: Field,
    public range: EditorRange
  ) {}
}

export interface Field {
  id: string;
  field: string;
}

export interface ViewportDocState {
  // scrollAnchor?: string;
  scrolledToDocId?: string;
  scrollOffset?: number;
  // scrollTop?: number;
  selection?: ViewportSelection | null;
}

export interface Viewport {
  view: Writable<ViewportView>;
  container: Writable<HTMLElement>;
  scrollTop: Writable<number>;
  selection: Writable<ViewportSelection>;
  focusedDocId: LockableStore<string>;
  selectedEditor: Readable<Editor>;
  waitForLoading(): Promise<void>;
  skipNextRestore(): void;
  getFields(): Field[];
  getEditor(field: Field): Promise<Editor>;
  getSelection(): [Field, Editor, EditorRange];
  getSelectedText(): string;
  select(field: Field, range: EditorRange, scrollToTopArea?: boolean): Promise<Editor>;
  selectNearTop(): Promise<Editor>;
  scrollIntoView(field: Field, index?: number, scrollToTopArea?: boolean): Promise<Editor>;
}

export interface VirtualList {
  scrollTo(doc: Doc): Promise<void>;
}

// An API for find/replace to ask the viewport what is available and to tell it to select text. This is needed because
// some views are virtualized and the view itself needs to handle the scrolling and selection. This also allows for
// views like the plot grid to pop open a card for searching within plot cards.
export class ViewportView {
  // Return the fields within this view (used for find/replace), e.g. [{ docId, name }, { docId, name }, ...]
  getFields(container: HTMLElement): Field[] {
    const editables = Array.from(container.querySelectorAll(editableSelector)) as HTMLElement[];
    return editables.map(editable => ({ id: editable.dataset.id, field: editable.dataset.field }));
  }

  getSelection(container: HTMLElement): [Field, Editor, EditorRange] {
    const selection = container.ownerDocument.getSelection();
    let anchor = selection.anchorNode as Element;
    if (anchor && anchor.nodeType === Node.TEXT_NODE) anchor = anchor.parentNode as Element;
    if (anchor) {
      const editable = anchor.closest(editableSelector) as HTMLElement;
      if (editable) {
        const {
          dataset: { id, field },
        } = editable;
        const editor = (editable.querySelector('.typewriter-editor') as EditorElement)?.editor;
        if (id && field && editor && editor.doc.selection) {
          return [{ id: id, field: field }, editor, editor.doc?.selection];
        }
      }
    }

    return emptySelection;
  }

  // Select the content for a given range in the given field, scrolling into view if needed
  async getEditor(container: HTMLElement, field: Field) {
    if (!container) return;
    const root = container.querySelector(`${getFieldSelector(field)} > .typewriter-editor`);
    return root && ((root as any).editor as Editor);
  }

  // Select the content for a given range in the given field, scrolling into view if needed
  async select(container: HTMLElement, field: Field, range: EditorRange, scrollToTopArea?: boolean): Promise<Editor> {
    if (!container) return;
    if (!range || !field) {
      const active = container.ownerDocument.activeElement as HTMLElement;
      if (active.matches('.typewriter-editor')) {
        active.blur();
      }
      return;
    }
    const root = container.querySelector(`${getFieldSelector(field)} > .typewriter-editor`);
    const editor = root && ((root as any).editor as Editor);
    if (!editor) return;
    if (range !== null) {
      const [, currentEditor, currentRange] = this.getSelection(container);
      if (currentEditor && currentRange && currentEditor !== editor) {
        currentEditor.select(null);
      }
      editor.root.focus();
      editor.select(range);
      const bounds = editor && editor.getBounds(range, container, true);
      scrollRectIntoView(container, bounds, scrollToTopArea);
    } else {
      const sel = container.ownerDocument.getSelection();
      sel.removeAllRanges();
    }
    return editor;
  }

  async selectNearTop(container: HTMLElement) {
    const { scrollTop } = container;
    let { clientHeight } = container;
    clientHeight = Math.floor(clientHeight / 3);
    const scrollBottom = scrollTop + clientHeight;

    const roots = Array.from(container.querySelectorAll(`.typewriter-editor`)).sort((a, b) => {
      const rectA = a.getBoundingClientRect();
      const rectB = b.getBoundingClientRect();
      return (
        closeness(rectA.top, rectA.bottom, scrollTop, scrollBottom) -
        closeness(rectB.top, rectB.bottom, scrollTop, scrollBottom)
      );
    });
    const root = roots[0] as HTMLElement;
    const editor = root && ((root as any).editor as Editor);
    if (!editor) return;

    root.focus();
    const index = editor.getIndexFromPoint(0, container.getBoundingClientRect().top + clientHeight / 2);
    editor.select([index, index]);
    let bounds = editor.getBounds(index, container, true);
    if (!bounds) bounds = editor.getBounds(0, container, true);
    if (bounds) scrollRectIntoView(container, bounds, true);
    return editor;
  }

  // Scroll the given index into view
  async scrollIntoView(container: HTMLElement, field: Field, index = 0, scrollToTopArea?: boolean): Promise<Editor> {
    const root = container.querySelector(`${getFieldSelector(field)} > .typewriter-editor`);
    const editor = root && ((root as any).editor as Editor);
    const bounds = editor && editor.getBounds(index, container, true);
    if (!bounds) return;
    scrollRectIntoView(container, bounds, scrollToTopArea);
    return editor;
  }
}

export class VirtualViewportView extends ViewportView {
  constructor(
    public list: VirtualList,
    public isTop: (doc: Doc) => boolean,
    public getFields: (container: HTMLElement) => Field[]
  ) {
    super();
  }

  async getEditor(container: HTMLElement, field: Field) {
    if (field && !container.querySelector(`${getFieldSelector(field)} > .typewriter-editor`)) {
      await this.scrollIntoView(container, field, 0);
    }
    return super.getEditor(container, field);
  }

  async select(container: HTMLElement, field: Field, range: EditorRange, scrollToTopArea?: boolean): Promise<Editor> {
    if (field && !container.querySelector(`${getFieldSelector(field)} > .typewriter-editor`)) {
      await this.scrollIntoView(container, field, range[0], scrollToTopArea);
    }
    return super.select(container, field, range);
  }

  async scrollIntoView(container: HTMLElement, field: Field, index = 0, scrollToTopArea?: boolean) {
    let top = projectStore.getDoc(field.id);
    while (top && !this.isTop(top)) {
      top = projectStore.getParent(top.id);
    }
    if (!top) return;
    await this.list.scrollTo(top);
    return super.scrollIntoView(container, field, index, scrollToTopArea);
  }
}

const defaultViewportView = new ViewportView();

/**
 * Defines what is visible in the viewport, the scroll position, and the selection. The viewport is the area in Dabble
 * where the view for documents is displayed. Some of this functionality will not apply to non-page views such as the
 * plot grid.
 */
export function createViewport(displayedDocId: Readable<string>): Viewport {
  const view = writable(defaultViewportView);
  const listeners: Unsubscriber[] = [];
  const container = writable<HTMLElement>(null);
  const scrollTop = writable(0);
  const selection = writable<ViewportSelection>(null);
  const scrolledToDocId = lockable<string>(null);
  const focusedDocId = lockable<string>(null);
  const selectedEditor = writable<Editor>(null);
  let loading: Promise<any>;

  let cache: ViewportDocState = {};
  let updating = false;
  let skipNext = false;

  const updateCache = throttle((updates: Partial<ViewportDocState>) => {
    const docId = displayedDocId.get();
    if (!docId) return;
    cache = { ...cache, ...updates };
    sessionStorage.setItem(`viewportCache-${docId}`, JSON.stringify(cache));
  }, 0);

  displayedDocId.subscribe(reset);
  scrollTop.subscribe(updateScroll);
  selection.subscribe(updateSelection);
  container.subscribe(value => {
    updateContainer(value);
    if (value) reset();
  });
  scrolledToDocId.subscribe(refreshFocusedDoc);

  async function waitForLoading() {
    await loading;
  }

  async function reset() {
    loading = performReset();
  }

  async function performReset() {
    const docId = displayedDocId.get();
    view.set(defaultViewportView);
    scrolledToDocId.set(null);
    focusedDocId.set(null);
    selectedEditor.set(null);
    let scrollTopValue = 0;
    let selectionValue: ViewportSelection | null = null;

    if (docId && container.get()) {
      updating = true;
      await waitForUpdates();
      cache = JSON.parse(sessionStorage.getItem(`viewportCache-${docId}`) || '{}');
      // if (cache.scrollTop) scrollTopValue = cache.scrollTop;
      if (cache.selection) selectionValue = cache.selection;
      const element = container.get();
      if (!skipNext) {
        if (element) {
          if (cache.scrolledToDocId) {
            await view.get().scrollIntoView(element, { id: cache.scrolledToDocId, field: null });
            const selector = editableSelector.replace('[data-id]', `[data-id="${cache.scrolledToDocId}"]`);
            const editable = element.querySelector(selector) as HTMLElement;
            if (editable) {
              const elementTop = getOffsetWithin(editable, element);
              element.scrollTop = scrollTopValue = elementTop + cache.scrollOffset;
            }
          }

          await waitForScroll(element, scrollTopValue);
          await waitForScroll(element, scrollTopValue);
          scrollTop.set(scrollTopValue);
          selection.set(selectionValue);
        } else {
          scrollTop.set(scrollTopValue);
          selection.set(selectionValue);
        }
        await refreshScrolldToDoc();
      } else {
        skipNext = false;
      }
      updating = false;
    } else {
      cache = {};
    }
  }

  function updateContainer(newContainer: HTMLElement | null) {
    listeners.forEach(unsubscribe => unsubscribe());
    listeners.length = 0;
    if (newContainer) {
      listeners.push(addListener(newContainer, 'scroll', onScroll));
      listeners.push(addListener(newContainer, 'select', onSelect));
    }
  }

  function updateScroll(value: number) {
    const element = container.get();
    if (element && element.scrollTop !== value) {
      element.scrollTop = value;
    }
    refreshScrolldToDoc();
    // updateCache({ scrollTop: value });
  }

  function updateSelection(value: ViewportSelection | null) {
    const element = container.get();
    const existing = element && getViewportSelection(element);
    if (element && !isEqual(existing, value)) {
      view.get().select(element, value?.field, value?.range);
    }
    updateCache({ selection: value });
    refreshFocusedDoc();
  }

  function onScroll() {
    const element = container.get();
    if (updating || !element || element.scrollTop === scrollTop.get()) return;
    scrollTop.set(element.scrollTop);
  }

  function onSelect() {
    const element = container.get();
    if (updating || !container) return;
    const selectionValue = getViewportSelection(element);
    if (!isEqual(selectionValue, selection.get())) {
      selection.set(selectionValue);
    }
  }

  async function refreshScrolldToDoc() {
    if (!container.get() || !displayedDocId.get()) await waitForUpdates();
    if (!container.get() || !displayedDocId.get()) {
      scrolledToDocId.set(null);
      focusedDocId.set(null);
      selectedEditor.set(null);
      return;
    }

    const element = container.get();
    const { project, docs } = projectStore.get();
    const editables = element.querySelectorAll(docIndicatorSelector) as NodeListOf<HTMLElement>;
    if (!editables.length) {
      scrolledToDocId.set(null);
      focusedDocId.set(null);
      selectedEditor.set(null);
      return;
    }

    const scrollTop = element.scrollTop;
    const scrollBottom = scrollTop + element.offsetHeight;
    let scrolledTo = null;
    let scrollOffset = 0;
    const tops: { [id: string]: number } = {};
    const SCROLL_ADJUSTMENT = 48; // at least 1/4 inch from the top

    for (let i = 0; i < editables.length; i++) {
      const editable = editables[i];
      const id = editable.dataset.id;
      const doc = project.id === id ? project : docs[id];
      if (!doc) continue;

      const elementTop = getOffsetWithin(editable, element);
      // SCROLL_ADJUSTMENT is added in, because of a special case when there is one chapter and one scene. The scene card doesn't show up
      // because the check for containment always succeeds at the chapter level. This adjustment doesn't seem to affect any other case,
      // so it should be okay. It's a small enought adjustment.
      const elementBottom = elementTop + editable.offsetHeight - SCROLL_ADJUSTMENT;
      if (!tops[doc.id]) tops[doc.id] = elementTop;

      if (elementBottom > scrollTop && elementTop < scrollBottom) {
        scrolledTo = doc.id;
        scrollOffset = scrollTop - tops[scrolledTo];
        break;
      } else if (elementTop > scrollBottom) {
        break;
      }
    }

    scrolledToDocId.set(scrolledTo);
    updateCache({ scrolledToDocId: scrolledTo, scrollOffset });
  }

  async function refreshFocusedDoc() {
    if (!container.get() || !displayedDocId.get()) await waitForUpdates();
    if (!container.get() || !displayedDocId.get()) {
      focusedDocId.set(null);
      selectedEditor.set(null);
      return;
    }

    const element = container.get();
    const { project, docs } = projectStore.get();

    const selection = element.ownerDocument.getSelection();
    let anchor = selection.anchorNode as Element;
    if (anchor && anchor.nodeType === Node.TEXT_NODE) anchor = anchor.parentNode as Element;
    if (anchor) {
      const editable = anchor.closest(editableSelector) as HTMLElement;
      if (editable) {
        const editorElement = editable.querySelector('.typewriter-editor') as EditorElement;
        if (!editorElement) return;
        selectedEditor.set(editorElement.editor);
        const id = editable.dataset.id;
        const focusedDoc = project.id === id ? project : docs[id];
        if (focusedDoc) {
          focusedDocId.set(focusedDoc.id);
          return;
        }
      }
    }

    // If no doc has the focus, set the one that is currently scrolled in the viewport as the focus
    focusedDocId.set(scrolledToDocId.get());
  }

  function getOffsetWithin(node: HTMLElement, container: HTMLElement) {
    let offset = 0;
    while (node && node !== container) {
      offset += node.offsetTop;
      node = node.offsetParent as HTMLElement;
    }
    return offset;
  }

  return {
    view,
    container,
    scrollTop,
    selection,
    focusedDocId,
    selectedEditor,
    waitForLoading,

    // Skip the next selection/scroll restore when the page loads so find-replace can set it
    skipNextRestore() {
      skipNext = true;
    },

    getFields(): Field[] {
      return view.get().getFields(container.get());
    },
    getEditor(field: Field) {
      return view.get().getEditor(container.get(), field);
    },
    getSelection(): [Field, Editor, EditorRange] {
      return view.get().getSelection(container.get());
    },
    getSelectedText(): string {
      const [, editor] = view.get().getSelection(container.get());
      if (editor && editor.doc.selection) return editor.getText(editor.doc.selection);
      return '';
    },
    select(field: Field, range: EditorRange, scrollToTopArea?: boolean): Promise<Editor> {
      return view.get().select(container.get(), field, range, scrollToTopArea);
    },
    selectNearTop(): Promise<Editor> {
      return view.get().selectNearTop(container.get());
    },
    scrollIntoView(field: Field, index = 0, scrollToTopArea?: boolean): Promise<Editor> {
      return view.get().scrollIntoView(container.get(), field, index, scrollToTopArea);
    },
  };
}

export function scrollRectIntoView(container: Element, rect: DOMRect, topArea?: boolean) {
  const { scrollTop } = container;
  let { clientHeight } = container;
  if (topArea) clientHeight = Math.floor(clientHeight / 3);
  const scrollBottom = scrollTop + clientHeight;
  const centered = rect.bottom - Math.floor(clientHeight / 2);
  if (rect.top < scrollTop) {
    if (rect.bottom > scrollTop) {
      container.scrollTop = rect.top - scrollPadding;
    } else {
      container.scrollTop = centered;
    }
  } else if (rect.bottom > scrollBottom) {
    if (rect.top < scrollBottom) {
      container.scrollTop = rect.bottom - container.clientHeight + scrollPadding;
    } else {
      container.scrollTop = centered;
    }
  }
}

export function getFieldSelector(field: Field) {
  return `[data-id="${field.id}"][data-field${field.field ? `="${field.field}"` : ''}]`;
}

function getViewportSelection(element: HTMLElement): ViewportSelection | null {
  const sel = element.ownerDocument.getSelection();
  const target = (
    sel.focusNode && sel.focusNode.nodeType !== Node.ELEMENT_NODE ? sel.focusNode.parentNode : sel.focusNode
  ) as HTMLElement;
  const editor = (target?.closest('.typewriter-editor') as any)?.editor as Editor;
  return editor?.identifier
    ? new ViewportSelection({ id: editor.identifier.id, field: editor.identifier.field }, editor.doc.selection)
    : null;
}

function waitForScroll(container: HTMLElement, top: number) {
  if (container.scrollTop === top) return;
  container.scrollTop = top;
  return new Promise(resolve => {
    container.addEventListener('scroll', resolve, { once: true });
    setTimeout(resolve, 100);
  });
}

function closeness(aTop: number, aBottom: number, bTop: number, bBottom: number) {
  return aBottom < bTop // if before, get distance
    ? bTop - aBottom
    : aTop > bBottom // if after, get distance
      ? aTop - bBottom
      : Math.min(aBottom, bBottom) - Math.max(aTop - bTop); // get the amount of overlap
}
