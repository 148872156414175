<script lang="ts" context="module">
  declare global {
    interface Window {
      google: any;
    }
  }
</script>

<script lang="ts">
  import { rest } from '@dabble/data/rest';
  import { userAccountStore } from '@dabble/data/user-data';
  import { onMount } from 'svelte';

  export let connected = false;

  // This is google api client without the types
  let client: any;

  $: accessToken = $userAccountStore?.external?.google?.access_token;
  $: connected = accessToken ? true : false;

  function authorize() {
    client.requestCode();
  }

  async function revoke() {
    window.google.accounts.oauth2.revoke(accessToken, async () => {
      await rest.delete('google/auth').send();
    });

    connected = false;
  }

  function getRedirectUrl() {
    const url = new URL(location.href);
    const validUrl =
      url.hostname.endsWith('dabblewriter.com') ||
      url.hostname.startsWith('localhost') ||
      url.hostname.endsWith('dabble-writer.pages.dev');
    if (!validUrl) throw new Error('Not a valid origin');
    return `${url.origin}`;
  }

  onMount(() => {
    client = window.google.accounts.oauth2.initCodeClient({
      client_id: '890907808375-h9lo7bcj9805mrhjrcom6p7f1lgri4hs.apps.googleusercontent.com',
      scope: 'https://www.googleapis.com/auth/drive.file',
      ux_mode: 'popup',
      access_type: 'offline',
      callback: connectGoogle,
      redirect_uri: getRedirectUrl(),
    });
    window.google.accounts.id.prompt(); // also display the One Tap dialog
  });

  async function connectGoogle(response: any) {
    await rest.post('google/auth').send({
      code: response.code,
    });
    connected = true;
  }
</script>

{#if connected}
  <button class="btn" on:click={revoke}>Disconnect Google</button>
{:else}
  <button class="btn primary" on:click={authorize}>Connect Google</button>
{/if}
