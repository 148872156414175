import { readonlyStore } from '@dabble/data/app-state';
import { docIdStore } from '@dabble/data/ids';
import { localeStore } from '@dabble/data/intl';
import { plugins } from '@dabble/data/plugins';
import { projectMetaSettingsStore, projectStore } from '@dabble/data/project-data';
import { settingsStore } from '@dabble/data/settings';
import { Field } from '@dabble/data/stores/viewport';
import { viewport } from '@dabble/data/ui';
import { derived, observe, readable, writable } from 'easy-signal';
import { DecorationsModule, Editor, isEqual, normalizeRange } from 'typewriter-editor';

// ALLL voices available
export const systemVoicesStore = readable<SpeechSynthesisVoice[]>([], set => {
  if (!window.speechSynthesis) return;
  set(speechSynthesis.getVoices());
  speechSynthesis.onvoiceschanged = () => set(speechSynthesis.getVoices());
  return () => (speechSynthesis.onvoiceschanged = null);
});

// Voices available for the current locale
export const voicesStore = derived(() => {
    const lang = (projectMetaSettingsStore?.spellingLanguage || localeStore.get() || 'en-US') as unknown as string;
    const langShort = lang.slice(0, 2);
    return systemVoicesStore.get()
      .filter(v => v.lang.slice(0, 2) === langShort)
      .sort((a, b) => {
        const scoreA = voiceScore(a, lang),
          scoreB = voiceScore(b, lang);
        return scoreB - scoreA;
      });
  }
);

export const readingSpeedStore = derived(() => {
  return projectMetaSettingsStore.get()?.readingSpeed || 1;
});

export const readingVoiceStore = derived(() => {
  const readingVoice = projectMetaSettingsStore.get()?.readingVoice;
  let voice = voicesStore.get().find(v => v.name === readingVoice);
  if (!voice) voice = voicesStore.get().find(v => v.default) || voicesStore.get()[0];
  return voice;
});

export function createReadingStore() {
  let reading = false;
  let startPos = 0;
  let endPos = 0;
  let currentField: Field | null = null;
  let speech: SpeechSynthesisUtterance;
  let promise: Promise<SpeechSynthesisEvent>;
  const { get, set, subscribe } = writable(reading);
  docIdStore.subscribe(stop);
  addEventListener('beforeunload', stop);

  type Fields = { [id: string]: string[] };

  async function start() {
    if (!window.speechSynthesis) return;
    let [field, editor] = viewport.getSelection();
    if (!field || !field.id) {
      viewport.selectNearTop();
      [field, editor] = viewport.getSelection();
    }
    if (!field.id) return;

    readonlyStore.addLock('reading');
    const fields = viewport.getFields();
    let i = fields.findIndex(f => isEqual(f, field));
    if (i === -1) return;
    set((reading = true));
    let [at] = normalizeRange(editor.doc.selection) || [0];
    [at] = editor.doc.getLineRange(at);
    const alreadyRead: Fields = {};
    for (; i < fields.length; i++, at = 0) {
      const field = fields[i];
      if (!alreadyRead[field.id]) {
        alreadyRead[field.id] = [];
      }
      if (alreadyRead[field.id].find((f: string) => f === field.field)) continue;
      alreadyRead[field.id].push(field.field);
      editor = await viewport.scrollIntoView(field, at);
      let text = editor.getText([at, editor.doc.length]);
      if (!text.trim()) {
        const doc = projectStore.getDoc(field.id);
        const placeholder = settingsStore.getPlaceholder(doc, field.field);
        if (placeholder && settingsStore.getPlaceholderClass(doc, field.field) === 'unstyled-placeholder') {
          text = placeholder;
        } else {
          continue;
        }
      }
      const voice = readingVoiceStore.get();
      const paragraphs = text.match(/[^\n]+[\n]*/g) || [];
      startPos = at;
      if (!voice || voice.localService || navigator.userAgent.includes('Edg')) {
        for (let n = 0; n < paragraphs.length; n++) {
          if (!reading) break;
          const paragraph = paragraphs[n];
          endPos = startPos + paragraph.length;
          const decorator = (editor.modules.decorations as DecorationsModule).getDecorator('reading');
          decorator.clear().decorateText([startPos, endPos], { class: 'reading', ['data-ids']: field.id });
          decorator.apply();
          currentField = field;
          viewport.select(field, [startPos, endPos], true);
          if (!paragraph) continue;
          [speech, promise] = speakText(paragraph, null, null);
          await promise;
          speech = null;
          if (!reading) break;
          startPos = endPos;
        }
        editor.modules.decorations.removeDecorations('reading');
      } else {
        for (let j = 0; j < paragraphs.length; j++) {
          const paragraph = paragraphs[j];
          let sentences = [paragraph];
          if (paragraph.length > 240) {
            sentences = paragraph.match(/[^?!…—;:]+[.!?…—;:”]+[\n]*/g);
          }
          if (!sentences) {
            sentences = [];
          }

          for (let k = 0; k < sentences.length; k++) {
            const sentence = sentences[k];
            let lines = [sentence];
            if (sentence.length > 240) {
              lines = sentence.match(/[^.?!…—;:]+[.!?…—;:”]+[\n]*/g);
            }
            if (!lines) {
              lines = [];
            }
            for (let m = 0; m < lines.length; m++) {
              const line = lines[m];
              let phrases = [line];
              if (line.length > 240) {
                phrases = line.match(/[^.,?!…—;:]+[.,!?…—;:”]+[\n]*/g);
              }
              for (let n = 0; n < phrases.length; n++) {
                if (!reading) break;
                const phrase = phrases[n];
                endPos = startPos + phrase.length;
                const decorator = (editor.modules.decorations as DecorationsModule).getDecorator('reading');
                decorator.clear().decorateText([startPos, endPos], { class: 'reading', ['data-ids']: field.id });
                decorator.apply();
                currentField = field;
                viewport.select(field, [endPos, endPos], false);
                if (!phrase) continue;
                [speech, promise] = speakText(phrase, null, null);
                await promise;
                speech = null;
                if (!reading) break;
                startPos = endPos;
              }
              if (!reading) break;
              editor.modules.decorations.removeDecorations('reading');
            }
            if (!reading) break;
          }
          editor.modules.decorations.removeDecorations('reading');
          if (!reading) break;
        }
        editor.modules.decorations.removeDecorations('reading');
        if (!reading) break;
      }
      editor.modules.decorations.removeDecorations('reading');
      if (!reading) break;
    }
    readonlyStore.removeLock('reading');
    await viewport.select(currentField, [startPos, startPos], true);
    set((reading = false));
    editor.modules.decorations.removeDecorations('reading');
  }

  async function stop() {
    set((reading = false));
    readonlyStore.removeLock('reading');
    window.speechSynthesis?.cancel();

    let editor: Editor;
    if (currentField !== null) {
      editor = await viewport.select(currentField, [startPos, startPos], true);
      editor?.select([startPos, startPos]);
    }
    editor?.modules.decorations.removeDecorations('reading');
  }

  function restart() {
    if (!window.speechSynthesis || !speech) return;
    speechSynthesis.cancel();
    speech.rate = readingSpeedStore.get();
    speech.voice = readingVoiceStore.get();
    speechSynthesis.speak(speech);
  }

  return {
    start,
    stop,
    restart,
    get,
    set,
    subscribe,
  };
}

export const readingStore = createReadingStore();

// Update the reading when the reading speed or voice changes
observe(()=> {
  if (readingStore.get()) {
    readingStore.restart();
  }
});

plugins.register({ readingStore, voicesStore, readingVoiceStore, readingSpeedStore });

function speakText(
  text: string,
  onBoundary: (event: SpeechSynthesisEvent) => void,
  onEnd: (event: SpeechSynthesisEvent) => void
): [SpeechSynthesisUtterance, Promise<SpeechSynthesisEvent>] {
  const speech = new SpeechSynthesisUtterance(text);
  return [
    speech,
    new Promise(resolve => {
      speech.voice = readingVoiceStore.get();
      speech.rate = readingSpeedStore.get();
      speech.onboundary = onBoundary;
      speech.onend = onEnd
        ? event => {
            onEnd(event);
            resolve(event);
          }
        : resolve;
      speechSynthesis.speak(speech);
    }),
  ];
}

function voiceScore(voice: SpeechSynthesisVoice, lang: string) {
  let score = 0;
  if (voice.default) score += 100;
  if (!voice.localService) score += 10;
  if (voice.lang === lang) score += 1;
  return score;
}
