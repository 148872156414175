// It would be nice to have these in the model files, but in order to run tests that can load settings without the
// svelte files, I am placing these here.

import { settingsStore } from '@dabble/data/settings';
import GenericVirtualizedView from '@dabble/plugins/core/components/GenericVirtualView.svelte';
import { mdiCardTextOutline } from '@mdi/js';
import ProfileView from '../core/components/ProfileView.svelte';
import Book from './components/Book.svelte';
import CastSection from './components/CastSection.svelte';
import Chapter from './components/Chapter.svelte';
import FolderView from './components/Folder.svelte';
import ImagePage from './components/ImagePage.svelte';
import ManuscriptSection from './components/ManuscriptSection.svelte';
import NoteView from './components/Note.svelte';
import NoteImage from './components/NoteImage.svelte';
import Part from './components/Part.svelte';
import PlotGrid from './components/PlotGrid.svelte';
import PlotLine from './components/PlotLine.svelte';
import PlotPoint from './components/PlotPoint.svelte';
import PlotSection from './components/PlotSection.svelte';
import Scene from './components/Scene.svelte';

settingsStore.configure('novel_book', {
  view: {
    component: GenericVirtualizedView,
    searchableFields: ['title', 'subtitle', 'author'],
    ownComponent: Book,
  },
});

settingsStore.configure('novel_part', {
  view: {
    component: GenericVirtualizedView,
    searchableFields: ['title'],
    ownComponent: Part,
  },
});

settingsStore.configure('novel_chapter', {
  view: {
    component: Chapter,
    searchableFields: { novel_chapter: ['title'], novel_scene: ['body'] },
  },
});

settingsStore.configure('novel_prologue', {
  view: {
    component: Chapter,
    searchableFields: { novel_prologue: ['title'], novel_scene: ['body'] },
  },
});

settingsStore.configure('novel_scene', {
  view: {
    component: Scene,
    searchableFields: ['body'],
  },
});

settingsStore.configure('novel_folder', {
  view: {
    component: FolderView,
    searchableFields: ['title'],
  },
});

settingsStore.configure('novel_page', {
  view: {
    component: NoteView,
  },
});

settingsStore.configure('novel_plot_point', { view: { component: PlotPoint } });
settingsStore.configure('novel_book_scenes', { view: { component: PlotLine } });

settingsStore.configure('novel_plot', {
  view: {
    component: PlotGrid,
  },
});

settingsStore.configure('novel_plot_line', {
  view: {
    component: PlotLine,
  },
});

settingsStore.configure('novel_plot_point', {
  icon: mdiCardTextOutline,
  view: {
    component: PlotPoint,
  },
});

settingsStore.configure('novel_book_scenes', {
  view: {
    component: PlotLine,
  },
});

settingsStore.configure('novel_manuscript', {
  view: {
    component: ManuscriptSection,
  },
});

settingsStore.configure('novel_plots', {
  view: {
    component: PlotSection,
  },
});

settingsStore.configure('novel_notes', {
  view: {
    component: FolderView,
    searchableFields: [],
  },
});

settingsStore.configure('novel_image', {
  view: {
    component: ImagePage,
  },
});

settingsStore.configure('note_image', {
  view: {
    component: NoteImage,
  },
});

settingsStore.configure('novel_characters', {
  view: {
    component: FolderView,
    searchableFields: ['title'],
  },
});

settingsStore.configure('novel_cast', {
  view: {
    component: CastSection,
  },
});

settingsStore.configure('novel_character', {
  view: {
    component: ProfileView,
    searchableFields: ['title'],
  },
});
