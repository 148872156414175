<script lang="ts">
  import { readonlyStore } from '@dabble/data/app-state';
  import { t } from '@dabble/data/intl';
  import { projectStore } from '@dabble/data/project-data';
  import { settingsStore } from '@dabble/data/settings';
  import { Doc } from '@dabble/data/types';
  import { confirm } from '@dabble/data/ui';
  import { deleteTooltipText, EMPTY_ARRAY, getTitle, isDocEmpty } from '@dabble/data/util';
  import Card from '@dabble/toolkit/Card.svelte';
  import EditableContent from '@dabble/toolkit/EditableContent.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import { mdiPlusThick } from '@mdi/js';
  import PlotRowActions from './PlotRowActions.svelte';

  export let doc: Doc;
  let hoveredPointId = '';

  $: book = doc && doc.type === 'novel_book_scenes' && $projectStore.docs[doc.id.split('-')[0]];
  $: children = (doc && $projectStore.childrenLookup[doc.id]) || EMPTY_ARRAY;
  $: hideCreate = doc.type === 'novel_book_scenes' && !doc.children.length && !findFirst(book, 'novel_chapter');
  $: isPlotLine = doc.type === 'novel_plot_line';

  function addChild(after?: Doc) {
    const [parent, index] = getParentAndIndex(after && after.id);
    if (!parent) return; // Some error occurred
    const type = settingsStore.getFor(parent).defaultChildType;
    projectStore.createDoc({ type }, parent.id, index);
  }

  async function deleteChild(child: Doc) {
    const title = getTitle(child);
    if (isDocEmpty(child) || (await confirm($t('send_to_trash'), $t('trash_confirm', { title })))) {
      projectStore.trashDoc(child.id);
    }
  }

  function getParentAndIndex(afterId: string): [Doc?, number?] {
    const index = afterId ? doc.children.indexOf(afterId) : doc.children.length;
    if (!book) {
      return [doc, index];
    }

    const beforeId = doc.children[index - 1];
    if (beforeId) {
      const parent = $projectStore.parentsLookup[beforeId];
      const index = parent.children.indexOf(beforeId) + 1;
      return [parent, index];
    } else {
      const firstChapter = findFirst(book, 'novel_chapter');
      if (!firstChapter) return [];
      return [firstChapter, 0];
    }
  }

  function findFirst(doc: Doc, type: string): Doc {
    if (doc.type === type) return doc;
    const children = $projectStore.childrenLookup[doc.id];
    if (children) {
      for (let i = 0; i < children.length; i++) {
        const found = findFirst(children[i], type);
        if (found) return found;
      }
    }
    return null;
  }
</script>

<div data-id={doc.id} class="plot-line">
  {#if book}
    <div class="title">
      <h1>{settingsStore.getPlaceholder(doc)}</h1>
      <h5>for</h5>
      <EditableContent doc={book} field="title" class="title centered" header="h4" />
    </div>
  {:else}
    <EditableContent {doc} field="title" class="title centered" header="h1" />
  {/if}
  <div data-id={doc.id} class="plot-line-rows">
    <div class="plot-points">
      {#each children as point, i (point.id)}
        {#if !isPlotLine && $projectStore.parentsLookup[point.id].children[0] === point.id}
          <div class="chapter-ribbon-container">
            <p class="chapter-ribbon">
              <span class="chapter-ribbon-text">{getTitle($projectStore.parentsLookup[point.id])}</span>
            </p>
          </div>
        {/if}
        <!-- svelte-ignore a11y-no-static-element-interactions -->
        <div
          class="plot-point"
          on:mouseenter={() => (hoveredPointId = point.id)}
          on:mouseleave={() => (hoveredPointId = '')}
        >
          {#if !$readonlyStore}
            <div class="plot-point-actions">
              <PlotRowActions
                visible={hoveredPointId === point.id}
                above={children[i - 1] && hoveredPointId === children[i - 1].id}
                addTooltip={$t('new_document', {
                  type: $settingsStore[doc.type].defaultChildType,
                })}
                deleteTooltip={$t(deleteTooltipText(point))}
                on:add={event => addChild(point)}
                on:delete={event => deleteChild(point)}
              />
            </div>
          {/if}

          <Card doc={point} />
        </div>
      {/each}
      {#if !$readonlyStore && !hideCreate}
        <div class="plot-point">
          <!-- svelte-ignore a11y-no-static-element-interactions -->
          <div
            class="add-point-button"
            on:click={() => addChild()}
            on:keydown={() => addChild()}
            use:tooltipTop={$t('new_document', { type: $settingsStore[doc.type].defaultChildType })}
          >
            <button class="icon">
              <Icon path={mdiPlusThick} size="40px" />
            </button>
          </div>
        </div>
      {/if}
    </div>
  </div>
</div>

<style>
  .title h1,
  .title :global(h4),
  .title h5 {
    color: var(--text-color-lighter);
    text-shadow: var(--text-highlight);
    text-align: center;
  }
  .title h1 {
    margin-bottom: 0;
  }
  .title :global(h4) {
    font-size: 1.2rem;
    margin-top: 0;
  }
  .title h5 {
    font-size: 1rem;
    margin: 0;
  }

  .plot-line :global(.title h1) {
    color: var(--text-color-lighter);
    text-shadow: var(--text-highlight);
    text-align: center;
  }
  .plot-line {
    padding-bottom: 16px;
    contain: layout;
  }
  .plot-line-rows {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
  .plot-line :global(.plot-row-actions) {
    height: 100%;
  }
  .plot-points {
    flex-basis: var(--card-width);
    position: relative;
  }
  .plot-point {
    padding: 10px 0;
    position: relative;
  }
  .plot-point :global(.notecard) {
    margin: 0;
  }
  .plot-point-actions {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -30px;
  }
  .add-point-button {
    max-width: var(--card-width);
    height: var(--card-height);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 3px;
    border: 1px dashed var(--text-color-lightest);
  }
  .add-point-button:hover {
    background: var(--background-lightened);
  }
  .plot-point:hover :global(.icon),
  .plot-point:hover + :global(.plot-point .plot-row-actions .insert-above .icon) {
    opacity: 1;
  }
  /* chapter ribbon */
  .chapter-ribbon-container {
    margin: 30px 0px 0;
  }
  .chapter-ribbon {
    position: absolute;
    z-index: 1;
    margin-top: 1px;
    color: var(--text-color-normal);
    width: 84%;
    margin-left: -4px;
    margin-top: -25px;
  }
  .chapter-ribbon-text {
    display: inline-block;
    padding: 0.5em 0.8em;
    width: 100%;
    line-height: 1.2em;
    background: var(--gray-lighter);
    border-radius: 2px;
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.3);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
  }
</style>
