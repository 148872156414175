<script lang="ts">
  import { t } from '@dabble/data/intl';
  import { Doc } from '@dabble/data/types';
  // TODO core should not import from content
  import InlineMenuAddImageButton from '@dabble/plugins/content/components/InlineMenuAddImageButton.svelte';
  import EditableContent from '@dabble/toolkit/EditableContent.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiColumnsHeader } from '@dabble/toolkit/custom-icons';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import { mdiFormatColumns, mdiMinus } from '@mdi/js';

  export let doc: Doc;
  export let fillPage = false;
  export let readonly = false;
</script>

<EditableContent
  class={fillPage ? 'fill-page' : undefined}
  {doc}
  field="body"
  {readonly}
  let:commands
  let:active
  let:editor
>
  <svelte:fragment>
    {#if commands.hr}
      <button
        use:tooltipTop={$t('project_insert_hr')}
        class="menu-button icon no-icon"
        class:active={active.hr}
        on:click={commands.hr}
      >
        <Icon path={mdiMinus} />
      </button>
    {/if}
    {#if commands.dlh}
      <button
        use:tooltipTop={$t('project_insert_dlh')}
        class="menu-button icon no-icon"
        class:active={active.dlh}
        on:click={commands.dlh}
      >
        <Icon path={mdiColumnsHeader} />
      </button>
    {/if}
    {#if commands.dl}
      <button
        use:tooltipTop={$t('project_insert_dl')}
        class="menu-button icon no-icon"
        class:active={active.dl}
        on:click={() => commands.dl()}
      >
        <Icon path={mdiFormatColumns} />
      </button>
    {/if}
    <InlineMenuAddImageButton {editor} />
  </svelte:fragment>
</EditableContent>
