import { settingsStore } from '@dabble/data/settings';
import { onChapterTitlePaste, onHeaderPaste, onSceneBodyPaste } from '../paste-import';

settingsStore.configure('manuscript', {
  onPaste: {
    title: onChapterTitlePaste,
    subtitle: onHeaderPaste,
    author: onHeaderPaste,
  },
});

settingsStore.configure('novel_scene', {
  onPaste: {
    body: onSceneBodyPaste,
  },
});

settingsStore.configure('novel_chapter', {
  onPaste: {
    title: onChapterTitlePaste,
  },
});

settingsStore.configure('novel_part', {
  onPaste: {
    title: onHeaderPaste,
  },
});
