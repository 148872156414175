<script lang="ts">
  import { featuresStore } from '@dabble/data/global-data';
  import { t } from '@dabble/data/intl';
  import { projectMetasStore, projectStore } from '@dabble/data/project-data';
  import { Doc } from '@dabble/data/types';
  import { editingModeStore } from '@dabble/data/ui';
  import { EMPTY_OBJECT } from '@dabble/data/util';
  import EditableContent from '@dabble/toolkit/EditableContent.svelte';
  import Page from '@dabble/toolkit/Page.svelte';
  import Avatar from './Avatar.svelte';
  import Banner from './Banner.svelte';
  import RichBody from './RichBody.svelte';

  export let doc: Doc;
  export let last = true;
  let className: string = null;

  $: readonly = !$featuresStore.story_notes || $editingModeStore === 1;
  $: meta = $projectMetasStore[$projectStore.projectId];
  $: font = (meta && meta.settings?.font) || EMPTY_OBJECT;
  $: className =
    'profile-view' +
    ` paragraph-spacing-${font.noteSpacing || 'half'}` +
    ` paragraph-style-${font.noteStyle || 'modern'}`;
</script>

<div data-id={doc && doc.id} class={className}>
  <Page {doc}>
    <div class="header-image">
      <Banner {doc} canEdit />
    </div>
    <div class="avatar">
      <Avatar {doc} canEdit />
    </div>
    <EditableContent {doc} field="title" header="h1" class="title" {readonly} />
    <EditableContent {doc} field="subtitle" header="h2" class="subtitle" placeholder={$t('home_subtitle')} {readonly} />
    <RichBody {doc} readonly={!$featuresStore.story_notes} fillPage={last} />
  </Page>
</div>

<style>
  .header-image {
    margin: calc(-1 * var(--page-padding));
    margin-bottom: 0;
    aspect-ratio: 3 / 1;
    border-radius: 2px 2px 0 0;
    background-color: rgb(var(--neutral-300));
    overflow: hidden;
  }
  :global(html[data-theme='dark']) .header-image {
    background-color: rgb(var(--neutral-500));
  }
  .avatar {
    position: relative;
    width: 140px;
    width: clamp(120px, 20%, 140px);
  }
  .profile-view :global(.page .title h1) {
    margin: 0;
    padding: 1rem 0 0.5rem;
    padding-bottom: 0;
    text-align: left;
    font-size: 1.375rem;
    color: var(--text-color-light);
    font-weight: bold;
  }
  .profile-view :global(.page .subtitle h2) {
    margin: 0;
    padding: 0.5rem 0 1rem;
    text-align: left;
    font-size: 1rem;
    color: var(--text-color-lightest);
  }
</style>
