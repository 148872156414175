<script lang="ts">
  import { t } from '@dabble/data/intl';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiCheckboxMarkedCircleOutline } from '@mdi/js';
  import { onboardingAvailableStore, onboardingStore } from '../onboarding';

  function toggleOnboarding() {
    onboardingStore.update({ hide: !$onboardingStore.hide });
  }
</script>

{#if $onboardingAvailableStore}
  <button class="dropdown-item" class:checked={!$onboardingStore.hide} on:click={toggleOnboarding}>
    <Icon path={mdiCheckboxMarkedCircleOutline} />
    {$t('onboarding_getting_started')}
  </button>
{/if}
