{
  "editor_upload_image": "Insert Image",
  "editor_unsplash_image": "Search & Insert Unsplash Image",
  "unsplash_search": "Search Unsplash Images",
  "content_paste_images_removed_title": "Images Removed",
  "content_paste_images_removed_text": "One or more images were found in the content being pasted.  You will need to download to your computer and upload them to Dabble to use them.",
  "content_not_document": "Image Not Uploaded",
  "load_more": "Load More",
  "no_more_results": "There are no more results",
  "photo_by": "Photo by",
  "photos_at": "Photos from",
  "unsplash_timeout": "Unsplash search has timed out. You may be offline."
}
