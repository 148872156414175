<script lang="ts">
  import { t } from '@dabble/data/intl';
  import { projectStore } from '@dabble/data/project-data';
  import ImagePage from '@dabble/plugins/novel/components/ImagePage.svelte';
  import EditableContent from '@dabble/toolkit/EditableContent.svelte';
  import { number } from '@dabble/toolkit/helpers';
  import Page from '@dabble/toolkit/Page.svelte';
  import TableOfContents from './TableOfContents.svelte';

  export let doc;

  $: wordCount = $projectStore.counts[doc.id].wordCount;
</script>

{#if doc.image}
  <ImagePage {doc} margin={false} />
{/if}

<Page {doc} class="title-page">
  <div class="word-count">{$t('novel_book_word_count', { wordCount: number(wordCount), count: wordCount })}</div>
  <div>
    <EditableContent {doc} field="title" header="h1" />
    <EditableContent {doc} field="subtitle" hiddenPlaceholder header="h2" />
  </div>
  <div class="info">{$t('project_by')}</div>
  <EditableContent {doc} field="author" header="h3" />
</Page>

{#if doc.toc}
  <TableOfContents {doc} />
{/if}

<style>
  .word-count {
    position: absolute;
    right: var(--page-padding);
    top: var(--page-padding);
    font-size: 0.9em;
    text-align: right;
    font-weight: normal;
    color: #bbb;
  }
</style>
