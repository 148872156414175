<script lang="ts">
  import { iconOverlayClips, iconOverlays } from './icons';

  export let path = '';
  export let overlay = '';
  export let highlight: boolean | 'inverse' = false;
  export let inline = false;
  export let spin = false;
  export let spinInverse = false;
  let className = '';
  export { className as class };
  export let size: string = undefined;
  $: overlayPath = iconOverlays[overlay];
</script>

<svg
  class="icon{className ? ' ' + className : ''}"
  viewBox="0 0 24 24"
  style={size && `width: ${size}; height: ${size};`}
  {...$$restProps}
  class:inline
  class:highlight={highlight && highlight !== 'inverse'}
  class:highlight-inverse={highlight === 'inverse'}
>
  {#if overlay && iconOverlayClips[overlay]}
    <defs>
      <clipPath id={overlay}>
        <path d={iconOverlayClips[overlay]} />
      </clipPath>
    </defs>
  {/if}
  {#if !overlayPath && (spin || spinInverse)}
    <g class:spin={spin || spinInverse} class:inverse={spinInverse}>
      <path d={path} />
    </g>
  {:else}
    <path d={path} clip-path={overlay ? `url(#${overlay})` : null} />
    {#if overlayPath}
      {#if spin || spinInverse}
        <g class="overlay" class:spin={spin || spinInverse} class:inverse={spinInverse}>
          <path d={overlayPath} />
        </g>
      {:else}
        <path d={overlayPath} />
      {/if}
    {/if}
  {/if}
</svg>

<style>
  .icon {
    position: relative;
    display: inline-block;
    flex: 0 0 auto;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
  }
  .inline {
    top: 0.16em; /* when next to text, it is too high, but when alone, it is just right */
  }
  .spin {
    animation: spin linear 0.8s infinite;
    transform-origin: center;
  }
  .inverse {
    animation-name: spin-inverse;
  }
  .overlay.spin {
    transform-origin: 81.25% 72.9166666667%;
    animation-duration: 1.5s;
  }
  .highlight {
    filter: drop-shadow(var(--text-highlight));
  }
  .highlight-inverse {
    filter: drop-shadow(var(--text-highlight-inverse));
  }
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes spin-inverse {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(-360deg);
    }
  }
</style>
