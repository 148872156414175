import { getImmutable, getImmutableValue, makeChanges } from '@dabble/util/immutable';
import { TextDocument, isEqual } from 'typewriter-editor';
import { TextQueue } from '../../text-queue';
import { Doc } from '../../types';
import { Docs } from './docs';

/**
 * Module for getting all the committed and queued texts for each doc for counting, find/replace, and other text-only
 * needs.
 */

const STRING_FIELDS = new Set<keyof Doc>(['title', 'subtitle', 'author']);
const processedTexts = new WeakMap<Doc, TextFields[]>();
const emptyTuple: TextFields[] = [];

export interface TextFields {
  [fieldName: string]: string;
}

export interface DocText {
  [docId: string]: TextFields;
}

export interface DocTexts {
  textWithQueued: DocText;
  textWithoutQueued: DocText;
}

export const emptyTexts = { textWithQueued: {}, textWithoutQueued: {} };

/**
 * Returns a new texts object with all the committed and queued texts for each doc. Takes the previous texts to
 * determine what has changed and only update those parts for an efficient immutable update.
 */
export function getTexts(docs: Docs, textQueue: TextQueue, texts: DocTexts) {
  if (!docs.project) return emptyTexts;

  makeChanges(() => {
    const projectId = docs.project.id;
    const docsWithQueued = new Set<string>();
    textQueue.getAll().forEach(entry => docsWithQueued.add(entry.key.split(':')[1]));
    Object.keys(texts.textWithQueued).forEach(docId => {
      if (texts.textWithQueued[docId] !== texts.textWithoutQueued[docId]) {
        docsWithQueued.add(docId);
      }
    });
    Object.values(docs).forEach(updateTexts);

    function updateTexts(doc: Doc) {
      let [fields, queued] = processedTexts.get(doc) || emptyTuple;
      if (!fields || docsWithQueued.has(doc.id)) {
        [fields, queued] = getTextFields(projectId, doc, textQueue);
        processedTexts.set(doc, [fields, queued]);

        if (!isEqual(texts.textWithoutQueued[doc.id], fields)) {
          texts = getImmutableValue(texts);
          getImmutable(texts, 'textWithoutQueued')[doc.id] = fields;
        }

        if (!isEqual(texts.textWithQueued[doc.id], queued)) {
          texts = getImmutableValue(texts);
          getImmutable(texts, 'textWithQueued')[doc.id] = queued;
        }
      } else if (!isEqual(texts.textWithoutQueued[doc.id], fields)) {
        texts = getImmutableValue(texts);
        getImmutable(texts, 'textWithoutQueued')[doc.id] = fields;
        getImmutable(texts, 'textWithQueued')[doc.id] = queued;
      }
    }
  });

  return texts;
}

function getTextFields(projectId: string, doc: Doc, textQueue: TextQueue) {
  const fields: TextFields = {};
  const handled = new Set<keyof Doc>();
  let queued: TextFields = fields;

  Object.keys(doc).forEach((field: keyof Doc) => {
    handled.add(field);
    const value = doc[field];
    if (STRING_FIELDS.has(field)) {
      fields[field] = value;
    } else if (value instanceof TextDocument) {
      fields[field] = value.getText();
    } else {
      return;
    }
    const queueItem = textQueue.getFor(`${projectId}:${doc.id}:${field}`);
    if (queueItem) {
      queued = { ...queued };
      queued[field] = ((doc[field] as TextDocument) || new TextDocument()).apply(queueItem.delta).getText();
    }
  });

  // Catch any texts which are being newly created and haven't committed yet
  textQueue.getAll(`${projectId}:${doc.id}:`).forEach(queueItem => {
    const field = queueItem.key.split(':')[2];
    if (!handled.has(field)) {
      queued = { ...queued };
      queued[field] = ((doc[field] as TextDocument) || new TextDocument()).apply(queueItem.delta).getText();
    }
  });

  return [fields, queued];
}
