<script lang="ts">
  import { featuresStore } from '@dabble/data/global-data';
  import { localeStore, t } from '@dabble/data/intl';
  import { projectMetaSettingsStore } from '@dabble/data/project-data';
  import { ProjectMetaSettings } from '@dabble/data/types';
  import { EMPTY_ARRAY } from '@dabble/data/util';
  import CheckButton from '@dabble/toolkit/CheckButton.svelte';
  import { getLanguages } from '../grammar-api';
  import { Language } from '../grammar-types';
  import SpeedControls from './SpeedControls.svelte';
  import VoiceSelect from './VoiceSelect.svelte';


  export function updateEdits() {}

  let languages:Language[] = [];
  let spellingLanguage: string;

  $: currentLanguage = $projectMetaSettingsStore.spellingLanguage || localeStore || 'en-US';
  $: currentVoice = $projectMetaSettingsStore?.readingVoice;
  $: $projectMetaSettingsStore && loadLanguages();

  function toggle(field: keyof ProjectMetaSettings) {
    if (!$featuresStore.spell_check && field === 'spellingCheck') {
      featuresStore.promptUpgrade('spell_check');
    } else if (!$featuresStore.grammar && field === 'grammarCheck') {
      featuresStore.promptUpgrade('grammar');
    } else {
      if ($projectMetaSettingsStore) {
        const settings = $projectMetaSettingsStore || {} as ProjectMetaSettings
        // TODO: another way to fixes typescript error? (if statement is current fix)
        if(field === 'spellingCheck' || field === 'grammarCheck') {
          projectMetaSettingsStore.update({ [field]: !projectMetaSettingsStore[field] });
        }
      }
    }
  }

  function onLanguageChange() {
    currentVoice = spellingLanguage;
    projectMetaSettingsStore.update({ 'spellingLanguage': spellingLanguage });
    // loadVoices();
  }

  async function loadLanguages() {
    languages = (await getLanguages()) || EMPTY_ARRAY;
  }
</script>

<div class="section preferences">
  <h2>{$t('preferences_spelling_header')}</h2>
  <div class="preference vertical">
    <CheckButton
      on:click={() => toggle('spellingCheck')}
      checked={$featuresStore.spell_check &&
        ($projectMetaSettingsStore?.spellingCheck || $projectMetaSettingsStore?.spellingCheck === undefined)}
    >
      {$t('preferences_spellcheck_label')}
    </CheckButton>
    <div class="description">{$t('preferences_spellcheck_description')}</div>
  </div>

  <div class="preference vertical">
    <CheckButton on:click={() => toggle('grammarCheck')} checked={$featuresStore.grammar && $projectMetaSettingsStore?.grammarCheck}>
      {$t('preferences_grammarcheck_label')}
    </CheckButton>
    <div class="description">{$t('preferences_grammarcheck_description')}</div>
  </div>

  <div class="preference vertical">
    <h6>{$t('preferences_spelling_language_header')}</h6>
    <select bind:value={spellingLanguage} class="form-control" id="spellingLanguage" on:change={onLanguageChange}>
      <option value="" disabled>{$t('preferences_select_language')}</option>
      {#each languages as language}
        <option selected={language.languageCode === currentLanguage} value={language.languageCode}
          >{language.displayName}</option
        >
      {/each}
    </select>
  </div>

  <div class="preference vertical">
    <h6>{$t('preferences_spelling_voice_header')}</h6>
    <div class="row">
      <VoiceSelect />
      <SpeedControls />
    </div>
  </div>

  <p class="description">{@html $t('preferences_spellcheck_poweredby')}</p>
</div>

<style>
  .preferences {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  .preferences :global(h6) {
    margin-top: 0;
    font-weight: bold;
    color: var(--text-color-lighter);
    text-transform: uppercase;
  }
  .preferences :global(.preference) {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  .preferences :global(.preference.vertical) {
    flex-direction: column;
    align-items: flex-start;
  }
  .preferences :global(.preference:not(.vertical) .description) {
    margin-left: 10px;
  }
  .preferences :global(.preference.vertical .description) {
    margin-bottom: 10px;
  }
  .preferences :global(.preference.vertical .buttons) {
    margin-top: 10px;
  }
  .row {
    display: flex;
    width: 100%;
  }
  .row > :global(*) {
    width: 50%;
  }
  .section {
    order: 0;
  }

  .form-control {
    max-width: 100%;
  }

  @media (max-width: 688px) {
    .preferences :global(.preference) {
      flex-direction: column;
      align-items: flex-start;
    }
    .preferences :global(.check-button) {
      margin-right: 0;
      margin-bottom: 4px;
    }
    .row {
      display: block;
    }
    .row > :global(*) {
      width: 100%;
    }
  }
</style>
