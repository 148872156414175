import { delegate } from '@dabble/data/delegate';
import { AttributeMap, h, line } from 'typewriter-editor';
import { imageApproved } from './classifyImages';
import './images.css';

line({
  name: 'image',
  selector: 'div.image',
  frozen: true,
  fromDom: (node: HTMLElement) => {
    const img = node.firstElementChild as HTMLImageElement;
    const attr: AttributeMap = {};
    if (img) {
      attr.image = node.dataset.src || img.src;
      if (img.alt) attr.alt = img.alt;
    }
    if (node.dataset.style) attr.style = node.dataset.style;
    if (node.dataset.width) attr.width = parseInt(node.dataset.width);
    if (node.dataset.height) attr.height = parseInt(node.dataset.height);
    if (node.dataset.dataurl) attr.dataUrl = node.dataset.dataurl;
    return attr;
  },
  render: attributes => {
    const shouldBlur = delegate && !imageApproved(attributes.image);
    return h(
      'div',
      {
        class: 'image' + (shouldBlur ? ' blurred' : ''),
        'data-style': attributes.style,
        'data-width': attributes.width,
        'data-height': attributes.height,
        'data-dataurl': attributes.dataUrl,
        'data-src': attributes.image,
      },
      [
        h('img', {
          src: attributes.image,
          alt: attributes.alt,
          style: 'max-width: 100%',
          onerror: (event: Event) => {
            const target = event.target as HTMLImageElement;
            target.onerror = null;
            target.src = attributes.dataUrl;
          },
        }),
        h('div', {
          style: `padding-bottom: ${(attributes.height / attributes.width) * 100 || 100}%;`,
        }),
      ]
    );
  },
});
