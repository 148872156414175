<script lang="ts">
  import { Doc } from '@dabble/data/types';
  import EditableContent from '@dabble/toolkit/EditableContent.svelte';

  export let doc: Doc;
</script>

<div id={doc.id} class="folder-view">
  <EditableContent {doc} field="title" header="h1" class="centered" />
</div>

<style>
  .folder-view {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
  }
  .folder-view :global(.editable-content) {
    margin-top: -3em;
    flex: 1 1 auto;
  }
  .folder-view :global(h1) {
    text-align: center;
    font-size: 2.4em;
    text-shadow: var(--text-highlight);
    box-shadow: var(--horizontal-bevel);
    color: var(--text-color-light);
    box-sizing: border-box;
  }
</style>
