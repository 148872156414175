<script lang="ts">
  import { featuresStore } from '@dabble/data/global-data';
  import { localeStore, t } from '@dabble/data/intl';
  import { routerStore } from '@dabble/data/navigation';
  import { projectMetaSettingsStore } from '@dabble/data/project-data';
  import { ViewportSelection } from '@dabble/data/stores/viewport';
  import { viewport } from '@dabble/data/ui';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import SearchInput from '@dabble/toolkit/SearchInput.svelte';
  import { mdiFileReplaceOutline } from '@mdi/js';
  import debounce from 'lodash/debounce';
  import { tick } from 'svelte';
  import { EditorRange, normalizeRange } from 'typewriter-editor';
  import BeyondGrammarApi from '../api/beyond-grammar-api';
  import { Paragraph, SynonymList } from '../grammar-types';
  import { readingStore, voicesStore } from '../reading';

  const { selection, selectedEditor } = viewport;
  const searchOnType = debounce(getSynonyms, 1000);
  const empty: SynonymList = [];
  let shadowed = false;
  let synonymList = empty;
  let preSpaces = 0;
  let postSpaces = 0;
  let selectedText = '';
  let searchValue = '';
  let paragraph: Paragraph | null = null;
  let loading = false;

  $: hasFeature = $featuresStore.grammar;
  $: isLanguageSupported = true;
  $: wordToLookup = searchValue.trim();
  $: onSelectionUpdated($selection);
  $: replaceable = !!$selectedEditor?.doc?.selection;
  $: currentLanguage = $localeStore || 'en-US';

  $: setThesaurusLanguage($projectMetaSettingsStore?.spellingLanguage || currentLanguage);
  $: beyondGrammar = currentLanguage && new BeyondGrammarApi();
  $: localeVoices = [];

  function setThesaurusLanguage(lang: string) {
    isLanguageSupported = true;
    if (lang) {
      currentLanguage = lang;
      localeVoices = $voicesStore.filter(v => v.lang.substring(0, 2) === currentLanguage.substring(0, 2)).map(v => v.name);
    }
  }

  function onSelectionUpdated(selection: ViewportSelection) {
    preSpaces = 0;
    postSpaces = 0;
    if ($readingStore) return;

    const previouslySelectedText = selectedText;
    if (!selection || !selection.range || selection.range[0] === selection.range[1]) {
      selectedText = '';
      paragraph = null;
    } else {
      const editor = $selectedEditor;
      if (editor) {
        selectedText = editor.getText(selection.range);
        preSpaces = selectedText.replace(/^( *).*/, '$1').length;
        postSpaces = selectedText.replace(/.*?( *)$/, '$1').length;
        selectedText = selectedText.trim();
        const paragraphRange = editor.doc.getLineRange(selection.range[0]);
        const paragraphText = editor.getText(paragraphRange);
        const start = paragraphText.indexOf(selectedText);
        paragraph = { start: start, end: start + selectedText.trim().length - 1, text: paragraphText };
      }
    }
    if (previouslySelectedText !== selectedText && !wordToLookup) {
      getSynonyms();
    }
  }

  async function getSynonyms() {
    await tick(); // wait for wordToLookup to be set reactively
    beyondGrammar.languageIsoCode = currentLanguage;
    const fromInput = !!wordToLookup;
    const text = wordToLookup || selectedText;
    synonymList = empty;
    if (!text || text.length < 2) return;
    if (!fromInput && paragraph) {
      try {
        loading = true;
        const results = await beyondGrammar.contextualThesaurus(
          paragraph.text.toLowerCase(),
          paragraph.start,
          paragraph.end
        );
        loading = false;
        if (results?.suggestions?.length) {
          synonymList = [
            {
              meaning: $t('display_contextual_entries'),
              partOfSpeech: null,
              synonyms: results?.suggestions.sort((s1: string, s2: string) => (s1.toLowerCase() < s2.toLowerCase() ? -1 : 1)),
            },
          ];
        } else {
          synonymList = [{ meaning: $t('display_contextual_entries'), partOfSpeech: null, synonyms: [] }];
        }
      } catch (err) {
        isLanguageSupported = false;
        loading = false;
      }
    } else {
      try {
        loading = true;
        const results = await beyondGrammar.thesaurus(text.toLowerCase());
        loading = false;
        if (results?.meanings?.length) {
          results.meanings = results.meanings.filter(m => {
            return m.meaning && m.meaning.trim() !== text;
          });
          synonymList = results?.meanings.map(m => {
            return {
              meaning: m.meaning.trim(),
              partOfSpeech: m.partOfSpeech,
              synonyms: m.synonyms.sort((s1: string, s2:string) => (s1.toLowerCase() < s2.toLowerCase() ? -1 : 1)),
            };
          });
        } else {
          synonymList = [{ meaning: text, partOfSpeech: 'Original Word', synonyms: [] }];
        }
      } catch (err) {
        isLanguageSupported = false;
        loading = false;
      }
    }
  }

  function onSelectWord(synonym: string) {
    const range = normalizeRange($selectedEditor?.doc?.selection);
    if (!range) return;
    const trimmedRange:EditorRange = [range[0] + preSpaces, range[1] - postSpaces];
    const editor = $selectedEditor;
    editor.change
      .delete(trimmedRange)
      .insert(trimmedRange[0], synonym, editor.doc.getTextFormat(trimmedRange))
      .select([range[0], range[0] + synonym.length + preSpaces + postSpaces])
      .apply();
  }

  function setShadowed(event: Event) {
    const target = event.target as HTMLElement;
    shadowed = target.scrollTop > 0;
  }
</script>

<div class="thesaurus">
  {#if hasFeature}
    <div class="header" class:shadowed>
      <h3 class="section-header">{$t('thesaurus_open')}</h3>
      <div class="reading-label">{$t('thesaurus')}</div>
      <div class="lookup-input">
        {#if isLanguageSupported}
          <SearchInput
            placeholder={selectedText ? selectedText + ' ' + $t('in_context') : $t('lookup_placeholder')}
            bind:value={searchValue}
            on:input={searchOnType}
            on:change={() => searchOnType.flush()}
          />
        {:else}
          <div>{$t('selected_grammar_language_not_supported')}</div>
        {/if}
      </div>
    </div>
    <div class="thesaurus-list" class:replaceable on:scroll={setShadowed}>
      {#if loading}
        <div class="loading-placeholder" style="margin:12px 0 4px;height: 21px;" />
        <div class="loading-placeholder" style="height: 34px;margin-bottom: 4px;" />
        <div class="loading-placeholder" style="height: 34px;margin-bottom: 4px;" />
        <div class="loading-placeholder" style="height: 34px;margin-bottom: 4px;" />
      {:else if synonymList && synonymList.length > 0}
        {#each synonymList as meaning}
          <div class="meaning-header">
            {`${meaning.meaning} ${meaning.partOfSpeech ? '(' + meaning.partOfSpeech + ')' : ''}`}
          </div>
          {#each meaning.synonyms as synonym}
            <button class="synonym-entry" on:click={() => onSelectWord(synonym)}>
              <span class="hover-icon"><Icon path={mdiFileReplaceOutline} inline /></span>
              {synonym}
            </button>
          {:else}
            {$t('no_results')}
          {/each}
        {/each}
      {/if}
    </div>
  {:else}
    <div class="header no-feature" class:shadowed>
      <h3 class="section-header">{$t('thesaurus_open')}</h3>
      <div class="upgrade-container">
        <div class="reading-label">{$t('thesaurus')}</div>
        <p>{@html $t('feature_upgrade', { feature: $t('thesaurus') })}</p>
        <button type="button" class="btn primary" on:click={() => routerStore.navigate('/checkout/plans')}
          >{$t('feature_upgrade_now')}</button
        >
      </div>
    </div>
  {/if}
</div>

<style>
  .thesaurus {
    display: flex;
    flex-direction: column;
    height: 100%;
    color: var(--text-color-lighter);
    font-size: var(--font-size-sm);
  }
  .header {
    padding-bottom: 10px;
  }
  .header.no-feature {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .header .reading-label {
    padding: 10px 10px 7px;
  }

  .upgrade-container {
    padding: 0 10px;
  }
  .section-header {
    padding: 10px;
  }
  .lookup-input {
    margin: 0 10px;
  }
  .thesaurus-list {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    padding: 0 10px 10px;
  }
  .meaning-header {
    font-weight: bold;
    margin: 12px 0 4px;
  }
  .synonym-entry {
    padding: 4px 8px;
    margin-bottom: 4px;
    border: var(--form-border);
    border-radius: 4px;
    background-color: var(--white);
    font-size: var(--font-size-base);
    user-select: text;
    cursor: text;
    flex: 0 0 auto;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .replaceable .synonym-entry {
    user-select: none;
    cursor: pointer;
  }
  .replaceable .synonym-entry:hover {
    background-color: var(--side-nav-color-light);
    color: var(--text-color-light);
  }
  .synonym-entry .hover-icon {
    display: inline-block;
    opacity: 0;
    margin-left: -16px;
    transition: all 0.2s ease-in-out;
  }
  .replaceable .synonym-entry:hover .hover-icon {
    opacity: 1;
    margin-left: 0;
  }
  .reading-label {
    flex: 1;
    font-weight: bold;
    font-size: var(--font-size-sm);
  }
</style>
