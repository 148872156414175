import { $t } from '@dabble/data/intl';
import { projectStore } from '@dabble/data/project-data';
import { Doc } from '@dabble/data/types';
import { confirm } from '@dabble/data/ui';
import { userRoleStore } from '@dabble/data/user-project';
import { mdiImage } from '@mdi/js';
import { writable } from 'easy-signal';

export const titleImageDocId = writable('');

export const setTitleImage = [
  {
    key: 'novel_title_image',
    icon: mdiImage,
    action: openDropTitleImageModal,
    hide: (doc: Doc) => (doc.image ? true : false),
    disabled: () => !userRoleStore.get(),
  },
];

export const removeTitleImage = [
  {
    key: 'novel_title_image_remove',
    icon: mdiImage,
    action: deleteTitleImage,
    hide: (doc: Doc) => (doc.image ? false : true),
    disabled: () => !userRoleStore.get(),
  },
];

function openDropTitleImageModal(doc: Doc) {
  titleImageDocId.set(doc.id);
}

async function deleteTitleImage(doc: Doc) {
  const shouldDelete = await confirm($t('novel_image_delete'), $t('novel_image_delete_message'));

  if (shouldDelete) {
    projectStore.updateDoc(doc.id, { image: null });
  }
}
