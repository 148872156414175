<script lang="ts">
  import { uidStore } from '@dabble/data/ids';
  import { t } from '@dabble/data/intl';
  import { plugins } from '@dabble/data/plugins';
  import { escKey } from '@dabble/toolkit/events';
  import { createEventDispatcher } from 'svelte';
  import { commentsStore, updateCommentDisplay } from '../comments-store';
  import CommentEditor from './CommentEditor.svelte';
  import CommentEntry from './CommentEntry.svelte';
  import CommentHeader from './CommentHeader.svelte';
  import { CommentData, CommentEditorData, getSaveDataForComment } from './display-helpers';

  export let info: CommentData;
  export let selected: boolean;
  const COLLAPSE_SIZE = 2;
  const dispatch = createEventDispatcher();
  const uid = plugins.stores.uid;
  let commentElem: HTMLElement;
  let input: CommentEditor;
  let deleting: boolean; // This prevents a flash of comment changes when deleting
  let hasInput: boolean;
  let showAll: boolean;
  let openingMenu: boolean;

  $: comment = info.comment;
  $: showInput = !comment || selected || hasInput;
  $: if (selected) showAll = true;
  $: if (!comment && selected && input) {
    setTimeout(() => input.focus());
  }
  $: collapsed = !showAll && comment && comment.replies && comment.replies.length > COLLAPSE_SIZE;
  $: replies = comment ? (collapsed ? comment.replies.slice(-COLLAPSE_SIZE) : comment.replies) : [];
  $: if (openingMenu) setTimeout(() => (openingMenu = false));

  async function save({ detail: { content } }: CustomEvent) {
    if (info.comment) {
      commentsStore.reply(info.id, content);
      cancel();
    } else {
      const pageElement: HTMLElement = commentElem.closest('.page-wrapper');
      const { editor, range } = getSaveDataForComment(pageElement, info.id) as CommentEditorData;
      cancel();
      if (editor) {
        editor.select(range);
        await commentsStore.comment(editor, range, content, info.id);
        updateCommentDisplay(true);
      }
    }
  }

  function cancel() {
    if (!comment) dispatch('release', { hasInput });
  }

  function onFocusIn(event: FocusEvent) {
    if (
      (event.target as HTMLElement).classList.contains('menu-opener') ||
      (event.target as HTMLElement).nodeName === 'BUTTON'
    )
      return;
    dispatch('select');
    if (hasInput) input.focus();
  }

  function onClick(event: MouseEvent) {
    if (
      (event.target as HTMLElement).classList.contains('menu-opener') ||
      (event.target as HTMLElement).nodeName === 'BUTTON'
    )
      return;
    if (!selected) commentElem.focus();
  }

  function onFocusOut(event: FocusEvent) {
    if (openingMenu) return;
    if (commentElem) {
      const active = commentElem.ownerDocument.activeElement;
      // If the window loses focus, we don't need to do anything
      if (
        (event && event.relatedTarget && commentElem.contains(event.relatedTarget as HTMLElement)) ||
        commentElem.contains(active)
      ) {
        return;
      }
    }
    dispatch('release', { hasInput });
  }

  function onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') return;
    if (event.target !== commentElem) return;
    if (event.key && event.key.length > 1) return;
    if (input) input.focus();
  }

  function onOpenMenu() {
    openingMenu = true;
  }

  function blur() {
    (commentElem.ownerDocument.activeElement as HTMLElement).blur();
  }
</script>

{#if !deleting}
  <!-- svelte-ignore a11y-no-static-element-interactions -->
  <div
    bind:this={commentElem}
    class="thread"
    class:new={!comment}
    class:selected
    data-id={info.id}
    style="top:{info.top}px"
    on:focusin={onFocusIn}
    on:focusout={onFocusOut}
    on:keydown={onKeyDown}
    on:mousedown={event => !selected && event.preventDefault()}
    on:click={onClick}
    use:escKey={blur}
    tabindex="-1"
  >
    <div class="comments">
      {#if comment}
        <CommentEntry {comment} on:menuOpen={onOpenMenu} on:update />
        {#if collapsed}
          <div class="show-all"><span>{$t('comments_show_all_replies', { count: comment.replies.length })}</span></div>
        {/if}
        {#each replies as reply}
          <CommentEntry {comment} {reply} on:menuOpen={onOpenMenu} on:update />
        {/each}
      {:else}
        <div class="new-comment">
          <CommentHeader uid={$uidStore} />
        </div>
      {/if}
    </div>

    {#if showInput}
      <div class="reply" class:borderless={!comment}>
        <CommentEditor
          on:update
          on:save={save}
          on:cancel={cancel}
          bind:this={input}
          bind:hasInput
          shyButtons={!!comment}
          style={comment ? 'reply' : 'comment'}
        />
      </div>
    {/if}
  </div>
{/if}

<style>
  .thread {
    --comment-padding: 12px;
    position: absolute;
    cursor: pointer;
    width: 256px;
    margin-top: calc(-1 * var(--comment-padding)); /* bring it up the size of top padding to look more aligned */
    left: 16px;
    padding: 0 8px;
    font-size: var(--font-size-base);
    background: var(--white);
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15);
    outline: none;
    transition: all 0.2s ease-in-out;
    contain: layout;
  }
  .thread:not(.selected):hover {
    box-shadow:
      0 1px 3px rgba(0, 0, 0, 0.2),
      0 2px 5px rgba(0, 0, 0, 0.15);
  }
  .thread.selected {
    cursor: default;
    z-index: 1;
    left: -16px;
    box-shadow:
      0 1px 3px rgba(0, 0, 0, 0.2),
      0 4px 12px rgba(0, 0, 0, 0.2);
  }
  .show-all {
    position: relative;
    z-index: 1;
    text-align: center;
    font-size: var(--font-size-xs);
    color: var(--link-color);
    line-height: 0;
  }
  .show-all span {
    padding: 0 8px;
    background: var(--white);
  }
  .reply {
    margin: 0 -8px;
    padding: var(--comment-padding) 8px;
    border-top: var(--form-border);
  }
  .reply.borderless {
    border-top: none;
  }
  .new-comment {
    padding-top: 8px;
  }
</style>
