<script lang="ts">
  import { readingNovelStore, readonlyStore } from '@dabble/data/app-state';
  import { Editable } from '@dabble/data/editables';
  import { projectStore } from '@dabble/data/project-data';
  import { settingsStore } from '@dabble/data/settings';
  import { Doc, EditorOnSave, EditorOptions } from '@dabble/data/types';
  import { editingModeStore, isTouchStore, selectedCountStore } from '@dabble/data/ui';
  import { preferencesStore } from '@dabble/data/user-data';
  import { countWords } from '@dabble/data/word-count';
  import TitleImage from '@dabble/plugins/content/components/TitleImage.svelte';
  import ContextMenu from '@dabble/toolkit/ContextMenu.svelte';
  import EditableContextMenu from '@dabble/toolkit/EditableContextMenu.svelte';
  import LineMenu from '@dabble/toolkit/LineMenu.svelte';
  import TextBubbleMenu from '@dabble/toolkit/TextBubbleMenu.svelte';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import { fadeUp } from '@dabble/toolkit/transitions';
  import { Unsubscriber } from 'easy-signal';
  import { onDestroy, onMount, tick } from 'svelte';
  import { Delta, EditorChangeEvent, Source, TextDocument, TypesetTypes } from 'typewriter-editor';
  import { editables } from './editables';

  export let doc: Doc;
  export let field: string;
  export let header: EditorOptions['header'] = null;
  export let typeset: TypesetTypes = null;
  export let onSave: EditorOnSave = null;
  export let hiddenPlaceholder = false;
  export let placeholder = '';
  export let isEmpty = true;
  export let prefix = false;
  export let prefixClick = null;
  export let prefixTooltip = null;
  let fieldFocus = false;
  export { fieldFocus as focus };
  let className = '';
  export { className as class };
  let fieldReadonly = false;
  export { fieldReadonly as readonly };
  export let editable: Editable = null;
  export let showTitleImages = true;

  let docId: string;
  let root: HTMLElement;
  let unsubscribes: Unsubscriber[] = [];
  let emptyUnsubscribe: Unsubscriber;

  $: docId = doc && doc.id;
  $: onDocIdChange(docId, root);
  $: onReadonlyChange($readonlyStore && fieldReadonly);
  $: $settingsStore && rerender();

  onMount(() => {
    unsubscribes = [projectStore.forceTextUpdate(onForceTextUpdate)];
  });

  onDestroy(() => {
    unsubscribes.forEach(unsub => unsub());
    if (editable) editable.destroy();
  });

  function onDocIdChange(docId: string, root: HTMLElement) {
    if (editable) {
      editable.editor.off('change', onChange);
      if (emptyUnsubscribe) {
        emptyUnsubscribe();
      }
      editable.destroy();
      editable = null;
      if (root) root.innerHTML = '';
    }

    if (root && docId) {
      const options: EditorOptions = { header, onSave, placeholder };
      if ($readonlyStore || fieldReadonly) options.enabled = false;
      if (typeset) options.typeset = typeset;
      editable = editables.getEditable(docId, field, root, options);
      emptyUnsubscribe = editable.empty.subscribe(value => (isEmpty = value));
      editable.editor.on('change', onChange);
      if (fieldFocus) Promise.resolve().then(() => editable.editor.select(0));
    }
  }

  async function rerender() {
    await tick();
    if (editable) editable.editor.render();
  }

  function onChange({ doc: { selection }, change, source }: EditorChangeEvent) {
    if (!header) {
      if (!selection) {
        selectedCountStore.set(0);
      } else {
        const text = editable.editor.doc.getText(selection);
        const numWords = countWords(text);
        selectedCountStore.set((text && numWords) || 0);
      }
    }
    if (
      change &&
      change.contentChanged &&
      (source === 'user' || source === 'input') &&
      change.delta.ops.some(op => op.delete || typeof op.insert === 'string')
    ) {
      root.dispatchEvent(
        new CustomEvent('editorinput', { detail: { editor: editable.editor, change }, bubbles: true })
      );
    }
  }

  function onReadonlyChange(readonly: boolean) {
    if (editable) editable.editor.enabled = !readonly;
  }

  function onForceTextUpdate(key: string) {
    if (!editable || (key && key !== editable.key)) return;
    const { editor } = editable;
    const queued = projectStore.textQueue.getFor(projectStore.getQueueKey(doc.id, field));

    if (header || !doc[field]) {
      let text = doc[field] || '';
      if (queued) {
        text = new Delta()
          .insert(text)
          .compose(queued.delta)
          .filter(op => typeof op.insert === 'string')
          .map(op => op.insert)
          .join('');
      }

      if (editor.getText() !== text) {
        editor.setText(text, editor.doc.selection, Source.api);
      } else {
        editor.render();
      }
    } else {
      let textDoc = doc[field] || new TextDocument();
      if (queued) {
        textDoc = textDoc.apply(queued.delta);
      }

      if (!textDoc.equals(editor.doc, { contentOnly: true })) {
        editor.set(textDoc, Source.api);
      } else {
        editor.render();
      }
    }
  }
</script>

<div
  data-id={doc && doc.id}
  data-field={field}
  class="editable-content {className} {!placeholder && settingsStore.getPlaceholderClass(doc, field, isEmpty)}"
  class:empty={isEmpty}
  class:hidden-placeholder={hiddenPlaceholder}
  class:has-image={!!doc.image}
>
  <div bind:this={root} />
  {#if editable}
    {#if !isEmpty && prefix}
      <div
        transition:fadeUp={{ distance: 100 }}
        class="title-prefix"
        class:clickable={prefixClick}
        on:click={prefixClick}
        on:keydown={prefixClick}
        use:tooltipTop={prefixTooltip}
        role="button"
        tabindex="0"
      >
        {($projectStore.project && settingsStore.getPlaceholder(doc)) || ''}
      </div>
    {/if}
  {/if}

  {#if doc.image && doc.type === 'novel_chapter' && showTitleImages}
    <TitleImage {doc} />
  {/if}

  {#if !header}
    {#if !$readingNovelStore}
      <TextBubbleMenu editor={editable && editable.editor} />
    {/if}
    {#each settingsStore.getValuesFromPlugins('editableMenus', $projectStore.project) as menu}
      <svelte:component this={menu} editor={editable && editable.editor} {doc} />
    {/each}
  {/if}

  {#if $$slots.default && $editingModeStore !== 1}
    <LineMenu editor={editable && editable.editor} let:commands let:active let:selection let:focus>
      <svelte:fragment>
        <slot {commands} {active} {selection} {focus} editor={editable && editable.editor} />
      </svelte:fragment>
    </LineMenu>
  {/if}

  {#if !$isTouchStore && !$preferencesStore.preferNativeContextMenu}
    <ContextMenu activeClass="menu-open" target={root}>
      <EditableContextMenu editor={editable && editable.editor} />
    </ContextMenu>
  {/if}
</div>

<style>
  .clickable {
    cursor: pointer;
  }
</style>
