<script lang="ts">
  import { readonlyStore } from '@dabble/data/app-state';
  import { docStore } from '@dabble/data/doc-data';
  import { featuresStore } from '@dabble/data/global-data';
  import { t } from '@dabble/data/intl';
  import { projectSettingsStore, projectStore } from '@dabble/data/project-data';
  import { settingsStore } from '@dabble/data/settings';
  import { Children } from '@dabble/data/stores/project/children';
  import { Parents } from '@dabble/data/stores/project/parents';
  import { Doc } from '@dabble/data/types';
  import { userRoleStore } from '@dabble/data/user-project';
  import { EMPTY_ARRAY, getTitle } from '@dabble/data/util';
  import Dropdown from '@dabble/toolkit/Dropdown.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { createFromTemplate, createNewDoc, scrollTo } from '@dabble/toolkit/new-docs';
  import { tooltipBottom } from '@dabble/toolkit/tooltip';
  import { mdiAlphaTBox, mdiPlusThick } from '@mdi/js';
  import SubNavItem from './SubNavItem.svelte';

  let createMenuOpen = false;

  $: createMenuTypes = $projectSettingsStore.createMenu || EMPTY_ARRAY; // add $settings so it refreshes when changed

  $: templatesAvailable = ($projectStore.project && projectStore.getChildren('templates')) || EMPTY_ARRAY;
  $: templateDocIds = Object.values(templatesAvailable).map(t => t.id);

  function getAddIcon(type: string) {
    let icon = settingsStore.getFor(type).icon;
    if (typeof icon === 'string') return icon;

    return icon({ id: '', type });
  }

  function canCreate(type: string, doc: Doc) {
    const requiresFeature = settingsStore.getFor(type).requiresFeature;
    if (requiresFeature && !$featuresStore[requiresFeature]) return false;
    return (
      !$readonlyStore &&
      doc &&
      !!getNextPossibleInsertion(doc, type, $projectStore.parentsLookup, $projectStore.childrenLookup)
    );
  }

  function getInsertionPoint(type: string) {
    const { project, parentsLookup, childrenLookup } = $projectStore;
    let insertion = getNextPossibleInsertion($docStore, type, parentsLookup, childrenLookup);
    if (!insertion) insertion = getNextPossibleInsertion(project, type, parentsLookup, childrenLookup);
    if (!insertion) return;
    return insertion;
  }

  async function addChild(type: string) {
    const insertion = getInsertionPoint(type);
    const id = projectStore.createDocId();
    await createNewDoc({ id, type }, insertion.parent.id, insertion.index);
    await scrollTo($docStore, id);
  }

  function getNextPossibleInsertion(doc: Doc, type: string, parentsLookup: Parents, childrenLookup: Children) {
    let insertion = getInsertion(doc, type, parentsLookup, childrenLookup);
    if (insertion) return insertion;

    let parent = parentsLookup[doc.id];
    while (parent) {
      insertion = getInsertion(parent, type, parentsLookup, childrenLookup);
      if (insertion) return insertion;
      if (!parentsLookup[parent.id]) break;
      parent = parentsLookup[parent.id];
    }

    // Flatten the children into one array
    function tree(item: Doc): Doc[] {
      if (item.virtual || !childrenLookup[item.id]) return [item];
      return childrenLookup[item.id].reduce((children, child) => children.concat(tree(child)), [item]);
    }

    const docTree = tree(doc);
    for (let i = docTree.length - 1; i >= 0; i--) {
      insertion = getInsertion(docTree[i], type, parentsLookup, childrenLookup);
      if (insertion) return insertion;
    }

    const allTree = tree(parent);
    for (let i = allTree.indexOf(doc) + 1; i < allTree.length; i++) {
      insertion = getInsertion(allTree[i], type, parentsLookup, childrenLookup);
      if (insertion) return insertion;
    }
  }

  function getInsertion(doc: Doc, type: string, parentsLookup: Parents, childrenLookup: Children) {
    if (isValidParent(doc, type)) {
      return { parent: doc, index: childrenLookup[doc.id].length };
    }
    const parent = parentsLookup[doc.id];
    if (parent && isValidParent(parent, type)) {
      return { parent, index: childrenLookup[parent.id].indexOf(doc) + 1 };
    }
  }

  function isValidParent(parent: Doc, type: string) {
    const docSettings = settingsStore.getFor(parent);
    return (
      parent &&
      parent.children &&
      !docSettings.getChildren &&
      !parent.virtual &&
      (!docSettings.validChildTypes || docSettings.validChildTypes[type])
    );
  }

  function canEdit(type: string) {
    const requiresFeature = settingsStore.getFor(type).requiresFeature;
    if (requiresFeature && !$featuresStore[requiresFeature]) return false;
    return true;
  }

  async function fromTemplate(templateId: string) {
    const template = projectStore.getDoc(templateId);
    const insertion = getInsertionPoint(template.type);
    const id = await createFromTemplate(template, insertion.parent.id, insertion.index);
    await scrollTo($docStore, id);
  }

  function getTemplateIcon(docId: string) {
    return getAddIcon(projectStore.getDoc(docId).type);
  }

  function getTemplateText(docId: string) {
    return getTitle(projectStore.getDoc(docId));
  }
</script>

<div class="nav-actions">
  <button
    class="icon"
    on:click={() => (createMenuOpen = true)}
    use:tooltipBottom={$t('new_items')}
    disabled={$readonlyStore || !createMenuTypes || !$userRoleStore}
  >
    <Icon path={mdiPlusThick} />
  </button>
  {#if createMenuOpen}
    <Dropdown placement="bottom-start" arrow on:close={() => (createMenuOpen = false)}>
      {#each createMenuTypes as type}
        {#if type === '-'}
          <hr />
        {:else}
          <button
            class="dropdown-item create-button"
            on:click={() => addChild(type)}
            disabled={!canCreate(type, $docStore)}
          >
            <Icon path={mdiPlusThick} class="prefix" /><Icon path={getAddIcon(type)} />
            {$t('add_new_document', { type })}
          </button>
        {/if}
      {/each}

      {#if templatesAvailable.length}
        <SubNavItem>
          <svelte:fragment slot="submenu-button">
            <Icon path={mdiPlusThick} class="prefix" /><Icon path={mdiAlphaTBox} />
            {$t('templates_add_from')}
          </svelte:fragment>

          {#if templatesAvailable.length}
            {#each templateDocIds as template}
              <button
                class="dropdown-item nav-item-menu add-child"
                on:click={() => fromTemplate(template)}
                disabled={$readonlyStore || !canEdit($docStore.type)}
              >
                <Icon path={mdiPlusThick} class="prefix" /><Icon path={getTemplateIcon(template)} />
                {$t(getTemplateText(template))}
              </button>
            {/each}
          {/if}
        </SubNavItem>
      {/if}
    </Dropdown>
  {/if}
</div>
