import { readable } from 'easy-signal';
import { projectStore } from './project-data';
import { createRouterStore } from './stores/router';
import { Doc } from './types';
import { idLengths } from './uuid';

export const routerStore = createRouterStore();

export function goto(path: string, replace?: boolean) {
  return routerStore.navigate(path, replace);
}

export type GetURLFunction = (doc: string | Doc, projectId?: string) => string;

export function getUrl(doc: Doc | string, projectId?: string) {
  if (!doc && !projectId) return '/';
  const docId = !doc ? projectId : typeof doc === 'string' ? doc : doc.id;
  if (!projectId) {
    if (docId.length === idLengths.project) projectId = docId;
    else {
      const currentProject = projectStore.get().project;
      projectId = currentProject && currentProject.id;
    }
  }
  if (!projectId || !docId) return '/';
  return projectId === docId ? `/p/${projectId}/` : `/p/${projectId}/${docId}`;
}

export const url = readable(location.href, set => {
  onURLChange();

  function onURLChange() {
    set(location.href);
  }

  addEventListener('popstate', onURLChange);
  addEventListener('pushstate', onURLChange);
  addEventListener('replacestate', onURLChange);
  return () => {
    removeEventListener('popstate', onURLChange);
    removeEventListener('pushstate', onURLChange);
    removeEventListener('replacestate', onURLChange);
  };
});

const sessionParams = new Set([
  'delegate',
  'lastUrl',
  'lastProductUrl',
  'product',
  'interval',
  'currency',
  'invite',
  'lastUrlBeforeNaNoAuth',
  'dontSync',
  'importTemplate',
  'referralCode',
  'coupon',
  'code', // google auth return token
  'irclickid', // Impact Affiliate click ID
  'genre', // genre to create a new book from
]);

// Initialize session storage from URL
pullSessionFromUrl();

function pullSessionFromUrl() {
  const query = routerStore.getQuery();
  let key: string;
  let value: any;
  let hasAny = false;
  for ([key, value] of query) {
    hasAny = true;
    if (!value) value = true;
    else if (value[0] === '{') {
      try {
        value = JSON.parse(value);
      } catch (e) {}
    }

    if (sessionParams.has(key)) {
      sessionStorage[key] = value;
    }
  }
  if (hasAny) {
    goto(routerStore.get().url.replace(/\?.*/, ''), true);
  }
}
