<script lang="ts">
  import { editingModeStore, writingStore } from '@dabble/data/ui';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import Interactions from '@dabble/toolkit/Interactions.svelte';
  import { mdiPlusCircleOutline } from '@mdi/js';
  import { fade } from 'svelte/transition';
  import { InlineMenu, Line } from 'typewriter-editor';
  import { slideH } from './transitions';

  export let editor;
  let menu: HTMLElement;
  let open = false;
  let line: Line;

  $: if (line) {
    open = false;
  }

  function onMouseDown(event: CustomEvent<MouseEvent>) {
    if (!menu || !open || menu.contains(event.detail.target as Node)) return;
    open = false;
  }

  function onEscape() {
    if (open) open = false;
  }
</script>

{#if $editingModeStore !== 1}
  <InlineMenu {editor} class="inline-menu" let:active let:commands let:selection let:focus bind:line atLine any={false}>
    <div class="menu" in:fade={{ duration: 100 }} bind:this={menu}>
      {#if !$writingStore}
        <button class="opener menu-button icon" class:open on:click={() => (open = !open)} in:fade={{ duration: 300 }}>
          <Icon path={mdiPlusCircleOutline} />
        </button>
      {/if}
      {#if open}
        <div class="actions" in:slideH={{ duration: 300 }}>
          <slot {commands} {active} {selection} {focus} />
        </div>
      {/if}
    </div>
  </InlineMenu>
{/if}

<Interactions on:mousedown={onMouseDown} on:shortcut-Escape={onEscape} />

<style>
  :global(.inline-menu.active) {
    pointer-events: none;
    width: 100%;
    pointer-events: none;
  }
  .menu {
    display: flex;
    align-items: center;
    color: #999;
    white-space: nowrap;
    height: 36px;
    min-width: 36px;
    margin-left: -40px;
  }
  .menu > * {
    pointer-events: all;
  }
  .menu :global(button.icon) {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    padding: 4px;
    height: 36px;
    width: 36px;
  }
  .menu button.icon.opener {
    font-size: 28px;
    transition:
      0.15s transform ease-in-out,
      0.15s color linear;
  }
  .opener:not(.open) {
    color: var(--text-color-faint);
  }
  .opener:not(.open):hover {
    color: var(--text-color-lightest);
  }
  .opener.open {
    transform: rotate(45deg);
    color: var(--text-color-lightest);
  }
  .actions {
    display: flex;
    padding: 0 8px;
    flex: 1 1 100%;
    background: var(--page-background);
  }
  .menu-button:hover,
  .menu-button:focus {
    border-top-color: var(--text-color-lighterer);
  }
  .menu :global(.separator) {
    height: 16px;
    margin: 8px 0;
    border-right: 1px solid #aaa;
  }
</style>
