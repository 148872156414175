{
  "collab_accept": "Aceptar",
  "collab_authors": "Coautores",
  "collab_author_name": "Nombre",
  "collab_author_me": "(yo)",
  "collab_author_role": "Rol",
  "collab_author_unloaded": "Nombre no Cargado",
  "collab_decline": "Rechazar",
  "collab_error_DECLINED": "Esta invitación ya ha sido rechazada",
  "collab_error_EXPIRED": "Esta invitación ha caducado",
  "collab_error_NOT_FOUND": "Esta invitación ya no existe",
  "collab_explainer": "La coautoría es una función que le permite trabajar con otros autores en un proyecto. Esos otros autores necesitan una cuenta de Dabble y tendrán la misma funcionalidad y características con su proyecto compartido que tienen con sus otros proyectos, dependiendo de su suscripción. P.ej. si su coautor no está suscrito, tendrá acceso a su proyecto en modo de solo lectura como lo haría con sus propios proyectos. Si están en el plan Básico, podrán ver la trama y tendrán acceso de escritura al manuscrito. Hay un límite de {{limit}} colaboradores en un proyecto.",
  "collab_header": "Coautoría",
  "collab_author_email": "Email del coautor",
  "collab_invite_author": "Invitar a un Coautor",
  "collab_author_deleted": "Coautor Eliminado",
  "collab_success_decline": "Has rechazado la invitación",
  "collab_success_accept": "¡Diviértete colaborando en tu nuevo proyecto!",
  "collab_invite": "Invitar",
  "collab_invite_sent": "Invitación Enviada",
  "collab_invite_delete": "Eliminar Invitación",
  "collab_invite_deleted": "Invitación Eliminada",
  "collab_invite_link_copy": "Copiar Link",
  "collab_invite_link_copied": "Enlace copiado al portapapeles",
  "collab_invites": "Invitaciones",
  "collab_invited_you": "<strong>{{inviter}}</strong> se ha invitado a ser coautor de un proyecto <strong><em>{{title}}</em></strong> en Dabble.",
  "collab_invite_email_message": "{{inviter}} se ha invitado a ser coautor de un proyecto entitulado {{title}} en Dabble.",
  "collab_invite_login": "Ha iniciado sesión en <strong>{{email}}</strong>. ¿Le gustaría aceptar esta invitación?",
  "collab_invite_accept": "Ha iniciado sesión en <strong>{{email}}</strong>. ¿Le gustaría aceptar esta invitación?",
  "collab_invite_email": "Email",
  "collab_invite_status": "Estado",
  "collab_invite_status_sent": "Pendiente",
  "collab_invite_status_declined": "Rechazado",
  "collab_invite_status_expired": "Caducado",
  "collab_full_project": "El proyecto está lleno, solo {{limit}} personas pueden usar o ser invitadas al proyecto.",
  "collab_only_author_invites": "Solo el propietario puede invitar a otros.",
  "collab_remove": "Eliminar {{role}}",
  "collab_set_role": "Establecer rol en {{role}}",
  "collab_role_changed": "Rol cambiado exitosamente.",
  "collab_signin_first_message": "Para aceptar esta invitación, primero debe <strong>crear una cuenta</strong> o <strong>iniciar sesión</strong> en una cuenta existente.",
  "collab_track_changes_button": "Seguimiento de Cambios de Autor",
  "collab_track_changes_button_description": "Lleve un registro de quién escribió qué. Permite diferentes colores de texto para cada autor.",
  "collab_track_changes_success": "Configuración de seguimiento de autor actualizada con éxito",
  "collab_type_author": "Coautor",
  "collab_type_write": "Coautor",
  "collab_type_owner": "Propietario",
  "collab_type_edit": "Editor",
  "collab_type_comment": "Para Comentarios",
  "collab_type_view": "Ver Sólo",
  "collab_type_declined": "Rechazado",
  "collab_invite_self": "No puedes invitarte a ti mismo a un proyecto."
}
