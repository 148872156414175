import { version } from '@dabble/version';
import { AgreeableClient, agreeableClient } from 'agreeable-client';
import { observe } from 'easy-signal';
import { uidStore } from '../ids';
import { DabbleConfig, config } from './agreeable-config';
import { agreeableOptions } from './agreeable-options';

export type DabbleAgreeableClient = AgreeableClient<DabbleConfig['children']>;

/**
 * This module instantiates and manages the Agreeable client, provides utilities to help work with the client, and
 * provides stores around the Agreeable data.
 */

/**
 * The Agreeable client instance.
 */
export function getAgreeableClient() {
  const agreeable = agreeableClient(config);
  agreeable.state.subscribe(state => {
    if (state.serverTimeOffset + '' !== localStorage.timeOffset) {
      localStorage.timeOffset = state.serverTimeOffset;
    }
  });
  observe(() => {
    const uid = uidStore.get();
    const isOpen = agreeable.isOpen.get();
    if (uid && !isOpen) {
      agreeable.open({ uid, appVersion: version, workerUrl: 'local', ...agreeableOptions });
      // agreeable.open({ uid, appVersion: version, workerUrl: '/data/agreeable/agreeable-worker.js' });
    } else if (!uid && isOpen) {
      agreeable.close();
    }
  });
  return agreeable;
}

// TODO set up client to listen for messages from leader, do things like refresh the page if the database was deleted,
// or auto-close, or switch accounts if the user logs out, etc.
