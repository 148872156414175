<script lang="ts">
  import { EditorElement } from '@dabble/data/editables';
  import { docIdStore } from '@dabble/data/ids';
  import { projectStore } from '@dabble/data/project-data';
  import { waitForUpdates } from '@dabble/data/ui.js';
  import { onDestroy, tick } from 'svelte';
  import { Editor, EditorRange, normalizeRange } from 'typewriter-editor';
  import {
    activeCommentIdStore,
    chosenCommentIdStore,
    CommentMap,
    commentsStore,
    selectedCommentIdStore,
    showCommentsStore,
    updateCommentDisplay,
  } from '../comments-store';
  import Comment from './Comment.svelte';
  import { CommentData, getEditorForComment, getPageComments } from './display-helpers';

  export let pageElement: HTMLElement;
  const margin = 16;
  let oldPageElement: Node;
  let commentsElement: HTMLElement;
  let commentList: CommentData[] = [];
  let showGutter: boolean;
  let changingDoc: boolean;
  let document: Document;

  $: onPageElement(pageElement);
  $: if (pageElement && commentsElement) updateComments(true);
  $: onDocId($docIdStore);
  $: onActiveCommentId($activeCommentIdStore);
  $: onCommentsChange($commentsStore);
  $: onListChange(commentList);
  $: height = commentList.length ? getBottom(commentList[commentList.length - 1]) : 0;
  $: if (!$showCommentsStore && $chosenCommentIdStore) {
    const info = commentList.find(c => c.id === $chosenCommentIdStore);
    onRelease(info);
  }

  onDestroy(updateCommentDisplay(updateComments));
  onDestroy(
    projectStore.forceTextUpdate(async () => {
      await tick();
      updateComments(true);
    })
  );

  async function onPageElement(pageElement: Node) {
    if (oldPageElement) {
      document.removeEventListener('selectionchange', onSelectionChange);
      oldPageElement.removeEventListener('editor-change', onEditorChange);
      oldPageElement.removeEventListener('comment-created', onComentCreating);
    }
    if (pageElement) {
      document = pageElement.ownerDocument;
      document.addEventListener('selectionchange', onSelectionChange);
      pageElement.addEventListener('editor-change', onEditorChange);
      pageElement.addEventListener('comment-created', onComentCreating);
    } else {
      commentList = [];
    }
    oldPageElement = pageElement;
  }

  async function onDocId(check: string) {
    changingDoc = true;
    await tick();
    changingDoc = false;
    if (pageElement) {
      await updateComments(true);
    }
  }

  async function onCommentsChange(check: CommentMap) {
    if (!changingDoc && pageElement) {
      updateComments();
    }
  }

  async function onEditorChange({ detail: { change } }: CustomEvent) {
    if (change && change.contentChanged) {
      await updateComments(true);
    }
  }

  function onSelectionChange() {
    const selection = document.getSelection();
    const range = selection.rangeCount && selection.getRangeAt(0);
    if (range) {
      const editable = range.startContainer.parentElement.closest('.typewriter-editor') as EditorElement;
      const editor: Editor = editable && editable.editor;
      if (editor && editor.doc.selection) {
        const [from, to] = editor.doc.selection;
        let [first, last] = normalizeRange([from, to]);
        if (first === last) last++;

        const format = editor.doc.getTextFormat([first, last]);
        if (format.comment) {
          const ids = Object.keys(format.comment).filter(id => format.comment[id]);
          if (ids.length > 1 && from !== to) {
            const source: EditorRange = [from, from + (from < to ? 1 : -1)];
            const format = editor.doc.getTextFormat(source);
            $selectedCommentIdStore = Object.keys(format.comment).pop();
          } else {
            $selectedCommentIdStore = ids.pop();
          }
          if ($chosenCommentIdStore) {
            $chosenCommentIdStore = '';
          }
          return;
        }
      }
    }

    $selectedCommentIdStore = '';
  }

  function onComentCreating() {
    updateComments();
  }

  let gutterTimeout: ReturnType<typeof setTimeout>;
  function onListChange(comments: CommentData[]) {
    clearTimeout(gutterTimeout);
    if (comments.length) {
      if (!showGutter) showGutter = true;
    } else {
      gutterTimeout = setTimeout(() => (showGutter = false), 300);
    }
  }

  // Look at all the comment spans on the page and create add the comments into the commentList, sorted by date created.
  async function updateComments(noTransition?: boolean) {
    if (!commentsElement) return;
    if (noTransition) {
      commentsElement.classList.add('initing');
    }
    commentList = getPageComments(pageElement);
    await tick();
    updatePosition();
    if (noTransition) {
      await waitForUpdates();
      if (commentsElement) {
        commentsElement.offsetHeight;
        commentsElement.classList.remove('initing');
      }
    }
  }

  function updatePosition() {
    if (!commentsElement) return;
    for (const node of commentsElement.children) {
      const { id } = (node as HTMLElement).dataset;
      if (!id) continue;
      const info = commentList.find(info => info.id === id);
      info.height = (node as HTMLElement).offsetHeight + margin;
      info.top = info.origin;
    }

    let top = 0;
    commentList.forEach(info => {
      if (info.id !== $activeCommentIdStore && info.top < top) {
        info.top = top;
      }
      top = getBottom(info);
    });

    if ($activeCommentIdStore) {
      const i = commentList.findIndex(info => info.id === $activeCommentIdStore);
      if (commentList[i]) {
        const active = commentList[i];
        top = active.top = active.origin;
        commentList
          .slice(0, i)
          .reverse()
          .some(info => {
            const bottom = getBottom(info);
            if (bottom >= top) {
              info.top = top - info.height;
              top -= info.height;
            } else {
              // We can break out of the loop once there is space between comments since the rest don't need adjusting.
              return true;
            }
          });
      }
    }

    // Trigger update
    commentList = commentList;
  }

  function getBottom(info: CommentData) {
    return info.top + info.height;
  }

  async function onActiveCommentId(check: string) {
    await tick();
    updatePosition();
  }

  function onRelease(info: CommentData, hasInput?: boolean) {
    $chosenCommentIdStore = '';
    if (!info.comment && !hasInput) {
      const editor = getEditorForComment(pageElement, info.id);
      if (editor) {
        editor.modules.decorations.getDecorator('comments').clear().apply();
        updateComments();
      }
    }
  }

  function onSelect(info: CommentData) {
    $chosenCommentIdStore = info.id;
  }
</script>

<div class="comments focus-fade" bind:this={commentsElement} style="height: {height}px;">
  {#each commentList as info (info.id)}
    <Comment
      {info}
      selected={$activeCommentIdStore === info.id}
      on:select={() => onSelect(info)}
      on:release={event => onRelease(info, event.detail.hasInput)}
    />
  {/each}
</div>

<style>
  .comments {
    position: relative;
    width: 272px;
  }
  .comments:global(.initing .thread) {
    transition: none;
  }
</style>
