<script lang="ts">
  import { t } from '@dabble/data/intl';
  import { Doc } from '@dabble/data/types';
  import { editingModeStore } from '@dabble/data/ui';
  import InlineMenuAddImageButton from '@dabble/plugins/content/components/InlineMenuAddImageButton.svelte';
  import EditableContent from '@dabble/toolkit/EditableContent.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiBookOpenBlankSplitVariant, mdiFileSplitOutline } from '@dabble/toolkit/custom-icons';
  import { tooltipTop } from '@dabble/toolkit/tooltip';
  import { splitChapter, splitScene } from '../split-scene';

  export let doc: Doc;
  export let className: string;
</script>

<EditableContent class={className} {doc} field="body" readonly={$editingModeStore === 1} let:selection let:editor>
  <svelte:fragment>
    <button
      use:tooltipTop={$t('novel_chapter_split_here')}
      class="menu-button icon"
      on:click={() => splitChapter(doc, selection)}
    >
      <Icon path={mdiBookOpenBlankSplitVariant} size="28px" />
    </button>
    <button
      use:tooltipTop={$t('novel_scene_split_here')}
      class="menu-button icon"
      on:click={() => splitScene(doc, selection)}
    >
      <Icon path={mdiFileSplitOutline} />
    </button>
    <InlineMenuAddImageButton {editor} />
  </svelte:fragment>
</EditableContent>
