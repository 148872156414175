<script lang="ts">
  import { featuresStore } from '@dabble/data/global-data';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiNote } from '@mdi/js';
  import { Commands } from 'typewriter-editor';

  // Show stickies whenever highlights are also available
  export let commands: Commands;
  export let section: string;
  // leave these in even though they aren't used to keep warnings from screen.
  export let editor;
  export let active;
  export let placement;
  export let selection;
</script>
{#if $featuresStore.stickies && commands.highlight}
  <button class="editor-menu-sticky" on:click={() => (section = 'sticky')}>
    <Icon path={mdiNote} />
  </button>
{/if}

<style>
  .editor-menu-sticky {
    order: 82;
  }
</style>
