<script context="module" lang="ts">
  import { rest } from '@dabble/data/rest';
  import { writable } from 'easy-signal';

  export function getUnsplashImage(opts: { maxSize?: number } = {}) {
    options = opts;
    openStore.set(true);
    return new Promise<Image | null>((res, rej) => {
      resolve = res;
      reject = rej;
    });
  }

  export interface UnsplashImage {
    id: string;
    created_at: string;
    updated_at: string;
    width: number;
    height: number;
    color: string;
    blur_hash: string;
    description: string;
    alt_description: string;
    user: {
      id: string;
      username: string;
      name: string;
      portfolio_url: string;
      bio: string;
      location: string;
      profile_image: {
        small: string;
        medium: string;
        large: string;
      };
      links: {
        self: string;
        html: string;
        photos: string;
        likes: string;
        portfolio: string;
      };
    };
    urls: {
      raw: string;
      full: string;
      regular: string;
      small: string;
      thumb: string;
    };
    links: {
      self: string;
      html: string;
      download: string;
      download_location: string;
    };
  }

  const openStore = writable(false);
  const selectedStore = writable<UnsplashImage | undefined>(undefined);
  let options: { maxSize?: number } | undefined;
  let resolve: (value: Image | null) => void;
  let reject: (reason: any) => void;

  async function selectImage(image: UnsplashImage) {
    selectedStore.set(image);
    try {
      await markDownload(image);
      resolve(await downloadImage(image));
      close();
    } catch (e) {
      reject(e);
    } finally {
      selectedStore.set(undefined);
    }
  }

  function close() {
    openStore.set(false);
    resolve(null);
  }

  // Let unsplash know we are downloading their image (see: https://unsplash.com/documentation#track-a-photo-download)
  function markDownload(image: UnsplashImage) {
    return rest.post('unsplash/download').send({ link: image.links.download_location });
  }

  async function downloadImage(image: UnsplashImage) {
    const unsplashUrl = image.urls.regular;
    const response = await fetch(unsplashUrl);
    const file = await response.blob();
    const info = await getImagePlaceholder(file);
    const url = await saveFile(file, options.maxSize);
    return { ...info, url } as Image;
  }
</script>

<script lang="ts">
  import { t } from '@dabble/data/intl';
  import { locallyStoredWritable } from '@dabble/data/stores/locally-stored-writable';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import Modal from '@dabble/toolkit/Modal.svelte';
  import { autoselect } from '@dabble/toolkit/autoselect';
  import { mdiImage } from '@mdi/js';
  import { inview } from 'svelte-inview';
  import { saveFile } from '../content';
  import { Image, getImagePlaceholder } from '../images';
  import UnsplashImageTile from './UnsplashImageTile.svelte';

  let galleryNode: HTMLElement;
  const searchHistory = locallyStoredWritable('searchHistory', '');
  let searchString = Array.isArray($searchHistory) ? $searchHistory[0] || '' : $searchHistory || '';

  const TIMEOUT = 10;
  const DEFAULT_SEARCH = 'fantasy';
  let timedOut = false;

  $: loading = true;
  $: noMore = false;
  $: images = [];
  $: pages = 1;
  $: page = 1;

  $: if ($openStore) {
    searchUnsplash();
  }

  async function sendSearch(searchString: string, page: number) {
    let timer = setTimeout(() => {
      timedOut = true;
    }, TIMEOUT * 1000);
    const response = await rest.post('unsplash/search').send({
      searchString,
      page,
      limit: 14,
    });
    timedOut = false;
    clearTimeout(timer);
    return response as { results: UnsplashImage[]; total_pages: number };
  }

  async function searchUnsplash() {
    loading = true;
    noMore = false;
    const search = searchString || DEFAULT_SEARCH;

    if (searchString === '') {
      page = Math.ceil(Math.random() * 20);
    } else {
      $searchHistory = searchString;
    }

    const results = await sendSearch(search, page);
    if (!results) return;

    images = results.results;
    pages = results.total_pages;
    if (page === pages) noMore = true;
    loading = false;
    galleryNode.scrollTop = 0;
  }

  async function loadMoreUnsplash(event: KeyboardEvent) {
    if (event.code !== 'Enter') return;

    if (noMore) return;
    loading = true;
    page++;

    const search = $searchHistory || DEFAULT_SEARCH;
    const response = await sendSearch(search, page);

    if (response) {
      images = [...images, ...response.results];
    }

    if (page === pages) noMore = true;
    loading = false;
  }
</script>

{#if $openStore}
  <Modal title={$t('unsplash_search')} size="large" on:close={close} class="unsplash-search">
    <section class="search-header">
      <form class="section-flex" on:submit|preventDefault={searchUnsplash}>
        <div class="input-group btn-group">
          <input class="form-control" placeholder={$t('unsplash_search')} bind:value={searchString} use:autoselect />
          <button class="btn primary input-group-append" type="submit">Search</button>
        </div>
      </form>
      {#if timedOut}
        <div class="alert danger">{$t('unsplash_timeout')}</div>
      {/if}
      <div class="history_site">
        <small>{$t('photos_at')} <a href="https://unsplash.com" target="_blank" rel="noreferrer">Unsplash</a></small>
      </div>
    </section>
    <section class="image-gallery" bind:this={galleryNode}>
      <div class="grid">
        {#each images as image}
          <UnsplashImageTile {image} selected={$selectedStore} on:select={() => selectImage(image)} />
        {/each}
        {#if loading}
          {#each Array(4) as _, n}
            <div class="tile">
              <div class="skeleton-image loading-placeholder">
                <figure>
                  <Icon path={mdiImage} />
                  <figcaption>&nbsp;</figcaption>
                </figure>
              </div>
            </div>
          {/each}
        {/if}
      </div>
      {#if !noMore}
        <button use:inview on:keydown={loadMoreUnsplash} class="load-more">
          {$t('load_more')}
        </button>
      {:else}
        <div class="no-more">
          {$t('no_more_results')}
        </div>
      {/if}
    </section>
  </Modal>
{/if}

<style>
  :global(.unsplash-search) {
    position: relative;
  }
  .history_site {
    display: flex;
  }
  .image-gallery {
    padding-top: 0;
    height: 500px;
    overflow-y: scroll;
  }
  .grid {
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }
  .grid::after {
    content: '';
    flex-grow: 500;
  }
  .no-more {
    text-align: center;
    color: var(--dabble-gray);
  }
  .load-more {
    width: 100%;
    background: var(--dabble-light-gray);
    color: var(--dabble-gray);
    text-align: center;
    padding: 1rem;
    margin-top: 1rem;
    border-radius: 5px;
  }
  .tile {
    padding: 1rem;
    border-radius: 5px;
  }
  .tile:hover {
    background: var(--dabble-light-teal);
    cursor: pointer;
  }

  .tile figure {
    margin: 0;
    padding: 0;
  }
  .tile figure figcaption {
    text-align: center;
    font-size: 0.8rem;
  }

  .skeleton-image {
    width: 100%;
    height: 10rem;
    font-size: 10rem;
    border: 1px solid var(--dabble-gray);
    color: var(--dabble-gray);
    border-radius: 5px;
  }
  .tile:nth-child(2n + 1) .skeleton-image {
    height: 15rem;
  }
  .skeleton-image figure {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .section-flex {
    display: flex;
  }
  .section-flex > :not(:first-child) {
    margin-left: 40px;
  }
  :global(.size-mobile) .section-flex {
    flex-direction: column;
  }
  :global(.size-mobile) .section-flex > :not(:first-child) {
    margin-left: 0;
    margin-top: 40px;
  }
</style>
