<script lang="ts">
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { mdiLoading } from '@mdi/js';
  import { exportingStore } from '../exports';
</script>

{#if $exportingStore}
  <div class="status">
    <Icon path={mdiLoading} spin inline />
    <span class="message">Exporting...</span>
  </div>
{/if}

<style>
  .status {
    display: flex;
    align-items: center;
    padding: 0 4px;
  }
  .message {
    margin-left: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
