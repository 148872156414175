import {
  AgreeableDoc,
  AsJson,
  Change,
  DataAccess,
  DataType,
  JSONObject,
  JSONPatch,
  JSONPatchOp,
} from 'agreeable-client';
import { Readable, derived, readable } from 'easy-signal';
import { DeepPartial } from '../types';

// Useful types for working with Agreeable stores
export type Updater<T> = (patch: JSONPatch, doc: T | null) => any;

export type DocType<T> = T extends AgreeableDoc<infer U, DataType, boolean, DataAccess, any> ? U : never;
export type WritableDoc = AgreeableDoc<AsJson, DataType, boolean, 'role', any>;

/**
 * Returns an AgreeableDoc that is an object within an AgreeableDoc's value but allows it to be worked with as if it
 * its own AgreeableDoc.
 *
 * @param path the path to the data starting with '/user' or '/project', e.g. 'user' or '/user/account' or '/project/stats'
 */
export function createAgreeablePrefixedStore<
  K extends string & keyof T,
  T extends unknown = JSONObject,
  DT extends DataType = DataType,
  V extends boolean = boolean,
  DA extends DataAccess = DataAccess,
>(store: AgreeableDoc<T, DT, V, DA>, key: K | Readable<K>, defaultValue = {} as T): AgreeableDoc<T[K], DT, false, DA> {
  if (typeof key === 'string') key = readable(key);

  const { get, subscribe } = derived(
    () => ((key.get() && store.get() && pluck(store.get(), [key.get()])) || defaultValue) as T
  );

  return {
    ...store,
    get,
    subscribe,
    patch(patch: JSONPatch | JSONPatchOp[], pathPrefix = ''): Promise<Change[]> {
      if (pathPrefix && pathPrefix[0] !== '/') pathPrefix = `/${pathPrefix}`;
      return (store as WritableDoc).patch(patch, `/${key.get()}${pathPrefix}`);
    },
    async update(updater: Updater<T> | DeepPartial<T>, pathPrefix = ''): Promise<Change[]> {
      if (pathPrefix && pathPrefix[0] !== '/') pathPrefix = `/${pathPrefix}`;
      return (store as WritableDoc).update(updater, `/${key.get()}${pathPrefix}`);
    },
  } as AgreeableDoc<T[K], DT, false, DA>;
}

/**
 * Plucks the value from an object given an array of keys. Example:
 * pluck({ a: { b: { c: 1 } } }, ['a', 'b', 'c']) => 1
 */
function pluck(object: any, keys: string[]) {
  for (const key of keys) {
    if (!object[key]) return null;
    object = object[key];
  }
  return object;
}
