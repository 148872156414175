<script lang="ts">
  import { projectIdStore } from '@dabble/data/ids';
  import { dateOptions, t } from '@dabble/data/intl';
  import { projectMetaSettingsStore, projectRolesStore } from '@dabble/data/project-data';
  import { rest } from '@dabble/data/rest';
  import { User } from '@dabble/data/types';
  import { isOnlineStore } from '@dabble/data/ui';
  import { currentUserStore } from '@dabble/plugins/account/accounts';
  import { editor } from '@dabble/plugins/export/utils';
  import Alert from '@dabble/toolkit/Alert.svelte';
  import CheckButton from '@dabble/toolkit/CheckButton.svelte';
  import Dropdown from '@dabble/toolkit/Dropdown.svelte';
  import { inform } from '@dabble/toolkit/Globals.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { escKey } from '@dabble/toolkit/events';
  import { focus } from '@dabble/toolkit/focus';
  import { slide } from '@dabble/toolkit/transitions';
  import { mdiAccount, mdiClose, mdiContentCopy, mdiDelete, mdiDotsVertical } from '@mdi/js';
  import { Role, UserAccess } from 'agreeable-client';
  import { format } from 'date-fns';
  import { getUserData } from '../collab-stores';

  let showExplainer: boolean;
  let menuOpen: string;
  let inviteAuthors: boolean;
  let authorEmail: string;
  let invalid: { [x: string]: string; email?: any } = null;
  let form: HTMLFormElement;
  let submitted: boolean;
  let error: any;
  let trackAuthorChanges: boolean;
  let authors: Record<string, User & UserAccess>;

  const MAX_COLLABORATORS = 5;

  const sortOrder = {
    owner: -1,
    author: 1,
  };
  function toggleRoles(role: string) {
    if (role === 'owner') {
      return 'write';
    } else {
      return 'owner';
    }
  }

  $: connected = $isOnlineStore;

  let users: Record<string, UserAccess>;
  let role: string[] = ['owner', 'write', 'edit', 'comment', 'view'];
  let update = 0;

  $: projectSettingsStore = $projectMetaSettingsStore;
  $: uid = $currentUserStore?.uid;
  $: update = 0;
  $: users = ($projectRolesStore?.users as Record<string, UserAccess>) || {};
  $: authors = users && update >= 0 ? getAuthors() : {};
  $: isOwner = users && users[uid] ? users[uid].role === 'owner' : true;
  $: canInvite = isOwner || (users[uid].role === 'write' && !$projectRolesStore.editorsCannotShare);
  $: multipleOwners = Object.values(users).filter(user => user.role === 'owner').length > 1;
  $: disconnected = !connected;
  $: totalCollaborators =
    (users && Object.keys(users).length) +
      ($projectRolesStore.invites && Object.keys($projectRolesStore.invites).length) || 0;
  $: fullProject = totalCollaborators >= MAX_COLLABORATORS;
  $: trackAuthorChanges = projectSettingsStore?.trackAuthorChanges ?? false;

  async function setTrackAuthorChanges() {
    if (!isOwner) return;

    trackAuthorChanges = !trackAuthorChanges;
    await projectMetaSettingsStore.update({ ...projectSettingsStore, trackAuthorChanges });

    inform('success', $t('collab_track_changes_success'));
  }

  const cache = new Map<string, User & UserAccess>();

  function getAuthors() {
    authors = {};
    Object.keys(users).forEach(async authorId => {
      if (cache.has(authorId)) {
        authors[authorId] = cache.get(authorId);
      } else {
        const author = getUserData(authorId).then(author => {
          const completeAuthor = { ...author, email: author.email || '', role: users[authorId].role };
          cache.set(authorId, completeAuthor);
          update++;
          editor.render;
        });
        authors[authorId] = { uid: authorId, email: '', ...author, name: 'loading...', role: users[authorId].role };
      }
    });
    return authors;
  }

  async function inviteByEmail() {
    if (!$isOnlineStore) {
      error = $t('DISCONNECTED');
      return;
    }
    form.checkValidity();
    if (!form.checkValidity()) {
      invalid = {};
      const elements = form.elements;
      for (let i = 0; i < elements.length; i++) {
        let elem = elements[i];
        if ((elem as HTMLInputElement).validationMessage)
          invalid[elem.id || (elem as HTMLInputElement).name] = (elem as HTMLInputElement).validationMessage;
      }
      return;
    } else {
      invalid = null;
    }

    if ($currentUserStore?.email.toLocaleLowerCase() === authorEmail.toLocaleLowerCase()) {
      error = $t('collab_invite_self');
      return;
    }

    submitted = true;
    error = null;

    const email = authorEmail.trim();

    submitted = true;
    error = null;

    // NOT a candidate for agreeable because the rest call will send the email.
    const role = 'write';
    const res = await rest.post(`/projects/${projectIdStore.get()}/roles/invites`).send({ email, role });
    if (res.ok) {
      inform('success', $t('collab_invite_sent'));
    } else {
      inform('danger', $t('collab_invite_error'));
    }
    submitted = false;
    authorEmail = '';
    inviteAuthors = false;
  }

  async function deleteInvite(inviteId: string) {
    if (!$isOnlineStore) {
      error = $t('DISCONNECTED');
      return;
    }

    await projectRolesStore.update(patch => {
      patch.remove(`/invites/${inviteId}`);
    });

    inform('success', $t('collab_invite_deleted'));
  }

  async function changeRole(userId: string, role: Role) {
    if (!$isOnlineStore) {
      error = $t('DISCONNECTED');
      return;
    }
    await projectRolesStore.update(patch => {
      patch.replace(`/users/${userId}/role`, role);
    });

    cache.clear();
    getAuthors();
    inform('success', $t('collab_role_changed'));
  }

  async function deleteCoauthor(userId: string) {
    if (!$isOnlineStore) {
      error = $t('DISCONNECTED');
      return;
    }
    // TODO: candidate for agreeable.
    await rest.delete(`/projects/${projectIdStore.get()}/roles/users/${userId}`).send();
    inform('success', $t('collab_author_deleted'));
  }

  function copyLink(inviteId: any) {
    navigator.clipboard.writeText(`https://app.dabblewriter.com/?invite=${inviteId}`);
    inform('info', $t('collab_invite_link_copied'));
  }
</script>

<div class="section">
  <h2 class="header-with-link">
    {$t('collab_header')}
    <button class="btn link" on:click={() => (showExplainer = !showExplainer)}>What is co-authoring?</button>
  </h2>
  {#if showExplainer}
    <p transition:slide={{ duration: 150 }}>{$t('collab_explainer', { limit: MAX_COLLABORATORS })}</p>
  {/if}

  <h4>{$t('collab_authors')}</h4>
  <table class="bordered table">
    <tr>
      <th>{$t('collab_author_name')}</th>
      <th>{$t('collab_author_role')}</th>
    </tr>
    {#each Object.keys(authors) as authorId}
      <tr class:me={$currentUserStore?.uid === authorId}>
        <td>
          <span class="name" class:unloaded={!authors[authorId]}>
            {authors[authorId] ? authors[authorId].name : $t('collab_author_unloaded')}
            {$currentUserStore?.uid === authorId ? $t('collab_author_me') : ''}
          </span>
        </td>
        <td>
          <span class="role">{$t('collab_type_' + authors[authorId].role)}</span>
          {#if isOwner}
            <div class="actions">
              <button class="icon menu-opener" on:click={() => (menuOpen = authorId)}>
                <Icon path={mdiDotsVertical} />
              </button>
              {#if menuOpen === authorId}
                <Dropdown placement="left-start" arrow on:close={() => (menuOpen = null)}>
                  <button
                    class="dropdown-item"
                    on:click={() => changeRole(authorId, toggleRoles($projectRolesStore.users[authorId].role))}
                    disabled={(authorId === uid && !multipleOwners) ||
                      ($projectRolesStore.users[authorId].role === 'owner' && authorId !== uid) ||
                      disconnected}
                  >
                    <Icon path={mdiAccount} />
                    {$t('collab_set_role', {
                      role: $t('collab_type_' + toggleRoles($projectRolesStore.users[authorId].role)),
                    })}
                  </button>
                  <button
                    class="dropdown-item"
                    on:click={() => deleteCoauthor(authorId)}
                    disabled={(authorId === uid && !multipleOwners) ||
                      ($projectRolesStore.users[authorId].role === 'owner' && authorId !== uid) ||
                      disconnected}
                  >
                    <Icon path={mdiDelete} />
                    {$t('collab_remove', { role: $t('collab_type_' + $projectRolesStore.users[authorId].role) })}
                  </button>
                </Dropdown>
              {/if}
            </div>
          {/if}
        </td>
      </tr>
    {/each}
  </table>

  <div class="track-preference">
    <CheckButton
      checked={trackAuthorChanges}
      on:click={setTrackAuthorChanges}
      disabled={$projectRolesStore.users && $projectRolesStore.users[uid]
        ? $projectRolesStore.users[uid].role !== 'owner'
        : false}
    >
      {$t('collab_track_changes_button')}
    </CheckButton>
    <p class="note">{$t('collab_track_changes_button_description')}</p>
  </div>

  <h4>{$t('collab_invites')}</h4>
  {#if error}
    <Alert type="danger" dismissible on:close={() => (error = null)}>
      <strong>{$t('error')}:</strong>
      {$t(error)}
    </Alert>
  {/if}
  <table class="bordered table">
    <tr>
      <th>{$t('collab_invite_email')}</th>
      <th>{$t('collab_invite_status')}</th>
    </tr>
    {#if $projectRolesStore.invites && Object.keys($projectRolesStore.invites).length}
      {#each Object.keys($projectRolesStore.invites) as inviteId}
        <tr>
          <td>
            <div class="invite-info">
              <span class="name">{$projectRolesStore.invites[inviteId].to?.email}</span>
              <span class="role">{$t('collab_type_' + $projectRolesStore.invites[inviteId].to?.role)}</span>
              <span class="date"
                >{format(new Date($projectRolesStore.invites[inviteId].created), 'PPP', dateOptions)}</span
              >
            </div>
          </td>
          <td>
            <div class="actions">
              <button
                class="icon menu-opener"
                on:click={() => (menuOpen = $projectRolesStore.invites[inviteId].to?.email)}
              >
                <Icon path={mdiDotsVertical} />
              </button>
              {#if menuOpen === $projectRolesStore.invites[inviteId].to?.email}
                <Dropdown placement="left-start" arrow on:close={() => (menuOpen = null)}>
                  <button class="dropdown-item" on:click={() => copyLink(inviteId)}>
                    <Icon path={mdiContentCopy} />
                    {$t('collab_invite_link_copy')}
                  </button>
                  <hr />
                  <button
                    class="dropdown-item"
                    disabled={!isOwner || disconnected}
                    on:click={() => deleteInvite(inviteId)}
                  >
                    <Icon path={mdiAccount} />
                    {$t('collab_invite_delete')}
                  </button>
                </Dropdown>
              {/if}
            </div>
          </td>
        </tr>
      {/each}
    {:else}
      <tr>
        <td colspan="2">{$t('No invitations sent.')}</td>
      </tr>
    {/if}

    {#if inviteAuthors}
      <tr>
        <td colspan="2" class="invite-row">
          <form bind:this={form} class="form" on:submit|preventDefault={inviteByEmail}>
            <div class="form-group">
              <input
                class:error={invalid && invalid.email}
                bind:value={authorEmail}
                type="email"
                name="email"
                required
                class="form-control"
                placeholder={$t('collab_author_email')}
                autocomplete="off"
                use:escKey={() => (inviteAuthors = false)}
                use:focus
              />
              <button class="btn primary" type="submit" disabled={submitted || disconnected || fullProject}
                >{$t('collab_invite')}</button
              >
              <button class="icon" type="button">
                <Icon path={mdiClose} />
              </button>
            </div>

            {#if invalid && invalid.email}
              <div class="error-message">{'something is wrong: ' + invalid.email}</div>
            {/if}
          </form>
        </td>
      </tr>
    {/if}
  </table>

  <div>
    <button
      class="btn primary"
      disabled={!canInvite || disconnected || fullProject}
      on:click={() => (inviteAuthors = true)}>{$t('collab_invite_author')}</button
    >
    {#if !canInvite}
      <span class="note">{$t('collab_only_author_invites')}</span>
    {/if}
    {#if canInvite && fullProject}
      <span class="note">{$t('collab_full_project', { limit: MAX_COLLABORATORS })}</span>
    {/if}
  </div>

  {#if disconnected}
    <span class="offline-indicator badge danger">{$t(connected ? 'account_disconnected' : 'account_offline')}</span>
  {/if}
</div>

<style>
  .track-preference {
    display: flex;
    align-items: center;
  }
  .track-preference .note {
    margin-left: 7px;
  }
  .header-with-link {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  .header-with-link button {
    font-size: var(--font-size-sm);
  }
  .me {
    font-weight: bold;
  }
  tr.me td {
    background: var(--gray-lightest);
  }
  .actions {
    float: right;
  }
  .unloaded {
    font-size: var(--font-size-xs);
    color: var(--text-color-lighter);
    text-transform: uppercase;
    font-style: italic;
  }
  .unloaded::before {
    content: '[';
    font-style: normal;
  }
  .unloaded::after {
    content: ']';
    font-style: normal;
  }
  .invite-row .form-group {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .invite-info {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  .invite-info .role {
    font-size: var(--font-size-xs);
    color: var(--text-color-lighterer);
  }
  .invite-info .date {
    font-size: var(--font-size-xs);
    color: var(--text-color-lighterer);
  }
  @media screen {
    html[data-theme='dark'] table.table th,
    html[data-theme='dark'] table.table td {
      color: var(--gray);
    }
  }
</style>
