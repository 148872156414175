<script lang="ts">
  import { signals } from '@dabble/data/app-state';
  import { todaysDateStore } from '@dabble/data/date';
  import { appHeaderStore, appUpdateStore } from '@dabble/data/ui';
  import { onDestroy, onMount } from 'svelte';
  import RecentProjects from './RecentProjects.svelte';

  $: year = $todaysDateStore.split('-')[0];

  signals.onScreenChange('Home');

  onMount(() => ($appHeaderStore = { ...$appHeaderStore, hideLogo: true }));
  onDestroy(() => ($appHeaderStore = { ...$appHeaderStore, hideLogo: false }));
</script>

<svelte:head>
  <title>Dabble</title>
</svelte:head>

<div class="projects-screen">
  <header>
    <div class="logo">Dabble</div>
  </header>
  <section class="project-list">
    <RecentProjects />
  </section>
  <footer>
    <div class="site-copyright">
      © {year} Dabble Writer, Inc.
      <br />Web v{$appUpdateStore.version}
      {#if $appUpdateStore.desktopVersion}
        <br />Desktop v{$appUpdateStore.desktopVersion}
      {/if}
    </div>
  </footer>
</div>

<style>
  .projects-screen {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    flex: 1 1 100px;
    width: 100%;
  }
  header {
    background-color: var(--home-background);
    cursor: default;
    outline: 3px solid var(--home-background);
    outline-offset: 2px;
    padding-top: 10px;
    height: 140px;
    margin-bottom: 3px;
  }
  .logo {
    background: url(../../images/Dabble-Color-Logotype-Tagline-Padded.png) no-repeat center;
    background-size: contain;
    height: 105px;
    box-sizing: content-box;
    overflow: hidden;
    text-indent: -400px;
    margin: 0 auto;
  }
  footer {
    clear: left;
    display: block;
    color: var(--text-color-lighterer);
    text-align: center;
    padding-top: 20px;
    box-shadow: var(--horizontal-bevel-top);
    font-size: 13px;
    text-shadow: var(--text-highlight);
    margin: 16px auto;
  }

  .project-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow:
      inset 0 5px 4px rgba(0, 0, 0, 0.1),
      inset 0 13px 12px rgba(0, 0, 0, 0.05);
  }

  /*
.site-links {
  font-size: 13px;
}
.site-links a {
  color: inherit;
  text-decoration: none;
  margin-right: 20px;
}
.site-links a:hover {
  color: var(--text-color-lighter);
}*/
  .site-copyright {
    margin-top: 20px;
  }

  :global(.holidays) header {
    /* background: url(../../images/happy-holidays.svg) no-repeat 40px 10px, linear-gradient(to bottom, #ccc, #f9f9f9); */
    /* background: url(../../images/merry-christmas.png) no-repeat bottom 10px right 20px, linear-gradient(to bottom, #D50002, #7B0103); */
    background:
      url(../../images/happy-holidays.png) no-repeat bottom 10px right 20px,
      linear-gradient(to bottom, #d50002, #7b0103);
    background-size:
      auto 40%,
      auto;
    border-bottom: 1px solid #fff;
    outline: 4px solid #7b0103;
  }
  :global([data-theme='dark'] .holidays) header {
    filter: brightness(0.7);
  }
  :global(.holidays) .logo {
    background: url(../../images/Dabble-White-Logotype-Tagline-Padded.png) no-repeat center;
    background-size: contain;
    filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
  }
  :global(.holidays) .projects-screen {
    background: radial-gradient(closest-side, #eee, transparent) no-repeat;
    background-size: 1800px 600px;
    background-position: 50% -40px;
  }
  :global([data-theme='dark'] .holidays) .projects-screen {
    background: radial-gradient(closest-side, #555, transparent) no-repeat;
    background-size: 1800px 600px;
    background-position: 50% -40px;
    background-attachment: local;
  }
  @media (max-width: 688px) {
    footer {
      width: auto;
      margin: 16px 8px;
    }
  }
  @media (max-width: 360px) {
    footer {
      width: auto;
      margin: 16px 0;
    }
  }
  @media (max-width: 600px) {
    :global(.holidays) .logo {
      background-position: left 16px center;
    }
    :global(.holidays) header {
      background-size:
        auto 30%,
        auto;
    }
  }
</style>
