{
  "comments": "Comentarios",
  "comments_accept": "Marcar como resuelto",
  "comments_comment": "Comentario",
  "comments_comment_placeholder": "Comentario",
  "comments_delete_reply": "¿Eliminar este comentario?",
  "comments_delete_thread": "¿Eliminar este hilo?",
  "comments_edit_comment": "Actualizar",
  "comments_edit_reply": "Actualizar",
  "comments_edit_comment_placeholder": "Edita tu comentario",
  "comments_edit_reply_placeholder": "Edita tu respuesta",
  "comments_hide": "Ocultar comentarios",
  "comments_history": "Historial de Comentarios",
  "comments_history_open": "Historial de Comentarios",
  "comments_none": "Todavía no hay comentarios",
  "comments_open": "Abrir comentarios",
  "comments_reopen": "Reabrir",
  "comments_reply": "Respuesta",
  "comments_reply_placeholder": "Respuesta",
  "comments_resolved": "Comentarios Resueltos",
  "comments_show": "Mostrar Comentarios",
  "comments_show_all_replies": "Mostrar todas las {{count}} respuestas"
}