import { docIdStore } from '@dabble/data/ids';
import { plugins } from '@dabble/data/plugins';
import { projectStore } from '@dabble/data/project-data';
import { settingsStore } from '@dabble/data/settings';
import { isProduction } from '@dabble/version';
import { Delta, PasteEvent } from 'typewriter-editor';
import { delegatesStore } from '../account/accounts';
import './comments-format';
import './comments.css';
import CommentBubbleMenu from './components/CommentBubbleMenu.svelte';
import CommentHistory from './components/CommentHistory.svelte';
import Comments from './components/CommentsGutter.svelte';
import CommentsModal from './components/CommentsModal.svelte';
import CommentsSidebarButton from './components/CommentsSidebarButton.svelte';
import CommentsToggle from './components/CommentsToggle.svelte';
import './locales';

settingsStore.configure('dabble', {
  globals: { CommentsModal },
  pageGutterRight: { Comments },
  textBubbleMenu: { CommentBubbleMenu },
  sidebarButtons: { CommentsSidebarButton },
  toolButtonsTop: { CommentsToggle },
  sidebar: {
    comment_history: { CommentHistory },
  },
  editorTypes: {
    comments: {
      formats: ['comment'],
    },
  },
});

settingsStore.configure('novel_scene', {
  onPasteSanitize: { comments: removeOtherCommentsOnPaste },
});

settingsStore.configure('novel_page', {
  onPasteSanitize: { comments: removeOtherCommentsOnPaste },
});

function removeOtherCommentsOnPaste(event: PasteEvent) {
  if (hasComment(event.html.toString())) {
    event = updateCommentDeltas(event);
  }

  return event;
}

function hasComment(htmlString: string) {
  return htmlString.includes('<format-comment');
}

function updateCommentDeltas(event: PasteEvent) {
  const allComments = plugins.stores.comments.get();
  for (const i in event.delta.ops) {
    const op = event.delta.ops[i] as any;
    if (op.attributes && op.attributes.comment) {
      const comments = Object.keys(op.attributes.comment);
      comments.forEach(commentId => {
        if (!allComments[commentId] || allComments[commentId].docId !== docIdStore.get()) {
          delete event.delta.ops[i].attributes.comment[commentId];
        }
      });
      if (
        op.attributes.comment.attributes &&
        op.attributes.comment.attributes.comment &&
        op.attributes.comment.attributes.comment.length === 0
      ) {
        delete event.delta.ops[i].attributes.comment;
      }
    }
  }
  return event;
}

if (delegatesStore.get() || !isProduction) {
  (window as any).fixComment = function (docId: string, commentId: string) {
    const doc = projectStore.getDoc(docId);
    console.log(doc.body.lines);
    let beforeCount = 0;
    let found = false;
    let commentLength: number;

    for (const i in doc.body.lines) {
      const line = doc.body.lines[i];
      for (const j in line.content.ops) {
        const op = line.content.ops[j];
        if (!found) beforeCount += op.insert.length;

        if (op.attributes?.comment?.[commentId]) {
          found = true;
          commentLength = op.insert.length;
        }
      }
    }
    const delta = new Delta();
    delta.retain(beforeCount).retain(commentLength, { comment: { [commentId]: null } });

    const patch = projectStore.patch();
    patch.changeText(docId, 'body', delta);
    patch.save();
  };
}
