<script lang="ts">
  import { settingsStore } from '@dabble/data/settings';
  import { Doc } from '@dabble/data/types';
  import { editingModeStore } from '@dabble/data/ui';
  import EditableContent from '@dabble/toolkit/EditableContent.svelte';
  import { droppable } from '../plugins/content/droppable';
  import { imageDropOptions } from '../plugins/content/imageDrop';

  export let doc: Doc = undefined;
  export let titleless = false;
  export let frameless = false;
  export let margin = true;
  let pageReadonly = undefined;
  export { pageReadonly as readonly };
  let className = '';
  export { className as class };
  let pageElement: HTMLElement;

  $: readonly = pageReadonly || $editingModeStore === 1;
</script>

<div class="page-wrapper" use:droppable={imageDropOptions}>
  {#each settingsStore.getValuesFromPlugins('pageGutterLeft') as component}
    <svelte:component this={component} {pageElement} />
  {/each}

  <div class="page {className}" class:frameless class:margin bind:this={pageElement}>
    <slot>
      {#if !titleless}
        <EditableContent {doc} field="title" header="h1" {readonly} />
      {/if}
      <EditableContent {doc} field="body" {readonly} let:commands let:active let:selection let:focus let:editor>
        <svelte:fragment>
          <slot {commands} {active} {selection} {focus} {editor} />
        </svelte:fragment>
      </EditableContent>
    </slot>
  </div>

  {#each settingsStore.getValuesFromPlugins('pageGutterRight') as component}
    <svelte:component this={component} {pageElement} />
  {/each}
</div>

<style>
  .page-wrapper {
    display: flex;
    justify-content: center;
    padding: 8px;
    contain: layout;
  }
  .page {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    max-width: var(--page-width); /* 8.5 inches */
    background-color: var(--page-background);
    box-shadow: var(--page-shadow);
    font-family: var(--font-family-text);
    font-size: var(--font-size); /* 12pt */
    border-radius: 2px;
    overflow-wrap: break-word;
    word-break: break-word;
    contain: layout;
  }
  .page.frameless {
    box-shadow: none;
    /* padding: 48px 48px 96px;
  max-width: 720px; */
    margin-top: 0;
    margin-bottom: 0;
    min-height: 0;
  }
  .margin {
    padding: var(--page-padding); /* 1 inch, 1/4 inch minimum */
    min-height: var(--page-height); /* 11 inches */
  }
  .page :global(a) {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    hyphens: auto;
    contain: strict;
  }
  .page :global(.typewriter-editor) {
    margin-left: calc(-1 * var(--page-padding));
    margin-right: calc(-1 * var(--page-padding));
    padding-left: var(--page-padding);
    padding-right: var(--page-padding);
  }
  .page > :global(.editable-content:first-of-type .typewriter-editor) {
    margin-top: calc(-1 * var(--page-padding));
    padding-top: var(--page-padding);
  }
  .page > :global(.editable-content:last-of-type .typewriter-editor) {
    margin-bottom: calc(-1 * var(--page-padding));
    padding-bottom: var(--page-padding);
  }
  .page > :global(.editable-content.fill:last-of-type) {
    flex-grow: 1;
  }
</style>
