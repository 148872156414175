<script lang="ts">
  import { agreeable } from '@dabble/data/agreeable';
  import { unload } from '@dabble/data/app-state';
  import { t } from '@dabble/data/intl';
  import { confirm, inform } from '@dabble/data/ui';
  const hasServiceWorkers = 'serviceWorker' in navigator;

  async function clearCache() {
    const registrations = await navigator.serviceWorker.getRegistrations();
    for (const registration of registrations) {
      await registration.unregister();
    }
    const keys = (await caches.keys()).filter(key => key.startsWith('webpack-offline:dabble-'));
    for (const key of keys) {
      await caches.delete(key);
    }

    location.reload();
  }

  async function clearImageCache() {
    await caches.delete('content');
    inform('success', $t('app_cache_images_cleared'));
  }

  async function deleteDatabase() {
    if (await agreeable.hasUncommittedChanges()) {
      const stop = !(await confirm($t('confirm_header_unsynced_data'), $t('confirm_unsynced_data_delete_database'), {
        yesNo: true,
      }));
      if (stop) return;
    }

    unload(true);
    location.reload();
  }
</script>

<div class="section data">
  <h2>{$t('app_cache')}</h2>

  {#if hasServiceWorkers}
    <div class="preference">
      <button type="button" class="btn primary" on:click={clearCache}>{$t('app_cache_clear')}</button>
      <div class="description">{$t('app_cache_clear_description')}</div>
    </div>

    <div class="preference">
      <button type="button" class="btn primary" on:click={clearImageCache}>{$t('app_cache_image_clear')}</button>
      <div class="description">{$t('app_cache_image_clear_description')}</div>
    </div>
  {/if}

  <div class="preference">
    <button type="button" class="btn primary" on:click={deleteDatabase}>{$t('app_db_delete')}</button>
    <div class="description">{$t('app_db_delete_description')}</div>
  </div>
</div>
