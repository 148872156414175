<script lang="ts">
  import { t } from '@dabble/data/intl';
  import { projectSharedStore } from '@dabble/data/project-data';
  import { rest } from '@dabble/data/rest';
  import { ShareData } from '@dabble/data/types';
  import Dropdown from '@dabble/toolkit/Dropdown.svelte';
  import Icon from '@dabble/toolkit/Icon.svelte';
  import { fade } from '@dabble/toolkit/transitions';
  import { mdiDelete, mdiDotsVertical, mdiEmail, mdiOpenInNew } from '@mdi/js';
  import { format, fromUnixTime } from 'date-fns';
  import { emailShared } from '../html';

  let shared: Record<string, ShareData>;
  let menuOpen: string;

  $: shared = $projectSharedStore;

  function getDocType(type: string) {
    return $t(`shared_${type}`);
  }

  function createPath(shareId: string) {
    return `https://share.dabblewriter.com/static/${shareId}`;
  }

  function openSharedDocument(shareId: string) {
    window.open(createPath(shareId), '_blank');
  }

  async function removeDocument(shareId: string) {
    await rest.delete(`share/${shareId}`).send();
    projectSharedStore.update({ [shareId]: undefined });
  }

  function emailDocument(shareId: string) {
    $emailShared = createPath(shareId);
  }

  function getSorted(shared: Record<string, ShareData>) {
    return Object.keys(shared).sort((a, b) => shared[b].created - shared[a].created);
  }
</script>

<div class="section" id="share-to-web">
  <h2 class="header-with-link">
    {$t('share_to_web_header')}
  </h2>

  <h4>{$t('share_to_web_documents')}</h4>
  <table class="bordered table">
    <tr>
      <th>{$t('shared_title')}</th>
      <th>{$t('shared_type')}</th>
      <th>{$t('shared_created')}</th>
    </tr>
    {#each getSorted(shared) as shareId}
      <tr transition:fade|local>
        <td>{shared[shareId].title}</td>
        <td>{getDocType(shared[shareId].docType)}</td>
        <td width="160">
          <div class="actions">
            <button class="icon menu-opener" on:click={() => (menuOpen = shareId)}>
              <Icon path={mdiDotsVertical} />
            </button>
            {#if menuOpen === shareId}
              <Dropdown placement="left-start" arrow on:close={() => (menuOpen = null)}>
                <button class="dropdown-item" on:click={() => openSharedDocument(shareId)}>
                  <Icon path={mdiOpenInNew} />
                  {$t('shared_view')}
                </button>
                <button class="dropdown-item" on:click={() => emailDocument(shareId)}>
                  <Icon path={mdiEmail} />
                  {$t('shared_send_email')}
                </button>
                <button class="dropdown-item" on:click={() => removeDocument(shareId)}>
                  <Icon path={mdiDelete} />
                  {$t('shared_delete')}
                </button>
              </Dropdown>
            {/if}
          </div>
          <div class="date">{format(fromUnixTime(shared[shareId].created), 'PP')}</div>
          <div class="time">{format(fromUnixTime(shared[shareId].created), 'p')}</div>
        </td>
      </tr>
    {/each}
  </table>
</div>

<style>
  .header-with-link {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  .actions {
    float: right;
  }
  .date {
    font-size: var(--font-size-sm);
    color: var(--text-color-lighter);
  }
  .time {
    font-size: var(--font-size-xs);
    color: var(--text-color-lighterer);
  }
  @media screen {
    html[data-theme='dark'] table.table th,
    html[data-theme='dark'] table.table td {
      color: var(--gray);
    }
  }
</style>
