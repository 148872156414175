{
  "export_count": "How many?",
  "export_failed": "$t(export_file_{{fileType}}) failed.",
  "export_file_word": "Word",
  "export_file_html": "Web",
  "export_file_text": "Text",
  "export_file_google": "Google Docs",
  "export_format_manuscript": "Manuscript Format",
  "export_format_dabble": "Dabble Format",
  "export_images": "Export Images?",
  "export_more": "Export to",
  "export_none": "No export options available for this document type.",
  "export_options": "Options",
  "export_options_limit_none": "Export the entire book",
  "export_options_limit_chapters": "Export a few chapters",
  "export_options_limit_pages": "Export a few pages",
  "export_pages_info": "Exporting pages may result in more pages than requested to avoid breaking mid-scene.",
  "export_short_name_label": "Short Title",
  "export_started": "$t(export_file_{{fileType}}) started.",
  "export_what": "What?",
  "export_settings": "Export Settings",
  "export_telephone": "Your phone # will not be sold nor shared except in Manuscript Exports",
  "export_scene_separator": "Scene Separator",
  "export_short_title": "Short Title (appears in the header of each page)",
  "export_address": "Address",
  "export_phone": "Phone",
  "shared_no_email": "No email address provided."
}
